import {call, select} from 'redux-saga/effects';
import {runUmbrellaUserOnboarding} from './umbrella/umbrella-onboarding.saga';
import {isUserUmbrellaFn} from '../../../utils/user-roles';
import {LoggedInUserSelectors} from '../../user/modules/logged-in-user';

export const runUserOnboarding = function* () {
    const user = yield select(LoggedInUserSelectors.selectLoggedInUser);

    if (isUserUmbrellaFn(user)) {
        yield call(runUmbrellaUserOnboarding);
    }
};
