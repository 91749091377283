import {all, call, delay, put, select, takeLatest} from 'redux-saga/effects';
import {UmbrellaStepperOnboardingActionTypes} from './umbrella-onboarding.action-types';
import {Toast} from '../../../../lib/toast';
import {LoggedInUserSelectors} from '../../../user/modules/logged-in-user';
import {TERM_DOCUMENTS} from '../../utils/onboarding.constants';
import {UmbrellaSteps} from '../../utils/umbrella.steps';
import {MainOnboardingActions} from '../main-onboarding.actions';
// eslint-disable-next-line import/no-cycle
import {MainOnboardingSaga} from '../main-onboarding.saga';
import {MainOnboardingSelectors} from '../main-onboarding.slector';

export const runUmbrellaUserOnboarding = function* () {
    yield call(determineUmbrellaUserOnboardingLocation);
};

export const determineUmbrellaUserOnboardingLocation = function* () {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);
    const onboardingData = yield call(MainOnboardingSaga.getOnboardingFlow, {userId: loggedInUser?.id});

    let stepToLand;
    let subStepToLand;

    const portageMainSteps = Object.values(UmbrellaSteps.PORTAGE_MAIN_STEPS_OBJECT);

    portageMainSteps.forEach(step => {
        const substeps = Object.values(step.subSteps);

        substeps.forEach(subStep => {
            // If already found don't check further
            // TODO This is until we allow free access
            if (stepToLand && subStepToLand) {
                return;
            }

            if (subStep?.shouldLandOnThePage) {
                const shouldShowPage = subStep.shouldLandOnThePage(onboardingData);

                if (shouldShowPage) {
                    stepToLand = step.id;
                    subStepToLand = subStep.id;
                }
            }
        });
    });

    // TODO Set step and substep
    yield delay(1000);

    if (stepToLand && subStepToLand) {
        yield put(MainOnboardingActions.setStep(stepToLand));
        yield put(MainOnboardingActions.setSubStep(subStepToLand));
    } else {
        Toast.error('accessDenied');
    }

    // TODO Setup progress
};


export const umbrellaStepperSaga = {
    goToCGVS: function* () {
        yield put(MainOnboardingActions.setStep(UmbrellaSteps.ALL_PORTAGE_STEPS.MEMBERSHIP_STEP));
        yield put(MainOnboardingActions.setSubStep(UmbrellaSteps.ALL_PORTAGE_SUBSTEPS.CGVS_SIGNING_SCREEN_SUBSTEP));
    },

    refuseSigning: function* () {
        yield put(MainOnboardingActions.setStep(UmbrellaSteps.ALL_PORTAGE_STEPS.MEMBERSHIP_STEP));
        yield put(MainOnboardingActions.setSubStep(UmbrellaSteps.ALL_PORTAGE_SUBSTEPS.USER_REFUSED_DOCUMENTS_SUBSTEP));
    },

    goToADP: function* () {
        yield put(MainOnboardingActions.setStep(UmbrellaSteps.ALL_PORTAGE_STEPS.MEMBERSHIP_STEP));
        yield put(MainOnboardingActions.setSubStep(UmbrellaSteps.ALL_PORTAGE_SUBSTEPS.ADP_SIGNING_SCREEN_SUBSTEP));
    },

    goToCompletedSigning: function* () {
        yield put(MainOnboardingActions.setStep(UmbrellaSteps.ALL_PORTAGE_STEPS.MEMBERSHIP_STEP));
        yield put(MainOnboardingActions.setSubStep(UmbrellaSteps.ALL_PORTAGE_SUBSTEPS.USER_ACCEPTED_DOCUMENTS_SUBSTEP));
    },

    goToPersonalInformation: function* () {
        yield put(MainOnboardingActions.setStep(UmbrellaSteps.ALL_PORTAGE_STEPS.INFORMATION_STEP));
        yield put(MainOnboardingActions.setSubStep(UmbrellaSteps.ALL_PORTAGE_SUBSTEPS.PERSONAL_INFORMATION_SUBSTEP));
    },

    backToSigning: function* () {
        const onboarding = yield select(MainOnboardingSelectors.selectOnboarding);

        if (onboarding.consents[TERM_DOCUMENTS.CGVS]) {
            yield call(umbrellaStepperSaga.goToADP);
        } else {
            yield call(umbrellaStepperSaga.goToCGVS);
        }
    },

    goToPersonalDocuments: function* () {
        yield put(MainOnboardingActions.setStep(UmbrellaSteps.ALL_PORTAGE_STEPS.INFORMATION_STEP));
        yield put(MainOnboardingActions.setSubStep(
            UmbrellaSteps.ALL_PORTAGE_SUBSTEPS.UPLOAD_PERSONAL_DOCUMENTS_SUBSTEP,
        ));
    },

    goToFinalization: function* () {
        yield put(MainOnboardingActions.setStep(UmbrellaSteps.ALL_PORTAGE_STEPS.FINALIZATION_STEP));
        yield put(MainOnboardingActions.setSubStep(
            UmbrellaSteps.ALL_PORTAGE_SUBSTEPS.ROCKET_SUBSTEP,
        ));
    },
};

export const watchUmbrellaStepperSaga = function* () {
    yield all([
        takeLatest(UmbrellaStepperOnboardingActionTypes.GO_TO_CGVS, umbrellaStepperSaga.goToCGVS),
        takeLatest(UmbrellaStepperOnboardingActionTypes.GO_TO_ADP, umbrellaStepperSaga.goToADP),
        takeLatest(UmbrellaStepperOnboardingActionTypes.REFUSE_SIGNING, umbrellaStepperSaga.refuseSigning),
        takeLatest(UmbrellaStepperOnboardingActionTypes.COMPLETED_SIGNING, umbrellaStepperSaga.goToCompletedSigning),
        takeLatest(UmbrellaStepperOnboardingActionTypes.BACK_TO_SIGNING, umbrellaStepperSaga.backToSigning),

        // eslint-disable-next-line max-len
        takeLatest(UmbrellaStepperOnboardingActionTypes.GO_TO_PERSONAL_INFORMATION, umbrellaStepperSaga.goToPersonalInformation),
        // eslint-disable-next-line max-len
        takeLatest(UmbrellaStepperOnboardingActionTypes.GO_TO_PERSONAL_DOCUMENTS, umbrellaStepperSaga.goToPersonalDocuments),
        takeLatest(UmbrellaStepperOnboardingActionTypes.GO_TO_FINALIZATION, umbrellaStepperSaga.goToFinalization),
    ]);
};
