import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import PageHeader from '../../components/headers/PageHeader/PageHeader';
import ProfileChip from '../../components/profile-chip/ProfileChip';
import {ContractInformation} from '../../features/contract-information/components/ContractInformation';
import {JobProfession} from '../../features/job-profession-and-specilties/components/JobProfession';
import {LoggedInUserSelectors} from '../../features/user/modules/logged-in-user';
import ProfileInformation from '../../features/user/modules/logged-in-user/components/ProfileInformation';
import {USER_ROLES, isUserClientFn} from '../../utils/user-roles';

export const MyProfileScreen = () => {
    const user = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const {t} = useTranslation('common');

    const content = useMemo(() => {
        if (!user) {
            return null;
        }

        return (
            <>
                <Box component="div" sx={{mt: {lg: 0, xs: 3}}}>
                    <PageHeader
                        title={t('userMenu.profile')}
                        hasMargin={false}
                    />
                </Box>

                <ProfileChip userId={user.id} />

                <Paper elevation={0} sx={{borderRadius: 4}}>
                    <ProfileInformation user={user} />
                </Paper>

                {user.role === USER_ROLES.FREELANCER && (
                    <>
                        <Paper elevation={0} sx={{borderRadius: 4, margin: theme => theme.spacing(3, 0, 0, 0)}}>
                            <JobProfession />
                        </Paper>
                    </>
                )}

                {isUserClientFn(user) && (
                    <Paper elevation={0} sx={{borderRadius: 4, margin: theme => theme.spacing(3, 0, 0, 0)}}>
                        <ContractInformation />
                    </Paper>
                )}
            </>
        );
    }, [t, user]);

    return (
        <Box sx={{px: {xs: 2, md: 1}}}>
            {content}
        </Box>
    );
};
