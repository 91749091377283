import {Skeleton, Typography} from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';

import {useDataTableCellStyles} from './styles';
import ReactRouterLink from '../../../../components/app/router/ReactRouterLink';
import FormattedNumber from '../FormattedNumber/FormattedNumber';
import NoInput from '../NoInput/NoInput';

const DataTableCell = props => {
    const {
        rowData,
        children,
        isAmount,
        isDate,
        isLoading,
        isCurrency,
        urlResolver,
        formatter,
        dateFormat,
        clickHandler,
        noInputText,
        isJoyTheme,
    } = props;

    const styles = useDataTableCellStyles({isJoyTheme});

    const content = useMemo(() => {
        if (!children) {
            return null;
        }

        if (isAmount) {
            return <FormattedNumber numberStyle={isCurrency ? 'currency' : 'default'} amount={children} />;
        } else if (isDate) {
            let date = moment(children, 'DD/MM/YYYY');

            if (!date.isValid()) {
                date = moment(children);

                if (!date.isValid) {
                    return null;
                }
            }

            return date.format(dateFormat);
        } else if (formatter) {
            return formatter(rowData);
        }

        return children;
    }, [children, dateFormat, formatter, isAmount, isCurrency, isDate, rowData]);

    if (isLoading) {
        return (
            <Skeleton>
                <Typography className={styles.root}>
                    12345
                </Typography>
            </Skeleton>
        );
    }

    if (!content) {
        return <NoInput noInputText={noInputText} />;
    }

    if (urlResolver) {
        return (
            <ReactRouterLink to={urlResolver(rowData)} className={styles.link}>
                {content}
            </ReactRouterLink>
        );
    }

    if (clickHandler) {
        return (
            <Typography
                onClick={() => clickHandler(rowData)}
                className={styles.root}
            >
                {content}
            </Typography>
        );
    }

    return (
        <Typography className={styles.root}>
            {content}
        </Typography>
    );
};

DataTableCell.propTypes = {
    rowData: PropTypes.object,
    isAmount: PropTypes.bool,
    isDate: PropTypes.bool,
    isLoading: PropTypes.bool,
    isCurrency: PropTypes.bool,
    formatter: PropTypes.func,
    urlResolver: PropTypes.func,
    dateFormat: PropTypes.string,
    clickHandler: PropTypes.func,
    noInputText: PropTypes.string,
    isJoyTheme: PropTypes.bool,
};

DataTableCell.defaultProps = {
    rowData: {},
    isAmount: false,
    isDate: false,
    isLoading: false,
    isCurrency: false,
    formatter: null,
    urlResolver: null,
    dateFormat: 'L',
    clickHandler: null,
    noInputText: null,
    isJoyTheme: false,
};

export default React.memo(DataTableCell);
