import {FormLabel, Grid, MenuItem, TextField} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {Controller} from 'react-hook-form';
import {useSelector} from 'react-redux';
import Grow from '../../../../../../components/joy-ui/mui-legacy/Grow';
import {COUNTRIES} from '../../../../../../utils/countries';
import {NATIONALITIES} from '../../../../../../utils/nationalities';
import {REGIONAL_DEPARTMENTS} from '../../../../../../utils/regional-departments';
import {
    usePersonalInformationFormStyles,
} from '../../../../../../v1/components/setup-company/PersonalInformationForm/styles';
import {AnimationsSelectors} from '../../../../../animations/store/animations.selector';
import {FORM_ANIMATION_DELAY} from '../../../../../animations/utils/constants';

const PersonalInformationPlace = ({
    t,
    control,
    errors,
    userLanguage,
    isRegionalDepartmentOfBirthDisabled,
}) => {
    const styles = usePersonalInformationFormStyles();
    const isSubAnimationActive = useSelector(AnimationsSelectors.selectIsSubAnimationActive);

    const nationalityOptions = useMemo(() => {
        return NATIONALITIES.map(nationality => (
            <MenuItem value={nationality.code} key={nationality.code}>
                {nationality.name[userLanguage]}
            </MenuItem>
        ));
    }, [userLanguage]);

    const countryOptions = useMemo(() => {
        return COUNTRIES
            .sort((a, b) => {
                return a.name[userLanguage].localeCompare(b.name[userLanguage], userLanguage);
            })
            .map(country => (
                <MenuItem value={country.code} key={country.code}>
                    {country.name[userLanguage]}
                </MenuItem>
            ));
    }, [userLanguage]);

    const regionalDepartmentOptions = useMemo(() => {
        return REGIONAL_DEPARTMENTS
            .sort((a, b) => a.id.localeCompare(b.id, undefined, {numeric: true, sensitivity: 'base'}))
            .map(regionalDepartment => {
                let name = regionalDepartment.name;

                if (typeof name === 'object' && name !== null) {
                    name = name[userLanguage];
                }

                return (
                    <MenuItem value={regionalDepartment.id} key={regionalDepartment.id}>
                        {`${regionalDepartment.id}${name ? ` (${name})` : ''}`}
                    </MenuItem>
                );
            });
    }, [userLanguage]);

    return (
        <>
            <FormLabel component="legend" className={styles.formLabel}>
                {t('and', {first: t('placeOfBirth'), second: t('nationality')})}
            </FormLabel>

            <Grow
                in={isSubAnimationActive}
                timeout={FORM_ANIMATION_DELAY * 3}
            >
                <Grid container spacing={4}>
                    <Grid item xs={12} md={7}>
                        <Controller
                            name="countryOfBirth"
                            control={control}
                            rules={{required: true}}
                            render={({field}) => {
                                return (
                                    <TextField
                                        {...field}
                                        label={t('countryOfBirth')}
                                        variant="filled"
                                        error={!!errors.countryOfBirth}
                                        helperText={errors.countryOfBirth?.message}
                                        required
                                        fullWidth
                                        select
                                    >
                                        {countryOptions}
                                    </TextField>
                                );
                            }}
                        />
                    </Grid>

                    {/* <Grid item xs={12} md={5}> */}
                    {/*    <CityAutocomplete
                                    label={t('cityOfBirth')}
                                    control={control}
                                     name="cityOfBirth" /> */}
                    {/* </Grid> */}

                    <Grid item xs={12} md={5}>
                        <Controller
                            name="placeOfBirth"
                            control={control}
                            rules={{required: true}}
                            render={({field}) => {
                                return (
                                    <TextField
                                        {...field}
                                        label={t('placeOfBirth')}
                                        variant="filled"
                                        error={!!errors.placeOfBirth}
                                        helperText={errors.placeOfBirth?.message}
                                        required
                                        fullWidth
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>
            </Grow>

            <Grow
                in={isSubAnimationActive}
                timeout={FORM_ANIMATION_DELAY * 4}
            >
                <Grid container spacing={4} sx={{mt: -2}}>
                    <Grid item xs={12} md={7}>
                        <Controller
                            name="postalCode"
                            control={control}
                            render={({field}) => {
                                return (
                                    <TextField
                                        {...field}
                                        label={t('postalCode')}
                                        variant="filled"
                                        error={!!errors.postalCode}
                                        helperText={errors.postalCode?.message}
                                        required
                                        fullWidth
                                    />
                                );
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={5}>
                        <Controller
                            name="regionalDepartmentOfBirth"
                            control={control}
                            rules={{required: true}}
                            render={({field}) => {
                                return (
                                    <TextField
                                        {...field}
                                        label={t('regionalDepartmentOfBirth')}
                                        variant="filled"
                                        error={!!errors.regionalDepartmentOfBirth}
                                        helperText={errors.regionalDepartmentOfBirth?.message}
                                        disabled={isRegionalDepartmentOfBirthDisabled}
                                        required
                                        fullWidth
                                        select
                                    >
                                        {regionalDepartmentOptions}
                                    </TextField>
                                );
                            }}
                        />
                    </Grid>
                </Grid>
            </Grow>

            <Grow
                in={isSubAnimationActive}
                timeout={FORM_ANIMATION_DELAY * 5}
            >
                <Grid container spacing={4} sx={{mt: -2}}>
                    <Grid item xs={12} md={7}>
                        <Controller
                            name="nationality"
                            control={control}
                            rules={{required: true}}
                            render={({field}) => {
                                return (
                                    <TextField
                                        {...field}
                                        label={t('nationality')}
                                        variant="filled"
                                        error={!!errors.nationality}
                                        helperText={errors.nationality?.message}
                                        required
                                        fullWidth
                                        select
                                    >
                                        {nationalityOptions}
                                    </TextField>
                                );
                            }}
                        />
                    </Grid>
                </Grid>
            </Grow>
        </>
    );
};

PersonalInformationPlace.propTypes = {
    t: PropTypes.func.isRequired,
    control: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    userLanguage: PropTypes.string.isRequired,
    isRegionalDepartmentOfBirthDisabled: PropTypes.bool.isRequired,
};

export default PersonalInformationPlace;
