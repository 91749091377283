import {Box} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';
import {useSelector} from 'react-redux';
import workshopPageCare from '../../../../../assets/png/WorkshopPageCare.png';
import workshopPageMobileCare from '../../../../../assets/png/WorkshopPageMobileCare.png';
import workshopInitialMobile from '../../../../../assets/svg/workshop-initial-mobile.svg';
import workshopPage from '../../../../../assets/svg/workshopPage.svg';
import {isUserCareFn} from '../../../../../utils/user-roles';
import {LoggedInUserSelectors} from '../../../../user/modules/logged-in-user';

// TODO This should be done with stepper component
export const WorkshopStepper = ({isFinished}) => {
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const loggedInUser = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const isUserCareRole = isUserCareFn(loggedInUser);

    if (isMobileSize) {
        return (
            <img
                style={{width: '100%'}}
                src={
                isUserCareRole ? workshopPageMobileCare : workshopInitialMobile}
                alt="workshop initial"
            />
        );
    }

    return (
        <Box sx={{width: '100%', height: '100%'}}>
            {!isFinished && (
            <img
                style={{width: '100%'}}
                src={
                isUserCareRole ? workshopPageCare : workshopPage
            }
                alt="Workshop pending"
            />
            )}
            {isFinished && (
            <img
                style={{width: '100%'}}
                src={
                isUserCareRole ? workshopPageCare : workshopPage
            }
                alt="Workshop final"
            />
            )}
        </Box>
    );
};

WorkshopStepper.propTypes = {
    isFinished: PropTypes.bool.isRequired,
};
