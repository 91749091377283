import {createSelector} from 'reselect';
import {
    createDataTableSearchCallback,
    createDataTableSortCallback,
    createDataTableTabFilterCallback,
    getPaginatedResult,
} from '../../../../../utils/data-table-utils';

const selectDocuments = state => state.document.database.documents;
const selectSignableDocuments = createSelector(
    [selectDocuments],
    documents => Object.values(documents).filter(document => document.signable),
);
const selectDepositDocuments = state => state.document.database.depositDocuments;

const selectNumberOfDocuments = () => createSelector(
    [selectDocuments],
    documents => Object.keys(documents).length,
);

const selectNumberOfSignableDocuments = () => createSelector(
    [selectDocuments],
    documents => Object.values(documents).filter(document => document.signable).length,
);

const selectDocumentIndex = id => createSelector(
    [selectDocuments],
    documents => Object.values(documents).sort((previous, next) => {
        const previousType = previous.type.toUpperCase();
        const nextType = next.type.toUpperCase();

        return (previousType < nextType) ? -1 : (previousType > nextType) ? 1 : 0;
    }).findIndex(document => document?.id === id) + 1,
);

const createDocumentsDataTableSelector = ({
    sortBy,
    sortDirection,
    pagination,
    tabFilterKey,
    tabFilterValue,
    searchBy,
    searchTerm,
}) => createSelector(
    [selectDocuments],
    documents => {
        if (!Object.keys(documents).length) {
            return {data: [], count: 0};
        }

        let data = Object.values(documents);

        if (tabFilterKey && tabFilterValue && tabFilterValue !== 'ALL') {
            data = data.filter(createDataTableTabFilterCallback(tabFilterKey, tabFilterValue));
        }

        if (searchTerm && searchBy && searchBy?.length) {
            data = data.filter(createDataTableSearchCallback(searchBy, searchTerm));
        }

        if (sortBy) {
            data.sort(createDataTableSortCallback(sortBy, sortDirection));
        }

        return getPaginatedResult(data, pagination);
    },
);

const createDocumentByIdSelector = documentId => createSelector(
    [selectDocuments],
    documents => documents[documentId],
);


const createDocumentByTypeSelector = type => createSelector(
    [selectDocuments],
    documents => {
        return Object.values(documents).find(document => document.type === type);
    },
);

const createDepositDocumentByTypeSelector = type => createSelector(
    [selectDepositDocuments],
    documents => {
        return documents.find(document => document.doc_type === type);
    },
);

export const DatabaseSelectors = {
    selectDocuments,
    selectDepositDocuments,
    createDepositDocumentByTypeSelector,
    selectNumberOfDocuments,
    selectDocumentIndex,
    createDocumentsDataTableSelector,
    createDocumentByIdSelector,
    createDocumentByTypeSelector,
    selectSignableDocuments,
    selectNumberOfSignableDocuments,
};
