/* eslint-disable react/boolean-prop-naming */
/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import * as React from 'react';
import {DatePickerToolbar} from '../DatePicker/DatePickerToolbar';
import {
    datePickerValueManager,
    useDatePickerDefaultizedProps,
} from '../DatePicker/shared';
import {CalendarOrClockPicker} from '../internals/components/CalendarOrClockPicker/CalendarOrClockPicker';
import {PickerStaticWrapper} from '../internals/components/PickerStaticWrapper/PickerStaticWrapper';
import {usePickerState} from '../internals/hooks/usePickerState';
import {useDateValidation} from '../internals/hooks/validation/useDateValidation';

/**
 *
 * Demos:
 *
 * - [Date Picker](https://mui.com/x/react-date-pickers/date-picker/)
 *
 * API:
 *
 * - [StaticDatePicker API](https://mui.com/x/api/date-pickers/static-date-picker/)
 */
export const StaticDatePicker = React.forwardRef((
    inProps,
    ref,
) => {
    const props = useDatePickerDefaultizedProps(inProps, 'MuiStaticDatePicker');

    // Note that we are passing down all the value without spread.
    // It saves us >1kb gzip and make any prop available automatically on any level down.
    const {
        ToolbarComponent = DatePickerToolbar,
        value,
        onChange,
        displayStaticWrapperAs = 'mobile',
        components,
        componentsProps,
        className,
        headerAdditionalContent,
        ...other
    } = props;

    const {pickerProps, inputProps, wrapperProps} = usePickerState(
        props,
        datePickerValueManager,
    );
    const validationError = useDateValidation(props) !== null;

    const DateInputProps = {
        ...inputProps,
        ...other,
        ref,
        validationError,
        components,
    };

    return (
        <PickerStaticWrapper
            displayStaticWrapperAs={displayStaticWrapperAs}
            components={components}
            componentsProps={componentsProps}
            className={className}
            {...wrapperProps}
        >
            <CalendarOrClockPicker
                {...pickerProps}
                toolbarTitle={props.label || props.toolbarTitle}
                ToolbarComponent={ToolbarComponent}
                DateInputProps={DateInputProps}
                components={components}
                componentsProps={componentsProps}
                headerAdditionalContent={headerAdditionalContent}
                {...other}
            />
        </PickerStaticWrapper>
    );
});

StaticDatePicker.propTypes = {
    // ----------------------------- Warning --------------------------------
    // | These PropTypes are generated from the TypeScript type definitions |
    // | To update them edit the TypeScript types and run "yarn proptypes"  |
    // ----------------------------------------------------------------------
    /**
     * Regular expression to detect "accepted" symbols.
     * @default /\dap/gi
     */
    acceptRegex: PropTypes.instanceOf(RegExp),

    autoFocus: PropTypes.bool,

    /**
     * className applied to the root component.
     */
    className: PropTypes.string,

    /**
     * If `true` the popup or dialog will immediately close after submitting full date.
     * @default `true` for Desktop, `false` for Mobile (based on the chosen wrapper and `desktopModeMediaQuery` prop).
     */
    closeOnSelect: PropTypes.bool,

    /**
     * Overrideable components.
     * @default {}
     */
    components: PropTypes.object,

    /**
     * The props used for each component slot.
     * @default {}
     */
    componentsProps: PropTypes.object,

    /**
     * Formats the day of week displayed in the calendar header.
     * @param {string} day The day of week provided by the adapter's method `getWeekdays`.
     * @returns {string} The name to display.
     * @default (day) => day.charAt(0).toUpperCase()
     */
    dayOfWeekFormatter: PropTypes.func,

    /**
     * Default calendar month displayed when `value={null}`.
     */
    defaultCalendarMonth: PropTypes.any,

    /**
     * If `true`, the picker and text field are disabled.
     * @default false
     */
    disabled: PropTypes.bool,

    /**
     * If `true` future days are disabled.
     * @default false
     */
    disableFuture: PropTypes.bool,

    /**
     * If `true`, today's date is rendering without highlighting with circle.
     * @default false
     */
    disableHighlightToday: PropTypes.bool,

    /**
     * Disable mask on the keyboard, this should be used rarely. Consider passing proper mask for your format.
     * @default false
     */
    disableMaskedInput: PropTypes.bool,

    /**
     * Do not render open picker button (renders only text field with validation).
     * @default false
     */
    disableOpenPicker: PropTypes.bool,

    /**
     * If `true` past days are disabled.
     * @default false
     */
    disablePast: PropTypes.bool,

    /**
     * Force static wrapper inner components to be rendered in mobile or desktop mode.
     * @default 'mobile'
     */
    displayStaticWrapperAs: PropTypes.oneOf(['desktop', 'mobile']),

    /**
     * Get aria-label text for control that opens picker dialog. Aria-label text must include selected date. @DateIOType
     * @template TInputDate, TDate
     * @param {TInputDate} date The date from which we want to add an aria-text.
     * @param {MuiPickersAdapter<TDate>} utils The utils to manipulate the date.
     * @returns {string} The aria-text to render inside the dialog.
     * @default (date, utils) => `Choose date, selected date is ${utils.format(utils.date(date), 'fullDate')}`
     */
    getOpenDialogAriaText: PropTypes.func,

    /**
     * Get aria-label text for switching between views button.
     * @param {CalendarPickerView} currentView The view from which we want to get the button text.
     * @returns {string} The label of the view.
     * @deprecated Use the `localeText` prop of `LocalizationProvider` instead, see https://mui.com/x/react-date-pickers/localization/.
     */
    getViewSwitchingButtonText: PropTypes.func,

    ignoreInvalidInputs: PropTypes.bool,

    /**
     * Props to pass to keyboard input adornment.
     */
    InputAdornmentProps: PropTypes.object,

    /**
     * Format string.
     */
    inputFormat: PropTypes.string,

    InputProps: PropTypes.object,

    /**
     * Pass a ref to the `input` element.
     */
    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({
            current: PropTypes.object,
        }),
    ]),

    label: PropTypes.node,

    /**
     * Left arrow icon aria-label text.
     * @deprecated
     */
    leftArrowButtonText: PropTypes.string,

    /**
     * If `true` renders `LoadingComponent` in calendar instead of calendar view.
     * Can be used to preload information and show it in calendar.
     * @default false
     */
    loading: PropTypes.bool,

    /**
     * Custom mask. Can be used to override generate from format. (e.g. `__/__/____ __:__` or `__/__/____ __:__ _M`).
     */
    mask: PropTypes.string,

    /**
     * Maximal selectable date. @DateIOType
     */
    maxDate: PropTypes.any,

    /**
     * Minimal selectable date. @DateIOType
     */
    minDate: PropTypes.any,

    /**
     * Callback fired when date is accepted @DateIOType.
     * @template TValue
     * @param {TValue} value The value that was just accepted.
     */
    onAccept: PropTypes.func,

    /**
     * Callback fired when the value (the selected date) changes @DateIOType.
     * @template TValue
     * @param {TValue} value The new parsed value.
     * @param {string} keyboardInputValue The current value of the keyboard input.
     */
    onChange: PropTypes.func.isRequired,

    /**
     * Callback that fired when input value or new `value` prop validation
     * returns **new** validation error (or value is valid after error).
     * In case of validation error detected `reason` prop return non-null
     * value and `TextField` must be displayed in `error` state.
     * This can be used to render appropriate form error.
     *
     * [Read the guide](https://next.material-ui-pickers.dev/guides/forms) about form integration and error displaying.
     * @DateIOType
     *
     * @template TError, TInputValue
     * @param {TError} reason The reason why the current value is not valid.
     * @param {TInputValue} value The invalid value.
     */
    onError: PropTypes.func,

    /**
     * Callback firing on month change @DateIOType.
     * @template TDate
     * @param {TDate} month The new month.
     * @returns {void|Promise} -
     */
    onMonthChange: PropTypes.func,

    /**
     * Callback fired on view change.
     * @param {CalendarPickerView} view The new view.
     */
    onViewChange: PropTypes.func,

    /**
     * Callback firing on year change @DateIOType.
     * @template TDate
     * @param {TDate} year The new year.
     */
    onYearChange: PropTypes.func,

    /**
     * Props to pass to keyboard adornment button.
     */
    OpenPickerButtonProps: PropTypes.object,

    /**
     * First view to show.
     * Must be a valid option from `views` list
     * @default 'day'
     */
    openTo: PropTypes.oneOf(['day', 'month', 'year']),

    /**
     * Force rendering in particular orientation.
     */
    orientation: PropTypes.oneOf(['landscape', 'portrait']),

    /**
     * Make picker read only.
     * @default false
     */
    readOnly: PropTypes.bool,

    /**
     * Disable heavy animations.
     * @default typeof navigator !== 'undefined' && /(android)/i.test(navigator.userAgent)
     */
    reduceAnimations: PropTypes.bool,

    /**
     * Custom renderer for day. Check the [PickersDay](https://mui.com/x/api/date-pickers/pickers-day/) component.
     * @template TDate
     * @param {TDate} day The day to render.
     * @param {Array<TDate | null>} selectedDays The days currently selected.
     * @param {PickersDayProps<TDate>} pickersDayProps The props of the day to render.
     * @returns {JSX.Element} The element representing the day.
     */
    renderDay: PropTypes.func,

    /**
     * The `renderInput` prop allows you to customize the rendered input.
     * The `props` argument of this render prop contains props of [TextField](https://mui.com/material-ui/api/text-field/#props) that you need to forward.
     * Pay specific attention to the `ref` and `inputProps` keys.
     * @example ```jsx
     * renderInput={props => <TextField {...props} />}
     * ````
     * @param {MuiTextFieldPropsType} props The props of the input.
     * @returns {React.ReactNode} The node to render as the input.
     */
    renderInput: PropTypes.func.isRequired,

    /**
     * Component displaying when passed `loading` true.
     * @returns {React.ReactNode} The node to render when loading.
     * @default () => <span data-mui-test="loading-progress">...</span>
     */
    renderLoading: PropTypes.func,

    /**
     * Custom formatter to be passed into Rifm component.
     * @param {string} str The un-formatted string.
     * @returns {string} The formatted string.
     */
    rifmFormatter: PropTypes.func,

    /**
     * Right arrow icon aria-label text.
     * @deprecated
     */
    rightArrowButtonText: PropTypes.string,

    /**
     * Disable specific date. @DateIOType
     * @template TDate
     * @param {TDate} day The date to test.
     * @returns {boolean} Returns `true` if the date should be disabled.
     */
    shouldDisableDate: PropTypes.func,

    /**
     * Disable specific months dynamically.
     * Works like `shouldDisableDate` but for month selection view @DateIOType.
     * @template TDate
     * @param {TDate} month The month to check.
     * @returns {boolean} If `true` the month will be disabled.
     */
    shouldDisableMonth: PropTypes.func,

    /**
     * Disable specific years dynamically.
     * Works like `shouldDisableDate` but for year selection view @DateIOType.
     * @template TDate
     * @param {TDate} year The year to test.
     * @returns {boolean} Returns `true` if the year should be disabled.
     */
    shouldDisableYear: PropTypes.func,

    /**
     * If `true`, days that have `outsideCurrentMonth={true}` are displayed.
     * @default false
     */
    showDaysOutsideCurrentMonth: PropTypes.bool,

    /**
     * If `true`, show the toolbar even in desktop mode.
     */
    showToolbar: PropTypes.bool,

    /**
     * Component that will replace default toolbar renderer.
     * @default DatePickerToolbar
     */
    ToolbarComponent: PropTypes.elementType,

    /**
     * Date format, that is displaying in toolbar.
     */
    toolbarFormat: PropTypes.string,

    /**
     * Mobile picker date value placeholder, displaying if `value` === `null`.
     * @default '–'
     */
    toolbarPlaceholder: PropTypes.node,

    /**
     * Mobile picker title, displaying in the toolbar.
     * @default 'Select date'
     */
    toolbarTitle: PropTypes.node,

    /**
     * The value of the picker.
     */
    value: PropTypes.any,

    /**
     * Array of views to show.
     * @default ['year', 'day']
     */
    views: PropTypes.arrayOf(PropTypes.oneOf(['day', 'month', 'year']).isRequired),
    isInRange: PropTypes.bool,
    headerAdditionalContent: PropTypes.node,
};
