import React from 'react';
import {useDispatch} from 'react-redux';
import PersonalInformationForm from './personal-information/PersonalInformationForm';
import {MainOnboardingActions} from '../../../store/main-onboarding.actions';

const PersonalInformation = () => {
    const dispatch = useDispatch();

    const onSubmitFn = payload => {
        dispatch(MainOnboardingActions.postPersonalInformation(payload));
    };

    return (
        <PersonalInformationForm
            onSubmitFn={onSubmitFn}
        />
    );
};

export default PersonalInformation;
