import {DOCUMENT_TYPES} from '../../../consts/document-constants';

// Default IDS, we should use API for most
// TODO Check if they are needed for something once onboarding refactor is completed
// export const CGVS_DOC_ID = '2a9c114c-6fba-4025-bc84-5bc96fc499ee';
// export const ADP_DOC_ID = 'b04326a2-2545-485d-8bf0-ec583cffd04f';

export const TERM_DOCUMENTS = {
    CGVS: 'CGVS',
    ADP: 'ADP',
};

export const OnboardingStatus = {
    IN_PROGRESS: 'IN_PROGRESS',
    READY_FOR_LAUNCH: 'READY_FOR_LAUNCH',
    COMPLETED: 'COMPLETED',
};

export const PortageOnboardingProcessState = {
    Pending: 'PENDING',
    PendingPersonalInfo: 'PENDING_FOR_PERSONAL_SET',
    PendingPersonalInfoInsuranceInfo: 'PENDING_FOR_PERSONAL_INSURANCE_INFO',
    PendingPersonalDocuments: 'PENDING_PERSONAL_DOCUMENTS',
    Completed: 'COMPLETED',
};

export const PortageOnboardingState = {
    termsAndConditionState: 'termsAndConditionState',
    personalInfoState: 'personalInfoState',
    personalDocumentsUploadState: 'personalDocumentsUploadState',
};

export const personalDocumentsUploadConfig = [
    {name: DOCUMENT_TYPES.ID_DOC, maxCount: 1},
    {name: DOCUMENT_TYPES.RESIDENCE_CERT, maxCount: 1},
    {name: DOCUMENT_TYPES.NIN_DOC, maxCount: 1}, // Justificatif de sécurité sociale *
    {name: DOCUMENT_TYPES.RIB, maxCount: 1},
    {name: DOCUMENT_TYPES.CV, maxCount: 1},
    {name: DOCUMENT_TYPES.DRIVERS_LICENCE, maxCount: 1}, // Not mandatory
    {name: DOCUMENT_TYPES.VEHICLE_REGISTRATION_CARD, maxCount: 1}, // Not mandatory
];

export const subscribedToHiwayInsuranceEnum = {
    HAS_HIWAY: true,
    WITHOUT_HIWAY: false,
};
