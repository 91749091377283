import React from 'react';
import {useDispatch} from 'react-redux';
import DocumentInformationForm from './document-information/DocumentInformationForm';
import {MainOnboardingActions} from '../../../store/main-onboarding.actions';

const DocumentInformation = () => {
    const dispatch = useDispatch();

    const onSubmitFn = data => {
        dispatch(MainOnboardingActions.postPersonalDocuments(data));
    };

    return (
        <DocumentInformationForm
            onSubmitFn={onSubmitFn}
        />
    );
};

export default DocumentInformation;
