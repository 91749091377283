import React from 'react';
import {useDispatch} from 'react-redux';
import {AnimationActions} from '../../../../animations/store/animations.action';
import {InitialStep} from '../../../../freelancer/modules/contract-signing/components/ContractSigning';
import {UmbrellaStepperOnboardingActions} from '../../../store/umbrella/umbrella-onboarding.actions';

const Kickoff = () => {
    const dispatch = useDispatch();

    const onClick = () => {
        dispatch(AnimationActions.setIsSubAnimationActive(false));
        dispatch(AnimationActions.storeNextDispatch(
            UmbrellaStepperOnboardingActions.goToCGVS(),
        ));
    };

    return (
        <InitialStep
            onClickFn={onClick}
            isUmbrella={true}
        />
    );
};

export default Kickoff;
