import moment from 'moment';
import {all, call, fork, put, select, takeEvery} from 'redux-saga/effects';

import {watchAdminExpenses} from './adminExpenses/adminExpenses.sagas';
import {watchBusinessAllowanceSagas} from './businessAllowance/businessAllowance.sagas';
import {selectCurrentlyEditingTravel} from './businessAllowance/businessAllowance.selectors';
import * as actions from './expenses.actions';
import {setIsLoadingHomeOfficeTravelsForm} from './homeOfficeTravelsForm/homeOfficeTravelsForm.actions';
import {watchHomeOfficeTravelsFormSagas} from './homeOfficeTravelsForm/homeOfficeTravelsForm.sagas';
import {watchPersonalExpensesSagas} from './personalExpenses/personalExpenses.sagas';
import {watchPersonalExpensesFormSagas} from './personalExpensesForm/personalExpensesForm.sagas';
import {watchProfessionalExpensesSagas} from './professionalExpenses/professionalExpenses.sagas';
import {watchProfessionalExpensesFormSagas} from './professionalExpensesForm/professionalExpensesForm.sagas';
import {setIsLoadingProfessionalTravelsForm} from './professionalTravelsForm/professionalTravelsForm.actions';
import {watchProfessionalTravelsFormSagas} from './professionalTravelsForm/professionalTravelsForm.sagas';
import {getConfig} from '../../../config/get-config';
import {FreelancerSelectors} from '../../../features/freelancer';
import {replace} from '../../../lib/router/connected-router-saga';
import {Toast} from '../../../lib/toast';
import {getBusinessAllowanceTotalDistanceRequest} from '../api/providers/expenses/expenses.provider';
import {COMPANY_LEGAL_STATUSES} from '../company/company.consts';
import {createDefaultCompanySelector} from '../company/companyList/companyList.selectors';

const getTotalDistanceSaga = function* (freelancerId, companyId, date) {
    try {
        const totalDistance = yield call(
            getBusinessAllowanceTotalDistanceRequest,
            freelancerId,
            companyId,
            date,
        );

        yield put(actions.storeTotalDistance(totalDistance));
    } catch (error) {
        Toast.error('anErrorOccurred');
    }
};

const getTotalDistanceWorker = function* ({payload}) {
    yield put(setIsLoadingHomeOfficeTravelsForm(true));
    yield put(setIsLoadingProfessionalTravelsForm(true));

    const freelancer = yield select(FreelancerSelectors.selectAccount);

    // const {id: freelancerId} = yield select(selectCurrentCognitoUser);
    // const {defaultCompanyId} = yield select(selectFreelancerAccount);

    const {date} = payload;

    yield call(getTotalDistanceSaga, freelancer.id, freelancer.defaultCompanyId, date);

    yield put(setIsLoadingHomeOfficeTravelsForm(false));
    yield put(setIsLoadingProfessionalTravelsForm(false));
};

export const homeOfficeRestrictionSaga = function* () {
    const company = yield select(createDefaultCompanySelector());

    if (!company) {
        return;
    }

    if (company.legalForm === COMPANY_LEGAL_STATUSES.EURL
        || company.legalForm === COMPANY_LEGAL_STATUSES.EURL_HOLDING) {
        yield put(replace(getConfig().ROUTE_PATHS.ACCESS_DENIED));
    }
};

export const travelLoaderSaga = function* () {
    yield put(setIsLoadingHomeOfficeTravelsForm(true));
    yield put(setIsLoadingProfessionalTravelsForm(true));

    const freelancer = yield select(FreelancerSelectors.selectAccount);
    const freelancerId = freelancer.id;
    const defaultCompanyId = freelancer.defaultCompanyId;

    const travel = yield select(selectCurrentlyEditingTravel);

    let date = moment().endOf('month').format('DD/MM/YYYY');

    if (travel?.date) {
        date = travel.date;
    }

    yield call(getTotalDistanceSaga, freelancerId, defaultCompanyId, date);

    yield put(setIsLoadingHomeOfficeTravelsForm(false));
    yield put(setIsLoadingProfessionalTravelsForm(false));
};

export const watchExpensesSagas = function* () {
    yield all([
        fork(watchPersonalExpensesSagas),
        fork(watchPersonalExpensesFormSagas),
        fork(watchProfessionalExpensesSagas),
        fork(watchProfessionalExpensesFormSagas),
        fork(watchHomeOfficeTravelsFormSagas),
        fork(watchProfessionalTravelsFormSagas),
        fork(watchBusinessAllowanceSagas),
        fork(watchAdminExpenses),
        takeEvery(actions.GET_TOTAL_DISTANCE, getTotalDistanceWorker),
    ]);
};
