// TODO Move to separate files
import {OnboardingObjects} from './onboarding.objects';
import {DOCUMENT_TYPES} from '../../../consts/document-constants';

// TODO It is short version for v1, lacking bottom part of data
const toPostCreateUserDto = data => {
    return {
        role: data?.role,
        email: data?.email,
        firstName: data?.firstName,
        lastName: data?.lastName,
        phone: data?.phone,
        coachingInfo: {
            coachId: data?.coach?.id,
            cityOfResidence: data?.city,
            contactSource: data?.contactSource,
            availabilityDate: data?.lastDayOfWork,
            professions: data?.jobType ? {
                jobType: [data.jobType],
            } : undefined,
            comment: data?.note,
        },
    };
};

const toPostInformationDto = information => {
    return {
        firstName: information?.firstName,
        lastName: information?.lastName,
        dateOfBirth: information?.dateOfBirth,
        country: information?.countryOfBirth,
        city: information?.placeOfBirth,
        postalCode: information?.postalCode,
        nationality: information?.nationality,
        socialSecurityNumber: information?.socialSecurityNumber,
        lastMedicalCheckupDate: information?.lastMedicalCheckupDate ?? null,
        subscribedToHiwayInsurance: information?.subscribedToHiwayInsurance,
    };
};

const toPostDocumentsDto = documents => {
    const formData = new FormData();

    if (documents.personalInsuranceDocument) {
        formData.append(DOCUMENT_TYPES.PERSONAL_INSURANCE, documents.personalInsuranceDocument);
    }

    if (documents.identificationDocument) {
        formData.append(DOCUMENT_TYPES.ID_DOC, documents.identificationDocument);
    }
    if (documents.residenceCertificationDocument) {
        formData.append(DOCUMENT_TYPES.RESIDENCE_CERT, documents.residenceCertificationDocument);
    }
    if (documents.insuranceDocument) {
        formData.append(DOCUMENT_TYPES.NIN_DOC, documents.insuranceDocument);
    }

    if (documents.ribDocument) {
        formData.append(DOCUMENT_TYPES.RIB, documents.ribDocument);
    }
    if (documents.cvDocument) {
        formData.append(DOCUMENT_TYPES.CV, documents.cvDocument);
    }
    if (documents.driversLicenceDocument) {
        formData.append(DOCUMENT_TYPES.DRIVERS_LICENCE, documents.driversLicenceDocument);
    }
    if (documents.vehicleRegistrationCardDocument) {
        formData.append(DOCUMENT_TYPES.VEHICLE_REGISTRATION_CARD, documents.vehicleRegistrationCardDocument);
    }

    return formData;
};

const fromOnboardingDto = dto => {
    return OnboardingObjects.Onboarding(dto);
};

const fromTermsDto = dto => {
    const terms = {};

    dto?.terms?.forEach(term => {
        terms[term?.type] = term;
    });

    return terms;
};

export const OnboardingDto = {
    toPostCreateUserDto,
    toPostInformationDto,
    toPostDocumentsDto,

    fromOnboardingDto,
    fromTermsDto,
};
