import GA4React from 'ga-4-react';
import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {getConfig} from '../../config/get-config';

const GOOGLE_TAG_ID = 'G-9NE0DHV2FT';

const initializeGA = (setga4, path) => {
    const ga4react = new GA4React(GOOGLE_TAG_ID);
    ga4react.initialize().then(ga4 => {
        ga4.pageview(path);
        setga4(ga4);
    }).catch(e => {
        // eslint-disable-next-line no-console
        console.error('Failed to load google tag', e);
    });
};

const GoogleAnalytics = () => {
    const config = getConfig();

    const [ga4, setga4] = useState(null);
    const location = useLocation();

    useEffect(() => {
        if (config?.environment === 'production') {
            const path = location ? location.pathname + location.search : window.location;
            if (ga4 && ga4.pageview) {
                ga4.pageview(path);
            } else {
                initializeGA(setga4, path);
            }
        }
    }, [config?.environment, ga4, location]);

    return null;
};

export default GoogleAnalytics;
