import {call, put, select} from 'redux-saga/effects';
import {isUserCareFn} from '../../../../../utils/user-roles';
import {
    getCompanyInformationDataRequest,
    getNonConvictionDeclarationRequest, getPersonalCompanyDocumentsRequest,
    getPersonalInformationDataRequest,
} from '../../../../../v1/app/api/providers/company/company.provider';
import {storeCompanyDocuments} from '../../../../../v1/app/company/company.actions';
import {
    storeCompanyInformationData,
} from '../../../../../v1/app/company/setupCompany/companyInformation/companyInformation.actions';
import {
    storeNonConvictionDeclarationData,
} from '../../../../../v1/app/company/setupCompany/nonConvictionDeclaration/nonConvictionDeclaration.actions';
import {
    storePersonalInformationData,
} from '../../../../../v1/app/company/setupCompany/personalInformation/personalInformation.actions';
// eslint-disable-next-line import/no-cycle
import {getBankIntegrationsFlow} from '../../../../bank/store/bank.loader.saga';
// eslint-disable-next-line import/no-cycle
import {getCapitalDepositWorker} from '../../../../bank/store/bank.saga';
import {UserApi} from '../../../../user/api/user.api';
import {LoggedInUserSelectors} from '../../../../user/modules/logged-in-user';

const loadPersonalInformation = function* ({freelancerId, companyId}) {
    try {
        const data = yield call(getPersonalInformationDataRequest, freelancerId, companyId);

        yield put(storePersonalInformationData(data));

        return !!Object.keys(data).length;
    } catch (error) {
        // no-op
    }
};

const loadAddressInformation = function* ({freelancerId}) {
    try {
        const data = yield call(UserApi.getContactInfo, freelancerId);
        return !!Object.keys(data).length;
    } catch (error) {
        // no-op
    }
};


const loadNonConvictionInformation = function* ({freelancerId, companyId}) {
    try {
        const data = yield call(getNonConvictionDeclarationRequest, freelancerId, companyId);

        yield put(storeNonConvictionDeclarationData(data));

        return !!Object.keys(data).length;
    } catch (error) {
        // no-op
    }
};


const loadCompanyInformation = function* ({freelancerId, companyId}) {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);
    const isUserCareRole = isUserCareFn(loggedInUser);

    try {
        const data = yield call(getCompanyInformationDataRequest, freelancerId, companyId);

        yield put(storeCompanyInformationData(data));

        return data?.companyName && data?.professionalActivity
            && (isUserCareRole || data?.amountOfShareCapital);
    } catch (error) {
        // no-op
    }
};

// TOOD Check if this is correct in all cases
const loadBankInformation = function* ({freelancerId, companyId}) {
    try {
        const data = yield call(getCompanyInformationDataRequest, freelancerId, companyId);

        return data?.bank
            || data?.bankAddress
            || data?.hiwayBankInfo;
    } catch (error) {
        // no-op
    }
};

export const loadDocumentInformation = function* ({freelancerId, companyId}) {
    try {
        const data = yield call(getPersonalCompanyDocumentsRequest, freelancerId, companyId);

        yield put(storeCompanyDocuments(data));

        return !!Object.keys(data).length;
    } catch (error) {
        // no-op
    }
};

const loadIntegrationKycAndDepositData = function* ({loggedInUser}) {
    // Fetch bank integration data
    const bankIntegrationDataList = yield call(getBankIntegrationsFlow);

    // Get capital deposit info
    let deposit;
    if (!isUserCareFn(loggedInUser)) {
        deposit = yield call(getCapitalDepositWorker);
    }

    const hasIntegration = !!bankIntegrationDataList?.[0];

    const bankAccountHoldersList = bankIntegrationDataList?.[0]?.bankAccountHolders;
    const individualBankAccountHolders = bankAccountHoldersList
        ? bankAccountHoldersList.find(holder => holder.type !== 'COMPANY')
        : undefined;

    const kycStatus = bankIntegrationDataList?.[0]?.kyc?.identificationStatus;

    return {
        bankIntegrationDataList,
        deposit,
        hasIntegration,
        bankAccountHoldersList,
        individualBankAccountHolders,
        kycStatus,
    };
};

export const OnboardingLoader = {
    loadPersonalInformation,
    loadAddressInformation,
    loadNonConvictionInformation,
    loadCompanyInformation,
    loadBankInformation,
    loadDocumentInformation,
    loadIntegrationKycAndDepositData,
};
