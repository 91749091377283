import {all, call, delay, put, select, takeEvery} from 'redux-saga/effects';
import {InsuranceActions} from './insurance.action';
import {InsuranceActionTypes} from './insurance.action-type';
import {DOCUMENT_TYPES} from '../../../consts/document-constants';
import {RoutePaths} from '../../../lib/router/route-paths';
import {Toast} from '../../../lib/toast';
import {Debug} from '../../../utils/debug';
import {isUserCareFn} from '../../../utils/user-roles';
import {DatabaseApi} from '../../document/modules/database/api/database.api';
import {DatabaseActions} from '../../document/modules/database/store/database.action';
import {DatabaseSelectors} from '../../document/modules/database/store/database.selector';
import {DocumentContexts} from '../../document/modules/database/utils/constants';
// eslint-disable-next-line import/no-cycle
import {signCurrentDocumentSaga} from '../../document/modules/signing/store/signing.saga';
import {FinalizationSubsteps} from '../../freelancer/modules/capital-deposit/utils/constants';
import {OnboardingActions} from '../../freelancer/modules/onboarding/store/onboarding.action';
import {OnboardingSelectors} from '../../freelancer/modules/onboarding/store/onboarding.selectors';
import {OnboardingSteps} from '../../freelancer/modules/onboarding/utils/onboadingStepsConstant';
import {LoadingActions, LoadingTypes} from '../../loading';
import {LoggedInUserSelectors} from '../../user/modules/logged-in-user';
import {InsuranceApi} from '../api/insurance.api';
import {InsuranceStatus} from '../utils/constants';

export const getInsuranceFlow = function* (freelancerId, companyId) {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    // Care user does not have access to insurance
    if (isUserCareFn(loggedInUser)) {
        return;
    }

    yield put(LoadingActions.setLoading(LoadingTypes.GET_INSURANCE, true));

    try {
        const response = yield call(InsuranceApi.getInsuranceData, freelancerId, companyId);

        yield put(InsuranceActions.storeInsurance(response));
        yield put(LoadingActions.setLoading(LoadingTypes.GET_INSURANCE, false));
    } catch (error) {
        Debug.error('insurance', 'Error: ', {error});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.GET_INSURANCE, false));
    }
};

export const getInsuranceWorker = function* ({payload}) {
    const {freelancerId, companyId} = payload;

    if (!freelancerId || !companyId) {
        return;
    }

    yield call(getInsuranceFlow, freelancerId, companyId);
};

const setInsuranceFlow = function* (freelancerId, companyId, data) {
    yield put(LoadingActions.setLoading(LoadingTypes.SET_INSURANCE, true));

    try {
        const response = yield call(InsuranceApi.updateInsuranceData, freelancerId, companyId, data);

        yield put(InsuranceActions.storeInsurance(response));
        yield put(LoadingActions.setLoading(LoadingTypes.SET_INSURANCE, false));

        if (!data.insurance_type) {
            Toast.success('insuranceRequestSent');
        }
    } catch (error) {
        Debug.error('insurance', 'Error: ', {error});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.SET_INSURANCE, false));
    }
};

export const setInsuranceWorker = function* ({payload}) {
    const {freelancerId, companyId, data} = payload;

    if (!freelancerId || !companyId || !data) {
        return;
    }

    yield call(setInsuranceFlow, freelancerId, companyId, data);
};

const skipInsuranceFlow = function* (freelancerId, companyId, data) {
    yield call(setInsuranceFlow, freelancerId, companyId, data);
    yield call(finalizeInsuranceSaga);
};

export const skipInsuranceWorker = function* ({payload}) {
    const {freelancerId, companyId, data} = payload;

    if (!freelancerId || !companyId || !data) {
        return;
    }

    yield call(skipInsuranceFlow, freelancerId, companyId, data);
};

const finalizeInsuranceSaga = function* () {
    const progress = yield select(OnboardingSelectors.selectProgress);

    yield put(OnboardingActions.setSubStep(FinalizationSubsteps.ACCESS_PLATFORM));
    yield put(OnboardingActions.setInternalSubStep(
        FinalizationSubsteps.ACCESS_PLATFORM,
    ));
    yield put(OnboardingActions.setProgress({
        ...progress,
        [OnboardingSteps.FINAL_POINTS]: {
            ...progress[OnboardingSteps.FINAL_POINTS],
            subStepProgress: {
                ...progress[OnboardingSteps.FINAL_POINTS].subStepProgress,
                [FinalizationSubsteps.INSURANCE]: {
                    isCompleted: true,
                },
            },
        },
    }));
};

const POLL_INTERVAL = 5000; // milliseconds
const POLL_MAX_DURATION = 180000; // milliseconds
export const pollGeneratedInsuranceDocument = function* (freelancerId, companyId, context = DocumentContexts.SIGNABLE) {
    let pollDuration = 0;
    yield put(LoadingActions.setLoading(LoadingTypes.GENERATING_DOCUMENTS, true));

    while (true) {
        const documents = yield call(DatabaseApi.getDocuments, {
            freelancerId,
            companyId,
            context: context,
        });

        const insuranceDoc = yield Object
            .values(documents)
            .find(doc => doc?.type === DOCUMENT_TYPES.INSURANCE_GROUP_INSURANCE_DIRECT_DEBIT_MANDATE);

        if (!insuranceDoc) {
            Toast.error('genericError');
            yield put(LoadingActions.setLoading(LoadingTypes.GENERATING_DOCUMENTS, false));
            break;
        }

        yield put(DatabaseActions.storeDocuments({[insuranceDoc?.id]: insuranceDoc}));

        const isDocumentGenerationInProgress = Object.values(documents).some(doc => doc?.status === 'GENERATING');
        const hasError = Object.values(documents).some(doc => doc?.status === 'ERROR');

        if (hasError) {
            Toast.error('genericError');
            yield put(LoadingActions.setLoading(LoadingTypes.GENERATING_DOCUMENTS, false));
            break;
        }

        if (pollDuration >= POLL_MAX_DURATION) {
            Toast.error('genericError');
            yield put(LoadingActions.setLoading(LoadingTypes.GENERATING_DOCUMENTS, false));
            break;
        }

        if (!isDocumentGenerationInProgress) {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERATING_DOCUMENTS, false));
            break;
        }

        pollDuration += POLL_INTERVAL;
        yield delay(POLL_INTERVAL);
    }
};

export const getInsuranceDocumentFlow = function* (freelancerId, companyId, context = DocumentContexts.SIGNABLE) {
    yield put(LoadingActions.setLoading(LoadingTypes.GENERATING_DOCUMENTS, true));

    try {
        const documents = yield call(DatabaseApi.getDocuments, {
            freelancerId,
            companyId,
            context,
        });

        const insuranceDoc = Object
            .values(documents)
            .find(doc => doc?.type === DOCUMENT_TYPES.INSURANCE_GROUP_INSURANCE_DIRECT_DEBIT_MANDATE);

        if (insuranceDoc) {
            yield put(DatabaseActions.storeDocuments({[insuranceDoc?.id]: insuranceDoc}));
        }

        yield put(LoadingActions.setLoading(LoadingTypes.GENERATING_DOCUMENTS, false));
    } catch (error) {
        Debug.error('insurance', 'Error: ', {error});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.GENERATING_DOCUMENTS, false));
    }
};

export const loadInsuranceDocument = function* (freelancerId, companyId, context) {
    yield call(getInsuranceDocumentFlow, freelancerId, companyId, context);

    const documents = yield select(DatabaseSelectors.selectDocuments);

    const isDocumentGenerationInProgress = Object.values(documents).some(doc => doc?.status === 'GENERATING');

    if (isDocumentGenerationInProgress) {
        yield call(pollGeneratedInsuranceDocument, freelancerId, companyId, context);
    }
};

export const loadInsuranceDocumentWorker = function* ({payload}) {
    const {freelancerId, companyId} = payload;

    if (!freelancerId || !companyId) {
        return;
    }

    yield call(loadInsuranceDocument, freelancerId, companyId);
};

const generateInsuranceDocumentFlow = function* (freelancerId, companyId, isFullyActive, navigateTo) {
    try {
        yield call(InsuranceApi.generateInsuranceDocument, freelancerId, companyId);

        yield call(loadInsuranceDocument, freelancerId, companyId);

        if (isFullyActive) {
            navigateTo(RoutePaths.SIGN_DOCUMENTS);
        }
    } catch (error) {
        Debug.error('insurance', 'Error: ', {error});
        Toast.error('genericError');
    }
};

export const generateInsuranceDocumentWorker = function* ({payload}) {
    const {freelancerId, companyId, isFullyActive, navigateTo} = payload;

    if (!freelancerId || !companyId) {
        return;
    }

    yield call(generateInsuranceDocumentFlow, freelancerId, companyId, isFullyActive, navigateTo);
};

// Signing insurance from dashboard
const signInsurancePostOnboardingWorker = function* () {
    try {
        const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);
        // Needs to be called with call so we can prevent setInsuranceFlow if signing crashes
        yield call(signCurrentDocumentSaga);
        yield call(
            setInsuranceFlow,
            loggedInUser?.id,
            loggedInUser?.defaultCompanyId,
            {status: InsuranceStatus.SIGNED},
        );
    } catch (error) {
        Debug.error('signInsurancePostOnboardingWorker', 'Error: ', {error});
    }
};

export const watchInsuranceSaga = function* () {
    yield all([
        takeEvery(InsuranceActionTypes.GET_INSURANCE, getInsuranceWorker),
        takeEvery(InsuranceActionTypes.SET_INSURANCE, setInsuranceWorker),
        takeEvery(InsuranceActionTypes.SKIP_INSURANCE, skipInsuranceWorker),
        takeEvery(InsuranceActionTypes.FINALIZE_INSURANCE, finalizeInsuranceSaga),
        takeEvery(InsuranceActionTypes.GENERATE_INSURANCE_DOCUMENT, generateInsuranceDocumentWorker),
        takeEvery(InsuranceActionTypes.LOAD_INSURANCE_DOCUMENT, loadInsuranceDocumentWorker),
        takeEvery(InsuranceActionTypes.SIGN_INSURANCE_POST_ONBOARDING, signInsurancePostOnboardingWorker),
    ]);
};
