import {LoadingButton} from '@mui/lab';
import Box from '@mui/material/Box';
import React, {useCallback, useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';
import {DOCUMENT_STATUSES} from '../../consts/document-constants';
import {AccountingSelector} from '../../features/company-profile/modules/accounting/store/accounting.selector';
import DepositOfAccountsPaymentModal
    from '../../features/company-profile/modules/formalities/deposit-of-accounts/components/DepositOfAccountsPaymentModal';
import {
    DEPOSIT_OF_ACCOUNTS_PAY_MODAL_DELAY,
    DEPOSIT_OF_ACCOUNTS_STATUS,
} from '../../features/company-profile/modules/formalities/utils/constants';
import {DatabaseSelectors} from '../../features/document/modules/database/store/database.selector';
import {DocumentSigningWrapper} from '../../features/document/modules/signing/components/DocumentSigningWrapper';
import {SigningSelectors} from '../../features/document/modules/signing/store/signing.selector';
import {DOCUMENT_SIGNING_TYPES} from '../../features/document/modules/signing/utils/constants';
import {UiActions} from '../../features/ui/store/ui.action';
import {ModalsKeys} from '../../features/ui/utils/constants';
import Alert from '../../v1/components/ui-kit/Alert/Alert';

const DepositOfAccountSigningScreen = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation('document');

    // Params
    const [searchParams] = useSearchParams();
    const year = useMemo(() => searchParams.get('year'), [searchParams]);

    const documents = useSelector(DatabaseSelectors.selectDocuments);
    const areAllDocumentsSigned = useMemo(() => {
        return !!Object.values(documents).length
            && Object.values(documents).every(document => document.status === DOCUMENT_STATUSES.SIGNED);
    }, [documents]);

    const annualAccounts = useSelector(AccountingSelector.selectAnnualAccounts);
    const currentAnnualAccount = annualAccounts[year];

    const isSigning = useSelector(SigningSelectors.selectIsSigningCurrentDocument);

    const onCallback = () => {
        dispatch(UiActions.setActiveModal(ModalsKeys.DEPOSIT_OF_ACCOUNTS_PAYMENT, true));
        dispatch(UiActions.setModalData(ModalsKeys.DEPOSIT_OF_ACCOUNTS_PAYMENT, {
            isOpenFromDashboard: false,
            paymentInfo: currentAnnualAccount?.payLinkInfo,
        }));
    };

    useEffect(() => {
        if (areAllDocumentsSigned) {
            setTimeout(() => {
                onCallback();
            }, DEPOSIT_OF_ACCOUNTS_PAY_MODAL_DELAY);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [areAllDocumentsSigned]);

    const signComponent = useCallback(() => {
        return (
            <LoadingButton
                sx={{py: 0.75, borderRadius: 2, mt: 1, color: '#fff'}}
                variant="contained"
                color="info"
                size="small"
                loading={isSigning}
                loadingPosition="start"
                onClick={onCallback}
                fullWidth
            >
                {t('companies:formalities.depositOfAccounts.payButton')}
            </LoadingButton>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSigning]);

    return (
        <Box>
            {currentAnnualAccount?.status === DEPOSIT_OF_ACCOUNTS_STATUS.AWAITING_PAYMENT && (
                <Alert
                    severity="info"
                    title={t('companies:formalities.depositOfAccounts.notifications.paymentAlertTitle')}
                    message={(
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <span>
                                {t('companies:formalities.depositOfAccounts.notifications.paymentAlertMessage')}
                            </span>
                            <span style={{fontWeight: 500}}>
                                {t('companies:formalities.depositOfAccounts.notifications.paymentAlertAmountMessage', {amount: currentAnnualAccount?.payLinkInfo?.amount})}
                            </span>
                        </Box>
                    )}
                    elevation={0}
                    sx={{
                        mt: 1,
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        borderColor: 'v2.light.info.main',
                        borderRadius: '12px',
                    }}
                />
            )}

            <DocumentSigningWrapper
                type={DOCUMENT_SIGNING_TYPES.ACCOUNTING}
                MainActionComponent={areAllDocumentsSigned ? signComponent : null}
            />

            <DepositOfAccountsPaymentModal />
        </Box>
    );
};

export default DepositOfAccountSigningScreen;
