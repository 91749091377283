import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {UserRoles} from '../../../../utils/user-roles';
import {LoggedInUserSelectors} from '../../../user/modules/logged-in-user';
import {SidebarSelectors} from '../../store/sidebar.selector';
import {
    AdministratorSidebarCareNavigation,
    AdministratorSidebarNavigation,
    AdministratorSidebarPortageNavigation,
} from '../../utils/constants';
import CommonSidebar from '../Common/CommonSidebar';
import {Search} from '../Common/Search';
import {SegmentSwitch} from '../Common/SegmentSwitch';

export const AdministratorSidebar = () => {
    const user = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const selectedRole = useSelector(SidebarSelectors.selectSelectedRole);

    const sidebarData = useMemo(() => {
        switch (selectedRole) {
            case UserRoles.FREELANCER:
                return AdministratorSidebarNavigation;
            case UserRoles.CARE:
                return AdministratorSidebarCareNavigation;
            case UserRoles.UMBRELLA:
                return AdministratorSidebarPortageNavigation;
            default:
                return AdministratorSidebarNavigation;
        }
    }, [selectedRole]);

    if (!user) {
        return;
    }

    return (
        <>
            <CommonSidebar
                user={user}
                sidebarData={sidebarData}
                sx={{pb: 4}}
                SearchComponent={(<Search />)}
                SegmentSwitchComponent={(<SegmentSwitch />)}
            />
        </>
    );
};
