import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {importLocaleBundle} from '../../../../lib/i18next';
import {isUserCareFn, isUserUmbrellaFn} from '../../../../utils/user-roles';
import {setCollapsedSidebarLists} from '../../../../v1/app/app.actions';
import {selectCollapsedSidebarLists} from '../../../../v1/app/app.selectors';
import {hasRibAndDocumentsPageAccess} from '../../../bank/modules/rib-and-documents/utils/ribAndDocuments.util';
import {BankSelector} from '../../../bank/store/bank.selector';
import {LoggedInUserSelectors} from '../../../user/modules/logged-in-user';
import {freelancerSidebar, umbrellaSidebar} from '../../utils/constants';
import CommonSidebar from '../Common/CommonSidebar';

importLocaleBundle('sidebar');

export const FreelancerSidebar = () => {
    const dispatch = useDispatch();

    const user = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const integrations = useSelector(BankSelector.selectIntegrations);

    const collapsedLists = useSelector(selectCollapsedSidebarLists);

    const isUserCareRole = isUserCareFn(user);

    const createCollapsedListClickHandler = useCallback(listItemId => () => {
        let lists;

        if (collapsedLists.includes(listItemId)) {
            lists = collapsedLists.filter(itemId => itemId !== listItemId);
        } else {
            lists = [...collapsedLists, listItemId];
        }

        dispatch(setCollapsedSidebarLists(lists));
    }, [collapsedLists, dispatch]);

    if (!user) {
        return;
    }

    const showRibAndDocumentsItem = hasRibAndDocumentsPageAccess(integrations);

    const sidebar = isUserUmbrellaFn(user)
        ? umbrellaSidebar()
        : freelancerSidebar({
            companyId: user.defaultCompanyId,
            hasTrainingMenu: user.hasTrainingMenu,
            hasBankAccess: user.hasBankAccess,
            numberOfIntegrations: integrations?.length,
            showRibAndDocumentsItem,
            isUserCareRole,
        });

    return (
        <CommonSidebar
            user={user}
            sidebarData={sidebar}
            createCollapsedListClickHandler={createCollapsedListClickHandler}
            sx={{pb: 11}}
        />
    );
};
