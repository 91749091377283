import moment from 'moment';
import {all, call, put, select, takeLatest} from 'redux-saga/effects';

import * as actions from './professionalTravelsForm.actions';
import {getConfig} from '../../../../config/get-config';
import {LoggedInUserSelectors} from '../../../../features/user/modules/logged-in-user';
import {push} from '../../../../lib/router/connected-router-saga';
import {Toast} from '../../../../lib/toast';
import {
    createCompanyBusinessKmTravelRequest,
    updateCompanyBusinessKmTravelRequest,
} from '../../api/providers/expenses/expenses.provider';
import {selectFreelancerAccount} from '../../freelancer/freelancer.selectors';
import {setCurrentlyEditingTravel} from '../businessAllowance/businessAllowance.actions';
import {TRAVEL_TYPES} from '../expenses.consts';

const submitProfessionalTravelFormSaga = function* ({payload}) {
    try {
        yield put(actions.setIsLoadingProfessionalTravelsForm(true));

        let freelancerAccount = yield select(selectFreelancerAccount);

        if (!freelancerAccount) {
            freelancerAccount = yield select(LoggedInUserSelectors.selectLoggedInUser);
        }

        // TODO:CRITICAL move to DTO
        const formattedData = {
            type: TRAVEL_TYPES.PROFESSIONAL,
            fiscal_power: payload.data.fiscalPower,
            distance: payload.data.distance,
            date: moment(payload.data.date).format('DD/MM/YYYY'),
            routes: [
                payload.data.departure,
                payload.data.arrival,
                ...(payload.data.return ? [payload.data.return] : []),
            ],
            vehicle_type: payload.data.vehicleType,
            engine_type: payload.data.engineType,
        };

        if (payload.data.reason) {
            formattedData.reason = payload.data.reason;
        }

        if (payload.isEdit) {
            yield call(
                updateCompanyBusinessKmTravelRequest,
                freelancerAccount.id,
                freelancerAccount.defaultCompanyId,
                payload.travelId,
                formattedData,
            );
        } else {
            yield call(
                createCompanyBusinessKmTravelRequest,
                freelancerAccount.id,
                freelancerAccount.defaultCompanyId,
                formattedData,
            );
        }

        yield put(setCurrentlyEditingTravel(null));

        if (payload.onSuccess && typeof payload.onSuccess === 'function') {
            payload.onSuccess();
        }

        yield put(actions.setIsLoadingProfessionalTravelsForm(false));

        Toast.success('payload.isEdit ? \'businessKmTravelUpdated\' : \'businessKmTravelCreated\'');

        yield put(push(getConfig().ROUTE_PATHS.BUSINESS_KILOMETERS_ALLOWANCE));
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        Toast.error('anErrorOccurred');

        yield put(actions.setIsLoadingProfessionalTravelsForm(false));
    }
};

export const watchProfessionalTravelsFormSagas = function* () {
    yield all([
        takeLatest(actions.SUBMIT_PROFESSIONAL_TRAVELS_FORM, submitProfessionalTravelFormSaga),
    ]);
};
