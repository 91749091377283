import {Box, Button, ButtonGroup} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useCallback} from 'react';
import Grow from '../../../../../../components/joy-ui/mui-legacy/Grow';
import DropZone from '../../../../../../v1/components/ui-kit/DropZone';
import {subscribedToHiwayInsuranceEnum} from '../../../../utils/onboarding.constants';

const PersonalInformationMutual = ({
    t,
    setValue,
    errors,
    subscribedToHiwayInsurance,
    personalInsuranceDocument,
}) => {
    const setChosenButton = value => {
        setValue('subscribedToHiwayInsurance', value, {
            shouldDirty: true,
            shouldValidate: true,
        });
    };

    const createDropAcceptedHandler = useCallback(name => files => {
        setValue(name, files, {
            shouldDirty: true,
            shouldValidate: true,
        });
    }, [setValue]);

    const createDeleteFileHandler = useCallback(name => () => {
        setValue(name, null, {
            shouldDirty: true,
            shouldValidate: true,
        });
    }, [setValue]);

    return (
        <Box>
            <Box sx={{
                mt: 2,
            }}
            >
                <ButtonGroup
                    sx={errors?.subscribedToHiwayInsurance ? {border: '1px solid red'} : {}}
                    variant="outlined"
                    fullWidth
                >
                    <Button
                        sx={{
                            backgroundColor:
                                subscribedToHiwayInsurance === subscribedToHiwayInsuranceEnum.HAS_HIWAY
                                    ? 'v2.light.primary.shades4'
                                    : 'inherit',
                        }}
                        onClick={(() => setChosenButton(subscribedToHiwayInsuranceEnum.HAS_HIWAY))}
                    >
                        {t('personalInsurance.subscribeInsurance')}
                    </Button>
                    <Button
                        sx={{
                            backgroundColor:
                                subscribedToHiwayInsurance === subscribedToHiwayInsuranceEnum.WITHOUT_HIWAY
                                    ? 'v2.light.primary.shades4'
                                    : 'inherit',
                        }}
                        onClick={(() => setChosenButton(subscribedToHiwayInsuranceEnum.WITHOUT_HIWAY))}
                    >
                        {t('personalInsurance.keepInsurance')}
                    </Button>
                </ButtonGroup>
            </Box>

            <Box
                sx={{
                    display: subscribedToHiwayInsurance === subscribedToHiwayInsuranceEnum.WITHOUT_HIWAY
                        ? 'block' : 'none',
                    mt: 2,
                }}
            >
                <Grow in={subscribedToHiwayInsurance === subscribedToHiwayInsuranceEnum.WITHOUT_HIWAY}>
                    <Box>
                        <DropZone
                            acceptFiles="image/jpeg, image/png, image/jpg, application/pdf"
                            files={personalInsuranceDocument}
                            onDeleteFile={createDeleteFileHandler('personalInsuranceDocument')}
                            onDropAccepted={createDropAcceptedHandler('personalInsuranceDocument')}
                            name="personalInsuranceDocument"
                            errorMessage={errors?.personalInsuranceDocument?.message}
                        />
                    </Box>
                </Grow>
            </Box>
        </Box>
    );
};

PersonalInformationMutual.propTypes = {
    t: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    subscribedToHiwayInsurance: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.object,
    ]),
    personalInsuranceDocument: PropTypes.object,
};

PersonalInformationMutual.defaultProps = {
    personalInsuranceDocument: null,
    subscribedToHiwayInsurance: null,
};

// Commenting out instead of  deleting, because it is expected to be returned/used again soon
// const DownloadBrochure = () => {
//     return (
//         <Box sx={{
//             background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%),
//             #3779BE',
//             borderRadius: '8px',
//             padding: '20px',
//             display: 'flex',
//             justifyContent: 'space-between',
//         }}
//         >
//             <Box>
//                 <Typography sx={{
//                     fontSize: theme => theme.typography.pxToRem(21),
//                     fontWeight: 700,
//                 }}
//                 >
//                     {t('personalInsurance.seeDetails')}
//                 </Typography>
//
//                 <Typography sx={{
//                     fontSize: theme => theme.typography.pxToRem(14),
//                 }}
//                 >
//                     {t('personalInsurance.downloadBrochure')}
//                 </Typography>
//             </Box>
//             <Box>
//                 <Button
//                     variant="contained"
//                     startIcon={<PictureAsPdfIcon />}
//                 >
//                     {t('personalInsurance.downloadSales')}
//                 </Button>
//             </Box>
//         </Box>
//     )
// }

export default PersonalInformationMutual;
