import {createSelector} from 'reselect';

import {createDataTableSortCallback, getPaginatedResult} from '../../../../utils/data-table-utils';

export const selectIsLoadingProfessionalExpenses = state => state.expenses?.professionalExpenses?.isLoading;

export const selectProfessionalExpenses = state => state.expenses?.professionalExpenses?.expenses ?? {};

export const createProfessionalExpensesSelector = ({
    sortBy,
    sortDirection,
    pagination,
}) => createSelector(
    [selectProfessionalExpenses],
    professionalExpenses => {
        if (!Object.keys(professionalExpenses).length) {
            return {data: [], count: 0};
        }

        const data = Object.values(professionalExpenses);

        if (sortBy) {
            data.sort(createDataTableSortCallback(sortBy, sortDirection));
        }

        return getPaginatedResult(data, pagination);
    },
);
