import {combineReducers} from 'redux';
import {MainOnboardingActionTypes} from './main-onboarding.action-types';
import {createReducer} from '../../../utils/create-reducer';


export const mainOnboardingReducer = combineReducers({
    onboarding: createReducer(null, MainOnboardingActionTypes.STORE_ONBOARDING_DATA),

    currentlyOpenStep: createReducer(null, MainOnboardingActionTypes.SET_STEP),
    currentlyOpenSubStep: createReducer(null, MainOnboardingActionTypes.SET_SUBSTEP),
});
