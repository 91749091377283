import {Avatar, Tooltip} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {capitalizeInitials} from './utils';
import {resolveRoute} from '../../lib/router/resolveRoute';
import {ROUTE_PATHS} from '../../lib/router/route-paths';
import ReactRouterLink from '../app/router/ReactRouterLink';

const Coach = ({rowData}) => {
    if (rowData?.coachFullName === '-') {
        return (
            <Avatar sx={{
                bgcolor: '#f3f3f3',
                color: 'text_v2.primary',
            }}
            > -
            </Avatar>
        );
    }

    return (
        rowData?.coachFullName
            ? (
                <ReactRouterLink
                    to={resolveRoute(ROUTE_PATHS.USER, {userId: rowData.coachId})}
                    sx={{'&:hover': {textDecoration: 'none'}}}
                    onClick={e => e.stopPropagation()}
                >
                    <Tooltip title={rowData?.coachFullName} enterTouchDelay={0} arrow placement="top">
                        <Avatar>{capitalizeInitials(rowData?.coachFullName)}</Avatar>
                    </Tooltip>
                </ReactRouterLink>
            )
            : null
    );
};

Coach.propTypes = {
    rowData: PropTypes.object.isRequired,
};

export default Coach;
