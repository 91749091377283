import {all, call, put, select, take, takeLatest} from 'redux-saga/effects';

import * as actions from './currentDocument.actions';
import {selectCurrentDocumentId} from './currentDocument.selectors';
import {DOCUMENT_STATUSES} from '../../../../../consts/document-constants';
import {Toast} from '../../../../../lib/toast';
import {getSignedUrlForDocumentRequest, signDocumentRequest} from '../../../api/providers/company/company.provider';
import {getFreelancerCompanies} from '../../../freelancer/freelancer.actions';
import {safe} from '../../../safeSaga';
import {getCompanyId} from '../../../utils/get-company-id';
import {getFreelancerId} from '../../../utils/get-freelancer-id';
import {STORE_COMPANY, getCompany} from '../../company.actions';
import {COMPANY_STATUSES} from '../../setupCompany/setupCompany.constants';
import {setHasUnsignedDocuments, storeCompanyDocuments} from '../companyDocuments.actions';
import {selectCompanyDocuments} from '../companyDocuments.selectors';

const getCurrentCompanyDocumentSaga = function* (documentId) {
    if (!documentId) {
        return;
    }

    yield put(actions.setIsLoadingCurrentDocument(true));

    const freelancerId = yield call(getFreelancerId);
    const companyId = yield call(getCompanyId);

    /**
     * @property {String|undefined|null} data.signedUrl The URL which can be used to download/open the document
     */
    const data = yield call(getSignedUrlForDocumentRequest, freelancerId, companyId, documentId, true);

    if (!data.hasOwnProperty('signedUrl') || !data.signedUrl) {
        Toast.error('missingDocument');
    }

    yield put(actions.storeCurrentDocumentUrl(data?.signedUrl));

    yield put(actions.setIsLoadingCurrentDocument(false));
};

const signCurrentDocumentSaga = function* () {
    yield put(actions.setIsSigningCurrentDocument(true));

    const freelancerId = yield call(getFreelancerId);
    const companyId = yield call(getCompanyId);
    const documentId = yield select(selectCurrentDocumentId);

    const {allDocumentsSigned} = yield call(signDocumentRequest, freelancerId, companyId, documentId);

    yield put(getCompany(freelancerId, companyId));
    yield put(getFreelancerCompanies(freelancerId));

    const {payload: company} = yield take(STORE_COMPANY);

    const {status} = company;

    if (allDocumentsSigned) {
        if (status === COMPANY_STATUSES.READY_FOR_REVIEW || status === COMPANY_STATUSES.ACTIVE) {
            yield put(setHasUnsignedDocuments(false));
        }
    }

    yield put(actions.setIsResigningDocument(false));

    const documents = yield select(selectCompanyDocuments);

    const newDocuments = Object.keys(documents)
        .reduce((result, current) => {
            if (current === documentId) {
                result[current] = {
                    ...documents[current],
                    status: DOCUMENT_STATUSES.SIGNED,
                };

                return result;
            }

            result[current] = documents[current];

            return result;
        }, {});

    yield put(storeCompanyDocuments(newDocuments));

    yield call(getCurrentCompanyDocumentSaga, documentId);

    yield put(actions.setIsSigningCurrentDocument(false));
};

const onSetCurrentCompanyDocument = function* ({payload}) {
    yield call(getCurrentCompanyDocumentSaga, payload);
};

export const watchCurrentDocumentSagas = function* () {
    yield all([
        takeLatest(actions.SET_CURRENT_COMPANY_DOCUMENT, safe(onSetCurrentCompanyDocument)),
        takeLatest(actions.SIGN_CURRENT_DOCUMENT, safe(signCurrentDocumentSaga)),
    ]);
};
