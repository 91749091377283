import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {Grow, Step, StepLabel, Stepper} from '@mui/material';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import UmbrellaLoadingSteps from './UmbrellaLoadingSteps';
import {UmbrellaStepperSubStep} from './UmbrellaSubStepper';
import {push, selectRouterLocation} from '../../../../lib/router/connected-router-saga';
import {RoutePaths} from '../../../../lib/router/route-paths';
import {CompanySetupSubSteps} from '../../../freelancer/modules/company-setup/utils/constants';
import {OnboardingSteps} from '../../../freelancer/modules/onboarding/utils/onboadingStepsConstant';
import {LoadingTypes, useLoadingState} from '../../../loading';
import {SidebarSelectors} from '../../../sidebar/store/sidebar.selector';
import {MainOnboardingSelectors} from '../../store/main-onboarding.slector';
import {UmbrellaSteps} from '../../utils/umbrella.steps';

const UmbrellaStepper = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation('freelancerOnboarding');

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const location = useSelector(selectRouterLocation);
    const isOnboardingLocation = location?.pathname === RoutePaths.UMBRELLA_ONBOARDING;

    const onboarding = useSelector(MainOnboardingSelectors.selectOnboarding);
    const activeStepKey = useSelector(MainOnboardingSelectors.selectStep);
    const activeSubStepKey = useSelector(MainOnboardingSelectors.selectSubStep);

    const onboardingSteps = {...UmbrellaSteps.PORTAGE_MAIN_STEPS_OBJECT};
    const onboardingStepsArray = Object.values(onboardingSteps);

    const activeStepIndex = onboardingStepsArray.findIndex(({id}) => id === activeStepKey);

    const isSidebarExpandedSelector = useSelector(SidebarSelectors.selectIsSidebarExpanded);
    const isSidebarExpanded = isMobileSize ? true : isSidebarExpandedSelector;
    const isLoading = useLoadingState(LoadingTypes.ONBOARDING);

    const hasLabel = isSidebarExpanded || isMobileSize;

    const onClick = () => () => {
        // TODO inside the onboarding
        if (!isOnboardingLocation) {
            dispatch(push(RoutePaths.UMBRELLA_ONBOARDING));
        }
    };

    if (!activeStepKey) {
        return null;
    }


    if (isLoading) {
        return (
            <UmbrellaLoadingSteps
                isSidebarExpanded={isSidebarExpanded}
                onboardingStepsArray={onboardingStepsArray}
            />
        );
    }

    return (
        <Box sx={{
            px: isSidebarExpanded ? 3 : 3.45,
            pt: 3,
            mt: isSidebarExpanded ? 0 : -1,
        }}
        >
            <Stepper orientation="vertical" activeStep={activeStepIndex}>
                {onboardingStepsArray.map((step, index) => {
                    const isCompleted = index < activeStepIndex || (step.isCompleted && step.isCompleted(onboarding));
                    const isCurrentlyOpen = index === activeStepIndex;
                    // const isNext = index === activeStepIndex + 1;

                    const hasClickEvent = !isOnboardingLocation; // TODO isCompleted || (!isCompleted && isNext) ||

                    return (
                        <Step
                            key={step.id}
                            onClick={hasClickEvent ? onClick(step.id) : undefined}
                            sx={
                                hasClickEvent
                                    ? {cursor: 'pointer', position: 'relative'}
                                    : {position: 'relative'}}
                            completed={isCompleted}
                            active={isCurrentlyOpen}
                            disabled={false}
                        >
                            <Grow
                                in={(
                                    (
                                        isCurrentlyOpen
                                        && (
                                            step.id === activeStepKey
                                            && (!step.subSteps || !activeSubStepKey)
                                        )
                                    ) || (
                                        step.key === activeStepKey
                                        && activeStepKey === OnboardingSteps.COMPANY_SETUP
                                        && activeSubStepKey === CompanySetupSubSteps.DATA_VALIDATED)
                                )}
                                unmountOnExit
                                mountOnEnter
                            >
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: -24,
                                        bottom: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: '100%',
                                        color: 'rgba(25, 118, 210, 0.5)',
                                    }}
                                >
                                    <ChevronRightIcon />
                                </Box>
                            </Grow>

                            <StepLabel>
                                {hasLabel
                                    ? (
                                        <Trans
                                            t={t}
                                            i18nKey={step.labelKey}
                                        />
                                    ) : ''}
                            </StepLabel>

                            {!!step.subSteps && step.hasVisibleSubSteps && (
                                <UmbrellaStepperSubStep
                                    hasLabel={hasLabel}
                                    subSteps={step.subSteps}
                                />
                            )}
                        </Step>
                    );
                })}
            </Stepper>
        </Box>
    );
};

export default UmbrellaStepper;
