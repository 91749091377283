import {
    OnboardingStatus,
    PortageOnboardingProcessState,
    PortageOnboardingState,
    TERM_DOCUMENTS,
} from './onboarding.constants';
import Launch from '../components/umbrella/finalization/Launch';
import UmbrellaFinalizationStep from '../components/umbrella/finalization/UmbrellaFinalizationStep';
import DocumentInformation from '../components/umbrella/information-step/DocumentInformation';
import PersonalInformation from '../components/umbrella/information-step/PersonalInformation';
import UmbrellaInformationStep from '../components/umbrella/information-step/UmbrellaInformationStep';
import AcceptCGVS from '../components/umbrella/membership-step/AcceptCGVS';
import AcceptDP from '../components/umbrella/membership-step/AcceptDP';
import Kickoff from '../components/umbrella/membership-step/Kickoff';
import TermsAccepted from '../components/umbrella/membership-step/TermsAccepted';
import TermsRefused from '../components/umbrella/membership-step/TermsRefused';
import UmbrellaMembershipStep from '../components/umbrella/membership-step/UmbrellaMembershipStep';

// Steps and substeps list
const ALL_PORTAGE_STEPS = {
    MEMBERSHIP_STEP: 'MEMBERSHIP_STEP',
    INFORMATION_STEP: 'INFORMATION_STEP',
    FINALIZATION_STEP: 'FINALIZATION_STEP',
};

const ALL_PORTAGE_SUBSTEPS = {
    // Membership
    KICKOFF_SUBSTEP: 'KICKOFF_SUBSTEP',
    CGVS_SIGNING_SCREEN_SUBSTEP: 'CGVS_SIGNING_SCREEN_SUBSTEP',
    ADP_SIGNING_SCREEN_SUBSTEP: 'ADP_SIGNING_SCREEN_SUBSTEP',
    USER_ACCEPTED_DOCUMENTS_SUBSTEP: 'USER_ACCEPTED_DOCUMENTS_SUBSTEP',
    USER_REFUSED_DOCUMENTS_SUBSTEP: 'USER_REFUSED_DOCUMENTS_SUBSTEP',

    // Information
    PERSONAL_INFORMATION_SUBSTEP: 'PERSONAL_INFORMATION_SUBSTEP',
    UPLOAD_PERSONAL_DOCUMENTS_SUBSTEP: 'UPLOAD_PERSONAL_DOCUMENTS_SUBSTEP',

    // Finalization
    ROCKET_SUBSTEP: 'ROCKET_SUBSTEP',
};


// Substeps per step
const MEMBERSHIP_SUBSTEPS = {
    [ALL_PORTAGE_SUBSTEPS.KICKOFF_SUBSTEP]: {
        id: ALL_PORTAGE_SUBSTEPS.KICKOFF_SUBSTEP,
        component: Kickoff,
        shouldLandOnThePage: ({state}) => {
            return state?.[PortageOnboardingState.termsAndConditionState] === PortageOnboardingProcessState.Pending;
        },
    },
    [ALL_PORTAGE_SUBSTEPS.CGVS_SIGNING_SCREEN_SUBSTEP]: {
        id: ALL_PORTAGE_SUBSTEPS.CGVS_SIGNING_SCREEN_SUBSTEP,
        component: AcceptCGVS,
    },
    [ALL_PORTAGE_SUBSTEPS.ADP_SIGNING_SCREEN_SUBSTEP]: {
        id: ALL_PORTAGE_SUBSTEPS.ADP_SIGNING_SCREEN_SUBSTEP,
        component: AcceptDP,
        shouldLandOnThePage: ({state, consents}) => {
            return state?.[PortageOnboardingState.termsAndConditionState] === PortageOnboardingProcessState.Pending
                && consents[TERM_DOCUMENTS.ADP] === false;
        },
    },
    [ALL_PORTAGE_SUBSTEPS.USER_ACCEPTED_DOCUMENTS_SUBSTEP]: {
        id: ALL_PORTAGE_SUBSTEPS.USER_ACCEPTED_DOCUMENTS_SUBSTEP,
        component: TermsAccepted,
    },
    [ALL_PORTAGE_SUBSTEPS.USER_REFUSED_DOCUMENTS_SUBSTEP]: {
        id: ALL_PORTAGE_SUBSTEPS.USER_REFUSED_DOCUMENTS_SUBSTEP,
        component: TermsRefused,
    },
};

const INFORMATION_SUBSTEPS = {
    [ALL_PORTAGE_SUBSTEPS.PERSONAL_INFORMATION_SUBSTEP]: {
        id: ALL_PORTAGE_SUBSTEPS.PERSONAL_INFORMATION_SUBSTEP,
        component: PersonalInformation,
        labelKey: 'umbrella.informationSubStep.personalInformation',
        shouldLandOnThePage: ({state}) => {
            // eslint-disable-next-line max-len
            return state?.[PortageOnboardingState.personalInfoState] === PortageOnboardingProcessState.PendingPersonalInfo;
        },
    },
    [ALL_PORTAGE_SUBSTEPS.UPLOAD_PERSONAL_DOCUMENTS_SUBSTEP]: {
        id: ALL_PORTAGE_SUBSTEPS.UPLOAD_PERSONAL_DOCUMENTS_SUBSTEP,
        component: DocumentInformation,
        labelKey: 'umbrella.informationSubStep.documentInformation',
        shouldLandOnThePage: ({state}) => {
            // eslint-disable-next-line max-len
            return state?.[PortageOnboardingState.personalDocumentsUploadState] === PortageOnboardingProcessState.PendingPersonalDocuments;
        },
    },
};

const FINALIZATION_SUBSTEPS = {
    [ALL_PORTAGE_SUBSTEPS.ROCKET_SUBSTEP]: {
        id: ALL_PORTAGE_SUBSTEPS.ROCKET_SUBSTEP,
        component: Launch,
        shouldLandOnThePage: ({state, status}) => {
            // eslint-disable-next-line max-len
            return state?.[PortageOnboardingState.personalDocumentsUploadState] === PortageOnboardingProcessState.Completed
                && status !== OnboardingStatus.COMPLETED;
        },
    },
};

// Define steps
const PORTAGE_MAIN_STEPS_OBJECT = {
    MEMBERSHIP_STEP: {
        id: ALL_PORTAGE_STEPS.MEMBERSHIP_STEP,
        component: UmbrellaMembershipStep,
        subSteps: MEMBERSHIP_SUBSTEPS,
        firstSubStep: ALL_PORTAGE_SUBSTEPS.KICKOFF_SUBSTEP,
        fallbackSubStep: ALL_PORTAGE_SUBSTEPS.USER_ACCEPTED_DOCUMENTS_SUBSTEP,
        hasVisibleSubSteps: false,
        labelKey: 'umbrella.membershipStep',
    },
    INFORMATION_STEP: {
        id: ALL_PORTAGE_STEPS.INFORMATION_STEP,
        component: UmbrellaInformationStep,
        subSteps: INFORMATION_SUBSTEPS,
        firstSubStep: ALL_PORTAGE_SUBSTEPS.PERSONAL_INFORMATION_SUBSTEP,
        fallbackSubStep: ALL_PORTAGE_SUBSTEPS.UPLOAD_PERSONAL_DOCUMENTS_SUBSTEP,
        hasVisibleSubSteps: true,
        labelKey: 'umbrella.informationStep',
    },
    FINALIZATION_STEP: {
        id: ALL_PORTAGE_STEPS.FINALIZATION_STEP,
        component: UmbrellaFinalizationStep,
        subSteps: FINALIZATION_SUBSTEPS,
        firstSubStep: ALL_PORTAGE_SUBSTEPS.ROCKET_SUBSTEP,
        hasVisibleSubSteps: false,
        labelKey: 'umbrella.finalizationStep',
        isCompleted: ({state}) => {
            return state?.[PortageOnboardingState.personalDocumentsUploadState]
                === PortageOnboardingProcessState.Completed;
        },
    },
};


export const UmbrellaSteps = {
    ALL_PORTAGE_STEPS,
    ALL_PORTAGE_SUBSTEPS,
    PORTAGE_MAIN_STEPS_OBJECT,
};
