import {createSelector} from 'reselect';
import {DOCUMENT_STATUSES} from '../../../../consts/document-constants';
import {
    createDataTableSearchCallback,
    createDataTableSortCallback,
    createDataTableTabFilterCallback,
    getPaginatedResult,
} from '../../../../utils/data-table-utils';

export const selectCompanyDocuments = state => state.v1Company.companyDocuments.companyDocuments;

export const selectHiwayDocuments = state => state.v1Company.companyDocuments.hiwayDocuments;

export const selectIsLoadingCompanyDocuments = state => state.v1Company.companyDocuments.isLoading;

export const selectHasDocumentGenerationError = state => state.v1Company.companyDocuments.hasDocumentGenerationError;

// export const selectHasUnsignedDocuments = state => state.v1Company.companyDocuments.hasUnsignedDocuments;

export const selectIsAllDocumentsSignedAlertOpen = state => state
    .v1Company.companyDocuments.isAllDocumentsSignedAlertOpen;

export const createCompanyDocumentsSelector = () => createSelector(
    selectCompanyDocuments,
    documents => Object.values(documents),
);

export const createDocumentByTypeSelector = type => createSelector(
    [selectCompanyDocuments],
    documents => {
        return Object.values(documents).find(document => document.type === type);
    },
);

export const createHiwayDocumentsSelector = () => createSelector(
    selectHiwayDocuments,
    documents => Object.values(documents),
);

export const createDocumentsPendingSignatureSelector = () => createSelector(
    selectCompanyDocuments,
    documents => Object.values(documents)
        .filter(
            document => [
                // TODO: See if we need status GENERATING here
                DOCUMENT_STATUSES.GENERATING,
                DOCUMENT_STATUSES.PENDING_SIGNING,
            ].includes(document.status),
        ),
);

export const createDocumentsDataTableSelector = ({
    sortBy,
    sortDirection,
    pagination,
    tabFilterKey,
    tabFilterValue,
    searchBy,
    searchTerm,
}) => createSelector(
    [selectCompanyDocuments],
    documents => {
        if (!Object.keys(documents).length) {
            return {data: [], count: 0};
        }

        let data = Object.values(documents);

        if (tabFilterKey && tabFilterValue && tabFilterValue !== 'ALL') {
            data = data.filter(createDataTableTabFilterCallback(tabFilterKey, tabFilterValue));
        }

        if (searchTerm && searchBy && searchBy?.length) {
            data = data.filter(createDataTableSearchCallback(searchBy, searchTerm));
        }

        if (sortBy) {
            data.sort(createDataTableSortCallback(sortBy, sortDirection));
        }

        return getPaginatedResult(data, pagination);
    },
);

export const selectAtLeastOnePayCategoryDocumentExist = state => state
    .v1Company
    .companyDocuments
    .atLastOnePayCategoryDocumentExists;
