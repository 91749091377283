import {TRAINING_CONSTANTS} from '../../../../training/utils/constants';
import {fromContactInfoDTO, toContactInfoDTO} from '../../../api/user.dto';

export const LoggedInUserAccount = function (dto) {
    this.id = dto.id;
    this.isActive = dto.active;
    this.email = dto.email;
    this.firstName = dto.first_name;
    this.lastName = dto.last_name;
    this.role = dto.role;
    this.status = dto.status;
    this.active = dto.active;
    this.isFullyActive = dto.is_fully_active;
    this.phone = dto.phone;
    this.onboardingLaunchedStatus = dto.additional_info?.on_boarding_launched_status;
    this.defaultCompanyId = dto.default_company_id;
    this.defaultCompanyName = dto.default_company_name;
    this.hasTrainingMenu = dto.default_company_training_info
        ? dto.default_company_training_info.is_eligible
        && dto.default_company_training_info.status !== TRAINING_CONSTANTS.TRAINING_STATUS.COMPLETED
        : false;
    this.additionalInfo = dto.additional_info;
    this.hasBankAccess = dto.additional_info?.can_access_bank_feature ?? false;
    this.hasInvoiceAccess = dto.additional_info?.can_access_invoicing_v2_feature ?? false;
    this.hasBridgeAccess = dto.additional_info?.can_access_bridge_api_feature ?? false;
    this.isTestUser = dto.additional_info?.is_test_user ?? false;
    this.contactInfo = fromContactInfoDTO(dto.contact_info);
    this.onboardingStatus = dto?.onboarding_status ?? false;
};

export const toUserDTO = userDTO => ({
    id: userDTO.id,
    first_name: userDTO.firstName,
    last_name: userDTO.lastName,
    email: userDTO.email,
    role: userDTO.role,
    status: userDTO.status,
    phone: userDTO.phone,
    ...(!!userDTO?.contactInfo?.address && {contact_info: toContactInfoDTO(userDTO.contactInfo)}),
});
