import {all, call, delay, put, select, takeLatest} from 'redux-saga/effects';

import {storeCompanyInformationData} from './companyInformation/companyInformation.actions';
import {storeNonConvictionDeclarationData} from './nonConvictionDeclaration/nonConvictionDeclaration.actions';
import {storePersonalInformationData} from './personalInformation/personalInformation.actions';
import * as actions from './setupCompany.actions';
import {capitalDepositGenerateSecondBatchDocuments} from './setupCompany.actions';
import {BANKS, FULL_COMPANY_STATUSES, isCompanyEIFnc} from './setupCompany.constants';
import {AnimationActions} from '../../../../features/animations/store/animations.action';
import {updateCompaniesFlow} from '../../../../features/backoffice/store/backoffice.saga';
import {BankApi} from '../../../../features/bank/api/bank.api';
import {BANK_TYPES} from '../../../../features/bank/modules/bridge-api/utils/constants';
import {bankCareFinalizeOnboardingFlow} from '../../../../features/bank/store/bank.loader.saga';
import {BankSelector} from '../../../../features/bank/store/bank.selector';
import {DatabaseActions} from '../../../../features/document/modules/database/store/database.action';
import {getDocumentsFlow, loadDocuments} from '../../../../features/document/modules/database/store/database.saga';
import {DatabaseSelectors} from '../../../../features/document/modules/database/store/database.selector';
import {DocumentContexts} from '../../../../features/document/modules/database/utils/constants';
import {SigningActions} from '../../../../features/document/modules/signing/store/signing.action';
import {FreelancerSelectors} from '../../../../features/freelancer';
import {
    CapitalDepositStatus,
    FinalizationSubsteps,
} from '../../../../features/freelancer/modules/capital-deposit/utils/constants';
import {CompaniesSelectors} from '../../../../features/freelancer/modules/companies';
import {loadFreelancerCompanies} from '../../../../features/freelancer/modules/companies/store/companies.saga';
import {CompanyRegistrationInternalSubSteps} from '../../../../features/freelancer/modules/company-registration/utils/constants';
import {
    CompanySetupInternalSubSteps,
    CompanySetupSubSteps,
} from '../../../../features/freelancer/modules/company-setup/utils/constants';
import {OnboardingApi} from '../../../../features/freelancer/modules/onboarding/api/onboarding.api';
import {OnboardingActions} from '../../../../features/freelancer/modules/onboarding/store/onboarding.action';
import {OnboardingSelectors} from '../../../../features/freelancer/modules/onboarding/store/onboarding.selectors';
import {OnboardingSteps} from '../../../../features/freelancer/modules/onboarding/utils/onboadingStepsConstant';
import {LoadingActions, LoadingTypes} from '../../../../features/loading';
import {UserApi} from '../../../../features/user/api/user.api';
import {LoggedInUserActions, LoggedInUserSelectors} from '../../../../features/user/modules/logged-in-user';
import {Toast} from '../../../../lib/toast';
import {isHeadquartersAddressSameAsHiwayAddress} from '../../../../utils/company-utils';
import {Debug} from '../../../../utils/debug';
import {getIsRegistrationWithExistingCompany} from '../../../../utils/holidays';
import {isUserCareFn} from '../../../../utils/user-roles';
import {
    getCompanyInformationDataRequest,
    getDocumentUrls,
    getNonConvictionDeclarationRequest,
    getPersonalCompanyDocumentsRequest,
    getPersonalInformationDataRequest,
    putDataInputFinishedForFreelancerWithExistingCompany,
    saveCompanyIbanBicInformationDataRequest,
    saveCompanyInformationDataRequest,
    saveDocumentFlagsRequest,
    saveDocumentsRequest,
    saveNonConvictionDeclarationDataRequest,
    savePersonalInformationDataRequest,
    saveRegistrationBankInfoAndKbisRequest,
    uploadDocumentRequest,
} from '../../api/providers/company/company.provider';
import {generateDocumentsRequest, submitForApprovalRequest} from '../../api/providers/documents/documents.provider';
import {getCompany, storeCompany, storeCompanyDocuments} from '../company.actions';
import {selectCompany, selectCompanyDocuments} from '../company.selectors';

const createDocumentsSaga = function* (formData, freelancerId, companyId) {
    const documents = [];

    if (formData.identificationDocument) {
        documents.push({
            doc_type: 'ID_DOC',
            doc_sub_type: formData.identificationDocumentType,
            doc_file_name: formData.identificationDocument.name,
            doc_size: formData.identificationDocument.size,
        });
    }

    if (formData.residenceCertificationDocument) {
        documents.push({
            doc_type: 'RESIDENCE_CERT',
            doc_file_name: formData.residenceCertificationDocument.name,
            doc_size: formData.residenceCertificationDocument.size,
        });
    }

    if (formData.isUserHostedByThirdParty) {
        if (formData.hostIdentificationDocument) {
            documents.push({
                doc_type: 'RESIDENCE_CERT_HOST_ID',
                doc_sub_type: formData.hostIdentificationDocumentType,
                doc_file_name: formData.hostIdentificationDocument.name,
                doc_size: formData.hostIdentificationDocument.size,
            });
        }

        if (formData.hostCertificationDocument) {
            documents.push({
                doc_type: 'RESIDENCE_CERT_HOST',
                doc_file_name: formData.hostCertificationDocument.name,
                doc_size: formData.hostCertificationDocument.size,
            });
        }
    }

    if (formData.insuranceDocument) {
        documents.push({
            doc_type: 'NIN_DOC',
            doc_sub_type: formData.insuranceDocumentType,
            doc_file_name: formData.insuranceDocument.name,
            doc_size: formData.insuranceDocument.size,
        });
    }

    const signedDocuments = yield call(getDocumentUrls, freelancerId, companyId, documents);

    for (const signedDocument of signedDocuments) {
        let document = null;

        switch (signedDocument.doc_type) {
            case 'ID_DOC':
                document = formData.identificationDocument;

                break;

            case 'RESIDENCE_CERT':
                document = formData.residenceCertificationDocument;

                break;

            case 'RESIDENCE_CERT_HOST':
                document = formData.hostCertificationDocument;

                break;

            case 'RESIDENCE_CERT_HOST_ID':
                document = formData.hostIdentificationDocument;

                break;

            case 'NIN_DOC':
                document = formData.insuranceDocument;

                break;

            default:
                break;
        }

        yield call(uploadDocumentRequest, signedDocument.signed_url, document);
    }

    const finalDocuments = signedDocuments.map(document => ({
        document_id: document.document_id,
        doc_type: document.doc_type,
        doc_sub_type: document.doc_sub_type,
        doc_file_name: document.doc_file_name,
    }));

    yield call(saveDocumentsRequest, freelancerId, companyId, finalDocuments);
};

const submitAddressInformationStepSaga = function* (payload) {
    try {
        const user = yield select(LoggedInUserSelectors.selectLoggedInUser);
        const company = yield select(selectCompany);
        const useCustomCompanyAddress = company?.enterpriseInformation?.useCustomCompanyAddress ?? false;
        const {formData} = payload;

        const contactInfo = yield call(
            UserApi.putContactInfo,
            {
                userId: user?.id,
                data: formData,
                propagateAddressChange: useCustomCompanyAddress,
            },
        );
        yield put(LoggedInUserActions.storeLoggedInUserAccount({...user, contactInfo}));

        // If the useCompanyAddress flag is set, headquarters address will be changed as well,
        // so we locally update enterprise data
        if (useCustomCompanyAddress) {
            const enterpriseInformationFormData = {
                headquartersCity: formData.city,
                headquartersCountry: formData.country,
                headquartersStreet: formData.streetName,
                headquartersStreetNumber: formData.streetNumber,
                headquartersZip: formData.zipCode,
            };
            const companyData = yield select(selectCompany);
            const newCompanyData = {
                ...companyData,
                enterpriseInformation: {
                    ...companyData.enterpriseInformation,
                    headquarters_street_number: enterpriseInformationFormData.headquartersStreetNumber,
                    headquarters_street: enterpriseInformationFormData.headquartersStreet,
                    headquarters_zip: enterpriseInformationFormData.headquartersZip,
                    headquarters_city: enterpriseInformationFormData.headquartersCity,
                    headquarters_country: enterpriseInformationFormData.headquartersCountry,
                },
            };
            yield put(storeCompany(newCompanyData));
        }
    } catch (error) {
    // eslint-disable-next-line no-console
        console.error(error);

        throw new Error(error);
    }
};


const submitActiveStepFormSaga = function* ({payload}) {
    yield put(actions.setIsLoadingSetupCompanyForm(true));

    try {
        const activeStep = yield select(OnboardingSelectors.selectSubStep);

        if (!activeStep) {
            Toast.error('anErrorOccurred');
        }

        const {isDirty} = payload;

        const freelancer = yield select(FreelancerSelectors.selectAccount);
        const companyId = freelancer.defaultCompanyId;

        const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);
        const {id: freelancerId} = loggedInUser;

        const company = yield select(CompaniesSelectors.createCompanyByIdSelector(companyId));
        const useCustomCompanyAddress = company?.enterpriseInformation?.use_custom_company_address ?? false;

        if (isDirty) {
            const {formData} = payload;

            switch (activeStep) {
                case CompanySetupSubSteps.PERSONAL_INFORMATION: {
                    yield call(savePersonalInformationDataRequest, freelancerId, companyId, formData);
                    const data = yield call(getPersonalInformationDataRequest, freelancerId, companyId);

                    yield put(storePersonalInformationData(data));

                    break;
                }

                case CompanySetupSubSteps.ADDRESS_INFORMATION: {
                    yield call(submitAddressInformationStepSaga, payload);
                    break;
                }

                case CompanySetupSubSteps.DECLARATION_OF_NON_CONVICTION: {
                    yield call(saveNonConvictionDeclarationDataRequest, freelancerId, companyId, formData);
                    const data = yield call(getNonConvictionDeclarationRequest, freelancerId, companyId);

                    yield put(storeNonConvictionDeclarationData(data));

                    break;
                }

                case CompanySetupSubSteps.COMPANY_INFORMATION: {
                    const isCompanyEI = isCompanyEIFnc(company);

                    const companyInfoFormData = {
                        professionalActivity: formData.professionalActivity,
                        activityStartDate: formData.activityStartDate,
                        amountOfShareCapital: formData.amountOfShareCapital,
                        companyName: isCompanyEI ? undefined : formData.companyName,
                    };

                    yield call(saveCompanyInformationDataRequest, freelancerId, companyId, companyInfoFormData);
                    const data = yield call(getCompanyInformationDataRequest, freelancerId, companyId);

                    yield put(storeCompanyInformationData(data));

                    const companyData = yield select(selectCompany);
                    if (getIsRegistrationWithExistingCompany(companyData?.enterpriseInformation?.registrationType)) {
                        yield call(putDataInputFinishedForFreelancerWithExistingCompany, freelancerId, companyId);
                    }
                    break;
                }

                case CompanySetupSubSteps.BANK: {
                    const companyInfoFormData = {
                        bank: BANKS.OTHER === formData.bank
                            ? formData.bankName
                            : formData.bank,
                        bankAddress: formData.bankAddress,
                    };

                    yield call(saveCompanyInformationDataRequest, freelancerId, companyId, companyInfoFormData);
                    const data = yield call(getCompanyInformationDataRequest, freelancerId, companyId);

                    yield put(storeCompanyInformationData(data));

                    const companyData = yield select(selectCompany);
                    if (getIsRegistrationWithExistingCompany(companyData?.enterpriseInformation?.registrationType)) {
                        yield call(putDataInputFinishedForFreelancerWithExistingCompany, freelancerId, companyId);
                    }
                    break;
                }

                case CompanySetupSubSteps.DOCUMENT_UPLOAD: {
                    const documents = yield select(selectCompanyDocuments);
                    const {dirtyFields} = payload;

                    const documentsForUpload = {};

                    const documentKeys = [
                        'identificationDocument',
                        'residenceCertificationDocument',
                        'hostCertificationDocument',
                        'hostIdentificationDocument',
                        'insuranceDocument',
                    ];

                    documentKeys.forEach(documentKey => {
                        if (!dirtyFields[documentKey]) {
                            return;
                        }

                        documentsForUpload[documentKey] = formData[documentKey];

                        if (documentKey === 'identificationDocument') {
                            documentsForUpload
                                .identificationDocumentType = formData.identificationDocumentType;
                        } else if (documentKey === 'insuranceDocument') {
                            documentsForUpload
                                .insuranceDocumentType = formData.insuranceDocumentType;
                        } else if (documentKey === 'hostIdentificationDocument') {
                            documentsForUpload
                                .hostIdentificationDocumentType = formData.hostIdentificationDocumentType;
                        }
                    });

                    if (Object.keys(documentsForUpload).length) {
                        documentsForUpload.isUserHostedByThirdParty = formData.isUserHostedByThirdParty;

                        yield call(createDocumentsSaga, documentsForUpload, freelancerId, companyId);
                    }

                    const oldDocumentFlags = {
                        identificationDocumentType: documents?.identificationDocument?.subType || null,
                        insuranceDocumentType: documents?.insuranceDocument?.subType || null,
                        hostIdentificationDocumentType: documents?.hostIdentificationDocument?.subType || null,
                    };

                    const newDocumentFlags = {
                        identificationDocumentType: formData.identificationDocumentType,
                        insuranceDocumentType: formData.insuranceDocumentType,
                        hostIdentificationDocumentType: formData.hostIdentificationDocumentType,
                    };

                    const diffKeys = [
                        'identificationDocumentType',
                        'insuranceDocumentType',
                        'hostIdentificationDocumentType',
                    ];

                    let isDifferent = false;

                    diffKeys.forEach(key => {
                        if (oldDocumentFlags[key] !== newDocumentFlags[key]) {
                            isDifferent = true;
                        }
                    });

                    if (isDifferent) {
                        yield call(saveDocumentFlagsRequest, freelancerId, companyId, {
                            isUserHostedByThirdParty: formData.isUserHostedByThirdParty,
                            identificationDocumentType: formData.identificationDocumentType,
                            insuranceDocumentType: formData.insuranceDocumentType,
                            hostIdentificationDocumentType: formData.hostIdentificationDocumentType,
                        });
                    }

                    const data = yield call(getPersonalCompanyDocumentsRequest, freelancerId, companyId);

                    yield put(storeCompanyDocuments(data));

                    break;
                }

                default:
                    break;
            }
        } else {
            if (
                CompanySetupSubSteps.ADDRESS_INFORMATION === activeStep
                && useCustomCompanyAddress
            ) {
                const {
                    headquarters_city: headquartersCity,
                    headquarters_country: headquartersCountry,
                    headquarters_street: headquartersStreet,
                    headquarters_street_number: headquartersStreetNumber,
                    headquarters_zip: headquartersZip,
                } = company?.enterpriseInformation || {};

                if (isHeadquartersAddressSameAsHiwayAddress({
                    headquartersCity,
                    headquartersStreet,
                    headquartersStreetNumber,
                    headquartersZip,
                    headquartersCountry,
                })) {
                    yield call(submitAddressInformationStepSaga, payload);
                }
            }

            // If not dirty we still need to check if we need to run /data-input-finished
            // if it is existing company on company information with company status in PENDING_DATA_INPUT
            if (activeStep === CompanySetupSubSteps.COMPANY_INFORMATION) {
                const companyData = yield select(selectCompany);
                if (
                    companyData?.status === FULL_COMPANY_STATUSES.PENDING_DATA_INPUT
                    && getIsRegistrationWithExistingCompany(companyData?.enterpriseInformation?.registrationType)
                ) {
                    yield call(putDataInputFinishedForFreelancerWithExistingCompany, freelancerId, companyId);
                }
            }
        }

        const {isSubmittingForApproval} = payload;

        if (company.status === 'PENDING_INIT_SIGS' || company.status === 'READY_FOR_REVIEW') {
            const progress = yield select(OnboardingSelectors.selectProgress);

            yield all([
                call(OnboardingApi.resetCompanySetupFlow, {freelancerId, companyId}),
                put(OnboardingActions.setProgress({
                    ...progress,
                    COMPANY_SETUP: {
                        ...progress.COMPANY_SETUP,
                        subStepProgress: {
                            ...progress.COMPANY_SETUP.subStepProgress,
                            [CompanySetupSubSteps.DOCUMENT_GENERATION]: {
                                isCompleted: false,
                            },
                        },
                    },
                })),
            ]);

            yield call(loadFreelancerCompanies, {freelancerId});
        }

        if ((company.status === 'PENDING_INIT_SIGS' || company.status === 'READY_FOR_REVIEW') && isSubmittingForApproval) {
            const progress = yield select(OnboardingSelectors.selectProgress);

            yield all([
                put(OnboardingActions.setProgress({
                    ...progress,
                    COMPANY_SETUP: {
                        ...progress.COMPANY_SETUP,
                        subStepProgress: {
                            ...progress.COMPANY_SETUP.subStepProgress,
                            [CompanySetupSubSteps.DOCUMENT_GENERATION]: {
                                isCompleted: false,
                            },
                        },
                    },
                })),
                call(OnboardingApi.resetCompanySetupFlow, {freelancerId, companyId}),
            ]);

            yield call(loadFreelancerCompanies, {freelancerId});
        }

        if (isSubmittingForApproval) {
            const progress = yield select(OnboardingSelectors.selectProgress);

            yield call(submitForApprovalRequest, freelancerId, companyId);
            yield call(loadFreelancerCompanies, {freelancerId});

            yield all([
                put(actions.setIsLoadingSetupCompanyForm(false)),
                put(AnimationActions.storeNextDispatch(
                    OnboardingActions.setSubStep(CompanySetupSubSteps.DOCUMENT_GENERATION),
                )),
                put(AnimationActions.setIsAnimationActive(false)),
                put(AnimationActions.setIsSubAnimationActive(false)),
                put(OnboardingActions.setInternalSubStep(null)),
                put(SigningActions.setCurrentCompanyDocument(null)),
                put(SigningActions.setCurrentDocumentIsSigned(false)),
                put(SigningActions.setCurrentDocumentUrl(null)),
                put(SigningActions.setCurrentDocumentId(null)),
                put(OnboardingActions.setProgress({
                    ...progress,
                    COMPANY_SETUP: {
                        ...progress.COMPANY_SETUP,
                        subStepProgress: {
                            ...progress.COMPANY_SETUP.subStepProgress,
                            [CompanySetupSubSteps.DOCUMENT_GENERATION]: {
                                isCompleted: false,
                            },
                        },
                    },
                })),
            ]);

            yield call(loadDocuments, {freelancerId, companyId, context: 'signable'});
            const documentsNumber = yield select(DatabaseSelectors.selectNumberOfDocuments());
            // TODO This should hopefully be improved on BE or solved with SSE
            // If there are no documents wait before trying again
            // because it might be case when they are deleted on BE and new ones are not yet generated
            if (documentsNumber === 0) {
                yield delay(2000);
                yield call(loadDocuments, {freelancerId, companyId, context: 'signable'});
                const documentsNumber = yield select(DatabaseSelectors.selectNumberOfDocuments());

                // If there are still no document we need to go to AWAITING_DOCUMENT_VERIFICATION step
                if (documentsNumber === 0) {
                    yield put(OnboardingActions.setInternalSubStep(
                        CompanySetupInternalSubSteps.AWAITING_DOCUMENT_VERIFICATION,
                    ));
                }
            }
            yield put(actions.setIsLoadingSetupCompanyForm(false));

            return;
        }

        const subStep = yield select(OnboardingSelectors.selectSubStep);
        const progress = yield select(OnboardingSelectors.selectProgress);
        const registrationWithExistingCompany = getIsRegistrationWithExistingCompany(company?.registrationType);

        if (subStep === CompanySetupSubSteps.PERSONAL_INFORMATION) {
            yield put(AnimationActions.storeNextDispatch(
                OnboardingActions.setSubStep(CompanySetupSubSteps.ADDRESS_INFORMATION),
            ));
            yield put(AnimationActions.setIsSubAnimationActive(false));

            yield put(OnboardingActions.setProgress({
                ...progress,
                COMPANY_SETUP: {
                    ...progress.COMPANY_SETUP,
                    subStepProgress: {
                        ...progress.COMPANY_SETUP.subStepProgress,
                        [CompanySetupSubSteps.PERSONAL_INFORMATION]: {
                            isCompleted: true,
                        },
                    },
                },
            }));
        } else if (subStep === CompanySetupSubSteps.ADDRESS_INFORMATION) {
            yield put(AnimationActions.storeNextDispatch(
                OnboardingActions.setSubStep(
                    registrationWithExistingCompany || isUserCareFn(loggedInUser)
                        ? CompanySetupSubSteps.COMPANY_INFORMATION
                        : CompanySetupSubSteps.DECLARATION_OF_NON_CONVICTION,
                ),
            ));
            yield put(AnimationActions.setIsSubAnimationActive(false));

            yield put(OnboardingActions.setProgress({
                ...progress,
                COMPANY_SETUP: {
                    ...progress['COMPANY_SETUP'],
                    subStepProgress: {
                        ...progress['COMPANY_SETUP'].subStepProgress,
                        [CompanySetupSubSteps.ADDRESS_INFORMATION]: {
                            isCompleted: true,
                        },
                    },
                },
            }));
        } else if (subStep === CompanySetupSubSteps.DECLARATION_OF_NON_CONVICTION) {
            yield put(AnimationActions.storeNextDispatch(
                OnboardingActions.setSubStep(CompanySetupSubSteps.COMPANY_INFORMATION),
            ));
            yield put(AnimationActions.setIsSubAnimationActive(false));

            yield put(OnboardingActions.setProgress({
                ...progress,
                COMPANY_SETUP: {
                    ...progress['COMPANY_SETUP'],
                    subStepProgress: {
                        ...progress['COMPANY_SETUP'].subStepProgress,
                        [CompanySetupSubSteps.DECLARATION_OF_NON_CONVICTION]: {
                            isCompleted: true,
                        },
                    },
                },
            }));
        } else if (subStep === CompanySetupSubSteps.COMPANY_INFORMATION) {
            if (!registrationWithExistingCompany) {
                yield put(AnimationActions.storeNextDispatch(
                    OnboardingActions.setSubStep(CompanySetupSubSteps.BANK),
                ));
                yield put(AnimationActions.setIsSubAnimationActive(false));

                yield put(OnboardingActions.setProgress({
                    ...progress,
                    COMPANY_SETUP: {
                        ...progress['COMPANY_SETUP'],
                        subStepProgress: {
                            ...progress['COMPANY_SETUP'].subStepProgress,
                            [CompanySetupSubSteps.COMPANY_INFORMATION]: {
                                isCompleted: true,
                            },
                        },
                    },
                }));
            } else {
                yield all([
                    call(getCompany, {freelancerId, companyId}),
                    put(OnboardingActions.setStep(OnboardingSteps.COMPANY_REGISTRATION)),
                    put(OnboardingActions.setInternalSubStep(CompanyRegistrationInternalSubSteps.IBAN_BIC_FORM)),
                    put(OnboardingActions.setSubStep(null)),
                    yield put(OnboardingActions.setProgress({
                        ...progress,
                        COMPANY_SETUP: {
                            ...progress['COMPANY_SETUP'],
                            isCompleted: true,
                            subStepProgress: {
                                ...progress['COMPANY_SETUP'].subStepProgress,
                                [CompanySetupSubSteps.COMPANY_INFORMATION]: {
                                    isCompleted: true,
                                },
                            },
                        },
                    })),
                ]);
                put(AnimationActions.storeNextDispatch(
                    OnboardingActions.setStep(OnboardingSteps.COMPANY_REGISTRATION),
                ));
            }
        } else if (subStep === CompanySetupSubSteps.BANK) {
            yield put(AnimationActions.storeNextDispatch(
                OnboardingActions.setSubStep(CompanySetupSubSteps.DOCUMENT_UPLOAD),
            ));
            yield put(AnimationActions.setIsSubAnimationActive(false));

            yield put(OnboardingActions.setProgress({
                ...progress,
                COMPANY_SETUP: {
                    ...progress['COMPANY_SETUP'],
                    subStepProgress: {
                        ...progress['COMPANY_SETUP'].subStepProgress,
                        [CompanySetupSubSteps.BANK]: {
                            isCompleted: true,
                        },
                    },
                },
            }));
        } else if (subStep === CompanySetupSubSteps.DOCUMENT_UPLOAD) {
            yield call(generateDocumentsRequest, freelancerId, companyId);

            const progress = yield select(OnboardingSelectors.selectProgress);

            yield all([
                put(actions.setIsLoadingSetupCompanyForm(false)),
                put(LoadingActions.setLoading(LoadingTypes.GENERATING_DOCUMENTS, true)),
                put(AnimationActions.storeNextDispatch(
                    OnboardingActions.setSubStep(CompanySetupSubSteps.DOCUMENT_GENERATION),
                )),
                put(AnimationActions.setIsAnimationActive(false)),
                put(AnimationActions.setIsSubAnimationActive(false)),
                put(OnboardingActions.setInternalSubStep(null)),
                put(SigningActions.setCurrentCompanyDocument(null)),
                put(SigningActions.setCurrentDocumentIsSigned(false)),
                put(SigningActions.setCurrentDocumentUrl(null)),
                put(SigningActions.setCurrentDocumentId(null)),
                put(OnboardingActions.setProgress({
                    ...progress,
                    COMPANY_SETUP: {
                        ...progress.COMPANY_SETUP,
                        subStepProgress: {
                            ...progress.COMPANY_SETUP.subStepProgress,
                            [CompanySetupSubSteps.DOCUMENT_UPLOAD]: {
                                isCompleted: true,
                            },
                        },
                    },
                })),
                put(AnimationActions.storeNextDispatch(
                    OnboardingActions.setSubStep(CompanySetupSubSteps.DOCUMENT_GENERATION),
                )),
                put(AnimationActions.setIsSubAnimationActive(false)),
            ]);

            yield call(loadFreelancerCompanies, {freelancerId});
            yield call(loadDocuments, {freelancerId, companyId, context: 'signable'});
            const documentsNumber = yield select(DatabaseSelectors.selectNumberOfDocuments());
            if (0 === documentsNumber) {
                yield put(OnboardingActions.setInternalSubStep(
                    CompanySetupInternalSubSteps.AWAITING_DOCUMENT_VERIFICATION,
                ));
            }
        }

        yield put(actions.setIsLoadingSetupCompanyForm(false));
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        // TODO:LOW Better error handling.
        Toast.error('anErrorOccurred');

        /**
         * This is not part of finally because, on success, we will go to the next step.
         * When we get to next step, we will start the loading process for that step, form is still loading.
         */
        yield put(actions.setIsLoadingSetupCompanyForm(false));
    }
};

const capitalDepositGenerateSecondBatchDocumentsFlow = function* ({capitalDeposit, progress, freelancerAccount}) {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);
    if (capitalDeposit?.status !== CapitalDepositStatus.COMPLETED && !isUserCareFn(loggedInUser)) {
        Toast.error('genericError');
        return;
    }

    try {
        yield put(LoadingActions.setLoading(LoadingTypes.SAVE_COMPANY_INFORMATION, true));

        const freelancerId = freelancerAccount?.id;
        const companyId = freelancerAccount?.defaultCompanyId;

        // Generate documents for stage 2
        yield put(DatabaseActions.clearDocuments());
        yield call(generateDocumentsRequest, freelancerId, companyId, 2);

        // DB entry is async so we need delay to make sure data we get is correct one
        yield delay(500);

        progress[OnboardingSteps.FINAL_POINTS].subStepProgress = {
            [FinalizationSubsteps.PENDING_FINALIZATION]: {
                isCompleted: true,
            },
        };
        yield put(OnboardingActions.setProgress(progress));

        // Move to the next step
        yield put(OnboardingActions.setStep(
            OnboardingSteps.FINAL_POINTS,
        ));
        yield put(OnboardingActions.setSubStep(
            FinalizationSubsteps.DOCUMENT_SIGNING_FINALIZATION,
        ));
        yield put(OnboardingActions.setInternalSubStep(
            CompanyRegistrationInternalSubSteps.FILE_LIST,
        ));
        yield put(AnimationActions.setIsSubAnimationActive(false));

        yield put(LoadingActions.setLoading(LoadingTypes.SAVE_COMPANY_INFORMATION, false));
    } catch (e) {
        Debug.error('capitalDepositGenerateSecondBatchDocumentsFlow', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.SAVE_COMPANY_INFORMATION, false));
    }
};

const submitRegistrationBankInfoAndKbisFlow = function* ({
    formData,
    freelancerAccount,
    integrations,
    progress,
    company,
}) {
    try {
        const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

        yield put(LoadingActions.setLoading(LoadingTypes.SAVE_COMPANY_INFORMATION, true));

        const id = freelancerAccount?.id;
        const companyId = freelancerAccount?.defaultCompanyId;

        const hasIntegration = !!integrations?.[0];

        yield call(
            saveRegistrationBankInfoAndKbisRequest,
            id,
            companyId,
            formData,
        );

        // TODO This logic should be in helper functions
        if (hasIntegration) {
            // Setup sidebar progress for step 5
            progress[OnboardingSteps.COMPANY_REGISTRATION] = {
                ...progress[OnboardingSteps.COMPANY_REGISTRATION],
                isCompleted: true,
            };
            yield put(OnboardingActions.setProgress(progress));

            if (!isUserCareFn(loggedInUser)) {
                // Move to the next step
                yield put(OnboardingActions.setStep(
                    OnboardingSteps.FINAL_POINTS,
                ));
                yield put(OnboardingActions.setSubStep(FinalizationSubsteps.PENDING_FINALIZATION));
                yield put(OnboardingActions.setInternalSubStep(FinalizationSubsteps.PENDING_FINALIZATION));
                yield put(AnimationActions.setIsSubAnimationActive(false));
            }
        } else {
            // Generate documents for stage 2 if does not have integration
            yield put(DatabaseActions.clearDocuments());
            yield call(generateDocumentsRequest, freelancerAccount.id, company.id, 2);

            // DB entry is async so we need delay to make sure data we get is correct one
            yield delay(500);

            // Check if there are documents any documents to determine step
            yield call(getDocumentsFlow, {
                freelancerId: freelancerAccount.id,
                companyId: company.id,
                context: DocumentContexts.STAGE_TWO,
            });
            const documentsNumber = yield select(DatabaseSelectors.selectNumberOfDocuments());

            if (documentsNumber > 0) {
                yield all([
                    // Go to the next sub step - file generation and list
                    put(AnimationActions.storeNextDispatch(
                        OnboardingActions.setInternalSubStep(CompanyRegistrationInternalSubSteps.FILE_LIST),
                    )),
                    put(AnimationActions.setIsSubAnimationActive(false)),
                ]);
            } else {
                // Setup sidebar progress for step 5
                progress[OnboardingSteps.COMPANY_REGISTRATION] = {
                    ...progress[OnboardingSteps.COMPANY_REGISTRATION],
                    isCompleted: true,
                };
                yield put(OnboardingActions.setProgress(progress));

                // Move to the next step
                yield put(OnboardingActions.setStep(
                    OnboardingSteps.FINAL_POINTS,
                ));
                yield put(OnboardingActions.setInternalSubStep(
                    'INITIAL_SCREEN',
                ));
            }
        }

        yield put(LoadingActions.setLoading(LoadingTypes.SAVE_COMPANY_INFORMATION, false));
    } catch (e) {
        Debug.error('submitRegistrationBankInfoAndKbisFlow', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.SAVE_COMPANY_INFORMATION, false));
    }
};

const submitIbanBicWorker = function* ({payload}) {
    const {formData, to} = payload;

    yield put(LoadingActions.setLoading(LoadingTypes.SAVE_COMPANY_INFORMATION, true));

    let id;
    let companyId;

    if (to.freelancerId) {
        id = to.freelancerId;
    }

    if (to.companyId) {
        companyId = to.companyId;
    }

    yield call(
        saveCompanyIbanBicInformationDataRequest,
        id,
        companyId,
        formData,
    );

    yield put(LoadingActions.setLoading(LoadingTypes.SAVE_COMPANY_INFORMATION, false));
    yield call(updateCompaniesFlow);
};

const submitRegistrationBankInfoAndKbisWorker = function* ({payload}) {
    const {formData} = payload;
    const freelancerAccount = yield select(FreelancerSelectors.selectAccount);
    const integrations = yield select(BankSelector.selectIntegrations);
    const progress = yield select(OnboardingSelectors.selectProgress);
    const company = yield select(CompaniesSelectors.createCompanyByIdSelector(freelancerAccount.defaultCompanyId));

    yield call(submitRegistrationBankInfoAndKbisFlow, {formData, freelancerAccount, integrations, progress, company});

    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);
    // Since care does not have capital deposit screens (where this is normally called)
    // we need to call it explicitly here
    if (isUserCareFn(loggedInUser)) {
        const bankIntegration = yield call(BankApi.getBankIntegrations);
        const hiwayIntegration = bankIntegration.find(integration => integration.type === BANK_TYPES.hiway);

        if (!hiwayIntegration) {
            yield put(capitalDepositGenerateSecondBatchDocuments());
        } else {
            // TODO Remove - Should not happen now since this screen is not for companies with hiwayIntegration
            yield call(bankCareFinalizeOnboardingFlow);
        }
    }
};

const capitalDepositGenerateSecondBatchDocumentsWorker = function* () {
    const capitalDeposit = yield select(BankSelector.selectCapitalDeposit);
    const progress = yield select(OnboardingSelectors.selectProgress);
    const freelancerAccount = yield select(FreelancerSelectors.selectAccount);

    yield call(capitalDepositGenerateSecondBatchDocumentsFlow, {capitalDeposit, progress, freelancerAccount});
};

export const watchSetupCompanySagas = function* watchSetupCompanySagas() {
    yield all([
        takeLatest(actions.SUBMIT_ACTIVE_STEP_FORM, submitActiveStepFormSaga),
        takeLatest(actions.SUBMIT_IBAN_BIC, submitIbanBicWorker),
        takeLatest(actions.SUBMIT_REG_BANK_INFO_AND_KBIS, submitRegistrationBankInfoAndKbisWorker),
        takeLatest(
            actions.CAPITAL_DEPOSIT_GENERATE_SECOND_BATCH_DOCUMENTS,
            capitalDepositGenerateSecondBatchDocumentsWorker,
        ),
    ]);
};
