import {Box, FormControl, Input} from '@mui/joy';
import PropTypes from 'prop-types';
import {Controller} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import NumberFormat from 'react-number-format';
import {AdditionalTopCareFields} from './AdditionalCareFields';
import {AdditionalTopPortageFields} from './AdditionalPortageFields';
import {requiredFieldProps} from './helper';
import {FormErrorText} from '../../../../../components/joy-ui/forms/FormErrorText';
import {FormFieldLabel} from '../../../../../components/joy-ui/forms/FormFieldLabel';
import {UserRoles} from '../../../../../utils/user-roles';
import {UserSelect} from '../../user-select/components/UserSelect';

export const RequiredFields = ({control, isLoading, errors, role, isSubmitted}) => {
    const {t} = useTranslation('createUser');

    return (
        <Box
            component="div"
            sx={{
                display: 'grid',
                gridTemplateColumns: {
                    desktop: 'repeat(2, 1fr)',
                    mobile: 'none',
                },
                gridColumnGap: 24,
                gridRowGap: 24,
            }}
        >
            <Controller
                id="firstName"
                name="firstName"
                control={control}
                render={({field}) => (
                    <FormControl error={!!errors.firstName}>
                        <FormFieldLabel label={t('firstName')} />
                        <Input
                            type="text"
                            color={errors.firstName ? 'danger' : 'neutral'}
                            placeholder={t('firstName')}
                            error={!!errors.firstName}
                            tabIndex={1}
                            disabled={isLoading}
                            {...field}
                            {...requiredFieldProps}
                        />
                        <FormErrorText errorMessage={errors.firstName?.message} />
                    </FormControl>
                )}
            />

            <Controller
                id="lastName"
                name="lastName"
                control={control}
                render={({field}) => (
                    <FormControl error={!!errors.lastName}>
                        <FormFieldLabel label={t('lastName')} />
                        <Input
                            type="text"
                            color={errors.lastName ? 'danger' : 'neutral'}
                            placeholder={t('lastName')}
                            error={!!errors.lastName}
                            tabIndex={2}
                            disabled={isLoading}
                            {...field}
                            {...requiredFieldProps}
                        />
                        <FormErrorText errorMessage={errors.lastName?.message} />
                    </FormControl>
                )}
            />

            <Controller
                id="email"
                name="email"
                control={control}
                render={({field}) => (
                    <FormControl error={!!errors.email}>
                        <FormFieldLabel label={t('email')} />
                        <Input
                            type="email"
                            color={errors.email ? 'danger' : 'neutral'}
                            placeholder={t('email')}
                            error={!!errors.email}
                            tabIndex={3}
                            disabled={isLoading}
                            {...field}
                            {...requiredFieldProps}
                        />
                        <FormErrorText errorMessage={errors.email?.message} />
                    </FormControl>
                )}
            />

            <Controller
                id="phone"
                name="phone"
                control={control}
                render={({field}) => (
                    <FormControl error={!!errors.phone}>
                        <FormFieldLabel label={t('phone')} />
                        <NumberFormat
                            type="tel"
                            label={t('phone')}
                            customInput={Input}
                            error={!!errors.phone}
                            format="## ## ## ## ##"
                            allowEmptyFormatting
                            mask="#"
                            color={errors.phone ? 'danger' : 'neutral'}
                            placeholder={t('phone')}
                            tabIndex={4}
                            disabled={isLoading}
                            {...field}
                            {...requiredFieldProps}
                        />
                        <FormErrorText errorMessage={errors.phone?.message} />
                    </FormControl>
                )}
            />

            {role === UserRoles.FREELANCER && (
                <Box
                    component="div"
                    sx={{
                        gridColumn: {
                            desktop: 'span 2',
                            mobile: '1',
                        },
                    }}
                >
                    <UserSelect
                        data-cy="coach"
                        label={t('coach')}
                        name="coach"
                        control={control}
                        isDisabled={isLoading}
                        hasError={!!errors.coach}
                        helperText={errors.coach?.message}
                        tabIndex={5}
                        isV3
                    />
                </Box>
            )}

            {role === UserRoles.UMBRELLA && (
                <AdditionalTopPortageFields
                    control={control}
                    isLoading={isLoading}
                    errors={errors}
                    isSubmitted={isSubmitted}
                />
            )}

            {role === UserRoles.CARE && (
                <AdditionalTopCareFields
                    control={control}
                    isLoading={isLoading}
                    errors={errors}
                    isSubmitted={isSubmitted}
                />
            )}
        </Box>
    );
};

RequiredFields.propTypes = {
    control: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    errors: PropTypes.object,
    role: PropTypes.string.isRequired,
    isSubmitted: PropTypes.bool.isRequired,
};

RequiredFields.defaultProps = {
    isLoading: false,
    errors: null,
};
