import {MainOnboardingActionTypes} from './main-onboarding.action-types';

export const MainOnboardingActions = {
    getOnboarding: userId => ({
        type: MainOnboardingActionTypes.GET_ONBOARDING_DATA,
        payload: userId,
    }),
    storeOnboarding: onboarding => ({
        type: MainOnboardingActionTypes.STORE_ONBOARDING_DATA,
        payload: onboarding,
    }),

    postLaunchOnboarding: () => ({
        type: MainOnboardingActionTypes.POST_LAUNCH_UMBRELLA_ONBOARDING,
    }),

    putSignCGVS: () => ({
        type: MainOnboardingActionTypes.PUT_SIGN_CGVS,
    }),
    putSignADP: () => ({
        type: MainOnboardingActionTypes.PUT_SIGN_AD,
    }),

    postPersonalInformation: payload => ({
        type: MainOnboardingActionTypes.POST_PERSONAL_INFORMATION,
        payload: payload,
    }),

    postPersonalDocuments: payload => ({
        type: MainOnboardingActionTypes.POST_PERSONAL_DOCUMENTS,
        payload: payload,
    }),

    setStep: (step, redirectToOnboarding) => ({
        type: MainOnboardingActionTypes.SET_STEP,
        payload: step,
        redirectToOnboarding,
    }),
    setSubStep: subStep => ({
        type: MainOnboardingActionTypes.SET_SUBSTEP,
        payload: subStep,
    }),
    determineStepSubStep: () => ({
        type: MainOnboardingActionTypes.DETERMINE_STEP_SUBSTEP,
    }),
};
