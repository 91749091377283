import {LoadingButton} from '@mui/lab';
import {Alert, Box, Button, List, ListItem, Typography, useMediaQuery} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {ModalWrapper} from '../../../../../../../components/modal-wrapper/ModalWrapper';
import {DOCUMENT_TYPES} from '../../../../../../../consts/document-constants';
import {Toast} from '../../../../../../../lib/toast';
import {isUserClientFn} from '../../../../../../../utils/user-roles';
import {storeUploadDocumentsData} from '../../../../../../../v1/app/company/companyDocuments/uploadDocuments/uploadDocuments.actions';
import {
    selectIsUploadingDocuments,
    selectUploadDocumentsData,
} from '../../../../../../../v1/app/company/companyDocuments/uploadDocuments/uploadDocuments.selectors';
import UploadDocuments from '../../../../../../../v1/components/ui-kit/UploadDocuments';
import {ModalsKeys} from '../../../../../../ui/utils/constants';
import {LoggedInUserSelectors} from '../../../../../../user/modules/logged-in-user';
import {mapCapitalDepositDocType} from '../../../utils/functions';

const modalKey = ModalsKeys.UPLOAD_REFUSED_DOCUMENT;

const AlertHeader = ({text}) => {
    return (
        <Typography sx={{
            fontSize: theme => theme.typography.pxToRem(14),
            lineHeight: theme => theme.typography.pxToRem(20),
            fontWeight: 400,
            letterSpacing: '0.17px',
            mb: 0.5,
        }}
        >
            {text}
        </Typography>
    );
};

AlertHeader.propTypes = {
    text: PropTypes.string.isRequired,
};

const AlertListHeader = ({text}) => {
    return (
        <Typography sx={{
            fontSize: theme => theme.typography.pxToRem(14),
            lineHeight: theme => theme.typography.pxToRem(20),
            letterSpacing: '0.1px',
            fontWeight: 500,
            color: '#541313',
            my: 0.5,
            p: 0,
            backgroundColor: 'transparent',
        }}
        >
            {text}
        </Typography>
    );
};

AlertListHeader.propTypes = {
    text: PropTypes.string.isRequired,
};

const AlertListItem = ({text}) => {
    return (
        <ListItem sx={{p: 0, display: 'flex', gap: 1, alignItems: 'center'}}>
            <Typography sx={{
                color: '#541313',
                fontSize: theme => theme.typography.pxToRem(24),
                lineHeight: theme => theme.typography.pxToRem(24),
            }}
            >
                •
            </Typography>
            {text}
        </ListItem>
    );
};

AlertListItem.propTypes = {
    text: PropTypes.string.isRequired,
};

const UploadDocumentModal = ({onFileSelect, fileType}) => {
    const {t} = useTranslation('freelancerOnboarding');
    const {t: tDoc} = useTranslation('documents');
    const isMobileSize = useMediaQuery(`(max-width:960px)`);
    const dispatch = useDispatch();

    const isUploading = useSelector(selectIsUploadingDocuments);
    const data = useSelector(selectUploadDocumentsData);
    const user = useSelector(LoggedInUserSelectors.selectLoggedInUser);

    const isContract = fileType === DOCUMENT_TYPES.HOME_RENTAL_CONTRACT
        || fileType === DOCUMENT_TYPES.DOMICILIATION_CERTIFICATE;

    const adjustedDocType = mapCapitalDepositDocType(fileType);

    const handleAddFiles = useCallback(addedFiles => {
        const newFiles = addedFiles.filter(addedFile => {
            if (!data.find(item => item.file.name === addedFile.name)) {
                return true;
            }

            Toast.error('fileAlreadyAdded', {
                translationValues: {
                    fileName: addedFile.name,
                },
            });

            return false;
        });

        dispatch(storeUploadDocumentsData([
            ...data,
            ...newFiles.map(newFiles => ({
                file: newFiles,
                type: isUserClientFn(user) ? 'OTHER' : '', // hard-coded for freelancers
                category: '',
            })),
        ]));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, dispatch, user.role]);

    const handleDeleteFile = useCallback(fileName => {
        dispatch(storeUploadDocumentsData(data.filter(item => item.file.name !== fileName)));
    }, [data, dispatch]);

    const handleCloseModal = useCallback(() => {
        dispatch(storeUploadDocumentsData([]));
    }, [dispatch]);

    return (
        <ModalWrapper
            modalKey={modalKey}
            containerSx={{
                width: '900px',
            }}
            title={(
                <Typography variant="h4">
                    {t('uploadModal.title')}<br />
                    {tDoc(`documentsDatabaseNames.${adjustedDocType}`)}
                </Typography>
            )}
            hasShowClose={!isUploading}
            handleCloseCB={handleCloseModal}
            buttons={({handleClose}) => (
                <>
                    <Button
                        disableElevation
                        fullWidth={isMobileSize}
                        onClick={() => {
                            handleClose();
                        }}
                        variant="contained"
                        color="blueGray"
                    >
                        {t('uploadModal.cancel')}
                    </Button>

                    <LoadingButton
                        color="secondary"
                        variant="contained"
                        disabled={!data.length}
                        fullWidth={isMobileSize}
                        loading={isUploading}
                        onClick={() => {
                            onFileSelect(data[0]);
                            handleClose();
                        }}
                    >
                        {t('uploadModal.confirm')}
                    </LoadingButton>

                </>
            )}
        >
            <Box sx={{transform: 'translate3d(0, 0, 0)'}}>
                <Box sx={{mb: 2}}>
                    <Alert
                        sx={{
                            'backgroundColor': '#FBE9EA',
                            'color': '#541313',
                            '.MuiAlert-icon': {
                                color: '#D54941',
                            },
                        }}
                        variant="filled"
                        severity="error"
                    >
                        {isContract ? (
                            <>
                                <AlertHeader text={t('refuseAlerts.alertHeader1')} />
                                <List sx={{listStyleType: 'disc', fontWeight: 400}}>
                                    <AlertListHeader text={t('refuseAlerts.alertListHeader1')} />
                                    <AlertListItem text={t('refuseAlerts.alertListText11')} />
                                    <AlertListItem text={t('refuseAlerts.alertListText12')} />
                                </List>
                                <List sx={{listStyleType: 'disc', fontWeight: 400}}>
                                    <AlertListHeader text={t('refuseAlerts.alertListHeader2')} />
                                    <AlertListItem text={t('refuseAlerts.alertListText21')} />
                                    <AlertListItem text={t('refuseAlerts.alertListText22')} />
                                    <AlertListItem text={t('refuseAlerts.alertListText23')} />
                                    <AlertListItem text={t('refuseAlerts.alertListText24')} />
                                </List>
                            </>
                        ) : (
                            <AlertHeader text={t('refuseAlerts.alertHeader2')} />
                        )}
                    </Alert>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mb: 1,
                    }}
                >
                    <Typography
                        variant="body1"
                        sx={theme => ({fontWeight: theme.typography.fontWeightMedium})}
                    >
                        {tDoc(`documentsDatabaseNames.${adjustedDocType}`)} *
                    </Typography>
                </Box>

                <UploadDocuments
                    files={data.map(item => item.file)}
                    onAddFiles={handleAddFiles}
                    onDeleteFile={handleDeleteFile}
                    fileLimit={1}
                    buttonText={t('uploadModal.uploadButton')}
                    acceptFiles="image/jpeg, image/png, image/jpg, application/pdf"
                    hasMaxFileInfoForced
                    readableFileExtension="PDF, PNG, JPG"
                />
            </Box>
        </ModalWrapper>
    );
};

UploadDocumentModal.propTypes = {
    onFileSelect: PropTypes.func.isRequired,
    fileType: PropTypes.string.isRequired,
};

export default UploadDocumentModal;
