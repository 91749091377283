import PropTypes from 'prop-types';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Slide from '../../../../../components/joy-ui/mui-legacy/Slide';
import {AnimationActions} from '../../../../animations/store/animations.action';
import {AnimationsSelectors} from '../../../../animations/store/animations.selector';
import {ANIMATION_TYPE} from '../../../../animations/utils/constants';
import {MainOnboardingSelectors} from '../../../store/main-onboarding.slector';

// TODO Maybe one generic component is enough?
const UmbrellaInformationStep = props => {
    const dispatch = useDispatch();

    const substep = useSelector(MainOnboardingSelectors.selectSubStep);
    const isAnimationActive = useSelector(AnimationsSelectors.selectIsAnimationActive);

    const onEndedAnimation = () => {
        dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.MAIN));
    };

    const Component = props.subSteps?.[substep]?.component;

    if (Component) {
        return (
            <Slide in={isAnimationActive} direction="left" onEntered={onEndedAnimation} onExited={onEndedAnimation}>
                <div>
                    <Component />
                </div>
            </Slide>
        );
    }

    return null;
};

UmbrellaInformationStep.propTypes = {
    subSteps: PropTypes.object.isRequired,
};

export default UmbrellaInformationStep;
