import {AWS_AMPLIFY_CONFIG} from './aws-amplify';
import {RoutePaths} from '../lib/router/route-paths';

export const getConfig = () => ({
    AWS_AMPLIFY_CONFIG: AWS_AMPLIFY_CONFIG,
    ROUTE_PATHS: RoutePaths,
    baseURL: process.env.REACT_APP_BASE_API_URL,
    environment: process.env.REACT_APP_ENVIRONMENT,
    legacyAppUrl: process.env.REACT_APP_LEGACY_BASE_URL,
    hubspotCodeUrl: process.env.REACT_APP_HUBSPOT_CODE_URL,
    hubspotDataUrl: process.env.REACT_APP_HUBSPOT_DATA_URL,
    inpiUrl: process.env.REACT_APP_INPI_LINK,
    ariaLink: process.env.REACT_APP_ARIA_LINK,
    ariaDocumentationLink: process.env.REACT_APP_ARIA_DOCUMENTATION_LINK,
    ariaDiscoverLink: process.env.REACT_APP_ARIA_DISCOVER_LINK,
    hotjarId: process.env.REACT_APP_HOTJAR_ID,
});
