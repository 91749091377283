export const UmbrellaStepperOnboardingActionTypes = {
    GO_TO_CGVS: 'mainOnboardingActions/GO_TO_CGVS',
    GO_TO_ADP: 'mainOnboardingActions/GO_TO_ADP',
    REFUSE_SIGNING: 'mainOnboardingActions/REFUSE_SIGNING',
    COMPLETED_SIGNING: 'mainOnboardingActions/COMPLETED_SIGNING',
    BACK_TO_SIGNING: 'mainOnboardingActions/BACK_TO_SIGNING',
    GO_TO_PERSONAL_INFORMATION: 'mainOnboardingActions/GO_TO_PERSONAL_INFORMATION',
    GO_TO_PERSONAL_DOCUMENTS: 'mainOnboardingActions/GO_TO_PERSONAL_DOCUMENTS',
    GO_TO_FINALIZATION: 'mainOnboardingActions/GO_TO_FINALIZATION',
};
