import {all, call, put, select, takeLatest} from 'redux-saga/effects';
import {ContractActions} from './contract.action';
import {ContractActionTypes} from './contract.action-type';
import {Toast} from '../../../lib/toast';
import {Debug} from '../../../utils/debug';
import {UserRoles} from '../../../utils/user-roles';
import {DocumentApi} from '../../document/api/document.api';
import {SigningActions} from '../../document/modules/signing/store/signing.action';
import {LoadingActions, LoadingTypes} from '../../loading';
import {LoggedInUserSelectors} from '../../user/modules/logged-in-user';
import {ContractApi} from '../api/contract.api';

const retryGenerateContract = function* ({freelancerId}) {
    try {
        yield call(DocumentApi.generateContractDocumentRequest, freelancerId);
        yield call(loadContractData);
    } catch (error) {
        Debug.error('retryGenerateContract', 'Error: ', {error});
        Toast.error('genericError');

        // We need to propagate error to stop execution to stop user from going on the next page
        throw error;
    }
};

export const getContractDataFlow = function* ({freelancerId, shouldRegenerateDocumentIfItDoesNotExist}) {
    try {
        const contract = yield call(ContractApi.getContract, {freelancerId});

        yield all([
            put(SigningActions.setCurrentDocumentUrl(contract.url)),
            put(SigningActions.setCurrentDocumentIsSigned(!!contract.signedAt)),
            put(SigningActions.setCurrentDocumentId(contract.documentId)),
            put(ContractActions.storeContractData(contract)),
        ]);
    } catch (error) {
        Debug.error('contract', 'Error: ', {error});
        // Retry to generate contract and load data
        if (error?.response?.status === 404 && shouldRegenerateDocumentIfItDoesNotExist) {
            try {
                yield call(retryGenerateContract, {freelancerId});
            } catch (e) {
                // We need to propagate error to stop execution to stop user from going on the next page
                throw e;
            }
        } else {
            Toast.error('genericError');
        }
    }
};

export const loadContractData = function* () {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.CONTRACT_DATA, true));

        const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

        // Admins should not load contract data for themselves
        if (loggedInUser?.role === UserRoles.ADMINISTRATOR || loggedInUser?.role === UserRoles.UMBRELLA) {
            return;
        }

        yield call(getContractDataFlow, {freelancerId: loggedInUser.id});

        yield put(LoadingActions.setLoading(LoadingTypes.CONTRACT_DATA, false));
    } catch (e) {
        Debug.error('loadContractData', 'Error: ', {e});
    }
};

export const contractSaga = function* () {
    yield all([
        takeLatest(ContractActionTypes.GET_CONTRACT_DATA, loadContractData),
    ]);
};
