import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import {Slide, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import moment from 'moment';
import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import envelope from '../../../../../assets/svg/envelope.svg';
import Banner from '../../../../../layout/ContainerWithBigImage/Banner';
import {isUserCareFn} from '../../../../../utils/user-roles';
import {AnimationActions} from '../../../../animations/store/animations.action';
import {AnimationsSelectors} from '../../../../animations/store/animations.selector';
import {ANIMATION_TYPE} from '../../../../animations/utils/constants';
import {DatabaseSelectors} from '../../../../document/modules/database/store/database.selector';
import {LoggedInUserSelectors} from '../../../../user/modules/logged-in-user';
import {FreelancerSelectors} from '../../../store/freelancer.selector';
import {CompaniesSelectors} from '../../companies';
import {calculateEstimatedDates} from '../utils/date';

export const InitialStep = () => {
    const document = useSelector(DatabaseSelectors.createDepositDocumentByTypeSelector('CAPITAL_DEPOSIT'));

    const dispatch = useDispatch();

    const freelancer = useSelector(FreelancerSelectors.selectAccount);
    const company = useSelector(CompaniesSelectors.createCompanyByIdSelector(freelancer?.defaultCompanyId));

    const loggedInUser = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const isCareUserRole = isUserCareFn(loggedInUser);

    const companyRegistrationDate = freelancer?.defaultCompanyInfo?.activity_start_date ? moment(freelancer.defaultCompanyInfo.activity_start_date, 'DD/MM/YYYY') : moment();
    const documentUploadDate = document?.created_at ? moment(document.created_at, 'DD/MM/YYYY') : moment();

    const [firstDate, secondDate] = calculateEstimatedDates(companyRegistrationDate, documentUploadDate);

    // const isMobileSize = useMediaQuery(`(max-width:960px)`);
    const {t} = useTranslation('freelancerOnboarding');

    const isAnimationActive = useSelector(AnimationsSelectors.selectIsAnimationActive);

    const onEndedAnimation = () => {
        dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.MAIN));
    };

    return (
        <Slide in={isAnimationActive} direction="left" onEntered={onEndedAnimation} onExited={onEndedAnimation}>
            <div>
                <Banner
                    header={(
                        <Trans
                            t={t}
                            i18nKey={isCareUserRole
                                ? 'companyRegistrationStep.step1.headlineCare'
                                : 'companyRegistrationStep.step1.headline'}
                            components={{bold: <Box component="span" sx={{color: '#E84C4C'}} />}}
                        />
                    )}
                    text={(
                        <Box sx={{color: '#0B2333'}}>
                            <Box sx={{display: 'flex', mt: 3}}>
                                <Box><ThumbUpIcon sx={{color: '#4CAF50', fontSize: '37px'}} /></Box>
                                <Box sx={{display: 'flex', flexDirection: 'column', ml: 2}}>
                                    <Box sx={{fontSize: '21px', fontWeight: 700, mb: 1}}>{t('companyRegistrationStep.step1.header1')}</Box>
                                    <Box>{isCareUserRole
                                        ? t('companyRegistrationStep.step1.paragraph1Care')
                                        : t('companyRegistrationStep.step1.paragraph1')}
                                    </Box>
                                </Box>
                            </Box>

                            <Box sx={{display: 'flex', my: 4, mb: 7}}>
                                <Box><InsertInvitationIcon sx={{color: '#4CAF50', fontSize: '37px'}} /></Box>
                                <Box sx={{display: 'flex', flexDirection: 'column', ml: 2}}>
                                    <Box sx={{fontSize: '21px', fontWeight: 700, mb: 1}}>{isCareUserRole
                                        ? t('companyRegistrationStep.step1.header2Care')
                                        : t('companyRegistrationStep.step1.header2')}
                                    </Box>
                                    <Box>{t('companyRegistrationStep.step1.paragraph2', {firstDate: firstDate.format('D MMMM'), secondDate: secondDate.format('D MMMM')})}</Box>
                                    <Box sx={{fontSize: '12px', color: 'rgba(0, 0, 0, 0.38)'}}>{t('companyRegistrationStep.step1.subtext')}</Box>
                                </Box>
                            </Box>
                        </Box>
                    )}
                    Illustration={(
                        <Box
                            sx={{mt: 2, position: 'relative'}}
                        >
                            <Box
                                sx={{
                                    minHeight: '80px',
                                    background: '#FFF59D',
                                    width: '165px',
                                    position: 'absolute',
                                    bottom: '60px',
                                    transform: 'rotate(4deg)',
                                    boxShadow: '4px 5px 5px -4px rgba(0, 0, 0, 0.3)',
                                    px: 1.5,
                                    py: 1.5,
                                    color: '#763601',
                                }}
                            >
                                <Box>
                                    <Typography sx={{fontSize: '13px'}}>
                                        {t('documents')}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography variant="h5" sx={{fontSize: '17px'}}>
                                        {company.name}
                                    </Typography>
                                </Box>
                            </Box>
                            <img src={envelope} alt="Envelope" />
                        </Box>
                    )}
                    cardSx={{
                        background: `linear-gradient(270deg, #F1F8E9 0%, #FFFFFF 55.73%)`,
                    }}
                />
            </div>
        </Slide>
    );
};
