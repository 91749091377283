import {yupResolver} from '@hookform/resolvers/yup';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckIcon from '@mui/icons-material/Check';
import {LoadingButton} from '@mui/lab';
import {
    Alert,
    Divider,
    Grid,
    Grow,
    InputAdornment,
    Paper,
    Slide,
    TextField,
    Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {DropZone} from '../../../../../components/dropzone/DropZone';
import Yup from '../../../../../lib/yup/yup';
import {getIsRegistrationWithExistingCompany} from '../../../../../utils/holidays';
import {isUserCareFn} from '../../../../../utils/user-roles';
import {getCompanyDocuments} from '../../../../../v1/app/company/companyDocuments/companyDocuments.actions';
import {createCompanyDocumentsSelector} from '../../../../../v1/app/company/companyDocuments/companyDocuments.selectors';
import {getCompanyInformationData} from '../../../../../v1/app/company/setupCompany/companyInformation/companyInformation.selectors';
import {submitRegistrationBankInfoAndKbis} from '../../../../../v1/app/company/setupCompany/setupCompany.actions';
import {useDocumentsUploadFormStyles} from '../../../../../v1/components/setup-company/DocumentsUploadForm/styles';
import {AnimationActions} from '../../../../animations/store/animations.action';
import {AnimationsSelectors} from '../../../../animations/store/animations.selector';
import {ANIMATION_TYPE, BANNER_ANIMATION_DELAY, FORM_ANIMATION_DELAY} from '../../../../animations/utils/constants';
import {BANK_TYPES} from '../../../../bank/modules/bridge-api/utils/constants';
import {BankSelector} from '../../../../bank/store/bank.selector';
import {LoadingSelectors, LoadingTypes, useLoadingState} from '../../../../loading';
import {LoggedInUserSelectors} from '../../../../user/modules/logged-in-user';
import {FreelancerSelectors} from '../../../store/freelancer.selector';
import {CompaniesSelectors} from '../../companies';
import {OnboardingActions} from '../../onboarding/store/onboarding.action';
import {OnboardingSelectors} from '../../onboarding/store/onboarding.selectors';
import {OnboardingSteps} from '../../onboarding/utils/onboadingStepsConstant';

const defaultValues = {
    iban: '',
    bic: '',
    shouldSendFile: false,
    file: [],
};
export const IbanBicForm = ({hasIntegration}) => {
    const styles = useDocumentsUploadFormStyles();
    const {t} = useTranslation(['freelancerOnboarding', 'companies']);
    const dispatch = useDispatch();

    const isOnboardingDataLoading = useLoadingState(LoadingTypes.ONBOARDING);
    const isLoadingCompanyInformation = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.SAVE_COMPANY_INFORMATION),
    );

    const isLoading = isOnboardingDataLoading || isLoadingCompanyInformation;

    const freelancer = useSelector(FreelancerSelectors.selectAccount);
    const loggedInUser = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const company = useSelector(CompaniesSelectors.createCompanyByIdSelector(freelancer?.defaultCompanyId));
    const hiwayBankInfo = company?.companyInfo?.hiway_bank_info;
    const companyInformation = useSelector(getCompanyInformationData);
    const registrationWithExistingCompany = getIsRegistrationWithExistingCompany(company?.registrationType);
    const progress = useSelector(OnboardingSelectors.selectProgress);

    const isStepCompleted = progress[OnboardingSteps.COMPANY_REGISTRATION].isCompleted;

    const isUserCareRole = isUserCareFn(loggedInUser);

    const isAnimationActive = useSelector(AnimationsSelectors.selectIsAnimationActive);
    const isSubAnimationActive = useSelector(AnimationsSelectors.selectIsSubAnimationActive);

    const integrations = useSelector(BankSelector.selectIntegrations);
    const bankAccountHoldersList = integrations?.[0]?.bankAccountHolders;
    const individualBankAccountHolders = bankAccountHoldersList
        ? bankAccountHoldersList.find(holder => holder.type !== 'COMPANY')
        : undefined;

    const hasHiwayBank = !!hiwayBankInfo?.bank_name || individualBankAccountHolders?.type === BANK_TYPES.hiway;

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const shouldHideIbanAndBic = isUserCareRole && hasHiwayBank;

    const validationSchema = Yup.object().shape({
        iban: Yup.string()
            .iban(t('companyRegistrationStep.step2.ibanInvalid'))
            .test(
                'is-iban-required',
                t('form:validation.required', {fieldName: t('companyRegistrationStep.step2.iban')}),
                iban => {
                    if (hasIntegration || shouldHideIbanAndBic) {
                        return true;
                    }

                    return !!iban;
                },
            ),
        bic: Yup.string()
            .bic(t('companyRegistrationStep.step2.bicInvalid'))
            .test(
                'is-bic-required',
                t('form:validation.required', {fieldName: t('companyRegistrationStep.step2.bic')}),
                bic => {
                    if (hasIntegration || shouldHideIbanAndBic) {
                        return true;
                    }

                    return !!bic;
                },
            ),
        file: Yup
            .mixed()
            .when('registrationWithExistingCompany', {
                is: registrationWithExistingCompany => registrationWithExistingCompany === false && !isUserCareRole,
                then: Yup.array().min(1),
            }),
    });

    const {
        control,
        formState: {errors, isValid},
        setValue,
        handleSubmit: createSubmitHandler,
        watch,
        trigger,
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            iban: hiwayBankInfo?.iban ?? company.iban,
            bic: hiwayBankInfo?.bic ?? company.bic,
            registrationWithExistingCompany: registrationWithExistingCompany,
            ...defaultValues,
        },
        resolver: yupResolver(validationSchema),
    });

    const file = watch('file');
    const shouldSendFile = watch('shouldSendFile');

    useEffect(() => {
        setValue(
            'registrationWithExistingCompany',
            getIsRegistrationWithExistingCompany(company?.registrationType),
        );
        trigger('file');
        // eslint-disable-next-line
    }, [company]);

    const documents = useSelector(createCompanyDocumentsSelector());

    useEffect(() => {
        if (documents.length > 0 && file.length === 0 && !shouldSendFile) {
            const kbis = documents.find(document => document.type === 'KBIS');
            if (kbis) {
                setValue('file', [kbis], {shouldValidate: true});
            }
        }
        // eslint-disable-next-line
    }, [documents]);

    useEffect(() => {
        if (freelancer?.id && freelancer?.defaultCompanyId && !isLoading) {
            dispatch(getCompanyDocuments(
                freelancer.id,
                freelancer.defaultCompanyId,
                'database',
            ));
        }
        // eslint-disable-next-line
    }, [dispatch, freelancer?.id, freelancer?.defaultCompanyId, isLoading]);

    const handleSubmit = data => {
        if (isStepCompleted) {
            // Not optimal regarding APIs, but easier to maintain than old huge block of code
            dispatch(OnboardingActions.determineStepSubStep());
            return;
        }

        dispatch(submitRegistrationBankInfoAndKbis(data));
    };

    const onEndedAnimation = () => {
        dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.MAIN));
    };

    useEffect(() => {
        if (hiwayBankInfo) {
            if (hiwayBankInfo.iban) {
                setValue('iban', hiwayBankInfo.iban);
            }
            if (hiwayBankInfo.bic) {
                setValue('bic', hiwayBankInfo.bic);
            }
        } else {
            if (company.iban) {
                setValue('iban', company.iban);
            }
            if (company.bic) {
                setValue('bic', company.bic);
            }
        }
    }, [company, setValue, hiwayBankInfo]);

    const onFileChange = useCallback(newFiles => {
        setValue('file', newFiles, {shouldValidate: true, shouldDirty: true});
        setValue('shouldSendFile', true, {shouldValidate: false});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setValue]);

    return (
        <>
            <form onSubmit={createSubmitHandler(handleSubmit)}>
                <Slide
                    direction="left"
                    in={isAnimationActive}
                    timeout={FORM_ANIMATION_DELAY}
                        // onTransitionEnd={() => {
                        //     dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.MAIN));
                        // }}
                    onEntered={onEndedAnimation}
                    onExited={onEndedAnimation}
                >
                    <Paper radius={16} elevation={2} sx={{px: 5, py: 4}}>
                        <div className={styles.documentUploadStepContainer}>
                            {!registrationWithExistingCompany && !isUserCareRole && (
                                <>
                                    <Typography sx={{
                                        fontSize: theme => theme.typography.pxToRem(21),
                                        fontWeight: theme => theme.typography.fontWeightBold,
                                        letterSpacing: '0.1px',
                                        color: '#0B2333',
                                        mb: isMobileSize ? 5 : 2,
                                    }}
                                    >
                                        {t('companyRegistrationStep.step2.headline')}
                                    </Typography>
                                    <Grow
                                        in={isSubAnimationActive}
                                        timeout={FORM_ANIMATION_DELAY}
                                        onExited={() => {
                                            dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.SECONDARY));
                                        }}
                                    >
                                        <Box sx={{mt: 1}} component="div">
                                            <DropZone
                                                sx={{
                                                    width: '100%',
                                                }}
                                                setIsOpen={true}
                                                maxFiles={1}
                                                fileTypes=".pdf"
                                                onChange={onFileChange}
                                                isFileDeleteAvailable={true}
                                                files={file ?? []}
                                                textTop={t('freelancerOnboarding:kbisDropzone.topText')}
                                                textBottom={t('freelancerOnboarding:kbisDropzone.bottomText')}
                                                isInfoTextVisible={isMobileSize}
                                            />
                                            {
                                                companyInformation?.bank === 'Qonto' && !hasIntegration && (
                                                    <Alert
                                                        severity="info"
                                                        sx={{
                                                            flexDirection: 'row',
                                                            alignItems: 'center',
                                                            my: 4.5,
                                                        }}
                                                    >
                                                        <Typography>
                                                            {t('companyRegistrationStep.step2.paragraph2')}
                                                        </Typography>
                                                    </Alert>
                                                )
                                            }
                                        </Box>
                                    </Grow>

                                    {!hasIntegration && !shouldHideIbanAndBic && (
                                    <Divider className={styles.divider} />
                                    )}
                                </>
                            )}

                            {!hasIntegration && !shouldHideIbanAndBic && (
                            <Typography sx={{
                                fontSize: theme => theme.typography.pxToRem(21),
                                fontWeight: theme => theme.typography.fontWeightBold,
                                letterSpacing: '0.1px',
                                color: '#0B2333',
                            }}
                            >
                                {t('companyRegistrationStep.step2.header2')}
                            </Typography>
                            )}

                            {!shouldHideIbanAndBic && (
                            <Grow
                                in={isSubAnimationActive && !hasIntegration}
                                timeout={FORM_ANIMATION_DELAY}
                                onExited={() => {
                                    dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.SECONDARY));
                                }}
                            >
                                <div>
                                    {!hasIntegration && (
                                    <Box sx={{mt: 1}}>
                                        <Typography sx={{mt: 2}}>{t('companyRegistrationStep.step2.paragraph3')}</Typography>

                                        <Box sx={{display: 'flex', mt: 3, flexDirection: isMobileSize ? 'column' : 'row'}}>
                                            <Controller
                                                control={control}
                                                name="iban"
                                                render={({field}) => (
                                                    <TextField
                                                        sx={{mr: 1.5}}
                                                        variant="filled"
                                                        label={t('companyRegistrationStep.step2.iban')}
                                                        disabled={isLoading || hiwayBankInfo || isStepCompleted}
                                                        error={!!errors.iban}
                                                        helperText={errors.iban?.message}
                                                        fullWidth
                                                        InputProps={{
                                                            endAdornment: field.value && !errors.iban ? (
                                                                <InputAdornment position="end">
                                                                    <CheckIcon sx={{color: 'green'}} />
                                                                </InputAdornment>
                                                            ) : null,
                                                        }}
                                                        {...field}
                                                    />
                                                )}
                                            />

                                            <Controller
                                                control={control}
                                                name="bic"
                                                render={({field}) => (
                                                    <TextField
                                                        sx={{ml: isMobileSize ? 0 : 1.5, mt: isMobileSize ? 1 : 0}}
                                                        variant="filled"
                                                        label={t('companyRegistrationStep.step2.bic')}
                                                        disabled={isLoading || hiwayBankInfo || isStepCompleted}
                                                        error={!!errors.bic}
                                                        helperText={errors.bic?.message}
                                                        fullWidth
                                                        InputProps={{
                                                            endAdornment: field.value && !errors.bic ? (
                                                                <InputAdornment position="end">
                                                                    <CheckIcon sx={{color: 'green'}} />
                                                                </InputAdornment>
                                                            ) : null,
                                                        }}
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </Box>
                                    </Box>
                                    )}
                                </div>
                            </Grow>
                            )}
                        </div>
                    </Paper>
                </Slide>

                {!isMobileSize && (
                <Slide direction="left" in={isAnimationActive} timeout={BANNER_ANIMATION_DELAY * 2}>
                    <Grid container item justifyContent="flex-end" sx={{mt: 3}} alignItems="center">
                        <Typography sx={{mr: 2, fontSize: '20px', color: '#0B2333'}}>
                            {isUserCareRole
                                ? t('companyRegistrationStep.step2.nextTextCare')
                                : t('companyRegistrationStep.step2.nextText')}
                        </Typography>
                        <LoadingButton
                            loading={isLoading}
                            type="submit"
                            variant="contained"
                            endIcon={<ArrowForwardIcon />}
                            color="secondary"
                            disabled={!isValid && !isStepCompleted}
                        >
                            {t('companyRegistrationStep.step2.nextButtonText')}
                        </LoadingButton>
                    </Grid>
                </Slide>
                )}
                {isMobileSize && (
                    <Box sx={{px: 2, mt: 1}}>
                        <Typography sx={{fontSize: '20px', color: '#0B2333', my: 2}}>
                            {isUserCareRole
                                ? t('companyRegistrationStep.step2.nextTextCare')
                                : t('companyRegistrationStep.step2.nextText')}
                        </Typography>
                        <LoadingButton
                            loading={isLoading}
                            type="submit"
                            variant="contained"
                            endIcon={<ArrowForwardIcon />}
                            color="secondary"
                            disabled={!isValid && !isStepCompleted}
                            fullWidth
                        >
                            {t('companyRegistrationStep.step2.nextButtonText')}
                        </LoadingButton>
                    </Box>
                )}
            </form>
        </>
    );
};

IbanBicForm.propTypes = {
    hasIntegration: PropTypes.bool,
};

IbanBicForm.defaultProps = {
    hasIntegration: false,
};
