import {faArrowLeft, faCalendarDays, faIdCardClip} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Button, Divider, Stack, Typography} from '@mui/joy';
import {Suspense, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Link, Outlet, generatePath} from 'react-router-dom';
import JoyUIThemeProvider from '../../components/joy-ui/joyui-theme-provider/JoyUiThemeProvider';
import {LoadingSkeleton, LoadingSkeletonProvider} from '../../components/joy-ui/loading-skeleton/LoadingSkeleton';
import {SuspenseContainer} from '../../components/suspense/Suspense';
import {ManualModeSwitch} from '../../features/vat-declaration/components/manual-mode/ManualModeSwitch';
import {Status} from '../../features/vat-declaration/components/Status';
import {VatDeclarationStatus} from '../../features/vat-declaration/constants';
import {VatDeclarationSelectors} from '../../features/vat-declaration/store/vat-declaration.selector';
import {getDeclarationPeriodString} from '../../features/vat-declaration/utils';
import {importLocaleBundle} from '../../lib/i18next';
import {routerActions} from '../../lib/router/connected-router-saga/connected-router.actions';
import {RoutePaths} from '../../lib/router/route-paths';

importLocaleBundle('vatDeclaration');

const Header = () => {
    const {t} = useTranslation('vatDeclaration');
    const declaration = useSelector(VatDeclarationSelectors.selectVATDeclaration);
    const dispatch = useDispatch();

    const goBack = () => {
        dispatch(routerActions.back());
    };

    const isTodo = declaration?.status === VatDeclarationStatus.TODO;
    const isSubmitted = declaration?.status === VatDeclarationStatus.SUBMITTED;
    const isValidated = declaration?.status === VatDeclarationStatus.VALIDATED;
    const shouldShowManualSwitch = declaration?.isManual
        || (!isTodo && !isSubmitted && !isValidated && !declaration?.childDeclarationId);

    const declarationPeriod = useMemo(() => getDeclarationPeriodString(declaration), [declaration]);

    return (
        <LoadingSkeletonProvider isLoading={!declaration} pulseBg="var(--joy-palette-common-black)">
            <Stack
                direction="row"
                alignItems="center"
                sx={{
                    py: 1,
                    px: 2,
                    mb: 5,
                    bgcolor: 'common.black',
                    justifyContent: 'space-between',
                    color: 'background.body',
                    position: 'sticky',
                    zIndex: 1,
                    top: 0,
                    left: 0,
                    right: 0,
                }}
            >
                <Button
                    variant="plain"
                    color="primary"
                    size="sm"
                    startDecorator={<FontAwesomeIcon icon={faArrowLeft} />}
                    sx={{'color': 'linen.500', '--variant-plainHoverBg': 'transparent'}}
                    onClick={goBack}
                >
                    {t('back')}
                </Button>
                <Stack direction="row" spacing={2} alignItems="center">
                    <Typography level="h4" textColor="text.tertiary">
                        {t('vatDeclaration')}
                    </Typography>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <FontAwesomeIcon icon={faIdCardClip} />
                        <LoadingSkeleton placeholder="------------">
                            {declaration && (
                                <Typography
                                    level="h3"
                                    color="background.body"
                                    component={Link}
                                    to={generatePath(RoutePaths.COMPANY, {
                                        freelancerId: declaration.freelancer.id,
                                        companyId: declaration.companyId,
                                    })}
                                    sx={{textDecoration: 'none'}}
                                >
                                    {declaration.company.name}
                                </Typography>
                            )}
                        </LoadingSkeleton>
                    </Stack>
                    <Divider orientation="vertical" sx={{bgcolor: 'text.secondary'}} />
                    <Stack direction="row" spacing={1} alignItems="center">
                        <FontAwesomeIcon icon={faCalendarDays} />
                        <Typography level="h3" color="background.body">
                            <LoadingSkeleton placeholder="------------">{declarationPeriod}</LoadingSkeleton>
                        </Typography>
                    </Stack>
                </Stack>
                <LoadingSkeleton placeholder="--------">
                    <Stack direction="row" spacing={2}>
                        {shouldShowManualSwitch && <ManualModeSwitch />}
                        <Status status={declaration?.status} />
                    </Stack>
                </LoadingSkeleton>
            </Stack>
        </LoadingSkeletonProvider>
    );
};

export const VatDeclarationLayout = () => {
    return (
        <JoyUIThemeProvider>
            <Box sx={{bgcolor: 'background.body', minHeight: '100vh'}}>
                <Header />
                <Box sx={{color: 'primary.main'}}>
                    <Suspense fallback={<SuspenseContainer />}>
                        <Outlet />
                    </Suspense>
                </Box>
            </Box>
        </JoyUIThemeProvider>
    );
};
