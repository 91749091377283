import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DownloadIcon from '@mui/icons-material/Download';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import {LoadingButton} from '@mui/lab';
import {Alert, Box, Grid, IconButton, Paper, Slide, Tooltip, Typography, useMediaQuery} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useFirstMountState} from 'react-use';
import DocumentSigningToolbar from './DocumentSigningToolbar';
import {DocumentSigningMobile} from './mobile/DocumentSigningMobile';
// eslint-disable-next-line import/no-cycle
import {DocumentSigningMobileTraining} from './mobile/DocumentSigningMobileTraining';
import {PdfViewer} from './PdfViewer';
import SwipeableEdgeDrawer from '../../../../../components/swipable-edge/SwipableEdge';
import {useMemoizedCreateSelector} from '../../../../../hooks/use-memoized-create-selector';
import {importLocaleBundle} from '../../../../../lib/i18next';
import {AnimationActions} from '../../../../animations/store/animations.action';
import {AnimationsSelectors} from '../../../../animations/store/animations.selector';
import {ANIMATION_TYPE} from '../../../../animations/utils/constants';
import {LoadingTypes, useLoadingState} from '../../../../loading';
import {SignatureSelectors} from '../../../../signature/store/signature.selector';
import {UiActions} from '../../../../ui/store/ui.action';
import {ModalsKeys} from '../../../../ui/utils/constants';
import {DocumentActions} from '../../../store/document.action';
import {DatabaseSelectors} from '../../database/store/database.selector';
import {SigningActions} from '../store/signing.action';
import {SigningSelectors} from '../store/signing.selector';
import {DOCUMENT_SIGNING_TYPES} from '../utils/constants';

importLocaleBundle('document');

const TIMEOUT_DELAY = 400;

const SIGNATURE_OFFSETS_BY_DOCUMENT_TYPE = {
    PARTNERS_LIST: 0.87,
    DNC: 0.52,
    DOMICILIATION_CERTIFICATE: 0.55,
    REGISTRATION_STATUS: 0.91,
    HOME_RENTAL_CONTRACT: 0.96,
    BANK_DELEGATED_ACCESS: 0.49,
    NON_REJECTION_ACRE_CERT: 0.49,
    CAPITAL_DEPOSIT_STATUS: 0.97,
    OGI_FRANCE_MEMBERSHIP: 0.35,
    TAX_OPTION: 0.54,
    SALARIED_PRESIDENT_CERTIFICATE: 0.34,
    POWER_OF_ATTORNEY_REGISTRATION_MANDATE: 0.75,
    HIWAY_ACCOUNTING_MISSION_LETTER: 0.25,
};

export const DocumentSigning = ({
    MainActionComponent,
    type,
    isLoadingNextStep,
    isDownloadPDFAvailable,
    signedLocale,
    swipableEdgeHeight,
    onSignFn,
    onNextFn,
}) => {
    const {t} = useTranslation('document');

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const dispatch = useDispatch();
    const isFirstMount = useFirstMountState() ?? true;

    const pdfViewerRef = useRef();
    const pdfViewerContainerRef = useRef();

    const signatureUrl = useSelector(SignatureSelectors.selectUrl);
    const isSigned = useSelector(SigningSelectors.selectCurrentDocumentIsSigned);
    const documentId = useSelector(SigningSelectors.selectCurrentDocumentId);
    const isSigning = useSelector(SigningSelectors.selectIsSigningCurrentDocument);
    const document = useMemoizedCreateSelector(DatabaseSelectors.createDocumentByIdSelector, documentId);

    const isDownloading = useLoadingState(LoadingTypes.DOWNLOAD_DOCUMENT);

    const isAnimationActive = useSelector(AnimationsSelectors.selectIsAnimationActive);

    const onEndedAnimation = () => {
        dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.MAIN));
    };

    const onSignClick = () => {
        if (onSignFn) {
            onSignFn();
        } else {
            // Sign document
            dispatch(SigningActions.signCurrentDocument());
        }
    };

    const onNextClick = () => {
        if (onNextFn) {
            onNextFn();
        } else {
            dispatch(AnimationActions.storeNextDispatch(
                SigningActions.openNextDocument(),
            ));
            dispatch(AnimationActions.setIsAnimationActive(false));
        }
    };

    const onOpenSignatureModal = () => {
        dispatch(UiActions.setActiveModal(ModalsKeys.UPLOAD_SIGNATURE, true));
    };

    const handleLoadSuccess = () => {
        if (isSigned && !isSigning && pdfViewerRef.current) {
            setTimeout(() => {
                const height = pdfViewerRef.current.size.content.height;
                const containerHeightOffset = pdfViewerContainerRef.current.offsetHeight / 2;
                let documentOffset = SIGNATURE_OFFSETS_BY_DOCUMENT_TYPE[document?.type];

                if (!document) {
                    // For contract
                    documentOffset = 0.95;
                }

                let offset = height * documentOffset;
                offset -= containerHeightOffset;

                pdfViewerRef.current.scrollTo(0, offset || pdfViewerRef.current.size.content.height, 1500, {
                    easing: position => {
                        return (position === 1) ? 1 : -Math.pow(2, -10 * position) + 1;
                    },
                });
            }, 500);
        }
    };

    if (!isMobileSize) {
        return (
            <Box display="grid" gridTemplateColumns="1fr 238px" gap={2} minHeight="100%">
                <Slide
                    in={isAnimationActive}
                    direction="up"
                    onEntered={onEndedAnimation}
                    onExited={onEndedAnimation}
                >
                    <div ref={pdfViewerContainerRef}>
                        <PdfViewer scrollRef={pdfViewerRef} onLoadSuccess={handleLoadSuccess} type={type} />
                    </div>
                </Slide>

                <Box sx={{height: 'min-content'}}>
                    <Slide
                        in={!isFirstMount || true}
                        direction="left"
                        timeout={TIMEOUT_DELAY}
                    >
                        <div>
                            <Paper radius={8} elevation={2} sx={{p: 2}}>
                                <Typography variant="h5">
                                    {t('signing.sidebarTitle')}
                                </Typography>

                                <Alert
                                    severity={isSigned ? 'success' : 'warning'}
                                    sx={{
                                        mt: 1,
                                        borderRadius: 3,
                                        color: isSigned ? 'rgba(0, 0, 0, 0.6)' : 'warning.dark',
                                    }}
                                    icon={isSigned
                                        ? <CheckCircleIcon sx={{color: theme => theme.palette.buttonSuccess.main}} />
                                        : <HourglassTopIcon sx={{color: 'warning.dark'}} />}
                                >
                                    {!isSigned
                                        ? t('signing.awaitingSignature')
                                        : t(signedLocale)
                                    }
                                </Alert>

                                <DocumentSigningToolbar
                                    MainActionComponent={MainActionComponent}
                                    isSigning={isSigning}
                                    isLoadingNextStep={isLoadingNextStep}
                                    documentId={documentId}
                                    isSigned={isSigned}
                                    onSignClick={onSignClick}
                                    onNextClick={onNextClick}
                                />

                                {isDownloadPDFAvailable && isSigned && signatureUrl && (
                                    <LoadingButton
                                        sx={{py: 0.75, borderRadius: 2, mt: 1}}
                                        startIcon={<DownloadIcon />}
                                        variant="outlined"
                                        size="small"
                                        loading={isDownloading || !documentId}
                                        loadingPosition="start"
                                        onClick={() => {
                                            dispatch(DocumentActions.getDocument(documentId, true));
                                        }}
                                        fullWidth
                                    >
                                        {t('signing.downloadPdf')}
                                    </LoadingButton>
                                )}
                            </Paper>
                        </div>
                    </Slide>

                    <Slide
                        in={signatureUrl}
                        direction="up"
                        timeout={TIMEOUT_DELAY * 2}
                    >
                        <div>
                            <Paper radius={8} elevation={2} sx={{p: 2, mt: 2}}>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography variant="h5">
                                        {t('signing.mySignatureTitle')}
                                    </Typography>

                                    <Tooltip title={t('signing.changeSignatureTooltip')} placement="top" arrow>
                                        <IconButton
                                            sx={{
                                                '&:hover': {
                                                    color: 'primary.light',
                                                },
                                            }}
                                            size="small"
                                            onClick={onOpenSignatureModal}
                                        >
                                            <PublishedWithChangesIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Box>

                                <Box sx={{mt: 2}}>
                                    <img
                                        src={signatureUrl}
                                        alt="Signature"
                                        style={{width: '100%', maxHeight: '273px'}}
                                    />
                                </Box>
                            </Paper>
                        </div>
                    </Slide>
                </Box>
            </Box>
        );
    }

    return (
        <>
            <Grid container justifyContent="space-between" flexDirection="column">
                <div ref={pdfViewerContainerRef}>
                    <PdfViewer scrollRef={pdfViewerRef} onLoadSuccess={handleLoadSuccess} type={type} />
                </div>
            </Grid>

            {type === DOCUMENT_SIGNING_TYPES.TRAINING ? (
                <SwipeableEdgeDrawer
                    drawerContent={
                        <DocumentSigningMobileTraining isLoadingNextStep={isLoadingNextStep} />
                    }
                    height="135px"
                />
            ) : (
                <SwipeableEdgeDrawer
                    drawerContent={(
                        <DocumentSigningMobile
                            MainActionComponent={MainActionComponent}
                            isLoadingNextStep={isLoadingNextStep}
                            onNextClick={onNextClick}
                            onSignClick={onSignClick}
                            type={type}
                        />
                    )}
                    height={isSigned ? swipableEdgeHeight.signed : swipableEdgeHeight.unsigned}
                />
            )}
        </>
    );
};

DocumentSigning.propTypes = {
    MainActionComponent: PropTypes.any,
    type: PropTypes.oneOf(Object.values(DOCUMENT_SIGNING_TYPES)),
    isLoadingNextStep: PropTypes.bool,
    isDownloadPDFAvailable: PropTypes.bool,
    signedLocale: PropTypes.string,
    swipableEdgeHeight: PropTypes.object,
    onSignFn: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    onNextFn: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

DocumentSigning.defaultProps = {
    MainActionComponent: null,
    type: DOCUMENT_SIGNING_TYPES.ONBOARDING,
    isLoadingNextStep: false,
    isDownloadPDFAvailable: false,
    signedLocale: 'signing.documentSigned',
    swipableEdgeHeight: {
        signed: '50px',
        unsigned: '50px',
    },
    onSignFn: null,
    onNextFn: null,
};
