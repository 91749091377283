import {all, call, put, select, takeLatest} from 'redux-saga/effects';
import {WorkshopsActionTypes} from './workshops.action-type';
// eslint-disable-next-line import/no-cycle
import {getCompanySaga} from '../../../../../v1/app/company/company.sagas';
import {LoadingActions, LoadingTypes} from '../../../../loading';
import {FreelancerSelectors} from '../../../store/freelancer.selector';
import {CompaniesActions, CompaniesSelectors} from '../../companies';
import {loadFreelancerCompanies} from '../../companies/store/companies.saga';
import {OnboardingActions} from '../../onboarding/store/onboarding.action';
import {OnboardingSelectors} from '../../onboarding/store/onboarding.selectors';
import {OnboardingSteps} from '../../onboarding/utils/onboadingStepsConstant';
import {WorkshopsApi} from '../api/workshops.api';

const goToCompanySetupFlow = function* ({freelancerId, companyId}) {
    try {
        const companies = yield select(CompaniesSelectors.selectEntities);
        const company = yield select(CompaniesSelectors.createCompanyByIdSelector(companyId));

        if (company.status === 'PENDING_SETUP_START') {
            const company = yield call(WorkshopsApi.putCompanySetupStep, {freelancerId, companyId});

            yield put(CompaniesActions.storeEntities({
                ...companies,
                [companyId]: company,
            }));
        }

        const progress = yield select(OnboardingSelectors.selectProgress);

        yield all([
            call(loadFreelancerCompanies, {freelancerId}),
            call(getCompanySaga, {
                payload: {freelancerId, companyId},
            }),
            put(OnboardingActions.setStep(OnboardingSteps.COMPANY_SETUP)),
            put(OnboardingActions.setInternalSubStep(null)),
            put(OnboardingActions.setSubStep(null)),
            put(OnboardingActions.setProgress({
                ...progress,
                [OnboardingSteps.WORKSHOPS]: {
                    ...progress[OnboardingSteps.WORKSHOPS],
                    isCompleted: true,
                },
            })),
        ]);
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error({error});
    }
};

const goToCompanySetupWorker = function* () {
    yield put(LoadingActions.setLoading(LoadingTypes.GO_TO_COMPANY_SETUP, true));

    const freelancer = yield select(FreelancerSelectors.selectAccount);

    yield call(goToCompanySetupFlow, {freelancerId: freelancer.id, companyId: freelancer.defaultCompanyId});

    yield put(LoadingActions.setLoading(LoadingTypes.GO_TO_COMPANY_SETUP, false));
};

export const workshopsSaga = function* () {
    yield all([
        takeLatest(WorkshopsActionTypes.GO_TO_COMPANY_SETUP, goToCompanySetupWorker),
    ]);
};
