/* eslint-disable react/prop-types */
import {faChevronDown} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import composeClasses from '@mui/base/composeClasses';
import {Box, IconButton, Stack, Typography} from '@mui/joy';
import {styled as defaultStyled} from '@mui/styles';
import {styled, useThemeProps} from '@mui/system';
import * as React from 'react';
import {getPickersCalendarHeaderUtilityClass} from './pickersCalendarHeaderClasses';
import {PickersFadeTransitionGroup} from './PickersFadeTransitionGroup';
import {useIsMobileSize} from '../../../../hooks/use-is-mobile-size';
import Fade from '../../mui-legacy/Fade';
import {PickersArrowSwitcher} from '../internals/components/PickersArrowSwitcher';
import {
    useNextMonthDisabled,
    usePreviousMonthDisabled,
} from '../internals/hooks/date-helpers-hooks';
import {useLocaleText, useUtils} from '../internals/hooks/useUtils';
import {buildDeprecatedPropsWarning} from '../internals/utils/warning';

const useUtilityClasses = ownerState => {
    const {classes} = ownerState;
    const slots = {
        root: ['root'],
        labelContainer: ['labelContainer'],
        label: ['label'],
        switchViewButton: ['switchViewButton'],
        switchViewIcon: ['switchViewIcon'],
    };

    return composeClasses(slots, getPickersCalendarHeaderUtilityClass, classes);
};

const PickersCalendarHeaderRoot = styled('div', {
    name: 'MuiPickersCalendarHeader',
    slot: 'Root',
    overridesResolver: (_, styles) => styles.root,
})({
    display: 'flex',
    alignItems: 'center',
    marginTop: 16,
    marginBottom: 8,
    paddingLeft: 24,
    paddingRight: 12,
    // prevent jumping in safari
    maxHeight: 32,
    minHeight: 32,
});

const PickersCalendarHeaderLabelContainer = styled(Typography, {
    name: 'MuiPickersCalendarHeader',
    slot: 'LabelContainer',
    overridesResolver: (_, styles) => styles.labelContainer,
})(({theme}) => ({
    display: 'flex',
    maxHeight: 32,
    overflow: 'hidden',
    alignItems: 'center',
    cursor: 'pointer',
    marginRight: 'auto',
    ...theme.typography['title-lg'],
    color: theme.palette.text.secondary,
}));

const PickersCalendarHeaderLabel = styled('div', {
    name: 'MuiPickersCalendarHeader',
    slot: 'Label',
    overridesResolver: (_, styles) => styles.label,
})({
    marginRight: 16,
});

const PickersCalendarHeaderSwitchViewButton = styled(IconButton, {
    name: 'MuiPickersCalendarHeader',
    slot: 'SwitchViewButton',
    overridesResolver: (_, styles) => styles.switchViewButton,
})({
    marginRight: 'auto',
    borderRadius: '50%',
});

const PickersCalendarHeaderSwitchViewIcon = defaultStyled(Box, {
    name: 'MuiPickersCalendarHeader',
    slot: 'SwitchViewIcon',
    overridesResolver: (_, styles) => styles.switchViewIcon,
})(({theme, ownerState}) => ({
    willChange: 'transform',
    transition: theme.transitions.create('transform'),
    transform: 'rotate(0deg)',
    ...(ownerState.openView === 'year' && {
        transform: 'rotate(180deg)',
    }),
}));

const deprecatedPropsWarning = buildDeprecatedPropsWarning(
    'Props for translation are deprecated. See https://mui.com/x/react-date-pickers/localization for more information.',
);

export const PickersCalendarHeader = inProps => {
    const props = useThemeProps({
        props: inProps,
        name: 'MuiPickersCalendarHeader',
    });
    const {
        components = {},
        componentsProps = {},
        currentMonth: month,
        disabled,
        disableFuture,
        disablePast,
        getViewSwitchingButtonText: getViewSwitchingButtonTextProp,
        leftArrowButtonText: leftArrowButtonTextProp,
        maxDate,
        minDate,
        onMonthChange,
        onViewChange,
        openView: currentView,
        reduceAnimations,
        rightArrowButtonText: rightArrowButtonTextProp,
        views,
        labelId,
        headerAdditionalContent,
    } = props;

    const isMobileSize = useIsMobileSize();

    deprecatedPropsWarning({
        leftArrowButtonText: leftArrowButtonTextProp,
        rightArrowButtonText: rightArrowButtonTextProp,
        getViewSwitchingButtonText: getViewSwitchingButtonTextProp,
    });

    const localeText = useLocaleText();

    const leftArrowButtonText = leftArrowButtonTextProp ?? localeText.previousMonth;
    const rightArrowButtonText = rightArrowButtonTextProp ?? localeText.nextMonth;
    const getViewSwitchingButtonText = getViewSwitchingButtonTextProp
    ?? localeText.calendarViewSwitchingButtonAriaLabel;

    const utils = useUtils();
    const classes = useUtilityClasses(props);

    const switchViewButtonProps = componentsProps.switchViewButton || {};

    const selectNextMonth = () => onMonthChange(utils.getNextMonth(month), 'left');
    const selectPreviousMonth = () => onMonthChange(utils.getPreviousMonth(month), 'right');

    const isNextMonthDisabled = useNextMonthDisabled(month, {
        disableFuture,
        maxDate,
    });
    const isPreviousMonthDisabled = usePreviousMonthDisabled(month, {
        disablePast,
        minDate,
    });

    const handleToggleView = () => {
        if (views.length === 1 || !onViewChange || disabled) {
            return;
        }

        if (views.length === 2) {
            onViewChange(views.find(view => view !== currentView) || views[0]);
        } else {
            // switching only between first 2
            const nextIndexToOpen = views.indexOf(currentView) !== 0 ? 0 : 1;
            onViewChange(views[nextIndexToOpen]);
        }
    };

    // No need to display more information
    if (views.length === 1 && views[0] === 'year') {
        return null;
    }

    const ownerState = props;

    const headerLabel = (
        <PickersCalendarHeaderLabelContainer
            role="presentation"
            onClick={handleToggleView}
            ownerState={ownerState}
            // putting this on the label item element below breaks when using transition
            aria-live="polite"
            className={classes.labelContainer}
        >
            <PickersFadeTransitionGroup
                reduceAnimations={reduceAnimations}
                transKey={utils.format(month, 'monthAndYear')}
            >
                <PickersCalendarHeaderLabel
                    id={labelId}
                    data-mui-test="calendar-month-and-year-text"
                    ownerState={ownerState}
                    className={classes.label}
                >
                    {utils.format(month, 'monthAndYear')}
                </PickersCalendarHeaderLabel>
            </PickersFadeTransitionGroup>
            {views.length > 1 && !disabled && (
                <PickersCalendarHeaderSwitchViewButton
                    size="sm"
                    variant="soft"
                    color="neutral"
                    as={components.SwitchViewButton}
                    aria-label={getViewSwitchingButtonText(currentView)}
                    className={classes.switchViewButton}
                    {...switchViewButtonProps}
                >
                    <PickersCalendarHeaderSwitchViewIcon
                        as={components.SwitchViewIcon}
                        ownerState={ownerState}
                        className={classes.switchViewIcon}
                    >
                        <FontAwesomeIcon icon={faChevronDown} />
                    </PickersCalendarHeaderSwitchViewIcon>
                </PickersCalendarHeaderSwitchViewButton>
            )}
        </PickersCalendarHeaderLabelContainer>
    );

    const arrows = (
        <Fade in={currentView === 'day'}>
            <PickersArrowSwitcher
                leftArrowButtonText={leftArrowButtonText}
                rightArrowButtonText={rightArrowButtonText}
                components={components}
                componentsProps={componentsProps}
                onLeftClick={selectPreviousMonth}
                onRightClick={selectNextMonth}
                isLeftDisabled={isPreviousMonthDisabled}
                isRightDisabled={isNextMonthDisabled}
            />
        </Fade>
    );

    return (
        <PickersCalendarHeaderRoot
            ownerState={ownerState}
            className={classes.root}
            sx={headerAdditionalContent && isMobileSize ? {
                maxHeight: 'none',
                minHeight: 'none',
                width: '100%',
            } : {}}
        >
            {!headerAdditionalContent && (
                <>
                    {headerLabel}
                    {arrows}
                </>
            )}
            {!!headerAdditionalContent && isMobileSize && (
                <Stack
                    direction="column"
                    gap={2}
                    width="100%"
                >
                    <Stack
                        direction="row"
                        alignItems="center"
                    >
                        {headerLabel}
                        {arrows}
                    </Stack>
                    {headerAdditionalContent}
                </Stack>
            )}
            {!!headerAdditionalContent && !isMobileSize && (
                <>
                    {headerLabel}
                    {arrows}
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        gap={2}
                        ml="auto"
                        flexGrow={1}
                    >
                        {headerAdditionalContent}
                    </Stack>
                </>
            )}
        </PickersCalendarHeaderRoot>
    );
};
