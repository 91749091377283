import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import PersonIcon from '@mui/icons-material/Person';
import {Box, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import ReactRouterLink from '../../../components/app/router/ReactRouterLink';
import {resolveRoute} from '../../../lib/router/resolveRoute';
import {ROUTE_PATHS} from '../../../lib/router/route-paths';

const CompanyAndUser = ({rowData}) => {
    const typographySx = {
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        color: 'v2.light.primary.main',
        textDecoration: 'none',
        fontSize: theme => theme.typography.pxToRem(14),
    };
    const iconSx = {
        color: 'v2.blueGray.100',
        fontSize: theme => theme.typography.pxToRem(16),
    };

    if (!rowData.freelancer) {
        return rowData.company.name;
    }

    const userFullName = `${rowData.freelancer.firstName} ${rowData.freelancer.lastName}`;

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: 0.5,
        }}
        >
            <ReactRouterLink
                to={resolveRoute(ROUTE_PATHS.COMPANY, {
                    freelancerId: rowData.freelancer.id,
                    companyId: rowData.company.id,
                })}
                onClick={e => e.stopPropagation()}
            >
                <Typography sx={{...typographySx, fontWeight: 700}}>
                    <BusinessCenterIcon sx={iconSx} />
                    {rowData?.company.name}
                </Typography>
            </ReactRouterLink>
            <ReactRouterLink
                to={resolveRoute(ROUTE_PATHS.USER, {
                    userId: rowData.freelancer.id,
                })}
                onClick={e => e.stopPropagation()}
            >
                <Typography sx={typographySx}>
                    <PersonIcon sx={iconSx} />
                    {userFullName}
                </Typography>
            </ReactRouterLink>
        </Box>

    );
};

CompanyAndUser.propTypes = {
    rowData: PropTypes.object.isRequired,
};

export default CompanyAndUser;
