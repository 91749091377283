import {FormLabel, Grid, MenuItem, TextField} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {Controller} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import Grow from '../../../../../../components/joy-ui/mui-legacy/Grow';
import {TITLES} from '../../../../../../v1/app/company/setupCompany/setupCompany.constants';
import {
    usePersonalInformationFormStyles,
} from '../../../../../../v1/components/setup-company/PersonalInformationForm/styles';
import {AnimationActions} from '../../../../../animations/store/animations.action';
import {AnimationsSelectors} from '../../../../../animations/store/animations.selector';
import {ANIMATION_TYPE, FORM_ANIMATION_DELAY} from '../../../../../animations/utils/constants';

const PersonalInformatioinName = ({t, control, errors}) => {
    const styles = usePersonalInformationFormStyles();
    const isSubAnimationActive = useSelector(AnimationsSelectors.selectIsSubAnimationActive);
    const dispatch = useDispatch();

    const titleOptions = useMemo(() => {
        return Object.values(TITLES).map(title => (
            <MenuItem value={title} key={title}>
                {t(`companies:titles.${title}`)}
            </MenuItem>
        ));
    }, [t]);

    return (
        <>
            <FormLabel component="legend" className={styles.formLabel}>
                {t('fullName')}
            </FormLabel>

            <Grow
                in={isSubAnimationActive}
                timeout={FORM_ANIMATION_DELAY}
                onExited={() => {
                    dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.SECONDARY));
                }}
            >
                <Grid container spacing={4}>
                    <Grid item xs={12} md={2}>
                        <Controller
                            name="title"
                            control={control}
                            rules={{required: true}}
                            render={({field}) => {
                                return (
                                    <TextField
                                        {...field}
                                        label={t('title')}
                                        variant="filled"
                                        error={!!errors.title}
                                        helperText={errors.title?.message}
                                        select
                                        required
                                        fullWidth
                                    >
                                        {titleOptions}

                                    </TextField>
                                );
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={5}>
                        <Controller
                            name="firstName"
                            control={control}
                            render={({field}) => {
                                return (
                                    <TextField
                                        {...field}
                                        label={t('firstName')}
                                        variant="filled"
                                        error={!!errors.firstName}
                                        helperText={errors.firstName?.message}
                                        required
                                        fullWidth
                                    />
                                );
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={5}>
                        <Controller
                            name="lastName"
                            control={control}
                            render={({field}) => {
                                return (
                                    <TextField
                                        {...field}
                                        label={t('lastName')}
                                        variant="filled"
                                        error={!!errors.lastName}
                                        helperText={errors.lastName?.message}
                                        required
                                        fullWidth
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>
            </Grow>
        </>
    );
};

PersonalInformatioinName.propTypes = {
    t: PropTypes.func.isRequired,
    control: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

export default PersonalInformatioinName;
