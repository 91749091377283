import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {CardsActionType} from './cards.action-type';
import {CardsActions} from './cards.actions';
import {Toast} from '../../../../../lib/toast';
import {COUNTRIES} from '../../../../../utils/countries';
import {Debug} from '../../../../../utils/debug';
import {LoadingActions, LoadingTypes} from '../../../../loading';
import {getHiwayAccountId} from '../../../store/bank.loader.saga';
import {BankSelector} from '../../../store/bank.selector';
import {BANK_TYPES} from '../../bridge-api/utils/constants';
import {CardsApi} from '../api/cards.api';

const getCardFlow = function* (accountId) {
    try {
        const response = yield call(CardsApi.getCards, {
            accountId,
        });

        yield put(CardsActions.storeCards(response));

        yield put(LoadingActions.setLoading(LoadingTypes.GET_CARD, false));
    } catch (e) {
        Debug.error('bank', 'Error: ', {e});
        yield put(LoadingActions.setLoading(LoadingTypes.GET_CARD, false));

        Toast.error('genericError');
    }
};

const createCardsFlow = function* (accountId, data) {
    yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));

    if (!accountId) {
        return;
    }

    try {
        const response = yield call(CardsApi.createCards, {
            accountId,
            data,
        });

        if (response.consentUrl) {
            window.location = response.consentUrl;
        }

        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));

        Toast.success('genericSuccessSave');
    } catch (e) {
        Debug.error('bank', 'Error: ', {e});
        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));

        // If BE is sending error message show that error
        if (e?.response?.status === 400 && e?.response?.data?.message?.[0]) {
            Toast.error(e.response.data.message[0]);
        } else {
            Toast.error('genericError');
        }
    }
};

const updateCardsFlow = function* (accountId, data, cardId) {
    yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));

    if (!accountId) {
        return;
    }

    try {
        const response = yield call(CardsApi.updateCards, {
            accountId,
            cardId,
            data,
        });

        if (response.consentUrl) {
            window.location = response.consentUrl;
        }

        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));

        Toast.success('genericSuccessSave');
    } catch (e) {
        Debug.error('bank', 'Error: ', {e});
        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));

        Toast.error('genericError');
    }
};

const getCountriesFlow = function* () {
    try {
        let response = yield call(CardsApi.getCountries);

        if (response?.length > 0) {
            response = response.map(countryResponse => {
                const constantCountry = COUNTRIES.find(
                    country => country.code === countryResponse.alpha2,
                );
                return {
                    ...countryResponse,
                    translation: constantCountry.name,
                    alpha3: countryResponse.alpha3.toUpperCase(),
                };
            });
        }

        yield put(CardsActions.storeCountries(response));
    } catch (e) {
        Debug.error('bank', 'Error: ', {e});
        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));

        Toast.error('genericError');
    }
};

const activatePhyCardFlow = function* (accountId, id, code) {
    try {
        const response = yield call(CardsApi.activatePhysCard, {accountId, id, code});

        if (response.consentUrl) {
            window.location = response.consentUrl;
        }

        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));
    } catch (e) {
        Debug.error('bank', 'Error: ', {e});
        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));

        Toast.error('genericError');
    }
};

const viewPinFlow = function* (accountId, id) {
    try {
        const response = yield call(CardsApi.viewPinFlow, {accountId, id});

        if (response?.consentUrl) {
            window.location = response.consentUrl;
        }
    } catch (e) {
        Debug.error('bank', 'Error: ', {e});
        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));

        Toast.error('genericError');
    }
};

const lockCardFlow = function* (accountId, id) {
    try {
        const response = yield call(CardsApi.lockCard, {accountId, id});

        yield put(CardsActions.storeCards([response]));

        yield put(LoadingActions.setLoading(LoadingTypes.LOCK_CARD, false));
    } catch (e) {
        Debug.error('bank', 'Error: ', {e});
        yield put(LoadingActions.setLoading(LoadingTypes.LOCK_CARD, false));

        Toast.error('genericError');
    }
};

const unlockCardFlow = function* (accountId, id) {
    try {
        const response = yield call(CardsApi.unlockCard, {accountId, id});

        if (response.consentUrl) {
            window.location = response.consentUrl;
        }

        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));
    } catch (e) {
        Debug.error('bank', 'Error: ', {e});
        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));

        Toast.error('genericError');
    }
};

const deleteFlow = function* (accountId, id) {
    try {
        const response = yield call(CardsApi.deleteCards, {accountId, id});

        if (response.consentUrl) {
            window.location = response.consentUrl;
        }

        yield put(CardsActions.storeCards(response));

        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));

        Toast.success('cardDeleteSuccess');
    } catch (e) {
        Debug.error('bank', 'Error: ', {e});
        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));

        Toast.error('genericError');
    }
};

const showNumbersFlow = function* (accountId, id, isPhysical) {
    try {
        let response;
        if (isPhysical) {
            response = yield call(CardsApi.showPhysicalNumbers, {accountId, id});
        } else {
            response = yield call(CardsApi.showNumbers, {accountId, id});
        }

        if (response.consentUrl) {
            window.location = response.consentUrl;
        }

        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));
    } catch (e) {
        Debug.error('bank', 'Error: ', {e});
        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));

        Toast.error('genericError');
    }
};

/* Workers */
export const startBankCardLoader = function* () {
    yield put(LoadingActions.setLoading(LoadingTypes.GET_CARD, true));
};

export const getCardWorker = function* ({payload}) {
    const accountId = yield call(getHiwayAccountId);
    const integrations = yield select(BankSelector.selectIntegrations);
    const hasHiwayIntegration = integrations.find(integration => integration.type === BANK_TYPES.hiway);

    if (!accountId || !hasHiwayIntegration) {
        return;
    }

    const {loading} = payload;

    if (loading !== false) {
        yield put(LoadingActions.setLoading(LoadingTypes.GET_CARD, true));
    }

    yield call(getCardFlow, accountId);
};

export const createCardsWorker = function* ({payload}) {
    const accountId = yield call(getHiwayAccountId);

    if (!accountId) {
        return;
    }

    yield call(createCardsFlow, accountId, payload);
};

export const updateCardsWorker = function* ({payload}) {
    const accountId = yield call(getHiwayAccountId);

    if (!accountId) {
        return;
    }

    yield call(updateCardsFlow, accountId, payload.data, payload.cardId);
};

export const getCountriesWorker = function* () {
    yield call(getCountriesFlow);
};

const activatePhyCardWorker = function* ({payload}) {
    const accountId = yield call(getHiwayAccountId);

    if (!accountId) {
        return;
    }

    yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));

    yield call(activatePhyCardFlow, accountId, payload.id, payload.code);
};

const viewPinWorker = function* ({payload}) {
    const accountId = yield call(getHiwayAccountId);

    if (!accountId) {
        return;
    }

    yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));

    yield call(viewPinFlow, accountId, payload.id);
};

const lockCardWorker = function* ({payload}) {
    const accountId = yield call(getHiwayAccountId);

    if (!accountId) {
        return;
    }

    yield put(LoadingActions.setLoading(LoadingTypes.LOCK_CARD, true));

    yield call(lockCardFlow, accountId, payload.id);
};

const unlockCardWorker = function* ({payload}) {
    const accountId = yield call(getHiwayAccountId);

    if (!accountId) {
        return;
    }

    yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));

    yield call(unlockCardFlow, accountId, payload.id);
};

const showNumbersWorker = function* ({payload}) {
    const accountId = yield call(getHiwayAccountId);

    if (!accountId) {
        return;
    }

    yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));

    yield call(showNumbersFlow, accountId, payload.id, payload.isPhysical);
};

const deleteWorker = function* ({payload}) {
    const accountId = yield call(getHiwayAccountId);

    if (!accountId) {
        return;
    }

    yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));

    yield call(deleteFlow, accountId, payload);
};

export const watchCardsSaga = function* () {
    yield all([
        takeEvery(CardsActionType.GET_CARDS, getCardWorker),
        takeEvery(CardsActionType.CREATE, createCardsWorker),
        takeEvery(CardsActionType.UPDATE, updateCardsWorker),
        takeEvery(CardsActionType.GET_COUNTRIES, getCountriesWorker),
        takeEvery(CardsActionType.ACTIVATE_PHYS_CARD, activatePhyCardWorker),
        takeEvery(CardsActionType.VIEW_PIN, viewPinWorker),
        takeEvery(CardsActionType.LOCK_CARD, lockCardWorker),
        takeEvery(CardsActionType.UNLOCK_CARD, unlockCardWorker),
        takeEvery(CardsActionType.SHOW_NUMBERS, showNumbersWorker),
        takeEvery(CardsActionType.DELETE, deleteWorker),
    ]);
};
