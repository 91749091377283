import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';

import {useTranslation} from 'react-i18next';
import {useCompanyDocumentsNameStyles} from './styles';
import {DOCUMENT_TYPES} from '../../../../consts/document-constants';
import ExcelIcon from '../../icons/ExcelIcon';
import ImageIcon from '../../icons/ImageIcon';
import PdfEditIcon from '../../icons/PdfEditIcon';
import PdfIcon from '../../icons/PdfIcon';
import WordIcon from '../../icons/WordIcon';

// TODO Refactor extensions for specific document cell requirements

const DISPLAY_FILENAME_FOR_DOCUMENT_TYPES = [
    'JOB_DOC',
    'INVOICE',
];

const DocumentNameCellTrainingApproved = ({rowData, isMobile, isTrainingMobile}) => {
    const {t} = useTranslation('training');

    const styles = useCompanyDocumentsNameStyles();

    const fileExtension = useMemo(() => {
        const parts = rowData.fileName.split('.');

        return parts[parts.length - 1].toLowerCase();
    }, [rowData.fileName]);

    const icon = useMemo(() => {
        const style = isTrainingMobile ? styles.iconMobileTraining : isMobile ? styles.iconMobile : styles.icon;
        switch (fileExtension) {
            case 'docx':
            case 'doc':
                return <WordIcon className={style} />;

            case 'xlsx':
            case 'xls':
                return <ExcelIcon className={style} />;

            case 'pdf':
                return <PdfIcon className={style} />;

            case 'pdf_edit':
                return <PdfEditIcon className={style} />;

            case 'jpg':
            case 'jpeg':
            case 'png':
                return <ImageIcon className={style} />;

            default:
                return null;
        }
        // eslint-disable-next-line
    }, [fileExtension, styles.icon]);

    return (
        <div className={styles.container}>
            {icon}
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                <Box>
                    {
                    DISPLAY_FILENAME_FOR_DOCUMENT_TYPES.includes(rowData.type)
                        ? rowData.fileName : rowData.docName
                    }
                </Box>
                <Box sx={{fontSize: theme => theme.typography.pxToRem(12), color: 'rgba(0, 0, 0, 0.38)'}}>
                    {t(`fundingApproval.approvedFileDescription.${rowData.type}`)}
                </Box>
            </Box>
        </div>
    );
};

DocumentNameCellTrainingApproved.propTypes = {
    rowData: PropTypes.shape({
        fileName: PropTypes.string.isRequired,
        docName: PropTypes.string.isRequired,
        type: PropTypes.oneOf(Object.values(DOCUMENT_TYPES)).isRequired,
    }).isRequired,
    isMobile: PropTypes.bool,
    isTrainingMobile: PropTypes.bool,
};

DocumentNameCellTrainingApproved.defaultProps = {
    isMobile: false,
    isTrainingMobile: false,
};

export default React.memo(DocumentNameCellTrainingApproved);
