const selectOnboarding = state => state.mainOnboarding.onboarding;

const selectStep = state => state.mainOnboarding.currentlyOpenStep;
const selectSubStep = state => state.mainOnboarding.currentlyOpenSubStep;

export const MainOnboardingSelectors = {
    selectOnboarding,
    selectStep,
    selectSubStep,
};
