import {TableCell, TableRow, TableSortLabel} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import {useDataTableHeaderColumns} from './styles';
import {Checkbox as JoyCheckbox} from '../../../../components/joy-ui/forms/Checkbox';
import {requiredIf} from '../../../../utils/conditional-prop-type';
import Checkbox from '../Checkbox';

const DataTableHeaderColumns = props => {
    const {
        columnConfig,
        sortBy,
        sortDirection,
        onSortClick,
        hasCheckboxes,
        selectedCount,
        count,
        onSelectAllClick,
        isJoyTheme,
    } = props;

    const styles = useDataTableHeaderColumns({isJoyTheme});

    const CheckboxComponent = isJoyTheme ? JoyCheckbox : Checkbox;

    return (
        <TableRow>
            {hasCheckboxes && (
                <TableCell padding="checkbox">
                    <CheckboxComponent
                        indeterminate={selectedCount > 0 && selectedCount < count}
                        checked={count > 0 && selectedCount === count}
                        onChange={onSelectAllClick}
                    />
                </TableCell>
            )}

            {columnConfig.map(column => (
                <TableCell
                    key={column.key}
                    className={styles.tableCell}
                    style={{width: column.width || 'auto', textAlign: column.textAlign || 'left'}}
                    {...(column.additionalCellProps || {})}
                    {...(column.isSortable ? {sortDirection, onClick: () => onSortClick(column.key)} : {})}
                >
                    {column.isSortable ? (
                        <TableSortLabel direction={sortDirection} active={sortBy === column.key}>
                            {column.title}
                        </TableSortLabel>
                    ) : column.title}
                </TableCell>
            ))}
        </TableRow>
    );
};

DataTableHeaderColumns.propTypes = {
    columnConfig: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        title: PropTypes.string.isRequired,
        additionalCellProps: PropTypes.object,
    })).isRequired,
    sortDirection: PropTypes.string.isRequired,
    sortBy: PropTypes.string,
    onSortClick: PropTypes.func.isRequired,
    hasCheckboxes: PropTypes.bool,
    selectedCount: PropTypes.number,
    count: PropTypes.number,
    // eslint-disable-next-line react/require-default-props
    onSelectAllClick: requiredIf(
        props => props.hasCheckboxes && typeof props.onSelectAllClick !== 'function',
        '"onSelectAllClick" must be a function if "hasCheckboxes" is true"',
    ),
    isJoyTheme: PropTypes.bool,
};

DataTableHeaderColumns.defaultProps = {
    hasCheckboxes: false,
    selectedCount: 0,
    count: 0,
    sortBy: null,
    isJoyTheme: false,
};

export default DataTableHeaderColumns;
