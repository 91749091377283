import {yupResolver} from '@hookform/resolvers/yup';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SaveIcon from '@mui/icons-material/Save';
import {LoadingButton} from '@mui/lab';
import {Box, Divider, FormLabel, Grid, Grow, MenuItem, Paper, Slide, TextField} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {DatePicker} from '@mui/x-date-pickers';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';

import {usePersonalInformationFormStyles} from './styles';
import {PageHeader} from '../../../../components/page-header/PageHeader';
import {AnimationActions} from '../../../../features/animations/store/animations.action';
import {AnimationsSelectors} from '../../../../features/animations/store/animations.selector';
import {
    ANIMATION_TYPE,
    FORM_ANIMATION_DELAY,
} from '../../../../features/animations/utils/constants';
import {SettingsSelectors} from '../../../../features/settings/store/settings.selectors';
import {LoggedInUserSelectors} from '../../../../features/user/modules/logged-in-user';
import {useCommonValidations} from '../../../../lib/yup/common-validatons';
import {COUNTRIES} from '../../../../utils/countries';
import {NATIONALITIES} from '../../../../utils/nationalities';
import {REGIONAL_DEPARTMENTS} from '../../../../utils/regional-departments';
import {getDefaultLocale} from '../../../app/app.helpers';
import {
    getPersonalInformationData,
} from '../../../app/company/setupCompany/personalInformation/personalInformation.selectors';
import {submitActiveStepForm} from '../../../app/company/setupCompany/setupCompany.actions';
import {TITLES} from '../../../app/company/setupCompany/setupCompany.constants';
import {getIsLoadingSetupCompanyForm} from '../../../app/company/setupCompany/setupCompany.selectors';

export const PersonalInformationForm = ({hasSubmitModificationsButton}) => {
    const styles = usePersonalInformationFormStyles();
    const {t} = useTranslation('setup');
    const dispatch = useDispatch();

    const loggedInUser = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const personalInformationData = useSelector(getPersonalInformationData);
    const settings = useSelector(SettingsSelectors.selectSettings);
    const isLoadingForm = useSelector(getIsLoadingSetupCompanyForm);

    const userLanguage = settings?.language || getDefaultLocale();

    const isAnimationActive = useSelector(AnimationsSelectors.selectIsAnimationActive);
    const isSubAnimationActive = useSelector(AnimationsSelectors.selectIsSubAnimationActive);

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const defaultValues = {
        title: personalInformationData?.title || '',
        firstName: personalInformationData?.firstName || loggedInUser?.firstName || '',
        lastName: personalInformationData?.lastName || loggedInUser?.lastName || '',
        dateOfBirth: personalInformationData?.dateOfBirth
            ? moment(personalInformationData.dateOfBirth, 'DD/MM/YYYY')
            : null,
        countryOfBirth: personalInformationData?.countryOfBirth || '',
        placeOfBirth: personalInformationData?.placeOfBirth || '',
        postalCode: personalInformationData?.postalCode || '',
        regionalDepartmentOfBirth: personalInformationData?.regionalDepartmentOfBirth || '',
        nationality: personalInformationData?.nationality || '',
        socialSecurityNumber: personalInformationData?.socialSecurityNumber || '',
    };

    const {dateOfBirthValidation} = useCommonValidations();

    const validationSchema = Yup.object().shape({
        title: Yup.string().oneOf(Object.values(TITLES)).required(t('validations.isRequired', {what: t('title')})),
        firstName: Yup.string().min(2, t('validations.minMessage')).max(26, t('validations.maxMessage')).required(t('validations.isRequired', {what: t('firstName')})),
        lastName: Yup.string().min(2, t('validations.minMessage')).max(26, t('validations.maxMessage')).required(t('validations.isRequired', {what: t('lastName')})),
        dateOfBirth: dateOfBirthValidation(),
        countryOfBirth: Yup.string().max(32, t('validations.maxMessage')).required(t('validations.isRequired', {what: t('countryOfBirth')})),
        placeOfBirth: Yup.string().max(128, t('validations.maxMessage')).required(t('validations.isRequired', {what: t('placeOfBirth')})),
        regionalDepartmentOfBirth: Yup.string().min(2, t('validations.minMessage')).max(3, t('validations.maxMessage')).required(t('validations.isRequired', {what: t('regionalDepartmentOfBirth')})),
        nationality: Yup.string().min(2, t('validations.minMessage')).max(16, t('validations.maxMessage')).required(t('validations.isRequired', {what: t('nationality')})),
        postalCode: Yup.string().length(5, t('validations.lengthZip')).required(t('validations.isRequired', {what: t('postalCode')})),
        socialSecurityNumber: Yup
            .string()
            .matches(/^\d+$/, t('validations.invalidSocialSecurity'))
            .length(15, t('validations.lengthSocialSecurity'))
            .required(t('validations.isRequired', {what: t('socialSecurityNumber')})),
    });

    const {
        handleSubmit: createSubmitHandler,
        control,
        formState: {errors, isValid, isDirty},
        watch,
        setValue,
        reset,
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues,
    });

    const countryOfBirth = watch('countryOfBirth');
    const postalCode = watch('postalCode');

    const [isRegionalDepartmentOfBirthDisabled, setIsRegionalDepartmentOfBirthDisabled] = useState(false);
    // const [isSubmittingForApproval, setIsSubmittingForApproval] = useState(false);

    const maxDate = moment('01/01/2100', 'DD/MM/YYYY');
    const minDate = moment('01/01/1900', 'DD/MM/YYYY');

    const titleOptions = useMemo(() => {
        return Object.values(TITLES).map(title => (
            <MenuItem value={title} key={title}>
                {t(`companies:titles.${title}`)}
            </MenuItem>
        ));
    }, [t]);

    const countryOptions = useMemo(() => {
        return COUNTRIES
            .sort((a, b) => {
                return a.name[userLanguage].localeCompare(b.name[userLanguage], userLanguage);
            })
            .map(country => (
                <MenuItem value={country.code} key={country.code}>
                    {country.name[userLanguage]}
                </MenuItem>
            ));
    }, [userLanguage]);

    const regionalDepartmentOptions = useMemo(() => {
        return REGIONAL_DEPARTMENTS
            .sort((a, b) => a.id.localeCompare(b.id, undefined, {numeric: true, sensitivity: 'base'}))
            .map(regionalDepartment => {
                let name = regionalDepartment.name;

                if (typeof name === 'object' && name !== null) {
                    name = name[userLanguage];
                }

                return (
                    <MenuItem value={regionalDepartment.id} key={regionalDepartment.id}>
                        {`${regionalDepartment.id}${name ? ` (${name})` : ''}`}
                    </MenuItem>
                );
            });
    }, [userLanguage]);

    const nationalityOptions = useMemo(() => {
        return NATIONALITIES.map(nationality => (
            <MenuItem value={nationality.code} key={nationality.code}>
                {nationality.name[userLanguage]}
            </MenuItem>
        ));
    }, [userLanguage]);

    const formRef = useRef(null);
    const isSubmittingForApproval = useRef(false);

    const handleSubmit = useCallback(data => {
        const payload = {
            ...data,
            dateOfBirth: moment(data.dateOfBirth).format('DD/MM/YYYY'),
        };

        dispatch(submitActiveStepForm(payload, isDirty, null, isSubmittingForApproval.current));

        isSubmittingForApproval.current = false;
    }, [dispatch, isDirty]);

    const handleSubmitModifications = useCallback(() => {
        isSubmittingForApproval.current = true;

        // Trigger form submit
        formRef.current.dispatchEvent(new Event('submit', {
            cancelable: true,
            bubbles: true,
        }));
    }, []);

    useEffect(() => {
        if (countryOfBirth !== 'fr' && countryOfBirth) {
            setValue('regionalDepartmentOfBirth', '99');
            setIsRegionalDepartmentOfBirthDisabled(true);

            return;
        }

        setIsRegionalDepartmentOfBirthDisabled(false);

        if (postalCode) {
            const digits = postalCode.substring(0, 2);

            if (digits.length < 2) {
                return;
            }

            const department = REGIONAL_DEPARTMENTS.find(department => department.id === digits);

            if (!department) {
                return;
            }

            setValue('regionalDepartmentOfBirth', digits);
        }
    }, [countryOfBirth, setValue, postalCode]);

    useEffect(() => {
        if (!personalInformationData || !loggedInUser) {
            return;
        }

        const foundTitle = Object.values(TITLES).find(title => title === personalInformationData.title);

        reset({
            title: foundTitle || '',
            firstName: personalInformationData?.firstName || loggedInUser?.firstName || '',
            lastName: personalInformationData?.lastName || loggedInUser?.lastName || '',
            dateOfBirth: personalInformationData?.dateOfBirth
                ? moment(personalInformationData.dateOfBirth, 'DD/MM/YYYY')
                : null,
            countryOfBirth: personalInformationData.countryOfBirth || '',
            placeOfBirth: personalInformationData.placeOfBirth || '',
            postalCode: personalInformationData.postalCode || '',
            regionalDepartmentOfBirth: personalInformationData.regionalDepartmentOfBirth || '',
            nationality: personalInformationData.nationality || '',
            socialSecurityNumber: personalInformationData.socialSecurityNumber || '',
        });
    }, [personalInformationData, reset, setValue, loggedInUser]);

    useEffect(() => {
        if (isRegionalDepartmentOfBirthDisabled) {
            return;
        }

        if (postalCode) {
            const digits = postalCode.substring(0, 2);

            if (digits.length < 2) {
                return;
            }

            const department = REGIONAL_DEPARTMENTS.find(department => department.id === digits);

            if (!department) {
                return;
            }

            setValue('regionalDepartmentOfBirth', digits);
        }
        // eslint-disable-next-line
    }, [postalCode]);

    // TODO Separate animation end actions for onEntered and onExited
    const onEndedAnimation = () => {
        dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.MAIN));
    };

    if (!isMobileSize) {
        return (
            <form ref={formRef} onSubmit={createSubmitHandler(handleSubmit)} noValidate>
                <PageHeader>
                    {t('stepTitles.personalInformation')}
                </PageHeader>

                <Slide
                    direction="left"
                    in={isAnimationActive}
                    timeout={FORM_ANIMATION_DELAY}
                    onEntered={onEndedAnimation}
                    onExited={onEndedAnimation}
                >
                    <Paper radius={16} elevation={2} sx={{px: 5, py: 4}}>
                        <div className={styles.container}>
                            <FormLabel component="legend" className={styles.formLabel}>
                                {t('fullName')}
                            </FormLabel>

                            <Grow
                                in={isSubAnimationActive}
                                timeout={FORM_ANIMATION_DELAY}
                                onExited={() => {
                                    dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.SECONDARY));
                                }}
                            >
                                <Grid container spacing={4}>
                                    <Grid item xs={12} md={2}>
                                        <Controller
                                            name="title"
                                            control={control}
                                            rules={{required: true}}
                                            render={({field}) => {
                                                return (
                                                    <TextField
                                                        {...field}
                                                        label={t('title')}
                                                        variant="filled"
                                                        error={!!errors.title}
                                                        helperText={errors.title?.message}
                                                        select
                                                        required
                                                        fullWidth
                                                    >
                                                        {titleOptions}

                                                    </TextField>
                                                );
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={5}>
                                        <Controller
                                            name="firstName"
                                            control={control}
                                            render={({field}) => {
                                                return (
                                                    <TextField
                                                        {...field}
                                                        label={t('firstName')}
                                                        variant="filled"
                                                        error={!!errors.firstName}
                                                        helperText={errors.firstName?.message}
                                                        required
                                                        fullWidth
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={5}>
                                        <Controller
                                            name="lastName"
                                            control={control}
                                            render={({field}) => {
                                                return (
                                                    <TextField
                                                        {...field}
                                                        label={t('lastName')}
                                                        variant="filled"
                                                        error={!!errors.lastName}
                                                        helperText={errors.lastName?.message}
                                                        required
                                                        fullWidth
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grow>

                            <Divider className={styles.divider} />

                            <FormLabel component="legend" className={styles.formLabel}>
                                {t('dateOfBirth')}
                            </FormLabel>

                            <Grow
                                in={isSubAnimationActive}
                                timeout={FORM_ANIMATION_DELAY * 2}
                            >
                                <Grid container spacing={4}>
                                    <Grid item xs={12} md={7}>
                                        <Controller
                                            name="dateOfBirth"
                                            control={control}
                                            render={({field}) => {
                                                return (
                                                    <DatePicker
                                                        {...field}
                                                        label={t('dateOfBirth')}
                                                        invalidDateMessage={t('form:validation.notDate')}
                                                        error={!!errors.dateOfBirth}
                                                        helperText={errors.dateOfBirth?.message}
                                                        openTo="year"
                                                        format={moment.localeData().longDateFormat('L')}
                                                        views={['year', 'month', 'day']}
                                                        inputVariant="filled"
                                                        minDateMessage={t('form:validation.minDate', {date: minDate.format('L')})}
                                                        maxDateMessage={t('form:validation.maxDate', {date: maxDate.format('L')})}
                                                    // date={}
                                                    // onChange={}
                                                    // value={}
                                                    // openPicker={}
                                                    // rawValue={}
                                                        renderInput={params => (
                                                            <TextField
                                                                {...params}
                                                                variant="filled"
                                                                required={true}
                                                            />
                                                        )}
                                                    />
                                                );
                                            }}
                                            required
                                            fullWidth
                                            disableFuture
                                        />
                                    </Grid>
                                </Grid>
                            </Grow>

                            <Divider className={styles.divider} />

                            <FormLabel component="legend" className={styles.formLabel}>
                                {t('and', {first: t('placeOfBirth'), second: t('nationality')})}
                            </FormLabel>

                            <Grow
                                in={isSubAnimationActive}
                                timeout={FORM_ANIMATION_DELAY * 3}
                            >
                                <Grid container spacing={4}>
                                    <Grid item xs={12} md={7}>
                                        <Controller
                                            name="countryOfBirth"
                                            control={control}
                                            rules={{required: true}}
                                            render={({field}) => {
                                                return (
                                                    <TextField
                                                        {...field}
                                                        label={t('countryOfBirth')}
                                                        variant="filled"
                                                        error={!!errors.countryOfBirth}
                                                        helperText={errors.countryOfBirth?.message}
                                                        required
                                                        fullWidth
                                                        select
                                                    >
                                                        {countryOptions}
                                                    </TextField>
                                                );
                                            }}
                                        />
                                    </Grid>

                                    {/* <Grid item xs={12} md={5}> */}
                                    {/*    <CityAutocomplete
                                    label={t('cityOfBirth')}
                                    control={control}
                                     name="cityOfBirth" /> */}
                                    {/* </Grid> */}

                                    <Grid item xs={12} md={5}>
                                        <Controller
                                            name="placeOfBirth"
                                            control={control}
                                            rules={{required: true}}
                                            render={({field}) => {
                                                return (
                                                    <TextField
                                                        {...field}
                                                        label={t('placeOfBirth')}
                                                        variant="filled"
                                                        error={!!errors.placeOfBirth}
                                                        helperText={errors.placeOfBirth?.message}
                                                        required
                                                        fullWidth
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grow>

                            <Grow
                                in={isSubAnimationActive}
                                timeout={FORM_ANIMATION_DELAY * 4}
                            >
                                <Grid container spacing={4} sx={{mt: -2}}>
                                    <Grid item xs={12} md={7}>
                                        <Controller
                                            name="postalCode"
                                            control={control}
                                            render={({field}) => {
                                                return (
                                                    <TextField
                                                        {...field}
                                                        label={t('postalCode')}
                                                        variant="filled"
                                                        error={!!errors.postalCode}
                                                        helperText={errors.postalCode?.message}
                                                        required
                                                        fullWidth
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={5}>
                                        <Controller
                                            name="regionalDepartmentOfBirth"
                                            control={control}
                                            rules={{required: true}}
                                            render={({field}) => {
                                                return (
                                                    <TextField
                                                        {...field}
                                                        label={t('regionalDepartmentOfBirth')}
                                                        variant="filled"
                                                        error={!!errors.regionalDepartmentOfBirth}
                                                        helperText={errors.regionalDepartmentOfBirth?.message}
                                                        disabled={isRegionalDepartmentOfBirthDisabled}
                                                        required
                                                        fullWidth
                                                        select
                                                    >
                                                        {regionalDepartmentOptions}
                                                    </TextField>
                                                );
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grow>

                            <Grow
                                in={isSubAnimationActive}
                                timeout={FORM_ANIMATION_DELAY * 5}
                            >
                                <Grid container spacing={4} sx={{mt: -2}}>
                                    <Grid item xs={12} md={7}>
                                        <Controller
                                            name="nationality"
                                            control={control}
                                            rules={{required: true}}
                                            render={({field}) => {
                                                return (
                                                    <TextField
                                                        {...field}
                                                        label={t('nationality')}
                                                        variant="filled"
                                                        error={!!errors.nationality}
                                                        helperText={errors.nationality?.message}
                                                        required
                                                        fullWidth
                                                        select
                                                    >
                                                        {nationalityOptions}
                                                    </TextField>
                                                );
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grow>

                            <Divider className={styles.divider} />

                            <FormLabel component="legend" className={styles.formLabel}>
                                {t('socialSecurityNumber')}
                            </FormLabel>

                            <Grow
                                in={isSubAnimationActive}
                                timeout={FORM_ANIMATION_DELAY * 6}
                            >
                                <Grid container spacing={4}>
                                    <Grid item xs={12} md={7}>
                                        <Controller
                                            name="socialSecurityNumber"
                                            control={control}
                                            rules={{required: true}}
                                            render={({field}) => {
                                                return (
                                                    <TextField
                                                        {...field}
                                                        label={t('socialSecurityNumber')}
                                                        variant="filled"
                                                        error={!!errors.socialSecurityNumber}
                                                        helperText={errors.socialSecurityNumber?.message}
                                                        required
                                                        fullWidth
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grow>
                        </div>
                    </Paper>
                </Slide>

                <Slide
                    direction="left"
                    in={isAnimationActive}
                    timeout={FORM_ANIMATION_DELAY * 4}
                >
                    <Box display="flex" justifyContent="flex-end" sx={{mt: 3}}>
                        <Grow in={hasSubmitModificationsButton} mountOnEnter unmountOnExit>
                            <Box sx={{mr: 2}}>
                                <LoadingButton
                                    loading={isLoadingForm}
                                    size="large"
                                    variant="contained"
                                    color="primary"
                                    startIcon={<SaveIcon />}
                                    onClick={handleSubmitModifications}
                                    disabled={!isValid}
                                >
                                    {t('submitForApproval')}
                                </LoadingButton>
                            </Box>
                        </Grow>

                        <LoadingButton
                            loading={isLoadingForm}
                            size="large"
                            variant="contained"
                            color="secondary"
                            endIcon={<ArrowForwardIcon />}
                            disabled={!isValid}
                            type="submit"
                        >
                            {t('saveAndProceed')}
                        </LoadingButton>
                    </Box>
                </Slide>
            </form>
        );
    }

    return (
        <form ref={formRef} onSubmit={createSubmitHandler(handleSubmit)} noValidate>
            <Paper radius={16} elevation={2} sx={{px: 5, py: 4}}>
                <div className={styles.container}>
                    <FormLabel component="legend" className={styles.formLabel}>
                        {t('fullName')}
                    </FormLabel>

                    <Grid container spacing={4}>
                        <Grid item xs={12} md={2}>
                            <Controller
                                name="title"
                                control={control}
                                rules={{required: true}}
                                render={({field}) => {
                                    return (
                                        <TextField
                                            {...field}
                                            label={t('title')}
                                            variant="filled"
                                            error={!!errors.title}
                                            helperText={errors.title?.message}
                                            select
                                            required
                                            fullWidth
                                        >
                                            {titleOptions}

                                        </TextField>
                                    );
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={5}>
                            <Controller
                                name="firstName"
                                control={control}
                                render={({field}) => {
                                    return (
                                        <TextField
                                            {...field}
                                            label={t('firstName')}
                                            variant="filled"
                                            error={!!errors.firstName}
                                            helperText={errors.firstName?.message}
                                            required
                                            fullWidth
                                        />
                                    );
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={5}>
                            <Controller
                                name="lastName"
                                control={control}
                                render={({field}) => {
                                    return (
                                        <TextField
                                            {...field}
                                            label={t('lastName')}
                                            variant="filled"
                                            error={!!errors.lastName}
                                            helperText={errors.lastName?.message}
                                            required
                                            fullWidth
                                        />
                                    );
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Divider className={styles.divider} />

                    <FormLabel component="legend" className={styles.formLabel}>
                        {t('dateOfBirth')}
                    </FormLabel>

                    <Grid container spacing={4}>
                        <Grid item xs={12} md={7}>
                            <Controller
                                name="dateOfBirth"
                                control={control}
                                render={({field}) => {
                                    return (
                                        <DatePicker
                                            {...field}
                                            label={t('dateOfBirth')}
                                            invalidDateMessage={t('form:validation.notDate')}
                                            error={!!errors.dateOfBirth}
                                            helperText={errors.dateOfBirth?.message}
                                            openTo="year"
                                            format={moment.localeData().longDateFormat('L')}
                                            views={['year', 'month', 'day']}
                                            inputVariant="filled"
                                            minDateMessage={t('form:validation.minDate', {date: minDate.format('L')})}
                                            maxDateMessage={t('form:validation.maxDate', {date: maxDate.format('L')})}
                                                // date={}
                                                // onChange={}
                                                // value={}
                                                // openPicker={}
                                                // rawValue={}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    variant="filled"
                                                />
                                            )}
                                        />
                                    );
                                }}
                                required
                                fullWidth
                                disableFuture
                            />
                        </Grid>
                    </Grid>

                    <Divider className={styles.divider} />

                    <FormLabel component="legend" className={styles.formLabel}>
                        {t('and', {first: t('placeOfBirth'), second: t('nationality')})}
                    </FormLabel>

                    <Grid container spacing={4}>
                        <Grid item xs={12} md={7}>
                            <Controller
                                name="countryOfBirth"
                                control={control}
                                rules={{required: true}}
                                render={({field}) => {
                                    return (
                                        <TextField
                                            {...field}
                                            label={t('countryOfBirth')}
                                            variant="filled"
                                            error={!!errors.countryOfBirth}
                                            helperText={errors.countryOfBirth?.message}
                                            required
                                            fullWidth
                                            select
                                        >
                                            {countryOptions}
                                        </TextField>
                                    );
                                }}
                            />
                        </Grid>

                        {/* <Grid item xs={12} md={5}> */}
                        {/*    <CityAutocomplete
                                    label={t('cityOfBirth')}
                                    control={control}
                                     name="cityOfBirth" /> */}
                        {/* </Grid> */}
                    </Grid>

                    <Grid container spacing={4} sx={{mt: -2}}>
                        <Grid item xs={12} md={7}>
                            <Controller
                                name="placeOfBirth"
                                control={control}
                                rules={{required: true}}
                                render={({field}) => {
                                    return (
                                        <TextField
                                            {...field}
                                            label={t('placeOfBirth')}
                                            variant="filled"
                                            error={!!errors.placeOfBirth}
                                            helperText={errors.placeOfBirth?.message}
                                            required
                                            fullWidth
                                        />
                                    );
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={5}>
                            <Controller
                                name="postalCode"
                                control={control}
                                render={({field}) => {
                                    return (
                                        <TextField
                                            {...field}
                                            label={t('postalCode')}
                                            variant="filled"
                                            error={!!errors.postalCode}
                                            helperText={errors.postalCode?.message}
                                            required
                                            fullWidth
                                        />
                                    );
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={5}>
                            <Controller
                                name="regionalDepartmentOfBirth"
                                control={control}
                                rules={{required: true}}
                                render={({field}) => {
                                    return (
                                        <TextField
                                            {...field}
                                            label={t('regionalDepartmentOfBirth')}
                                            variant="filled"
                                            error={!!errors.regionalDepartmentOfBirth}
                                            helperText={errors.regionalDepartmentOfBirth?.message}
                                            disabled={isRegionalDepartmentOfBirthDisabled}
                                            required
                                            fullWidth
                                            select
                                        >
                                            {regionalDepartmentOptions}
                                        </TextField>
                                    );
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={4} sx={{mt: -2}}>
                        <Grid item xs={12} md={7}>
                            <Controller
                                name="nationality"
                                control={control}
                                rules={{required: true}}
                                render={({field}) => {
                                    return (
                                        <TextField
                                            {...field}
                                            label={t('nationality')}
                                            variant="filled"
                                            error={!!errors.nationality}
                                            helperText={errors.nationality?.message}
                                            required
                                            fullWidth
                                            select
                                        >
                                            {nationalityOptions}
                                        </TextField>
                                    );
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Divider className={styles.divider} />

                    <FormLabel component="legend" className={styles.formLabel}>
                        {t('socialSecurityNumber')}
                    </FormLabel>

                    <Grid container spacing={4}>
                        <Grid item xs={12} md={7}>
                            <Controller
                                name="socialSecurityNumber"
                                control={control}
                                rules={{required: true}}
                                render={({field}) => {
                                    return (
                                        <TextField
                                            {...field}
                                            label={t('socialSecurityNumber')}
                                            variant="filled"
                                            error={!!errors.socialSecurityNumber}
                                            helperText={errors.socialSecurityNumber?.message}
                                            required
                                            fullWidth
                                        />
                                    );
                                }}
                            />
                        </Grid>
                    </Grid>

                    {/* <SetupCompanyFormFooter */}
                    {/*    areBothButtonsDisabled={isLoading} */}
                    {/*    isSubmitButtonDisabled={isSubmitButtonDisabled} */}
                    {/*    onSubmitForApprovalButtonClick={handleSubmitForApprovalButtonClick} */}
                    {/* /> */}
                </div>
            </Paper>

            <Box display="flex" justifyContent="center" flexDirection="column" sx={{mt: 3}}>
                <LoadingButton
                    sx={{mx: 2}}
                    loading={isLoadingForm}
                    size="large"
                    variant="contained"
                    color="secondary"
                    endIcon={<ArrowForwardIcon />}
                    disabled={!isValid}
                    type="submit"
                >
                    {t('saveAndProceed')}
                </LoadingButton>

                {hasSubmitModificationsButton
                    && (
                    <LoadingButton
                        sx={{mx: 2, mt: 2}}
                        loading={isLoadingForm}
                        size="large"
                        variant="contained"
                        color="primary"
                        startIcon={<SaveIcon />}
                        onClick={handleSubmitModifications}
                        disabled={!isValid}
                    >
                        {t('submitForApproval')}
                    </LoadingButton>
                    )
                }
            </Box>
        </form>
    );
};

PersonalInformationForm.propTypes = {
    hasSubmitModificationsButton: PropTypes.bool,
};

PersonalInformationForm.defaultProps = {
    hasSubmitModificationsButton: false,
};
