import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {selectRouterLocation} from '../../../lib/router/connected-router-saga';
import {ROUTE_PATHS} from '../../../lib/router/route-paths';
import {selectUser} from '../../auth/store/auth.selectors';
import {AnimationsSelectors} from '../store/animations.selector';

const backupRemoveLoaderTime = 10000;
const HIDE_TIMEOUT_TIMER = 2800;

const initRiveLoader = () => {
    const {
        stateMachineName,
        inputName,
        canvasId,
        riveAnimation,
        riveInstance,
    } = window.hiwayRive;

    if (!window.rive) {
        return;
    }

    return new window.rive.Rive({
        src: riveAnimation,
        autoplay: true,
        canvas: document.getElementById(canvasId),
        stateMachines: stateMachineName,
        onload: () => {
            const inputs = riveInstance.stateMachineInputs(stateMachineName);

            if (!inputs) {
                return;
            }

            const endAnimationInput = inputs.find(input => input.name === inputName);
            if (endAnimationInput) {
                endAnimationInput.value = true;
            }
        },
    });
};

const stopRiveLoader = () => {
    const {
        stateMachineName,
        inputName,
        canvasHolderDiv,
        riveInstance,
    } = window.hiwayRive;

    // Fade out the loader
    setTimeout(() => {
        const loader = document.getElementById(canvasHolderDiv);
        loader.style.opacity = '0';
        loader.style.visibility = 'hidden';

        if (window.hiwayRive) {
            window.hiwayRive.isRiveRunning = false;
        }

        riveInstance.stop(); // Stop any ongoing animations
        riveInstance.cleanup(); // Clean up resources after stopping animations
    }, HIDE_TIMEOUT_TIMER);

    if (riveInstance) {
        const inputs = riveInstance.stateMachineInputs(stateMachineName);

        if (!inputs) {
            return;
        }

        const endAnimationInput = inputs.find(input => input.name === inputName);

        if (endAnimationInput) {
            endAnimationInput.value = true;
        }
    }
};

const startRiveLoader = () => {
    const {
        canvasHolderDiv,
    } = window.hiwayRive;

    const loader = document.getElementById(canvasHolderDiv);
    loader.style.opacity = '1';
    loader.style.visibility = 'visible';

    if (window.hiwayRive) {
        window.hiwayRive.isRiveRunning = true;
    }

    if (window.rive) {
        window.hiwayRive.riveInstance = initRiveLoader();
    }
};

const InitialAnimation = () => {
    const location = useSelector(selectRouterLocation);
    const loggedInUser = useSelector(selectUser);

    const isLoginPage = location.pathname === ROUTE_PATHS.LOGIN;
    const isIgnoringOnInitialLoad = isLoginPage && !loggedInUser;

    const isInitAnimationActive = useSelector(AnimationsSelectors.selectIsInitialAnimationActive);

    // Important: It will re-run again after we get new user on login since entire app is re-rendered
    useEffect(() => {
        let timeout;

        if (!isInitAnimationActive && !isIgnoringOnInitialLoad) {
            // Stop the loader if the animation flag is inactive, and it's not the login page.
            // For the login process, we keep the loader running for a smoother user experience
            // and stop it in the logic bellow after the new user data is loaded.
            stopRiveLoader();
        } else {
            // eslint-disable-next-line no-lonely-if
            if (window.hiwayRive?.isRiveRunning) {
                if (isLoginPage) {
                    // If it is login page after we get new user stop loader - used mostly on reload
                    stopRiveLoader();
                } else {
                    timeout = setTimeout(() => {
                        // Backup stopper to cover emergencies if some page slips trough
                        // or it takes too long and user might start wondering if everything is ok
                        stopRiveLoader();
                    }, backupRemoveLoaderTime);
                }
            } else if (!isIgnoringOnInitialLoad) {
                // If it is not login page start loader - used everywhere other than login after logout
                startRiveLoader();
            }
        }

        return () => {
            // If new value for isInitAnimationActive came clear timeout which leads to backup loader stop
            clearTimeout(timeout);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInitAnimationActive]);


    return null;
};

export default InitialAnimation;
