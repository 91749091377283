import {
    HEADQUARTERS_DEFAULT_CITY,
    HEADQUARTERS_DEFAULT_STREET_NAME,
    HEADQUARTERS_DEFAULT_STREET_NUMBER,
    HEADQUARTERS_DEFAULT_ZIP_CODE,
    HEADQUARTERS_DEFAULT_ZIP_COUNTRY,
} from './constants';

export const isHeadquartersAddressSameAsHiwayAddress = ({
    headquartersCity,
    headquartersStreet,
    headquartersStreetNumber,
    headquartersZip,
    headquartersCountry,
}) => {
    if (
        !headquartersCity
        || !headquartersCountry
        || !headquartersStreet
        || !headquartersStreetNumber
        || !headquartersZip
    ) {
        return false;
    }

    return HEADQUARTERS_DEFAULT_STREET_NUMBER.trim().toLowerCase() === headquartersStreetNumber.trim().toLowerCase()
    && HEADQUARTERS_DEFAULT_STREET_NAME.trim().toLowerCase() === headquartersStreet.trim().toLowerCase()
    && HEADQUARTERS_DEFAULT_CITY.trim().toLowerCase() === headquartersCity.trim().toLowerCase()
    && HEADQUARTERS_DEFAULT_ZIP_CODE.trim().toLowerCase() === headquartersZip.trim().toLowerCase()
    && HEADQUARTERS_DEFAULT_ZIP_COUNTRY.trim().toLowerCase() === headquartersCountry.trim().toLowerCase();
};
