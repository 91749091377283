import {Alert, Box, Divider, Grow} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import DocumentUploadStepTitle from '../../../../../../v1/components/setup-company/DocumentUploadStepTitle';
import {
    usePersonalInformationFormStyles,
} from '../../../../../../v1/components/setup-company/PersonalInformationForm/styles';
import DropZone from '../../../../../../v1/components/ui-kit/DropZone';
import {AnimationsSelectors} from '../../../../../animations/store/animations.selector';
import {FORM_ANIMATION_DELAY} from '../../../../../animations/utils/constants';

const UmbrellaDocumentsUpload = ({
    t,
    watch,
    errors,
    createDeleteFileHandler,
    createDropAcceptedHandler,
}) => {
    const styles = usePersonalInformationFormStyles();
    const isSubAnimationActive = useSelector(AnimationsSelectors.selectIsSubAnimationActive);

    const {t: tOnboarding} = useTranslation('freelancerOnboarding');

    const ribDocument = watch('ribDocument');
    const cvDocument = watch('cvDocument');
    const driversLicenceDocument = watch('driversLicenceDocument');
    const vehicleRegistrationCardDocument = watch('vehicleRegistrationCardDocument');

    return (
        <>
            <Divider className={styles.divider} />

            <div className={styles.documentUploadStepContainer}>
                <DocumentUploadStepTitle
                    title={t('ribDocument') + ' *'}
                    stepNumber="D"
                    isCompleted={!!ribDocument}
                />

                <Grow
                    in={isSubAnimationActive}
                    timeout={FORM_ANIMATION_DELAY * 3}
                >
                    <Box sx={{
                        pl: 7,
                    }}
                    >
                        <DropZone
                            acceptFiles="image/jpeg, image/png, image/jpg, application/pdf"
                            errorMessage={errors.ribDocument?.message}
                            files={ribDocument}
                            onDeleteFile={createDeleteFileHandler('ribDocument')}
                            onDropAccepted={createDropAcceptedHandler('ribDocument')}
                            name="rib-document"
                        />
                    </Box>
                </Grow>
            </div>

            <Divider className={styles.divider} />

            <div className={styles.documentUploadStepContainer}>
                <DocumentUploadStepTitle
                    title={t('cvDocument') + ' *'}
                    stepNumber="E"
                    isCompleted={!!cvDocument}
                />

                <Grow
                    in={isSubAnimationActive}
                    timeout={FORM_ANIMATION_DELAY * 3}
                >
                    <Box sx={{
                        pl: 7,
                    }}
                    >
                        <Alert
                            sx={{
                                mt: 1,
                            }}
                            severity="info"
                        >
                            {tOnboarding('umbrella.cvAlert')}
                        </Alert>

                        <DropZone
                            acceptFiles="image/jpeg, image/png, image/jpg, application/pdf"
                            errorMessage={errors.cvDocument?.message}
                            files={cvDocument}
                            onDeleteFile={createDeleteFileHandler('cvDocument')}
                            onDropAccepted={createDropAcceptedHandler('cvDocument')}
                            name="cv-document"
                        />
                    </Box>
                </Grow>
            </div>

            <Divider className={styles.divider} />

            <div className={styles.documentUploadStepContainer}>
                <DocumentUploadStepTitle
                    title={t('driversLicenceDocument')}
                    stepNumber="F"
                    isCompleted={!!driversLicenceDocument}
                />

                <Grow
                    in={isSubAnimationActive}
                    timeout={FORM_ANIMATION_DELAY * 3}
                >
                    <Box sx={{
                        pl: 7,
                    }}
                    >
                        <Alert
                            sx={{
                                mt: 1,
                            }}
                            severity="info"
                        >
                            {tOnboarding('umbrella.driversLiscenceAlert')}
                        </Alert>

                        <DropZone
                            acceptFiles="image/jpeg, image/png, image/jpg, application/pdf"
                            errorMessage={errors.driversLicenceDocument?.message}
                            files={driversLicenceDocument}
                            onDeleteFile={createDeleteFileHandler('driversLicenceDocument')}
                            onDropAccepted={createDropAcceptedHandler('driversLicenceDocument')}
                            name="drivers-licence-document"
                        />
                    </Box>
                </Grow>
            </div>

            <Divider className={styles.divider} />

            <div className={styles.documentUploadStepContainer}>
                <DocumentUploadStepTitle
                    title={t('vehicleRegistrationCardDocument')}
                    stepNumber="G"
                    isCompleted={!!vehicleRegistrationCardDocument}
                />

                <Grow
                    in={isSubAnimationActive}
                    timeout={FORM_ANIMATION_DELAY * 3}
                >
                    <Box sx={{
                        pl: 7,
                        pt: 2,
                    }}
                    >
                        <Alert
                            sx={{
                                mt: 1,
                            }}
                            severity="info"
                        >
                            {tOnboarding('umbrella.vehicleRegistrationAlert')}
                        </Alert>

                        <DropZone
                            acceptFiles="image/jpeg, image/png, image/jpg, application/pdf"
                            errorMessage={errors.vehicleRegistrationCardDocument?.message}
                            files={vehicleRegistrationCardDocument}
                            onDeleteFile={createDeleteFileHandler('vehicleRegistrationCardDocument')}
                            onDropAccepted={createDropAcceptedHandler('vehicleRegistrationCardDocument')}
                            name="vehicle-registration-card-document"
                        />
                    </Box>
                </Grow>
            </div>
        </>
    );
};

UmbrellaDocumentsUpload.propTypes = {
    t: PropTypes.func.isRequired,
    watch: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    createDeleteFileHandler: PropTypes.func.isRequired,
    createDropAcceptedHandler: PropTypes.func.isRequired,
};

export default UmbrellaDocumentsUpload;
