import {UmbrellaStepperOnboardingActionTypes} from './umbrella-onboarding.action-types';

export const UmbrellaStepperOnboardingActions = {
    // Membership
    goToCGVS: () => ({
        type: UmbrellaStepperOnboardingActionTypes.GO_TO_CGVS,
    }),
    goToADPSigning: () => ({
        type: UmbrellaStepperOnboardingActionTypes.GO_TO_ADP,
    }),
    refuseSigning: () => ({
        type: UmbrellaStepperOnboardingActionTypes.REFUSE_SIGNING,
    }),
    completedSigning: () => ({
        type: UmbrellaStepperOnboardingActionTypes.COMPLETED_SIGNING,
    }),
    backToSigning: () => ({
        type: UmbrellaStepperOnboardingActionTypes.BACK_TO_SIGNING,
    }),
    // Informations
    goToPersonalInformation: () => ({
        type: UmbrellaStepperOnboardingActionTypes.GO_TO_PERSONAL_INFORMATION,
    }),
    goToPersonalDocuments: () => ({
        type: UmbrellaStepperOnboardingActionTypes.GO_TO_PERSONAL_DOCUMENTS,
    }),
    goToFinalization: () => ({
        type: UmbrellaStepperOnboardingActionTypes.GO_TO_FINALIZATION,
    }),
};
