import {TRAINING_CONSTANTS} from '../features/training/utils/constants';

export const TRAINING_STATUSES = TRAINING_CONSTANTS.TRAINING_STATUS;

export const TRAINING_INVOICE_STATES = {
    LOADING: 'LOADING',
    UPLOADING: 'UPLOADING',
    DELETING: 'DELETING',
    DONE: 'DONE',
};

// Number of days which is used to filter out companies which are at the end of training
// Used as: trainingEndDate - 10 days
export const TRAINING_FILTER_OFFSET = 10;
