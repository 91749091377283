import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import {LoadingButton} from '@mui/lab';
import {Box, Button, Paper, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {LoadingSelectors, LoadingTypes} from '../../../loading';

export const LegalDocumentsWrapper = ({title, children, onConfirm, onCancel}) => {
    const {t} = useTranslation('freelancerOnboarding');

    const isLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.GENERIC_CRUD_LOADER),
    );

    return (
        <Box>
            <Typography variant="h4">
                {title}
            </Typography>
            <Box sx={{
                mb: '88px',
                width: 'calc(100% - 64px)',
                display: 'flex',
                justifyContent: 'center',
            }}
            >
                {children}
            </Box>

            <Paper
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    width: 'calc(100vw - 240px)',
                    height: '88px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    background: 'white',
                    ml: -4,
                }}
                elevation={4}
            >
                <Box sx={{
                    display: 'flex',
                    gap: 2,
                    mr: 2,
                    pt: 2.5,
                    pb: 2.5,
                }}
                >
                    <Button
                        startIcon={<CloseIcon />}
                        variant="outlined"
                        onClick={onCancel}
                        disabled={isLoading}
                    >
                        {t('umbrella.refuse')}
                    </Button>
                    <LoadingButton
                        loading={isLoading}
                        endIcon={<ArrowForwardIcon />}
                        variant="contained"
                        onClick={onConfirm}
                        color="secondary"
                        disabled={isLoading}
                    >
                        {t('umbrella.accept')}
                    </LoadingButton>
                </Box>
            </Paper>
        </Box>
    );
};

LegalDocumentsWrapper.propTypes = {
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
};

export default LegalDocumentsWrapper;
