import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {Box, CircularProgress, Typography} from '@mui/material';
import moment from 'moment';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {BankAccountContainer} from './BankAccountContainer';
import PageHeader from '../../../components/headers/PageHeader/PageHeader';
import {Toast} from '../../../lib/toast';
import {copyToClipboard} from '../../../utils/copy-to-clipboard';
import {selectEnterpriseInformation} from '../../../v1/app/company/additionalCompanyData/additionalCompanyData.selectors';
import {COMPANY_LEGAL_STATUSES} from '../../../v1/app/company/company.consts';
import {selectIsLoadingMyCompany, selectMyCompany} from '../../../v1/app/my-companies/myCompanies.selectors';
import CompanyChip from '../../../v1/components/ui-kit/CompanyChip/CompanyChip';
import DataItemRow from '../../../v1/components/ui-kit/DataItemRow';
import DataItemRowLabel from '../../../v1/components/ui-kit/DataItemRowLabel';
import ExpandingContainer from '../../../v1/components/ui-kit/ExpandingContainer';
import NoInput from '../../../v1/components/ui-kit/NoInput/NoInput';

// TODO:LOW Extract to utility file
const calculateTva = siren => (12 + 3 * (siren % 97)) % 97;

const formatVatRegistrationNumber = siren => {
    if (!siren || siren.length !== 9) {
        return '';
    }

    return `FR${calculateTva(siren)}${siren}`;
};

const MyCompanyView = () => {
    const {t} = useTranslation('companies');

    const myCompany = useSelector(selectMyCompany);
    const isLoading = useSelector(selectIsLoadingMyCompany);
    const enterpriseInformation = useSelector(selectEnterpriseInformation);
    const legalForm = useMemo(() => {
        if (!myCompany) {
            return null;
        }

        const {legalForm} = myCompany;

        const foundLegalStatus = Object.values(COMPANY_LEGAL_STATUSES).find(status => status === legalForm);

        if (!foundLegalStatus) {
            return null;
        }

        return t(`legalStatuses.${foundLegalStatus}`);
    }, [myCompany, t]);

    const registrationNumber = useMemo(
        () => enterpriseInformation?.vat ?? formatVatRegistrationNumber(enterpriseInformation?.siren),
        [enterpriseInformation],
    );

    return (
        <>
            {isLoading && (
                <Box display="flex" alignItems="center" justifyContent="center" minHeight={200}>
                    <CircularProgress size={48} />
                </Box>
            )}

            {myCompany && !isLoading && (
                <>
                    <PageHeader title={myCompany.name} hasMargin={false} />

                    <CompanyChip companyId={myCompany.id} />

                    <ExpandingContainer title={t('companyProfile.director')}>
                        <DataItemRow
                            label={<DataItemRowLabel>{t('companyProfile.firstName')}</DataItemRowLabel>}
                            content={myCompany.personalInformation.firstName ? (
                                <Typography noWrap>{myCompany.personalInformation.firstName}</Typography>
                            ) : <NoInput />}
                        />

                        <DataItemRow
                            label={<DataItemRowLabel>{t('companyProfile.lastName')}</DataItemRowLabel>}
                            content={myCompany.personalInformation.lastName ? (
                                <Typography noWrap>{myCompany.personalInformation.lastName}</Typography>
                            ) : <NoInput />}
                        />

                        <DataItemRow
                            label={<DataItemRowLabel>{t('companyProfile.dateOfBirth')}</DataItemRowLabel>}
                            content={myCompany.personalInformation.dateOfBirth ? (
                                <Typography noWrap>{moment(myCompany.personalInformation?.dateOfBirth, 'DD/MM/YYYY').format('L')}</Typography>
                            ) : <NoInput />}
                        />

                        <DataItemRow
                            label={<DataItemRowLabel>{t('companyProfile.placeOfBirth')}</DataItemRowLabel>}
                            content={myCompany.personalInformation.placeOfBirth ? (
                                <Typography noWrap>{myCompany.personalInformation.placeOfBirth}</Typography>
                            ) : <NoInput />}
                        />

                        <DataItemRow
                            label={<DataItemRowLabel>{t('companyProfile.socialSecurityNumber')}</DataItemRowLabel>}
                            content={myCompany.personalInformation.socialSecurityNumber ? (
                                <Typography noWrap>{myCompany.personalInformation.socialSecurityNumber}</Typography>
                            ) : <NoInput />}
                        />
                    </ExpandingContainer>

                    <ExpandingContainer title={t('companyProfile.enterprise')}>
                        <DataItemRow
                            label={<DataItemRowLabel>{t('companyProfile.companyName')}</DataItemRowLabel>}
                            content={myCompany.name ? (
                                <Typography noWrap>{myCompany.name}</Typography>
                            ) : <NoInput />}
                        />

                        <DataItemRow
                            label={<DataItemRowLabel>{t('companyProfile.legalStatus')}</DataItemRowLabel>}
                            content={legalForm ? (
                                <Typography noWrap>{legalForm}</Typography>
                            ) : <NoInput />}
                        />

                        <DataItemRow
                            label={<DataItemRowLabel>{t('companyProfile.address')}</DataItemRowLabel>}
                            content={enterpriseInformation?.headquartersAddress ? (
                                <Typography noWrap>{enterpriseInformation?.headquartersAddress}</Typography>
                            ) : <NoInput />}
                        />

                        <DataItemRow
                            label={<DataItemRowLabel>{t('companyProfile.shareCapital')}</DataItemRowLabel>}
                            content={myCompany.companyInformation.amountOfShareCapital ? (
                                <Typography noWrap>
                                    {myCompany.companyInformation.amountOfShareCapital}&nbsp;&euro;
                                </Typography>
                            ) : <NoInput />}
                        />
                    </ExpandingContainer>

                    <ExpandingContainer title={t('companyProfile.information')}>
                        <DataItemRow
                            label={<DataItemRowLabel>{t('companyProfile.siret')}</DataItemRowLabel>}
                            content={enterpriseInformation?.siret ? (
                                <Box sx={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'space-between',
                                }}
                                >
                                    <Typography noWrap>
                                        {enterpriseInformation?.siret}
                                    </Typography>

                                    <ContentCopyIcon
                                        sx={{
                                            cursor: 'pointer',
                                            color: 'rgba(0, 0, 0, 0.54)',
                                            mr: 3,
                                        }}
                                        onClick={() => {
                                            copyToClipboard(enterpriseInformation?.siret);
                                            Toast.success('copiedToClipboard');
                                        }}
                                    />
                                </Box>
                            ) : <NoInput />}
                        />

                        <DataItemRow
                            label={<DataItemRowLabel>{t('companyProfile.siren')}</DataItemRowLabel>}
                            content={enterpriseInformation?.siren ? (
                                <Box sx={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'space-between',
                                }}
                                >
                                    <Typography noWrap>
                                        {enterpriseInformation?.siren}
                                    </Typography>

                                    <ContentCopyIcon
                                        sx={{
                                            cursor: 'pointer',
                                            color: 'rgba(0, 0, 0, 0.54)',
                                            mr: 3,
                                        }}
                                        onClick={() => {
                                            copyToClipboard(enterpriseInformation?.siren);
                                            Toast.success('copiedToClipboard');
                                        }}
                                    />
                                </Box>
                            ) : <NoInput />}
                        />

                        <DataItemRow
                            label={<DataItemRowLabel>{t('companyProfile.apeNafCode')}</DataItemRowLabel>}
                            content={enterpriseInformation?.apeNafCode ? (
                                <Box sx={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'space-between',
                                }}
                                >
                                    <Typography noWrap>
                                        {enterpriseInformation?.apeNafCode}
                                    </Typography>

                                    <ContentCopyIcon
                                        sx={{
                                            cursor: 'pointer',
                                            color: 'rgba(0, 0, 0, 0.54)',
                                            mr: 3,
                                        }}
                                        onClick={() => {
                                            copyToClipboard(enterpriseInformation?.apeNafCode);
                                            Toast.success('copiedToClipboard');
                                        }}
                                    />
                                </Box>
                            ) : <NoInput />}
                        />

                        <DataItemRow
                            label={<DataItemRowLabel>{t('companyProfile.vatRegistrationNumber')}</DataItemRowLabel>}
                            content={registrationNumber ? (
                                <Box sx={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'space-between',
                                }}
                                >
                                    <Typography noWrap>
                                        {registrationNumber}
                                    </Typography>

                                    <ContentCopyIcon
                                        sx={{
                                            cursor: 'pointer',
                                            color: 'rgba(0, 0, 0, 0.54)',
                                            mr: 3,
                                        }}
                                        onClick={() => {
                                            copyToClipboard(registrationNumber);
                                            Toast.success('copiedToClipboard');
                                        }}
                                    />
                                </Box>
                            ) : <NoInput />}
                        />

                        <DataItemRow
                            label={<DataItemRowLabel>{t('companyProfile.sie')}</DataItemRowLabel>}
                            content={enterpriseInformation?.sie ? (
                                <Box sx={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'space-between',
                                }}
                                >
                                    <Typography noWrap>
                                        {enterpriseInformation?.sie}
                                    </Typography>

                                    <ContentCopyIcon
                                        sx={{
                                            cursor: 'pointer',
                                            color: 'rgba(0, 0, 0, 0.54)',
                                            mr: 3,
                                        }}
                                        onClick={() => {
                                            copyToClipboard(enterpriseInformation?.sie);
                                            Toast.success('copiedToClipboard');
                                        }}
                                    />
                                </Box>
                            ) : <NoInput />}
                        />
                    </ExpandingContainer>

                    <BankAccountContainer t={t} />
                </>
            )}
        </>
    );
};

export default MyCompanyView;
