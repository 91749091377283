import {combineReducers} from 'redux';

import * as actions from './uploadDocuments.actions';
import {UPLOAD_DOCUMENTS_STEPS} from '../../../../../consts/document-constants';
import {createReducer} from '../../../createReducer';

export const uploadDocumentsReducer = combineReducers({
    activeStep: createReducer(UPLOAD_DOCUMENTS_STEPS.ATTACH_DOCUMENTS, actions.SET_UPLOAD_DOCUMENTS_STEP, true),
    data: createReducer([], actions.STORE_UPLOAD_DOCUMENTS_DATA, true),
    isUploading: createReducer(false, actions.SET_IS_UPLOADING_DOCUMENTS, true),
});
