import {Box, Divider, Paper} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useSelector} from 'react-redux';
import DocumentRowActions from './DocumentRowAction';
import {DOCUMENT_STATUSES} from '../../../../consts/document-constants';
import DocumentNameCell from '../../../../v1/components/company/DocumentNameCell/DocumentNameCell';
import DocumentNameCellTrainingApproved
    from '../../../../v1/components/company/DocumentNameCell/DocumentNameCellTrainingApproved';
import DocumentStatusCell from '../../../../v1/components/company/DocumentStatusCell/DocumentStatusCell';
import {DatabaseSelectors} from '../../../document/modules/database/store/database.selector';
import {LoadingSelectors, LoadingTypes} from '../../../loading';

const MobileTrainingDocuments = ({hasActions, step}) => {
    const documents = useSelector(DatabaseSelectors.selectDocuments);
    const documentsList = Object.values(documents);

    const isWaitingNonSigned = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.WAITING_UNSIGNED_DOCS),
    );

    return (
        <Paper elevation={0} sx={{borderRadius: '16px', mt: 2, border: '1px solid rgba(0, 0, 0, 0.12)'}}>
            {documentsList.map(rowData => {
                const document = {...rowData};

                // TODO Remove once BE has GENERATED status
                if (document.status === DOCUMENT_STATUSES.DONE) {
                    document.status = DOCUMENT_STATUSES.GENERATED;
                }

                if (isWaitingNonSigned) {
                    document.status = DOCUMENT_STATUSES.GENERATING;
                }


                return (
                    <React.Fragment key={document.id}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            p: 2,
                        }}
                        >
                            <Box sx={{
                                mb: 2,
                                fontSize: theme => theme.typography.pxToRem(14),
                            }}
                            >
                                {step === 3
                                    ? <DocumentNameCell rowData={document} isMobile={true} isTrainingMobile={true} />
                                    : <DocumentNameCellTrainingApproved rowData={document} isMobile={true} />
                                }
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                            >
                                <DocumentStatusCell rowData={document} />
                                {hasActions && (<DocumentRowActions rowData={document} />)}
                            </Box>
                        </Box>
                        <Divider />
                    </React.Fragment>
                );
            })}
        </Paper>
    );
};

MobileTrainingDocuments.propTypes = {
    hasActions: PropTypes.bool,
    step: PropTypes.number,
};

MobileTrainingDocuments.defaultProps = {
    hasActions: false,
    step: 3,
};

export default MobileTrainingDocuments;
