import {createSelector} from 'reselect';
import {COMPANY_TYPES} from '../../../consts/company-constants';
import {COMPANY_STATUSES} from '../company/setupCompany/setupCompany.constants';

export const selectFreelancerAccount = state => state.v1Freelancer.account;

export const selectFreelancerCompanies = state => state.v1Freelancer.companies;

export const selectIsLoadingFreelancerCompanies = state => state.v1Freelancer.isLoadingCompanies;

export const createFreelancerCompaniesSelector = () => createSelector(
    selectFreelancerCompanies,
    companies => Object.values(companies).sort(previous => {
        if (previous.type === COMPANY_TYPES.HOLDING) {
            return 1;
        }

        return -1;
    }),
);

export const createFreelancerCompanySelector = () => createSelector(
    createFreelancerCompaniesSelector(),
    companies => companies[0],
);

export const createFreelancerCompanyByIdSelector = companyId => createSelector(
    selectFreelancerCompanies,
    companies => companies[companyId],
);

export const createFreelancerDefaultCompanySelector = () => createSelector(
    [createFreelancerCompaniesSelector()],
    companies => companies.find(company => company.type === COMPANY_TYPES.DEFAULT),
);

export const createFreelancerHoldingCompanySelector = () => createSelector(
    [createFreelancerCompaniesSelector()],
    companies => companies.find(company => company.type === COMPANY_TYPES.HOLDING),
);

export const createCompanyForSetupSelector = () => createSelector(
    [createFreelancerCompaniesSelector()],
    companies => {
        return companies.find(company => company.status === COMPANY_STATUSES.PENDING_DATA_INPUT);
    },
);

export const createCompanyForEditsSelector = () => createSelector(
    [createFreelancerCompaniesSelector()],
    companies => {
        return companies.find(company => company.status === COMPANY_STATUSES.PENDING_EDITS);
    },
);

export const createCompanyForDocumentSigningSelector = () => createSelector(
    [createFreelancerCompaniesSelector()],
    companies => {
        return companies.find(company => company.status === COMPANY_STATUSES.PENDING_INIT_SIGS
            || company.status === COMPANY_STATUSES.PENDING_FINAL_SIGS);
    },
);
