import makeStyles from '@mui/styles/makeStyles';

export const useLegalPageCGVSStyles = makeStyles(theme => ({
    wrapper: {
        backgroundColor: '#F1F4F6',
    },
    container: {
        padding: theme.spacing(3),
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
    },
    logoContainer: {
        position: 'relative',
        top: 'auto',
        left: 'auto',
    },
    logo: {
        width: 104,
        height: 104,
    },
    title: {
        marginTop: theme.spacing(4),
        color: '#E84C4C',
        fontSize: theme.typography.pxToRem(48),
        marginBottom: theme.spacing(3),
    },
    subTitle: {
        fontSize: theme.typography.pxToRem(48),
        lineHeight: '48px',
        letterSpacing: -0.4,
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            fontSize: theme.typography.pxToRem(34),
        },
    },
    heading: {
        fontSize: theme.typography.pxToRem(24),
        fontWeight: 'bold',
        textTransform: 'uppercase',
        marginBottom: theme.spacing(2),
    },
    listItem: {
        display: 'flex',
        margin: theme.spacing(2.5),
    },
    listItemBullet: {
        marginRight: theme.spacing(2.5),
        color: '#FB643F',
        fontSize: theme.typography.pxToRem(18),
    },
    subHeader: {
        fontSize: theme.typography.pxToRem(21),
        fontWeight: 'bold',
        letterSpacing: '0.1px',
        marginBottom: theme.spacing(2),
    },
    subItemHeader: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 'bold',
        letterSpacing: '0.1px',
    },
    paragraph: {
        marginBottom: theme.spacing(2),
    },
    redText: {
        color: '#E84C4C',
        paddingRight: theme.spacing(1),
        fontWeight: 'bold',
    },
    paddedText: {
        paddingLeft: theme.spacing(4),
    },
    bulletList: {
        listStyle: 'none',
    },
    customBullet: {
        '&::before': {
            content: '"\u2022"',
            color: '#F1522E',
            fontWeight: 'bold',
            display: 'inline-block',
            width: '1em',
            marginLeft: '-1em',
            fontSize: '24px',
            lineHeight: 0,
            position: 'relative',
            top: '2px',
        },
    },
    info: {
        fontSize: theme.typography.pxToRem(16),
        color: 'rgba(0,0,0,0.54)',
        letterSpacing: '0.1px',
        textAlign: 'center',
        marginTop: theme.spacing(2),
    },
    footnote: {
        paddingLeft: theme.spacing(4),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        marginBottom: theme.spacing(2),
        borderLeft: 'solid 2px #E84C4C',
        fontStyle: 'italic',
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
    },
}));
