import PropTypes from 'prop-types';
import React from 'react';

import {DOCUMENT_STATUSES} from '../../../../consts/document-constants';
import DocumentStatus from '../../ui-kit/DocumentStatus/DocumentStatus';

const DocumentStatusCell = ({rowData}) => {
    return <DocumentStatus status={rowData.status} />;
};

DocumentStatusCell.propTypes = {
    rowData: PropTypes.shape({
        status: PropTypes.oneOf(Object.values(DOCUMENT_STATUSES)),
    }).isRequired,
};

export default DocumentStatusCell;
