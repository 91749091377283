import AddIcon from '@mui/icons-material/Add';
import EventRepeatTwoToneIcon from '@mui/icons-material/EventRepeatTwoTone';
import {Box, Divider, Skeleton, Typography, useMediaQuery} from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {recurringTransferValues} from './CreateRecurringTransfer';
import NewOrderIcon from '../../../../../../assets/png/bank/new-order.png';
import ReactRouterButtonLink from '../../../../../../components/app/router/ReactRouterButtonLink';
import {RoutePaths} from '../../../../../../lib/router/route-paths';
import {LoadingSelectors, LoadingTypes} from '../../../../../loading';
import {TransfersSelector} from '../../store/transfers.selector';
import {SIDEBAR_TYPES} from '../../utils/constants';

export const StandingOrders = ({
    setSidebarOpenInformation,
    sidebarOpenInformation,
}) => {
    const {t} = useTranslation('bankTransfer');
    const isLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.LOAD_TRANSFERS),
    );
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const ordersList = useSelector(TransfersSelector.selectList);

    if (isLoading) {
        return (
            <Box sx={{px: 2}}>
                <Typography variant="h5" sx={{px: 3, mb: 1}}>
                    <Skeleton variant="rectangular" width={110} height={16} />
                </Typography>

                <React.Fragment>

                    <OrderSkeleton />

                    <Divider sx={{
                        width: `100%`,
                        my: 1,
                    }}
                    />

                    <OrderSkeleton />

                </React.Fragment>
            </Box>
        );
    }

    if (ordersList?.length > 0) {
        return (
            <>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mr: 2,
                }}
                >
                    <Typography
                        variant="h5"
                        sx={{
                            px: 3,
                            mb: 1,
                        }}
                    >
                        {t('orders.home.title')}
                    </Typography>
                    {!isMobileSize && (
                        <ReactRouterButtonLink
                            sx={{
                                borderRadius: '6px',
                                py: 0.4,
                                px: 1.2,
                            }}
                            to={`${RoutePaths.BANK_CREATE_TRANSFER}?tab=recurring`}
                            color="primary"
                            variant="contained"
                            startIcon={<AddIcon />}
                        >
                            {t('orders.home.newTransfer')}
                        </ReactRouterButtonLink>
                    )}
                </Box>

                {ordersList.map((order, index) => {
                    let nextExecutionDate = ' - ';
                    if (order?.additionalData?.nextExecutionDate) {
                        nextExecutionDate = moment(order.additionalData.nextExecutionDate).format('DD MMM YYYY');
                    }

                    return (
                        <React.Fragment
                            key={order.id}
                        >
                            <Box
                                id={`transfers-item-${order.id}`}
                                onClick={() => {
                                    setSidebarOpenInformation({
                                        type: SIDEBAR_TYPES.editRecurringTransfer,
                                        order,
                                    });
                                }}
                                key={order.id}
                                sx={{
                                    cursor: 'pointer',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <StandingOrderItem
                                    order={order}
                                    sidebarOpenInformation={sidebarOpenInformation}
                                    nextExecutionDate={nextExecutionDate}
                                />
                            </Box>

                            {index !== ordersList.length - 1 && (
                                <Divider sx={{
                                    width: `calc(100% - 76px)`,
                                    ml: '76px',
                                }}
                                />
                            )}
                        </React.Fragment>
                    );
                })}

                {isMobileSize && (
                    <ReactRouterButtonLink
                        sx={{
                            borderRadius: '6px',
                            py: 0.4,
                            width: `calc(100% - 32px)`,
                            mx: 2,
                            mb: 2,
                            mt: 1,
                        }}
                        to={`${RoutePaths.BANK_CREATE_TRANSFER}?tab=recurring`}
                        color="primary"
                        variant="contained"
                        startIcon={<AddIcon />}
                    >
                        {t('orders.home.newTransfer')}
                    </ReactRouterButtonLink>
                )}
            </>
        );
    }

    return (
        <Box sx={{px: 3, pb: 2}}>
            {!isMobileSize && (
                <Typography variant="h5">
                    {t('orders.home.title')}
                </Typography>
            )}

            <Box sx={isMobileSize ? {
                display: 'flex',
                height: 'calc(100vh - 180px)',
                alignItems: 'center',
            } : {}}
            >
                <NewOrder />
            </Box>
        </Box>
    );
};

StandingOrders.propTypes = {
    setSidebarOpenInformation: PropTypes.func.isRequired,
    sidebarOpenInformation: PropTypes.object,
};

StandingOrders.defaultProps = {
    sidebarOpenInformation: {},
};

const StandingOrderItem = ({order, sidebarOpenInformation, nextExecutionDate}) => {
    const {t} = useTranslation('bankTransfer');

    return (
        <Box sx={{
            display: 'flex',
            py: 2,
            px: 3,
            justifyContent: 'space-between',
            width: '100%',
            backgroundColor: sidebarOpenInformation?.order?.id === order.id
                ? 'v2.light.primary.shades' : 'inherit',
        }}
        >
            <Box sx={{
                display: 'flex',
            }}
            >
                <Box sx={{
                    mr: 2,
                }}
                >
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '50%',
                        backgroundColor: 'v2.dark.info.shades12',
                        width: '48px',
                        height: '48px',
                    }}
                    >
                        <EventRepeatTwoToneIcon />
                    </Box>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                    <Box sx={{
                        fontWeight: 600,
                        textTransform: 'uppercase',
                        color: 'text_v2.secondary',
                        wordBreak: 'break-all',
                    }}
                    >
                        {order.label}
                    </Box>
                    <Box sx={{
                        fontWeight: 400,
                        fontSize: theme => theme.typography.pxToRem(12),
                        color: 'v2.light.text.disabled',
                    }}
                    >
                        {t('orders.home.next')}: {nextExecutionDate}
                    </Box>
                </Box>
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                <Box
                    sx={{
                        fontWeight: 600,
                        textTransform: 'uppercase',
                        color: 'text_v2.secondary',
                        textAlign: 'right',
                    }}
                >
                    {order.amount} €
                </Box>
                <Box sx={{
                    fontWeight: 400,
                    fontSize: theme => theme.typography.pxToRem(12),
                    color: 'v2.light.text.disabled',
                }}
                >
                    {order.period === recurringTransferValues.daily && t('transfer.daily')}
                    {order.period === recurringTransferValues.weekly && t('transfer.weekly')}
                    {order.period === recurringTransferValues.monthly && t('transfer.monthly')}
                </Box>
            </Box>
        </Box>
    );
};

StandingOrderItem.propTypes = {
    sidebarOpenInformation: PropTypes.object,
    order: PropTypes.object.isRequired,
    nextExecutionDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

StandingOrderItem.defaultProps = {
    sidebarOpenInformation: null,
    nextExecutionDate: null,
};

const NewOrder = () => {
    const {t} = useTranslation('bankTransfer');

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', px: 3}}>
            <Box sx={{
                mb: 2,
                mt: 3,
                display: 'flex',
                justifyContent: 'center',
            }}
            >
                <img src={NewOrderIcon} alt="new order icon" />
            </Box>
            <Box sx={{
                mb: 2.5,
                color: 'v2.light.text.disabled',
                display: 'flex',
                justifyContent: 'center',
            }}
            >
                {t('orders.home.noStandingOrders')}
            </Box>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
            }}
            >
                <ReactRouterButtonLink
                    sx={{
                        py: 0.4,
                        borderRadius: '6px',
                    }}
                    to={`${RoutePaths.BANK_CREATE_TRANSFER}?tab=recurring`}
                    color="primary"
                    variant="contained"
                    startIcon={<AddIcon />}
                >
                    {t('orders.home.newTransfer')}
                </ReactRouterButtonLink>
            </Box>
        </Box>
    );
};

export const OrderSkeleton = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                gap: 1,
            }}
        >
            <Box>
                <Skeleton variant="rectangular" width={24} height={24} />
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
            }}
            >
                <Box>
                    <Skeleton variant="rectangular" width={230} height={16} />
                </Box>
                <Box>
                    <Skeleton variant="rectangular" width={180} height={16} />
                </Box>
            </Box>
        </Box>
    );
};
