import {combineReducers} from 'redux';
import {SidebarActionTypes} from './sidebar.action-type';
import HiwayLocalStorage from '../../../config/storage';
import {createReducer} from '../../../utils/create-reducer';
import {UserRoles} from '../../../utils/user-roles';
import {SIDEBAR_ROLE_LS_KEY} from '../utils/constants';

// TODO Default role selected logic should be done in BE at some point
const localStorageRole = HiwayLocalStorage.get(SIDEBAR_ROLE_LS_KEY);

export const sidebarReducer = combineReducers({
    isSidebarExpanded: createReducer(true, SidebarActionTypes.SET_IS_SIDEBAR_EXPANDED),
    isProfileMenuExpanded: createReducer(false, SidebarActionTypes.SET_IS_PROFILE_MENU_EXPANDED),
    roleSelected: (state = (localStorageRole ?? UserRoles.FREELANCER), action) => {
        if (action.type === SidebarActionTypes.SET_ROLE_SELECTED) {
            HiwayLocalStorage.set(SIDEBAR_ROLE_LS_KEY, action.payload);
            return action.payload;
        }

        return state;
    },
    sidebarText: createReducer('', SidebarActionTypes.SET_SEARCH_TEXT),
});
