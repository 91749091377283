import {
    faBriefcase,
    faLaptop,
    faPersonChalkboard,
    faStethoscope,
    faUsers,
} from '@fortawesome/free-solid-svg-icons';

export const UserRoles = {
    FREELANCER: 'FREELANCER',
    CARE: 'CARE',
    UMBRELLA: 'UMBRELLA',
    // HPD-2013 - Hide coach/accountant
    // COACH: 'COACH',
    // ACCOUNTANT: 'ACCOUNTANT',
    ADMINISTRATOR: 'ADMINISTRATOR',
};

export const ClientRolesArray = [
    UserRoles.FREELANCER,
    UserRoles.CARE,
    UserRoles.UMBRELLA,
];

// It is the same as UserRoles, it should be standardised at some point
export const USER_ROLES = {
    FREELANCER: 'FREELANCER',
    CARE: 'CARE',
    UMBRELLA: 'UMBRELLA',
    // COACH: 'COACH', // HPD-2013 - Hide coach/accountant
    // ACCOUNTANT: 'ACCOUNTANT', // HPD-2013 - Hide coach/accountant
    ADMINISTRATOR: 'ADMINISTRATOR',
};

export const FULL_USER_ROLES = {
    FREELANCER: 'FREELANCER',
    CARE: 'CARE',
    UMBRELLA: 'UMBRELLA',
    COACH: 'COACH',
    ACCOUNTANT: 'ACCOUNTANT',
    ADMINISTRATOR: 'ADMINISTRATOR',
};

export const isUserClientFn = user => {
    return user?.role === UserRoles.FREELANCER
        || user?.role === UserRoles.CARE
        || user?.role === UserRoles.UMBRELLA;
};

export const isUserCareFn = user => {
    return user?.role === UserRoles.CARE;
};

export const isUserUmbrellaFn = user => {
    return user?.role === UserRoles.UMBRELLA;
};

export const isUserAdminFn = user => {
    return user?.role === UserRoles.ADMINISTRATOR;
};

// TODO Change names in the entire application before merging Portage to Dev
// TODO Remove when merging Portage to Dev
export const isUserFreelancer = isUserClientFn;
export const isUserCare = isUserCareFn;
export const isUserPortage = isUserUmbrellaFn;
export const isUserAdmin = isUserAdminFn;


// TODO: change colors adter start using JoyUI everywhere
export const UserRoleColors = {
    [FULL_USER_ROLES.FREELANCER]: '#E7604D', // 'roleColor.tech'
    [FULL_USER_ROLES.CARE]: '#C9B2F8', // 'roleColor.care'
    [FULL_USER_ROLES.UMBRELLA]: '#C1EAD4', // 'roleColor.portage'
    [FULL_USER_ROLES.ADMINISTRATOR]: '#E7604D', // 'roleColor.admin'
    [FULL_USER_ROLES.COACH]: '#FBF3EA', // 'primary.solidColor'
};

export const UserRoleContrastColors = {
    [FULL_USER_ROLES.FREELANCER]: '#FBF3EA', // 'primary.solidColor'
    [FULL_USER_ROLES.CARE]: '#7B2329', // 'text.icon'
    [FULL_USER_ROLES.UMBRELLA]: '#7B2329', // 'text.icon'
    [FULL_USER_ROLES.ADMINISTRATOR]: '#FBF3EA', // 'primary.solidColor'
    [FULL_USER_ROLES.COACH]: '#E7604D', // 'roleColor.admin'
};

export const userRoleLocalStorageKey = 'USER_ROLE_LOCAL_STORAGE';

export const userDefaultColor = UserRoleColors[UserRoles.ADMINISTRATOR];
export const userDefaultContrastColor = UserRoleContrastColors[UserRoles.ADMINISTRATOR];

export const UserRoleIcons = {
    [FULL_USER_ROLES.FREELANCER]: faLaptop,
    [FULL_USER_ROLES.CARE]: faStethoscope,
    [FULL_USER_ROLES.ADMINISTRATOR]: faUsers,
    [FULL_USER_ROLES.UMBRELLA]: faBriefcase,
    [FULL_USER_ROLES.COACH]: faPersonChalkboard,
};
