import {DOCUMENT_TYPES} from '../../../../../consts/document-constants';

export const mapCapitalDepositDocType = docType => {
    switch (docType) {
        case DOCUMENT_TYPES.HOME_RENTAL_CONTRACT:
        case DOCUMENT_TYPES.DOMICILIATION_CERTIFICATE:
            return DOCUMENT_TYPES.RESIDENCE_CERT;
        default:
            return docType;
    }
};
