import {all, call, put, takeLatest} from 'redux-saga/effects';
import * as actions from './assignFreelancersForm.actions';
import {assignFreelancersRequest} from '../../../../features/user-list/api/user.api';
import {push} from '../../../../lib/router/connected-router-saga';
import {resolveRoute} from '../../../../lib/router/resolveRoute';
import {ROUTE_PATHS} from '../../../../lib/router/route-paths';
import {Toast} from '../../../../lib/toast';
import {USER_TABS} from '../user.constants';

export const submitAssignFreelancersFormSaga = function* submitAssignFreelancersFormSaga(action) {
    yield put(actions.setIsLoadingAssignFreelancersForm(true));

    yield put(actions.storeAssignFreelancersFormError(''));

    try {
        const {payload} = action;

        yield call(assignFreelancersRequest, payload.userId, payload.freelancers);

        yield put(push(resolveRoute(ROUTE_PATHS.USER, {
            userId: payload.userId,
            tab: USER_TABS.ACCOUNT.value,
        })));

        Toast.success('freelancersAssigned');
    } catch (error) {
        Toast.error('anErrorOccurred');

        yield put(actions.storeAssignFreelancersFormError('UNKNOWN_ERROR'));
    } finally {
        yield put(actions.setIsLoadingAssignFreelancersForm(false));
    }
};

export const watchAssignFreelancersFormSagas = function* watchAssignFreelancersFormSagas() {
    yield all([takeLatest(actions.SUBMIT_ASSIGN_FREELANCERS_FORM, submitAssignFreelancersFormSaga)]);
};
