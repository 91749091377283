import {TERM_DOCUMENTS} from '../utils/onboarding.constants';

const User = userDTO => {
    return {
        id: userDTO.id,
        key: userDTO?.id,
        firstName: userDTO.first_name,
        lastName: userDTO.last_name,
        fullName: `${userDTO.first_name} ${userDTO.last_name}`,
        email: userDTO.email,
        role: userDTO.role,
        status: userDTO.status,
        phone: userDTO.phone,
        active: !!userDTO.active,
        coachId: userDTO.coach_id,
    };
};

const Document = documentDTO => {
    return {
        id: documentDTO?.id,
        status: documentDTO?.docStatus,
        type: documentDTO?.docType,
        name: documentDTO?.originalFilename,
        category: documentDTO?.docCategory,
        isSignable: documentDTO?.isSignable,
    };
};

const Documents = documentsDTO => {
    if (documentsDTO) {
        return documentsDTO.map(document => (Document(document)));
    }

    return [];
};

const Consents = consentsDTO => {
    const signed = {
        [TERM_DOCUMENTS.CGVS]: false,
        [TERM_DOCUMENTS.ADP]: false,
    };

    if (consentsDTO?.length > 0) {
        consentsDTO.forEach(consent => {
            if (consent?.term?.type) {
                signed[consent.term.type] = true;
            }
        });
    }

    return signed;
};

const Onboarding = dto => {
    return {
        id: dto?.id,
        status: dto?.status,
        state: dto?.state,
        userId: dto?.userId,
        user: User(dto?.user),
        documents: Documents(dto?.user?.documents),
        consents: Consents(dto?.user?.consents),
    };
};

export const OnboardingObjects = {
    User,
    Document,
    Documents,
    Onboarding,
};
