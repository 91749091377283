import BookIcon from '@mui/icons-material/Book';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import GradingIcon from '@mui/icons-material/Grading';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import UpdateIcon from '@mui/icons-material/Update';
import {Box, CircularProgress, Paper, Typography, useMediaQuery} from '@mui/material';
import moment from 'moment-timezone';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import QuickAccessCard from './QuickAccessCard';
import RenewCertificateModal from './RenewCertificateModal';
import {QuickAccessAction} from './store/quick-access.action';
import {QuickAccessSelector} from './store/quick-access.selector';
import {DOCUMENT_STATUSES} from '../../../../consts/document-constants';
import {FreelancerSelectors} from '../../../../features/freelancer';
import {CompaniesSelectors} from '../../../../features/freelancer/modules/companies';
import {LoadingSelectors, LoadingTypes} from '../../../../features/loading';
import {UiActions} from '../../../../features/ui/store/ui.action';
import {ModalsKeys} from '../../../../features/ui/utils/constants';
import {LoggedInUserSelectors} from '../../../../features/user/modules/logged-in-user';
import {DEFAULT_TIME_ZONE} from '../../../../utils/constants';
import {isUserCareFn} from '../../../../utils/user-roles';
import {getCompanyDocument} from '../../../app/company/company.actions';
import {CARE_COMPANY_LEGAL_STATUSES, COMPANY_LEGAL_STATUSES} from '../../../app/company/company.consts';

const DEFAULT_MONTHS_DIFF = 2;

const DocumentsQuickAccess = () => {
    const {t} = useTranslation('documents');
    const isMobileSize = useMediaQuery(`(max-width:960px)`);
    const dispatch = useDispatch();

    const freelancer = useSelector(FreelancerSelectors.selectAccount);
    const loggedInUser = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const isCompanyEI = freelancer?.defaultCompanyInfo?.legal_status === CARE_COMPANY_LEGAL_STATUSES.EI;

    const hasKbisAccess = !isUserCareFn(loggedInUser) && !isCompanyEI;

    const company = useSelector(CompaniesSelectors.createCompanyByIdSelector(freelancer?.defaultCompanyId));
    const vigilanceCert = useSelector(QuickAccessSelector.selectVigilanceCert);
    const balanceSheet = useSelector(QuickAccessSelector.selectBalanceSheet);
    const kbis = useSelector(QuickAccessSelector.selectKbis);
    const isLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.QUICK_ACCESS_DOCS),
    );
    const isButtonLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.REISSUE_VIGILANCE_CERT),
    );

    const isVigilanceCertGenerating = vigilanceCert?.doc_status === DOCUMENT_STATUSES.GENERATING;
    const payrollStartDate = company?.payInfo?.payrollStartDate;
    const hasSiret = !!company?.enterpriseInformation?.siret;
    const hasAppropriateLegalStatus = company?.enterpriseInformation?.legal_status === COMPANY_LEGAL_STATUSES.SASU;
    // time diff between now and payrollStartDate in months
    const diff = moment.tz(DEFAULT_TIME_ZONE).diff(moment.tz(payrollStartDate, 'DD/MM/YYYY', DEFAULT_TIME_ZONE), 'months', true);
    const hasMoreThanTwoMonthsDiff = diff > DEFAULT_MONTHS_DIFF;
    const canRenewVigilanceCert = hasMoreThanTwoMonthsDiff && hasSiret && hasAppropriateLegalStatus;

    useEffect(() => {
        dispatch(QuickAccessAction.getQuickAccessDocs({
            freelancerId: freelancer?.id,
            companyId: freelancer?.defaultCompanyId,
        }));
    }, [dispatch, freelancer?.id, freelancer?.defaultCompanyId]);

    if (isLoading) {
        return (
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                alignSelf: 'stretch',
            }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <>
            <Paper
                radius={16}
                elevation={1}
                sx={{
                    display: 'flex',
                    padding: isMobileSize ? 2 : '8px 8px 8px 24px',
                    justifyContent: 'space-between',
                    alignItems: isMobileSize ? 'flex-start' : 'center',
                    gap: isMobileSize ? 2 : '50px',
                    alignSelf: 'stretch',
                    backgroundColor: 'v2.light.ghostWhite',
                    marginBottom: 2,
                    flexDirection: isMobileSize ? 'column' : 'row',
                }}
            >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    gap: 0.5,
                }}
                >
                    <Typography sx={{
                        fontSize: theme => theme.typography.pxToRem(20),
                        fontWeight: 600,
                        lineHeight: '120%',
                        color: 'v2.gray.900',
                    }}
                    >
                        {t('quickAccess.title')}
                    </Typography>
                    <Typography sx={{
                        fontSize: theme => theme.typography.pxToRem(12),
                        lineHeight: '150%',
                        color: 'v2.light.trolleyGrey',
                    }}
                    >
                        {t('quickAccess.subtitle')}
                    </Typography>
                </Box>
                <Box sx={{
                    display: 'flex',
                    alignItems: isMobileSize ? 'flex-start' : 'center',
                    gap: 1,
                    flex: '1 0 0',
                    flexDirection: isMobileSize ? 'column' : 'row',
                    alignSelf: 'stretch',
                }}
                >
                    <QuickAccessCard
                        icon={<FindInPageIcon />}
                        title={t('documentsDatabaseNames.VIGILANCE_CERT')}
                        buttonIcon={<UpdateIcon />}
                        buttonText={t('quickAccess.renew')}
                        tooltip={t('quickAccess.download')}
                        cta={() => dispatch(UiActions.setActiveModal(ModalsKeys.RENEW_CERTIFICATE_MODAL, true))}
                        downloadCta={() => dispatch(
                            getCompanyDocument(freelancer?.id, freelancer?.defaultCompanyId, vigilanceCert?.id, true),
                        )}
                        isDownloadDisabled={!vigilanceCert}
                        hasLoading={true}
                        isButtonLoading={isButtonLoading || isVigilanceCertGenerating}
                        isCtaDisabled={!canRenewVigilanceCert}
                    />
                    <QuickAccessCard
                        icon={<BookIcon />}
                        title={t('documentsDatabaseNames.BALANCE_SHEET')}
                        buttonIcon={<OpenInNewIcon />}
                        buttonText={t('quickAccess.see')}
                        tooltip={t('quickAccess.download')}
                        cta={() => dispatch(
                            getCompanyDocument(freelancer?.id, freelancer?.defaultCompanyId, balanceSheet?.id),
                        )}
                        downloadCta={() => dispatch(
                            getCompanyDocument(freelancer?.id, freelancer?.defaultCompanyId, balanceSheet?.id, true),
                        )}
                        isCtaDisabled={!balanceSheet}
                        isDownloadDisabled={!balanceSheet}
                    />
                    {hasKbisAccess && (
                        <QuickAccessCard
                            icon={<GradingIcon />}
                            title={t('documentsDatabaseNames.KBIS')}
                            buttonIcon={<OpenInNewIcon />}
                            buttonText={t('quickAccess.see')}
                            tooltip={t('quickAccess.download')}
                            cta={() => dispatch(
                                getCompanyDocument(freelancer?.id, freelancer?.defaultCompanyId, kbis?.id),
                            )}
                            downloadCta={() => dispatch(
                                getCompanyDocument(freelancer?.id, freelancer?.defaultCompanyId, kbis?.id, true),
                            )}
                            isCtaDisabled={!kbis}
                            isDownloadDisabled={!kbis}
                        />
                    )}
                </Box>
            </Paper>
            <RenewCertificateModal freelancerId={freelancer?.id} companyId={freelancer?.defaultCompanyId} />
        </>
    );
};

export default DocumentsQuickAccess;
