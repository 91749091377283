import {yupResolver} from '@hookform/resolvers/yup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EuroIcon from '@mui/icons-material/Euro';
import SaveIcon from '@mui/icons-material/Save';
import {List, ListItem, Option, Select, Typography} from '@mui/joy';
import {LoadingButton} from '@mui/lab';
import {
    Box, Button,
    Divider,
    FormLabel,
    Grid, Grow,
    InputAdornment,
    MenuItem, Paper,
    Slide,
    TextField,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {DatePicker} from '@mui/x-date-pickers';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';

import {useCompanyInformationFormStyles} from './styles';
import JoyUIThemeProvider from '../../../../components/joy-ui/joyui-theme-provider/JoyUiThemeProvider';
import {PageHeader} from '../../../../components/page-header/PageHeader';
import {COMPANY_TYPES} from '../../../../consts/company-constants';
import {AnimationActions} from '../../../../features/animations/store/animations.action';
import {AnimationsSelectors} from '../../../../features/animations/store/animations.selector';
import {ANIMATION_TYPE, FORM_ANIMATION_DELAY} from '../../../../features/animations/utils/constants';
import {CompanySetupSubSteps} from '../../../../features/freelancer/modules/company-setup/utils/constants';
import {OnboardingActions} from '../../../../features/freelancer/modules/onboarding/store/onboarding.action';
import {requiredFieldProps} from '../../../../features/user/modules/create-user-form/components/helper';
import {LoggedInUserSelectors} from '../../../../features/user/modules/logged-in-user';
import {CareJobTypes, CareJobTypesWithGrouping} from '../../../../features/user/utils/constants';
import {getIsRegistrationWithExistingCompany} from '../../../../utils/holidays';
import {isUserCareFn} from '../../../../utils/user-roles';
import {selectCompany} from '../../../app/company/company.selectors';
import {getCompanyInformationData} from '../../../app/company/setupCompany/companyInformation/companyInformation.selectors';
import {submitActiveStepForm} from '../../../app/company/setupCompany/setupCompany.actions';
import {
    PROFESSIONAL_ACTIVIES_FOR_HOLDING_COMPANY,
    PROFESSIONAL_ACTIVITIES,
    SHARE_CAPITALS,
} from '../../../app/company/setupCompany/setupCompany.constants';
import {getIsLoadingSetupCompanyForm} from '../../../app/company/setupCompany/setupCompany.selectors';

export const CompanyInformationForm = ({hasSubmitModificationsButton}) => {
    const styles = useCompanyInformationFormStyles();

    const {t} = useTranslation('setup');
    const {t: createUserT} = useTranslation('createUser');

    const dispatch = useDispatch();

    const isLoadingForm = useSelector(getIsLoadingSetupCompanyForm);

    const isAnimationActive = useSelector(AnimationsSelectors.selectIsAnimationActive);
    const isSubAnimationActive = useSelector(AnimationsSelectors.selectIsSubAnimationActive);

    const isMobileSize = useMediaQuery(`(max-width:900px)`);

    const loggedInUser = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const isUserCareRole = isUserCareFn(loggedInUser);
    const companyInformationData = useSelector(getCompanyInformationData);
    const company = useSelector(selectCompany);
    let professionalActivities;
    if (isUserCareRole) {
        professionalActivities = CareJobTypes;
    } else {
        professionalActivities = company?.type === COMPANY_TYPES.HOLDING
            ? PROFESSIONAL_ACTIVIES_FOR_HOLDING_COMPANY
            : PROFESSIONAL_ACTIVITIES;
    }


    const validationSchema = Yup.object().shape({
        professionalActivity: Yup.string()
            .oneOf(!isUserCareRole ? Object
                .values(professionalActivities)
                : Object.values(CareJobTypes))
            .required(t('validations.isRequired', {what: t('professionalActivity')})),
        customProfessionalActivity: Yup.string().when('professionalActivity', {
            is: PROFESSIONAL_ACTIVITIES.OTHER,
            then: Yup.string().required(t('validations.isRequired', {what: t('professionalActivity')})),
        }),
        companyName: isUserCareRole
            ? Yup.mixed()
            : Yup.string().max(80, t('validations.maxMessage')).required(t('validations.isRequired', {what: t('companyName')})),
        amountOfShareCapital: !isUserCareRole ? Yup.string()
            .oneOf(Object
                .values(SHARE_CAPITALS))
            .required(t('validations.isRequired', {what: t('amountOfShareCapital')}))
            : Yup.mixed(),
        customAmountOfShareCapital: !isUserCareRole ? (Yup.string().when('amountOfShareCapital', {
            is: SHARE_CAPITALS.OTHER,
            then: Yup
                .string()
                .required(t('validations.isRequired', {what: t('amountOfShareCapital')}))
                .matches(/^\d+$/, t('validations.valueIsNotNumber')),
        })) : Yup.mixed(),
    });

    let initialProfessionalActivity = companyInformationData?.professionalActivity || '';
    const companyName = companyInformationData?.companyName || '';
    let initialAmountOfShareCapital = companyInformationData?.amountOfShareCapital || '';
    let customProfessionalActivity = '';
    let customAmountOfShareCapital = '';

    const {
        headquarters_city: headquartersCity,
        headquarters_street: headquartersStreetName,
        headquarters_street_number: headquartersStreetNumber,
        headquarters_zip: headquartersZipCode,
    } = company?.enterpriseInformation;

    const activityList = isUserCareRole ? Object.values(CareJobTypes) : [
        ...Object.values(PROFESSIONAL_ACTIVITIES),
        ...Object.values(PROFESSIONAL_ACTIVIES_FOR_HOLDING_COMPANY),
    ];
    const foundActivity = activityList.find(activity => {
        return initialProfessionalActivity === activity && activity !== PROFESSIONAL_ACTIVITIES.OTHER;
    });

    if (!foundActivity && initialProfessionalActivity && !isUserCareRole) {
        customProfessionalActivity = initialProfessionalActivity;
        initialProfessionalActivity = PROFESSIONAL_ACTIVITIES.OTHER;
    }

    const foundAmountOfShareCapital = Object.values(SHARE_CAPITALS).find(shareCapital => {
        return `${initialAmountOfShareCapital}` === shareCapital && shareCapital !== SHARE_CAPITALS.OTHER;
    });

    if (!foundAmountOfShareCapital && initialAmountOfShareCapital && !isUserCareRole) {
        customAmountOfShareCapital = initialAmountOfShareCapital;
        initialAmountOfShareCapital = SHARE_CAPITALS.OTHER;
    }

    const activityStartDate = company?.enterpriseInformation?.activityStartDate;
    const registrationType = company?.enterpriseInformation?.registrationType;
    const registrationWithExistingCompany = getIsRegistrationWithExistingCompany(registrationType);

    const defaultValues = useMemo(() => {
        return {
            professionalActivity: initialProfessionalActivity,
            customProfessionalActivity,
            companyName,
            amountOfShareCapital: initialAmountOfShareCapital,
            customAmountOfShareCapital,
            activityStartDate: activityStartDate ? moment(activityStartDate, 'DD/MM/YYYY').toDate() : moment(),
        };
    }, [
        activityStartDate,
        companyName,
        customAmountOfShareCapital,
        customProfessionalActivity,
        initialAmountOfShareCapital,
        initialProfessionalActivity,
    ]);

    const {
        formState: {errors, isDirty, isValid},
        control,
        reset,
        watch,
        handleSubmit: createSubmitHandler,
    } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(validationSchema),
    });

    const professionalActivity = watch('professionalActivity');
    const amountOfShareCapital = watch('amountOfShareCapital');

    const formRef = useRef(null);
    const isSubmittingForApproval = useRef(false);

    const handleSubmit = useCallback(data => {
        let {
            amountOfShareCapital,
            customAmountOfShareCapital,
            professionalActivity,
            customProfessionalActivity,
            companyName,
            ...rest
        } = data;

        // TODO:LOW Move this to DTO.
        professionalActivity = professionalActivity === PROFESSIONAL_ACTIVITIES.OTHER
            ? customProfessionalActivity
            : professionalActivity;

        amountOfShareCapital = amountOfShareCapital === SHARE_CAPITALS.OTHER
            ? customAmountOfShareCapital
            : amountOfShareCapital;

        if (isUserCareRole) {
            companyName = `${loggedInUser.firstName} ${loggedInUser.lastName}`;
        }

        dispatch(submitActiveStepForm({
            ...rest,
            professionalActivity,
            amountOfShareCapital,
            companyName,
        }, isDirty, null, isSubmittingForApproval.current));

        isSubmittingForApproval.current = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, isDirty, isSubmittingForApproval, isUserCareRole]);

    const handleSubmitModifications = useCallback(() => {
        isSubmittingForApproval.current = true;

        // Trigger form submit
        formRef.current.dispatchEvent(new Event('submit', {
            cancelable: true,
            bubbles: true,
        }));
    }, []);

    const handleBackButtonClick = () => {
        dispatch(OnboardingActions.setSubStep(
            registrationWithExistingCompany
                ? CompanySetupSubSteps.ADDRESS_INFORMATION
                : CompanySetupSubSteps.DECLARATION_OF_NON_CONVICTION,
        ));
    };

    const professionalActivitiesOptions = useMemo(() => {
        if (isUserCareRole) {
            const jobTypesWithGrouping = Object.entries(CareJobTypesWithGrouping);

            return jobTypesWithGrouping.map(jobTypeWithGrouping => {
                const group = jobTypeWithGrouping[0];
                const jobTypes = jobTypeWithGrouping[1];

                return (
                    <List
                        key={group}
                        aria-labelledby={`select-group-${group}`}
                        sx={{'--ListItemDecorator-size': '28px'}}
                    >
                        <ListItem id={`select-group-${group}`} sticky>
                            <Typography level="body-xs" sx={{textTransform: 'uppercase'}}>
                                {createUserT(`careJobTypeGroups.${group}`)}
                            </Typography>
                        </ListItem>
                        {jobTypes.map(jobType => {
                            return (
                                <Option
                                    key={jobType}
                                    value={jobType}
                                >
                                    {createUserT(`careJobTypes.${jobType}`)}
                                </Option>
                            );
                        })}
                    </List>
                );
            });
        }

        return Object.values(professionalActivities).map(professionalActivity => (
            <MenuItem value={professionalActivity} key={professionalActivity}>
                {t(`companies:professionalActivities.${professionalActivity}`)}
            </MenuItem>
        ));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [professionalActivities, t, createUserT]);

    const shareCapitalOptions = useMemo(() => {
        return Object.values(SHARE_CAPITALS).map(shareCapital => (
            <MenuItem value={shareCapital} key={shareCapital}>
                {t(`companies:shareCapitals.${shareCapital}`)}
            </MenuItem>
        ));
    }, [t]);

    useEffect(() => {
        if (!companyInformationData || !company) {
            return;
        }

        // TODO:LOW Move this to DTO.
        let {
            professionalActivity,
            companyName,
            amountOfShareCapital,
        } = companyInformationData;

        let customProfessionalActivity = '';
        let customAmountOfShareCapital = '';

        const foundActivity = activityList.find(activity => {
            return professionalActivity === activity && activity !== PROFESSIONAL_ACTIVITIES.OTHER;
        });

        if (!foundActivity && professionalActivity && !isUserCareRole) {
            customProfessionalActivity = professionalActivity;
            professionalActivity = PROFESSIONAL_ACTIVITIES.OTHER;
        }

        const foundAmountOfShareCapital = Object.values(SHARE_CAPITALS).find(shareCapital => {
            return `${amountOfShareCapital}` === shareCapital && shareCapital !== SHARE_CAPITALS.OTHER;
        });

        if (!foundAmountOfShareCapital && amountOfShareCapital && !isUserCareRole) {
            customAmountOfShareCapital = amountOfShareCapital;
            amountOfShareCapital = SHARE_CAPITALS.OTHER;
        }
        reset({
            activityStartDate: company.enterpriseInformation.activityStartDate
                ? moment(company.enterpriseInformation.activityStartDate, 'DD/MM/YYYY').toDate()
                : new Date(),
            professionalActivity: company?.type === COMPANY_TYPES.HOLDING
                ? PROFESSIONAL_ACTIVIES_FOR_HOLDING_COMPANY.SASU_PATRIMONIALE
                : professionalActivity || '',
            customProfessionalActivity: customProfessionalActivity || '',
            companyName: companyName || '',
            amountOfShareCapital: amountOfShareCapital || '',
            customAmountOfShareCapital: customAmountOfShareCapital || '',
        });

        return () => {};
        // eslint-disable-next-line
    }, [company, companyInformationData, reset]);

    // TODO: CLEANUP
    // TODO Find out what was this supposed to do
    // Now it will always go in if (isSubmittingForApproval is object)
    // Dispatching will do nothing without bubble: true
    // on most browswers and on some like FF it will crash
    // useEffect(() => {
    //     if (isSubmittingForApproval) {
    //         // Trigger form submit
    //         formRef.current.dispatchEvent(new Event('submit', {
    //             cancelable: true,
    //         }));
    //     }
    // }, [isSubmittingForApproval]);

    const onEndedAnimation = () => {
        dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.MAIN));
    };

    if (!isMobileSize) {
        return (
            <form ref={formRef} onSubmit={createSubmitHandler(handleSubmit)} noValidate>
                <PageHeader>
                    {isUserCareRole ? t('stepTitles.companyInformationCare') : t('stepTitles.companyInformation')}
                </PageHeader>

                <Slide
                    direction="left"
                    in={isAnimationActive}
                    timeout={FORM_ANIMATION_DELAY}
                    onEntered={onEndedAnimation}
                    onExited={onEndedAnimation}
                >
                    <Paper radius={16} elevation={2} sx={{px: 5, py: 4}}>
                        {/* <SetupCompanyFormTitle title={t('stepTitles.companyInformation')} /> */}

                        <FormLabel component="legend" className={styles.formLabel}>
                            {t('professionalActivity')}
                        </FormLabel>

                        <Grow
                            in={isSubAnimationActive}
                            timeout={FORM_ANIMATION_DELAY}
                            onExited={() => {
                                dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.SECONDARY));
                            }}
                        >
                            <Grid container spacing={4}>
                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name="professionalActivity"
                                        control={control}
                                        render={({field}) => (
                                            isUserCareRole ? (
                                                <JoyUIThemeProvider>
                                                    <Select
                                                        {...field}
                                                        data-cy="jobType"
                                                        color={errors.jobType ? 'danger' : 'neutral'}
                                                        error={!!errors.jobType}
                                                        placeholder={createUserT('jobType')}
                                                        tabIndex={6}
                                                        onChange={(_, value) => {
                                                            field.onChange(value);
                                                        }}
                                                        {...requiredFieldProps}
                                                        sx={{
                                                            ...requiredFieldProps.sx,
                                                            'width': '100%',
                                                        }}
                                                    >
                                                        {professionalActivitiesOptions}
                                                    </Select>
                                                </JoyUIThemeProvider>
                                            ) : (
                                                <TextField
                                                    {...field}
                                                    label={t('yourProfessionalActivity')}
                                                    variant="filled"
                                                    error={!!errors.professionalActivity}
                                                    helperText={errors.professionalActivity?.message}
                                                    disabled={company?.type === COMPANY_TYPES.HOLDING}
                                                    select
                                                    required
                                                    fullWidth
                                                >
                                                    {professionalActivitiesOptions}
                                                </TextField>
                                            )
                                        )}
                                    />
                                </Grid>

                                {professionalActivity === PROFESSIONAL_ACTIVITIES.OTHER && (
                                    <Grid item xs={12} md={6}>
                                        <Controller
                                            name="customProfessionalActivity"
                                            control={control}
                                            render={({field}) => (
                                                <TextField
                                                    {...field}
                                                    label={t('enterProfessionalActivity')}
                                                    variant="filled"
                                                    error={!!errors.customProfessionalActivity}
                                                    helperText={errors.customProfessionalActivity?.message}
                                                    required
                                                    fullWidth
                                                />
                                            )}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Grow>

                        {!isUserCareRole && (
                            <>
                                <Divider className={styles.divider} />

                                <FormLabel component="legend" className={styles.formLabel}>
                                    {t('companyName')}
                                </FormLabel>

                                <Grow
                                    in={isSubAnimationActive}
                                    timeout={FORM_ANIMATION_DELAY * 2}
                                >
                                    <Grid container spacing={4}>
                                        <Grid item xs={12} md={6}>
                                            <Controller
                                                name="companyName"
                                                control={control}
                                                render={({field}) => (
                                                    <TextField
                                                        {...field}
                                                        label={t('companyName')}
                                                        variant="filled"
                                                        error={!!errors.companyName}
                                                        helperText={errors.companyName?.message}
                                                        required
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grow>
                            </>
                        )}

                        <Divider className={styles.divider} />

                        {!isUserCareRole && (
                            <>
                                <FormLabel component="legend" className={styles.formLabel}>
                                    {t('shareCapital')}
                                </FormLabel>


                                <Grow
                                    in={isSubAnimationActive}
                                    timeout={FORM_ANIMATION_DELAY * 3}
                                >
                                    <Grid container spacing={4}>
                                        <Grid item xs={12} md={6}>
                                            <Controller
                                                name="amountOfShareCapital"
                                                control={control}
                                                render={({field}) => (
                                                    <TextField
                                                        {...field}
                                                        label={t('amountOfShareCapital')}
                                                        variant="filled"
                                                        error={!!errors.amountOfShareCapital}
                                                        helperText={errors.amountOfShareCapital?.message}
                                                        required
                                                        select
                                                        fullWidth
                                                    >
                                                        {shareCapitalOptions}
                                                    </TextField>
                                                )}
                                            />
                                        </Grid>

                                        {amountOfShareCapital === SHARE_CAPITALS.OTHER && (
                                        <Grid item xs={12} md={6}>
                                            <Controller
                                                name="customAmountOfShareCapital"
                                                control={control}
                                                render={({field}) => (
                                                    <TextField
                                                        {...field}
                                                        label={t('amountOfShareCapital')}
                                                        variant="filled"
                                                        error={!!errors.customAmountOfShareCapital}
                                                        helperText={errors.customAmountOfShareCapital?.message}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <EuroIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        required
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        )}
                                    </Grid>
                                </Grow>

                                <Divider className={styles.divider} />
                            </>
                        )}

                        <FormLabel component="legend" className={styles.formLabel}>
                            {isUserCareRole ? t('companyAddressEI') : t('companyAddress')}
                        </FormLabel>

                        <Grow
                            in={isSubAnimationActive}
                            timeout={FORM_ANIMATION_DELAY * 4}
                        >
                            <Grid container spacing={4}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        variant="filled"
                                        label={t('address')}
                                        fullWidth
                                        value={`${headquartersStreetNumber} ${headquartersStreetName}`}
                                        disabled={true}
                                    />
                                </Grid>

                                <Grid item xs={12} md={4} sx={{pt: 2}}>
                                    <TextField
                                        variant="filled"
                                        label={t('city')}
                                        fullWidth
                                        value={headquartersCity}
                                        disabled={true}
                                    />
                                </Grid>

                                <Grid item xs={12} md={2} sx={{pt: 4}}>
                                    <TextField
                                        variant="filled"
                                        label={t('zip')}
                                        fullWidth
                                        value={headquartersZipCode}
                                        disabled={true}
                                    />
                                </Grid>

                            </Grid>
                        </Grow>

                        <Divider className={styles.divider} />


                        <FormLabel component="legend" className={styles.formLabel}>
                            {t('activityStartDate')}
                        </FormLabel>

                        <Grid container spacing={4}>
                            <Grid item xs={12} md={6}>
                                <Controller
                                    name="activityStartDate"
                                    control={control}
                                    render={({field}) => (
                                        <DatePicker
                                            {...field}
                                            disabled={true}
                                            openTo="day"
                                            format={moment.localeData().longDateFormat('L')}
                                            views={['day', 'month', 'year']}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    id="activityStartDate"
                                                    variant="filled"
                                                    label={t('activityStartDate')}
                                                    error={!!errors.activityStartDate}
                                                    helperText={errors.activityStartDate?.message}
                                                />
                                            )}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        {/* TODO: CLEANUP */}
                        {/* <SetupCompanyFormFooter */}
                        {/*    onBackButtonClick={handleBackButtonClick} */}
                        {/*    isSubmitButtonDisabled={isSubmitButtonDisabled} */}
                        {/*    areBothButtonsDisabled={isLoading} */}
                        {/*    onSubmitForApprovalButtonClick={handleSubmitForApprovalButtonClick} */}
                        {/* /> */}
                    </Paper>
                </Slide>

                <Box display="flex" justifyContent="space-between" sx={{mt: 3}}>
                    <Slide
                        direction="right"
                        in={isAnimationActive}
                        timeout={FORM_ANIMATION_DELAY * 4}
                    >
                        <Button
                            size="large"
                            variant="outlined"
                            color="primary"
                            startIcon={<ArrowBackIcon />}
                            onClick={handleBackButtonClick}
                        >
                            {t('back')}
                        </Button>
                    </Slide>

                    <Slide
                        direction="left"
                        in={isAnimationActive}
                        timeout={FORM_ANIMATION_DELAY * 4}
                    >
                        <Box display="flex" justifyContent="space-between">
                            <Grow in={hasSubmitModificationsButton} mountOnEnter unmountOnExit>
                                <Box sx={{mr: 2}}>
                                    <LoadingButton
                                        loading={isLoadingForm}
                                        size="large"
                                        variant="contained"
                                        color="primary"
                                        startIcon={<SaveIcon />}
                                        onClick={handleSubmitModifications}
                                        disabled={!isValid}
                                    >
                                        {t('submitForApproval')}
                                    </LoadingButton>
                                </Box>
                            </Grow>

                            <LoadingButton
                                loading={isLoadingForm}
                                size="large"
                                variant="contained"
                                color="secondary"
                                endIcon={<ArrowForwardIcon />}
                                disabled={!isValid}
                                type="submit"
                            >
                                {t('saveAndProceed')}
                            </LoadingButton>
                        </Box>
                    </Slide>
                </Box>
            </form>
        );
    }

    return (
        <form ref={formRef} onSubmit={createSubmitHandler(handleSubmit)} noValidate>
            <Paper radius={16} elevation={2} sx={{px: 5, py: 4}}>
                {/* <SetupCompanyFormTitle title={t('stepTitles.companyInformation')} /> */}

                <FormLabel component="legend" className={styles.formLabel}>
                    {t('professionalActivity')}
                </FormLabel>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="professionalActivity"
                            control={control}
                            render={({field}) => (
                                isUserCareRole ? (
                                    <JoyUIThemeProvider>
                                        <Select
                                            {...field}
                                            data-cy="jobType"
                                            color={errors.jobType ? 'danger' : 'neutral'}
                                            error={!!errors.jobType}
                                            placeholder={createUserT('jobType')}
                                            tabIndex={6}
                                            onChange={(_, value) => {
                                                field.onChange(value);
                                            }}
                                            {...requiredFieldProps}
                                            sx={{
                                                ...requiredFieldProps.sx,
                                                'width': '100%',
                                            }}
                                        >
                                            {professionalActivitiesOptions}
                                        </Select>
                                    </JoyUIThemeProvider>
                                ) : (
                                    <TextField
                                        {...field}
                                        label={t('yourProfessionalActivity')}
                                        variant="filled"
                                        error={!!errors.professionalActivity}
                                        helperText={errors.professionalActivity?.message}
                                        disabled={company?.type === COMPANY_TYPES.HOLDING}
                                        select
                                        required
                                        fullWidth
                                    >
                                        {professionalActivitiesOptions}
                                    </TextField>
                                )
                            )}
                        />
                    </Grid>

                    {professionalActivity === PROFESSIONAL_ACTIVITIES.OTHER && (
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="customProfessionalActivity"
                                control={control}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        label={t('enterProfessionalActivity')}
                                        variant="filled"
                                        error={!!errors.customProfessionalActivity}
                                        helperText={errors.customProfessionalActivity?.message}
                                        required
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                    )}
                </Grid>

                <Divider className={styles.divider} />

                {!isUserCareRole && (
                    <>
                        <FormLabel component="legend" className={styles.formLabel}>
                            {t('companyName')}
                        </FormLabel>

                        <Grid container spacing={4}>
                            <Grid item xs={12} md={6}>
                                <Controller
                                    name="companyName"
                                    control={control}
                                    render={({field}) => (
                                        <TextField
                                            {...field}
                                            label={t('companyName')}
                                            variant="filled"
                                            error={!!errors.companyName}
                                            helperText={errors.companyName?.message}
                                            required
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>

                        <Divider className={styles.divider} />
                    </>
                )}


                {!isUserCareRole && (
                    <>
                        <FormLabel component="legend" className={styles.formLabel}>
                            {t('shareCapital')}
                        </FormLabel>

                        <Grid container spacing={4}>
                            <Grid item xs={12} md={6}>
                                <Controller
                                    name="amountOfShareCapital"
                                    control={control}
                                    render={({field}) => (
                                        <TextField
                                            {...field}
                                            label={t('amountOfShareCapital')}
                                            variant="filled"
                                            error={!!errors.amountOfShareCapital}
                                            helperText={errors.amountOfShareCapital?.message}
                                            required
                                            select
                                            fullWidth
                                        >
                                            {shareCapitalOptions}
                                        </TextField>
                                    )}
                                />
                            </Grid>

                            {amountOfShareCapital === SHARE_CAPITALS.OTHER && (
                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name="customAmountOfShareCapital"
                                        control={control}
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                label={t('amountOfShareCapital')}
                                                variant="filled"
                                                error={!!errors.customAmountOfShareCapital}
                                                helperText={errors.customAmountOfShareCapital?.message}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <EuroIcon />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                required
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Grid>
                            )}
                        </Grid>

                        <Divider className={styles.divider} />
                    </>
                )}

                <FormLabel component="legend" className={styles.formLabel}>
                    {isUserCareRole ? t('companyAddressEI') : t('companyAddress')}
                </FormLabel>

                <Grid container>
                    <Grid item xs={12} md={6}>
                        <TextField
                            variant="filled"
                            label={t('address')}
                            fullWidth
                            value={`${headquartersStreetNumber} ${headquartersStreetName}`}
                            disabled={true}
                        />
                    </Grid>

                    <Grid item xs={12} md={4} sx={{pt: 2}}>
                        <TextField
                            variant="filled"
                            label={t('city')}
                            fullWidth
                            value={headquartersCity}
                            disabled={true}
                        />
                    </Grid>

                    <Grid item xs={12} md={2} sx={{pt: 2}}>
                        <TextField
                            variant="filled"
                            label={t('zip')}
                            fullWidth
                            value={headquartersZipCode}
                            disabled={true}
                        />
                    </Grid>
                </Grid>
                <Divider className={styles.divider} />

                <FormLabel component="legend" className={styles.formLabel}>
                    {t('activityStartDate')}
                </FormLabel>

                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="activityStartDate"
                            control={control}
                            render={({field}) => (
                                <DatePicker
                                    {...field}
                                    disabled={true}
                                    openTo="day"
                                    format={moment.localeData().longDateFormat('L')}
                                    views={['day', 'month', 'year']}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            id="activityStartDate"
                                            variant="filled"
                                            label={t('activityStartDate')}
                                            error={!!errors.activityStartDate}
                                            helperText={errors.activityStartDate?.message}
                                        />
                                    )}
                                />
                            )}
                        />
                    </Grid>
                </Grid>

                {/* <SetupCompanyFormFooter */}
                {/*    onBackButtonClick={handleBackButtonClick} */}
                {/*    isSubmitButtonDisabled={isSubmitButtonDisabled} */}
                {/*    areBothButtonsDisabled={isLoading} */}
                {/*    onSubmitForApprovalButtonClick={handleSubmitForApprovalButtonClick} */}
                {/* /> */}
            </Paper>

            <Box display="flex" justifyContent="center" flexDirection="column" sx={{mt: 3}}>
                <Button
                    sx={{mx: 2}}
                    size="large"
                    variant="outlined"
                    color="primary"
                    startIcon={<ArrowBackIcon />}
                    onClick={handleBackButtonClick}
                >
                    {t('back')}
                </Button>

                <LoadingButton
                    sx={{mx: 2, mt: 3}}
                    loading={isLoadingForm}
                    size="large"
                    variant="contained"
                    color="secondary"
                    endIcon={<ArrowForwardIcon />}
                    disabled={!isValid}
                    type="submit"
                >
                    {t('saveAndProceed')}
                </LoadingButton>

                {hasSubmitModificationsButton && (
                    <LoadingButton
                        loading={isLoadingForm}
                        sx={{mx: 2, mt: 3}}
                        size="large"
                        variant="contained"
                        color="primary"
                        startIcon={<SaveIcon />}
                        onClick={handleSubmitModifications}
                        disabled={!isValid}
                    >
                        {t('submitForApproval')}
                    </LoadingButton>
                )}
            </Box>
        </form>
    );
};

CompanyInformationForm.propTypes = {
    hasSubmitModificationsButton: PropTypes.bool,
};

CompanyInformationForm.defaultProps = {
    hasSubmitModificationsButton: false,
};
