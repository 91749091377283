import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import CloseIcon from '@mui/icons-material/Close';
import EuroIcon from '@mui/icons-material/Euro';
import EventIcon from '@mui/icons-material/Event';
import LinkIcon from '@mui/icons-material/Link';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PaymentsIcon from '@mui/icons-material/Payments';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import VerifiedIcon from '@mui/icons-material/Verified';
import {LoadingButton} from '@mui/lab';
import {Box, Chip, IconButton, Paper, TextField, Typography, useMediaQuery} from '@mui/material';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import NumberFormat from 'react-number-format';
import {useDispatch, useSelector} from 'react-redux';
import InvoiceAlreadyPaidInfo from './InvoiceAlreadyPaidInfo';
import InvoiceInfoRow from './InvoiceInfoRow';
import {ConditionalTooltip} from '../../../../../../../../components/tooltip/ConditionalTooltip';
import {roundHalfUpToTwoDecimalPlaces} from '../../../../../../../../utils/number';
import {isUserClientFn} from '../../../../../../../../utils/user-roles';
import {getCompanyDocument} from '../../../../../../../../v1/app/company/company.actions';
import {selectFreelancerAccount} from '../../../../../../../../v1/app/freelancer/freelancer.selectors';
import {LoadingTypes, useLoadingState} from '../../../../../../../loading';
import {LoggedInUserSelectors} from '../../../../../../../user/modules/logged-in-user';

const Invoice = ({
    unallocatedAmount,
    isCategorised,
    invoice,
    isTheBest,
    setActiveInvoice,
    activeInvoice,
    matchType,
    onSelectInvoice,
    partialPaidValue,
    setPartialPaidValue,
}) => {
    const {t} = useTranslation('bank');
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const dispatch = useDispatch();
    const {
        docNumber,
        dueDate,
        invoiceId,
        paidAmount,
        allocatedAmount,
        selected,
        totalAmount,
        totalRemainingAmount,
        totalFeeAmount = null,
        clientName,
        documentId,
    } = invoice;

    const maxPartialPaidValue = selected
        ? Number(allocatedAmount)
        : Math.min(unallocatedAmount, Number(totalRemainingAmount));

    const loggedInUser = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const freelancerAccount = useSelector(selectFreelancerAccount);

    const freelancer = isUserClientFn(loggedInUser)
        ? loggedInUser
        : freelancerAccount;

    const isActive = invoiceId === activeInvoice;

    const dueDateFormat = moment(dueDate).format('DD/MM/YYYY');

    const newAllocatedAmount = isActive
        ? Number(partialPaidValue)
        : maxPartialPaidValue;

    const handleInvoiceClick = _ => {
        if (!isActive) {
            setPartialPaidValue(maxPartialPaidValue);
        }
        if (activeInvoice === invoiceId) {
            setActiveInvoice('');
        } else {
            setActiveInvoice(invoiceId);
        }
    };

    const handleSelect = e => {
        e.stopPropagation();
        onSelectInvoice(invoiceId, newAllocatedAmount, matchType);
    };

    const handleUnselect = e => {
        e.stopPropagation();
        onSelectInvoice(invoiceId);
    };

    const handleDownloadInvoice = e => {
        e.stopPropagation();

        dispatch(getCompanyDocument(
            freelancer.id,
            freelancer.defaultCompanyId,
            documentId,
        ));
    };

    const isFullPaid = !(Number(totalRemainingAmount) > 1);
    const isUpdating = useLoadingState(LoadingTypes.BANK_TRANSACTION_FIELD);
    const withFee = !!Number(totalFeeAmount);
    const isPartiallyPaid = (!selected && !!Number(paidAmount))
        || (selected && !isFullPaid);
    const invoiceStartingValue = selected
        ? Number(allocatedAmount) + 1
        : 1;

    // Partial paying amount exceeds remaining amount of the transaction
    const isAllocatedAmountExceeds = newAllocatedAmount - unallocatedAmount > invoiceStartingValue;

    // Partial paying amount exceeds total amount or the invoice
    const isInvoiceAmountExceeds = newAllocatedAmount - (Number(totalAmount) - Number(totalFeeAmount)) > 1;

    const allocatedFieldDisabled = isCategorised && !selected;

    const isValidationError = !newAllocatedAmount
        || isAllocatedAmountExceeds
        || isInvoiceAmountExceeds
        || allocatedFieldDisabled;

    const FeeInfo = () => (
        <InvoiceInfoRow
            title={t('invoices.fee')}
            Icon={PaymentsIcon}
            amount={totalFeeAmount}
            sign="-"
            isSelected={selected}
            isFee
        />
    );

    return (
        <Paper
            sx={{
                borderRadius: '16px',
                borderColor: isActive || selected ? 'primary_v2.main' : '#fff',
                borderWidth: '2px',
                borderStyle: 'solid',
                backgroundColor: selected ? 'primary_v2.main' : '#fff',
                padding: '8px 8px 16px 16px',
                display: 'flex',
                alignSelf: 'stretch',
                flexDirection: 'column',
                gap: '8px',
            }}
            onClick={handleInvoiceClick}
            elevation={2}
        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                flex: '1 0 0',
                mb: 1,
            }}
            >
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignSelf: 'stretch',
                    alignItems: 'flex-start',
                    gap: '8px',
                }}
                >
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        my: 0.5,
                        overflowWrap: 'anywhere',
                        flexDirection: isMobileSize ? 'column' : 'row',
                    }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 500,
                                fontSize: '16px',
                                lineHeight: '22px',
                                color: selected ? '#fff' : 'text_v2.primary',
                                textTransform: 'uppercase',
                            }}
                        >
                            {clientName}
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: 500,
                                fontSize: '16px',
                                lineHeight: '22px',
                                color: selected ? '#fff' : 'v2.light.text.disabled',
                            }}
                        >
                            &nbsp;•&nbsp;
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: '16px',
                                lineHeight: '22px',
                                color: selected ? '#fff' : 'text_v2.primary',
                            }}
                        >
                            N° {docNumber}
                        </Typography>
                    </Box>

                    <IconButton
                        sx={{
                            p: '4px',
                            color: selected ? '#fff' : 'v2.blueGray.500',
                        }}
                        onClick={handleDownloadInvoice}
                        disabled={isUpdating}
                    >
                        <OpenInNewIcon />
                    </IconButton>
                </Box>

                <Box sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    alignSelf: 'stretch',
                    gap: 0.5,
                }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        {selected && !isPartiallyPaid ? (
                            <Box sx={{display: 'flex', alignItems: 'center', my: 0.5}}>
                                <VerifiedIcon sx={{
                                    color: selected ? '#fff' : 'v2.blueGray.300',
                                    mr: 0.5,
                                }}
                                />
                                <Typography sx={{
                                    fontWeight: 500,
                                    fontSize: '16px',
                                    lineHeight: '26px',
                                    color: '#fff',
                                    letterSpacing: '0.4px',
                                }}
                                >
                                    {t(`invoices.paidFull`)}
                                </Typography>
                            </Box>
                        ) : (
                            <>
                                <Typography
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: '12px',
                                        lineHeight: '16px',
                                        color: selected ? '#fff' : 'text_v2.secondary',
                                        letterSpacing: '0.4px',
                                    }}
                                >
                                    {t(`invoices.remaining`)}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontWeight: 500,
                                        fontSize: '16px',
                                        lineHeight: '26px',
                                        color: selected ? '#fff' : 'v2.light.secondary.main',
                                        letterSpacing: '0.1px',
                                    }}
                                >
                                    {roundHalfUpToTwoDecimalPlaces(totalRemainingAmount)}&nbsp;€
                                </Typography>
                            </>
                        )}
                    </Box>

                    {isTheBest && !selected && (
                        <Chip
                            sx={{
                                mb: 1,
                                mt: 0.5,
                                py: '3px',
                                fontSize: '13px',
                                lineHeight: '18px',
                                borderRadius: '16px',
                                fontWeight: 400,
                                backgroundColor: 'v2.light.other.rating.active',
                            }}
                            color="secondary"
                            size="small"
                            variant="contained"
                            label={t(`invoices.match`)}
                            icon={<VerifiedIcon sx={{ml: 0}} />}
                        />
                    )}

                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <InvoiceInfoRow
                            title={t('invoices.total')}
                            Icon={ReceiptLongIcon}
                            amount={totalAmount}
                            isSelected={selected}
                        />
                        {isPartiallyPaid && (
                            <InvoiceInfoRow
                                title={t('invoices.alreadyPaid')}
                                Icon={TimelapseIcon}
                                amount={paidAmount}
                                isSelected={selected}
                            />
                        )}
                        {!isPartiallyPaid && withFee && !isMobileSize && <FeeInfo />}
                    </Box>

                    {(isPartiallyPaid || isMobileSize) && withFee && (
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <FeeInfo />
                        </Box>
                    )}

                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <InvoiceInfoRow
                            title={t(`invoices.date`, {date: dueDateFormat})}
                            Icon={EventIcon}
                            isSelected={selected}
                        />
                    </Box>
                </Box>
            </Box>

            {isActive && (
                <Box
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                    sx={{
                        backgroundColor: isFullPaid ? '#F6FAF7' : '#EDF4FC',
                        justifyContent: 'space-between',
                        margin: '0 -8px 8px -16px',
                        p: 2,
                        alignSelf: 'stretch',
                    }}
                >
                    <InvoiceAlreadyPaidInfo
                        amount={Number(totalAmount) - Number(totalFeeAmount)}
                        paid={Number(paidAmount)}
                        isFullPaid={isFullPaid}
                    />
                    <Box sx={{
                        display: 'flex',
                        gap: 2,

                    }}
                    >
                        <NumberFormat
                            customInput={TextField}
                            decimalSeparator="."
                            thousandSeparator={false}
                            allowNegative={false}
                            decimalScale={2}
                            error={isValidationError && !allocatedFieldDisabled}
                            disabled={allocatedFieldDisabled}
                            sx={{
                                mb: 1,
                                background: 'white',
                            }}
                            fullWidth
                            size="small"
                            label={t('invoices.alreadyPaidField')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                endAdornment: <EuroIcon sx={{color: 'v2.light.action.active'}} />,
                            }}
                            onValueChange={({value}) => {
                                setPartialPaidValue(value);
                            }}
                            value={partialPaidValue}
                        />
                        <NumberFormat
                            customInput={TextField}
                            decimalSeparator="."
                            thousandSeparator={false}
                            allowNegative={false}
                            decimalScale={2}
                            disabled
                            sx={{
                                mb: 1,
                                background: 'white',
                            }}
                            fullWidth
                            size="small"
                            label={t('invoices.toPay')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                endAdornment: <EuroIcon />,
                            }}
                            value={roundHalfUpToTwoDecimalPlaces(totalRemainingAmount)}
                        />
                    </Box>
                </Box>
            )}

            <ConditionalTooltip
                tooltip={isValidationError ? t('invoices.exceeds') : ''}
                placement="bottom"
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignSelf: 'stretch',
                        alignItems: 'center',
                        gap: 2,
                    }}
                >
                    <LoadingButton
                        loading={isUpdating}
                        disabled={isValidationError || !Number(totalAmount)}
                        loadingPosition="start"
                        startIcon={selected ? <AssignmentTurnedInIcon /> : <LinkIcon />}
                        variant="outlined"
                        size="medium"
                        onClick={handleSelect}
                        sx={{
                            'color': selected ? '#fff' : 'primary_v2.main',
                            'borderRadius': '8px',
                            'borderColor': selected ? '#fff' : 'v2.light.primary.shades50',
                            'lineHeight': '24px',
                            'letterSpacing': '0.4px',
                            'padding': '6px 16px',
                            'fontWeight': '500',
                            'flex': '1 0 0',
                            'mr': selected ? 0 : 1,
                            '&:hover': selected ? {
                                borderColor: '#fff',
                            } : {},
                            '&.Mui-disabled': selected ? {
                                color: 'rgba(255, 255, 255, 0.5)',
                                borderColor: 'rgba(255, 255, 255, 0.4)',
                            } : {},
                        }}
                    >
                        {selected ? t('invoices.selected') : t('invoices.attach')}
                    </LoadingButton>
                    {selected && (
                        <IconButton
                            sx={{
                                p: '4px',
                                color: selected ? '#fff' : 'v2.blueGray.500',
                            }}
                            onClick={handleUnselect}
                            disabled={isUpdating}
                        >
                            <CloseIcon />
                        </IconButton>
                    )}
                </Box>
            </ConditionalTooltip>
        </Paper>
    );
};

Invoice.propTypes = {
    unallocatedAmount: PropTypes.number.isRequired,
    isCategorised: PropTypes.bool.isRequired,
    invoice: PropTypes.object.isRequired,
    isTheBest: PropTypes.bool,
    setActiveInvoice: PropTypes.func.isRequired,
    activeInvoice: PropTypes.string.isRequired,
    matchType: PropTypes.string.isRequired,
    onSelectInvoice: PropTypes.func.isRequired,
    partialPaidValue: PropTypes.number.isRequired,
    setPartialPaidValue: PropTypes.func.isRequired,
};

Invoice.defaultProps = {
    isTheBest: false,
};

export default Invoice;
