import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DateRangeIcon from '@mui/icons-material/DateRange';
import EuroTwoToneIcon from '@mui/icons-material/EuroTwoTone';
import {LoadingButton, LocalizationProvider} from '@mui/lab';
import DatePicker from '@mui/lab/DatePicker';
import {Box, Divider, FormControl, Grid, RadioGroup, Switch, Typography} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import React, {useCallback, useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {formElements} from './constants';
import {BeneficiaryOption} from './CreateSingleTransfer';
import TransferFileSection from './TransferFIleSection';
import {DailyIcon} from '../../../../../../assets/wrapped-svg/daily';
import {MonthlyIcon} from '../../../../../../assets/wrapped-svg/monthly';
import {WeeklyIcon} from '../../../../../../assets/wrapped-svg/weekly';
import ReactRouterButtonLink from '../../../../../../components/app/router/ReactRouterButtonLink';
import ExpandableSidebar from '../../../../../../components/expandable-sidebar/ExpandableSidebar';
import {StandardNumberField} from '../../../../../../components/input/StandardNumberField';
import {RoutePaths} from '../../../../../../lib/router/route-paths';
import Yup from '../../../../../../lib/yup/yup';
import {LoadingSelectors, LoadingTypes} from '../../../../../loading';
import {UiActions} from '../../../../../ui/store/ui.action';
import {ModalsKeys} from '../../../../../ui/utils/constants';
import BankQr from '../../../../components/BankQR';
import {BankSelector} from '../../../../store/bank.selector';
import TransactionCategoryList from '../../../account-balance/components/transaction-details/TransactionCategoryList';
import {
    TransactionDetailsNonSelectedCategory, TransactionDetailsSelectedCategory,
} from '../../../account-balance/components/transaction-details/TransactionDetailsHelpers';
import {TransactionsActions} from '../../../account-balance/store/transactions.action';
import {TransactionsSelector} from '../../../account-balance/store/transactions.selector';
import {getIntegrationInfo} from '../../../bridge-api/utils/bridge-util';
import {getActiveHiwayIntegration} from '../../../rib-and-documents/utils/ribAndDocuments.util';
import {BeneficiariesSelector} from '../../store/beneficiaries.selector';
import PaperWrapper from '../util/PaperWrapper';

export const recurringTransferValues = {
    monthly: 'MONTHLY',
    weekly: 'WEEKLY',
    daily: 'DAILY',
};

const defaultValues = {
    amount: '',
    currency: 'EUR',
    mccKey: '',
    label: '',
    iban: '',
    description: '',
    period: recurringTransferValues.monthly,
    fileList: [],
    firstExecutionDate: moment(),
    lastExecutionDate: '',
    beneficiaryRadio: null,
    shouldSaveBeneficiary: false,
};

const createValidationSchema = (t, beneficiariesList) => Yup.object().shape({
    [formElements.amount]: Yup.number().typeError(t('form:validation.required', {
        fieldName: t('transfer.create.amount'),
    })).positive(t('form:validation/v2.number/positive', {
        label: t('transfer.create.amount'),
    })),
    [formElements.label]: Yup.string()
        .when('beneficiaryRadio', {
            is: null,
            then: Yup.string().label(t('beneficiaries.form.invalidName'))
                .required(),
        }),

    [formElements.iban]: Yup.string()
        .when('beneficiaryRadio', {
            is: null,
            then: Yup.string().iban(t('beneficiaries.form.ibanInvalid'))
                .test(
                    'iban-exists',
                    t('beneficiaries.form.ibanDuplicate'),
                    iban => {
                        const hasIban = beneficiariesList.find(beneficiary => beneficiary.iban === iban);

                        return !hasIban;
                    },
                )
                .required(),
        }),
    // eslint-disable-next-line
    [formElements.description]: Yup.string().matches(/^[A-Za-z0-9\s]+$/, {
        excludeEmptyString: true,
        message: t('transfer.create.descriptionCharacterValidation'),
    }).max(35, t('transfer.create.descriptionMaxLengthValidation'))
        .nullable(),
    [formElements.lastExecutionDate]: Yup.mixed().test('beforeStart',
        t('transfer.create.validationEndDateBeforeStart'),
        (value, other) => {
            if (!value) {
                return true;
            }

            return moment(other?.from?.[0]?.value?.[formElements.firstExecutionDate]).isBefore(value);
        }).nullable(),
});

const CreateRecurringTransfer = () => {
    const dispatch = useDispatch();

    const {t} = useTranslation('bankTransfer');
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const integration = useSelector(BankSelector.selectIntegrations);
    const beneficiariesList = useSelector(BeneficiariesSelector.selectList);
    const categoryList = useSelector(TransactionsSelector.selectCategories);
    const isLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.GENERIC_CRUD_LOADER),
    );

    const hiwayIntegration = getActiveHiwayIntegration(integration);
    const {account: hiwayBankAccount} = getIntegrationInfo(hiwayIntegration);

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isQROpen, setIsQROpen] = useState(false);

    const {
        control,
        handleSubmit: createSubmitHandler,
        formState: {isValid, errors},
        watch,
        trigger,
        setValue,
    } = useForm({
        resolver: yupResolver(createValidationSchema(t, beneficiariesList)),
        defaultValues: defaultValues,
        mode: 'onChange',
    });

    const mccKey = watch(formElements.mccKey);
    const fileList = watch(formElements.fileList);
    const iban = watch(formElements.iban);
    const label = watch(formElements.label);
    const period = watch(formElements.period);
    const shouldSaveBeneficiary = watch(formElements.shouldSaveBeneficiary);
    const beneficiaryRadio = watch(formElements.beneficiaryRadio);

    const handleSubmit = data => {
        dispatch(UiActions.setModalData(ModalsKeys.INSTANT_TRANSFER, data));
        dispatch(UiActions.setActiveModal(ModalsKeys.INSTANT_TRANSFER, true));
    };

    const onOpenCategory = () => {
        setIsSidebarOpen(true);
    };

    const onSelectCategory = value => {
        setValue('mccKey', value?.mccKey);
        setIsSidebarOpen(false);
    };

    const onChangePeriod = value => () => {
        setValue('period', value);
    };

    const setFileList = useCallback(files => setValue(formElements.fileList, files), [setValue]);

    useEffect(() => {
        if (categoryList.length === 0 && integration?.length > 0) {
            dispatch(TransactionsActions.getCategoryList());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [integration, dispatch]);

    useEffect(() => {
        if (beneficiaryRadio) {
            setValue('beneficiaryRadio', null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iban, label, shouldSaveBeneficiary]);

    if (isQROpen && isMobileSize) {
        return (
            <BankQr
                id={undefined}
                bankAccountId={hiwayBankAccount?.id}
                onClose={() => setIsQROpen(false)}
                type="transfer"
            />
        );
    }

    if (isSidebarOpen && isMobileSize) {
        return (
            <TransactionCategoryList
                isMobileSize={isMobileSize}
                onClose={() => setIsSidebarOpen(false)}
                selectedCategory={mccKey}
                setSelectedCategory={onSelectCategory}
            />
        );
    }

    return (
        <>
            <form onSubmit={createSubmitHandler(handleSubmit)}>
                <Box>
                    <Box sx={{
                        display: 'flex',
                        borderRadius: '8px',
                        border: '1px solid',
                        borderColor: 'v2.blueGray.100',
                        mb: isMobileSize ? 0 : 3,
                    }}
                    >
                        <Box
                            onClick={onChangePeriod(recurringTransferValues.monthly)}
                            sx={{
                                flexGrow: 1,
                                cursor: 'pointer',
                                p: 1,
                                display: 'flex',
                                flexDirection: isMobileSize ? 'column' : 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: period === recurringTransferValues.monthly
                                    ? 'primary_v2.main' : 'inherit',
                                color: period !== recurringTransferValues.monthly
                                    ? 'lightTextSecondary.main' : 'white',
                                borderRight: '1px solid',
                                borderColor: 'v2.blueGray.100',
                                borderRadius: '7px 0px 0px 7px',
                            }}
                        >
                            <Box sx={{
                                mr: isMobileSize ? 0 : 1.5,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            >
                                <MonthlyIcon sx={{
                                    width: '25px',
                                    height: '28px',
                                    fill: period !== recurringTransferValues.monthly
                                        ? 'v2.blueGray.500' : 'white',
                                }}
                                />
                            </Box>
                            <Typography sx={{
                                fontWeight: 500,
                                fontSize: theme => theme.typography.pxToRem(isMobileSize ? 12 : 18),
                            }}
                            >
                                {t('transfer.monthly')}
                            </Typography>
                        </Box>
                        <Box
                            onClick={onChangePeriod(recurringTransferValues.weekly)}
                            sx={{
                                flexGrow: 1,
                                cursor: 'pointer',
                                p: 1,
                                display: 'flex',
                                flexDirection: isMobileSize ? 'column' : 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: period === recurringTransferValues.weekly
                                    ? 'primary_v2.main' : 'inherit',
                                color: period !== recurringTransferValues.weekly
                                    ? 'lightTextSecondary.main' : 'white',
                                borderRight: '1px solid',
                                borderColor: 'v2.blueGray.100',
                            }}
                        >
                            <Box sx={{
                                mr: isMobileSize ? 0 : 1.5,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            >
                                <WeeklyIcon sx={{
                                    width: '24px',
                                    height: '28px',
                                    fill: period !== recurringTransferValues.weekly
                                        ? 'v2.blueGray.500' : 'white',
                                }}
                                />
                            </Box>
                            <Typography sx={{
                                fontWeight: 500,
                                fontSize: theme => theme.typography.pxToRem(isMobileSize ? 12 : 18),
                            }}
                            >
                                {t('transfer.weekly')}
                            </Typography>
                        </Box>
                        <Box
                            onClick={onChangePeriod(recurringTransferValues.daily)}
                            sx={{
                                flexGrow: 1,
                                cursor: 'pointer',
                                p: 1,
                                flexDirection: isMobileSize ? 'column' : 'row',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: period === recurringTransferValues.daily
                                    ? 'primary_v2.main' : 'inherit',
                                color: period !== recurringTransferValues.daily
                                    ? 'lightTextSecondary.main' : 'white',
                                borderRadius: '0px 7px 7px 0px',
                            }}
                        >
                            <Box sx={{
                                mr: isMobileSize ? 0 : 1.5,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            >
                                <DailyIcon sx={{
                                    width: '2tpx',
                                    height: '28px',
                                    fill: period !== recurringTransferValues.daily
                                        ? 'v2.blueGray.500' : 'white',
                                }}
                                />
                            </Box>
                            <Typography sx={{
                                fontWeight: 500,
                                fontSize: theme => theme.typography.pxToRem(isMobileSize ? 12 : 18),
                            }}
                            >
                                {t('transfer.daily')}
                            </Typography>
                        </Box>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        width: isSidebarOpen ? 'calc(100% - 400px)' : '100%',
                        transition: 'width 0.4s ease-in-out',
                        flexDirection: isMobileSize ? 'column' : 'row',
                    }}
                    >
                        {isMobileSize && (
                            <PaperWrapper
                                header={t('transfer.create.transferHeader')}
                                elevation={8}
                                sx={{mb: 1, mt: 2}}
                            >
                                <Box sx={{
                                    display: 'flex',
                                    gap: 2,
                                    position: 'relative',
                                    flexDirection: 'column',
                                }}
                                >
                                    <Box sx={{
                                        width: `100%`,
                                    }}
                                    >
                                        <Controller
                                            control={control}
                                            id={formElements.amount}
                                            name={formElements.amount}
                                            render={({field}) => (
                                                <StandardNumberField
                                                    {...field}
                                                    error={!!errors[formElements.amount]}
                                                    helperText={errors[formElements.amount]?.message}
                                                    allowNegative={false}
                                                    decimalScale={2}
                                                    sx={{
                                                        mb: 1,
                                                        background: 'white',
                                                    }}
                                                    fullWidth
                                                    label={t('transfer.create.amount')}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    InputProps={{
                                                        endAdornment: <EuroTwoToneIcon sx={{color: 'v2.light.action.active'}} />,
                                                    }}
                                                />
                                            )}
                                        />
                                    </Box>

                                    <Box sx={{
                                        width: `100%`,
                                    }}
                                    >
                                        <Box sx={{
                                            // position: 'absolute',
                                            // top: '-40px',
                                        }}
                                        >
                                            <Typography sx={{fontWeight: 500, mb: 2}}>
                                                {t('transfer.create.additionalInfoHeader')}
                                            </Typography>
                                        </Box>
                                        <Controller
                                            control={control}
                                            name={formElements.description}
                                            render={({field}) => (
                                                <TextField
                                                    variant="outlined"
                                                    disabled={isLoading}
                                                    error={!!errors[formElements.description]}
                                                    helperText={errors[formElements.description]?.message}
                                                    fullWidth
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Box>
                                </Box>

                                <Divider sx={{borderStyle: 'dotted', mt: 3}} />

                                <Box sx={{
                                    display: 'flex',
                                    gap: 2,
                                    mt: 3,
                                    flexDirection: isMobileSize ? 'column' : 'row',
                                }}
                                >
                                    <Controller
                                        control={control}
                                        name={formElements.firstExecutionDate}
                                        render={({field}) => (
                                            <LocalizationProvider
                                                dateLibInstance={moment}
                                                dateAdapter={AdapterMoment}
                                            >
                                                <DatePicker
                                                    {...field}
                                                    error={!!errors?.[formElements.firstExecutionDate]}
                                                    helperText={errors?.[formElements.firstExecutionDate]?.message}
                                                    openTo="day"
                                                    format={moment.localeData().longDateFormat('L')}
                                                    views={['year', 'month', 'day']}
                                                    disablePast={true}
                                                    InputProps={{
                                                        endAdornment: <DateRangeIcon sx={{color: 'v2.light.action.active'}} />,
                                                    }}
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params}
                                                            fullWidth
                                                            variant="outlined"
                                                            label={t('transfer.create.startDate')}
                                                            error={!!errors?.[formElements.firstExecutionDate]}
                                                            helperText={
                                                                errors?.[formElements.firstExecutionDate]?.message
                                                            }
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        )}
                                    />

                                    <Controller
                                        control={control}
                                        name={formElements.lastExecutionDate}
                                        render={({field}) => (
                                            <LocalizationProvider
                                                dateLibInstance={moment}
                                                dateAdapter={AdapterMoment}
                                            >
                                                <DatePicker
                                                    {...field}
                                                    error={!!errors?.[formElements.lastExecutionDate]}
                                                    helperText={errors?.[formElements.lastExecutionDate]?.message}
                                                    openTo="day"
                                                    format={moment.localeData().longDateFormat('L')}
                                                    views={['year', 'month', 'day']}
                                                    disablePast={true}
                                                    InputProps={{
                                                        endAdornment: <DateRangeIcon sx={{color: 'v2.light.action.active'}} />,
                                                    }}
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params}
                                                            fullWidth
                                                            label={t('transfer.create.endDate')}
                                                            variant="outlined"
                                                            error={!!errors?.[formElements.lastExecutionDate]}
                                                            helperText={
                                                                errors?.[formElements.lastExecutionDate]?.message
                                                            }
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        )}
                                    />
                                </Box>
                            </PaperWrapper>
                        )}

                        <Box sx={{
                            width: isMobileSize ? '100%' : '50%',
                            display: 'flex',
                            flexDirection: 'column',
                            mr: isMobileSize ? 0 : 1.5,
                            mt: isMobileSize ? 2 : 0,
                        }}
                        >
                            <PaperWrapper header={t('transfer.create.newBeneficiary')}>
                                <Box>
                                    <Grid item xs={12} lg={6}>
                                        <Controller
                                            control={control}
                                            name="label"
                                            render={({field}) => (
                                                <TextField
                                                    variant="outlined"
                                                    label={t('beneficiaries.form.label')}
                                                    disabled={isLoading}
                                                    error={!!errors[formElements.label]}
                                                    helperText={errors[formElements.label]?.message}
                                                    fullWidth
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={12} lg={6} sx={{mt: 2}}>
                                        <Controller
                                            control={control}
                                            name="iban"
                                            render={({field}) => (
                                                <TextField
                                                    variant="outlined"
                                                    label={t('beneficiaries.form.iban')}
                                                    disabled={isLoading}
                                                    error={!!errors[formElements.iban]}
                                                    helperText={errors[formElements.iban]?.message}
                                                    fullWidth
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={12} lg={6} sx={{mt: 2}}>
                                        <Controller
                                            control={control}
                                            name={formElements.shouldSaveBeneficiary}
                                            render={({field}) => (
                                                <FormControlLabel
                                                    sx={{m: 0, pt: 1}}
                                                    label={t('transfer.create.save')}
                                                    control={(
                                                        <Switch
                                                            checked={field.value}
                                                            color="primary"
                                                        />
                                                    )}
                                                    onChange={event => {
                                                        field.onChange(event?.target?.checked);
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Box>

                                <Divider
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        borderStyle: 'dotted',
                                    }}
                                >
                                    <Typography sx={{
                                        color: 'v2.light.text.disabled',
                                        textTransform: 'uppercase',
                                        fontSize: theme => theme.typography.pxToRem(12),
                                    }}
                                    >
                                        {t('transfer.create.or')}
                                    </Typography>
                                </Divider>

                                <Box sx={{mb: 2}}>
                                    <Typography sx={{fontWeight: 500, mb: 2}}>
                                        {t('transfer.create.myBeneficiaries')}
                                    </Typography>
                                    <FormControl sx={{
                                        width: '100%',
                                    }}
                                    >
                                        <Controller
                                            control={control}
                                            name="beneficiaryRadio"
                                            render={({field}) => (
                                                <RadioGroup {...field} row>
                                                    {beneficiariesList.map(beneficiary => (
                                                        <BeneficiaryOption
                                                            key={beneficiary.id}
                                                            isMobileSize={isMobileSize}
                                                            beneficiary={beneficiary}
                                                            value={field.value}
                                                            onClick={() => {
                                                                setValue(formElements.label, '');
                                                                setValue(formElements.iban, '');
                                                                setValue('shouldSaveBeneficiary', false);
                                                                setTimeout(() => {
                                                                    field.onChange(beneficiary.id);
                                                                    trigger('label');
                                                                    trigger('iban');
                                                                }, 50);
                                                            }}
                                                        />
                                                    ))}
                                                </RadioGroup>
                                            )}
                                        />
                                    </FormControl>
                                </Box>
                            </PaperWrapper>

                        </Box>
                        <Box sx={{
                            width: isMobileSize ? '100%' : '50%',
                            display: 'flex',
                            flexDirection: 'column',
                            ml: isMobileSize ? 0 : 1.5,
                            mt: isMobileSize ? 2 : 0,
                        }}
                        >
                            {!isMobileSize && (
                            <PaperWrapper
                                header={t('transfer.create.transferHeader')}
                                elevation={8}
                                sx={{mb: 3}}
                            >
                                <Box>
                                    <Box sx={{
                                        display: 'flex',
                                        gap: 3,
                                        position: 'relative',
                                    }}
                                    >
                                        <Box sx={{
                                            width: `calc(50% - 12px)`,
                                        }}
                                        >
                                            <Controller
                                                control={control}
                                                id={formElements.amount}
                                                name={formElements.amount}
                                                render={({field}) => (
                                                    <StandardNumberField
                                                        {...field}
                                                        error={!!errors[formElements.amount]}
                                                        helperText={errors[formElements.amount]?.message}
                                                        allowNegative={false}
                                                        decimalScale={2}
                                                        sx={{
                                                            mb: 1,
                                                            background: 'white',
                                                        }}
                                                        fullWidth
                                                        label={t('transfer.create.amount')}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        InputProps={{
                                                            endAdornment: <EuroTwoToneIcon sx={{color: 'v2.light.action.active'}} />,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Box>

                                        <Box sx={{
                                            width: `calc(50% - 12px)`,
                                        }}
                                        >
                                            <Box sx={{
                                                position: 'absolute',
                                                top: '-40px',
                                            }}
                                            >
                                                <Typography sx={{fontWeight: 500, mb: 2}}>
                                                    {t('transfer.create.additionalInfoHeader')}
                                                </Typography>
                                            </Box>
                                            <Controller
                                                control={control}
                                                name={formElements.description}
                                                render={({field}) => (
                                                    <TextField
                                                        variant="outlined"
                                                        disabled={isLoading}
                                                        error={!!errors[formElements.description]}
                                                        helperText={errors[formElements.description]?.message}
                                                        fullWidth
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </Box>
                                    </Box>

                                    <Divider sx={{borderStyle: 'dotted', mt: 3}} />

                                    <Box sx={{
                                        display: 'flex',
                                        gap: 2,
                                        mt: 3,
                                        flexDirection: isMobileSize ? 'column' : 'row',
                                    }}
                                    >
                                        <Controller
                                            control={control}
                                            name={formElements.firstExecutionDate}
                                            render={({field}) => (
                                                <LocalizationProvider
                                                    dateLibInstance={moment}
                                                    dateAdapter={AdapterMoment}
                                                >
                                                    <DatePicker
                                                        {...field}
                                                        error={!!errors?.[formElements.firstExecutionDate]}
                                                        helperText={errors?.[formElements.firstExecutionDate]?.message}
                                                        openTo="day"
                                                        format={moment.localeData().longDateFormat('L')}
                                                        views={['year', 'month', 'day']}
                                                        disablePast={true}
                                                        InputProps={{
                                                            endAdornment: <DateRangeIcon sx={{color: 'v2.light.action.active'}} />,
                                                        }}
                                                        renderInput={params => (
                                                            <TextField
                                                                {...params}
                                                                fullWidth
                                                                variant="outlined"
                                                                label={t('transfer.create.startDate')}
                                                                error={!!errors?.[formElements.firstExecutionDate]}
                                                                helperText={
                                                                    errors?.[formElements.firstExecutionDate]?.message
                                                                }
                                                            />
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                            )}
                                        />

                                        <Controller
                                            control={control}
                                            name={formElements.lastExecutionDate}
                                            render={({field}) => (
                                                <LocalizationProvider
                                                    dateLibInstance={moment}
                                                    dateAdapter={AdapterMoment}
                                                >
                                                    <DatePicker
                                                        {...field}
                                                        error={!!errors?.[formElements.lastExecutionDate]}
                                                        helperText={errors?.[formElements.lastExecutionDate]?.message}
                                                        openTo="day"
                                                        format={moment.localeData().longDateFormat('L')}
                                                        views={['year', 'month', 'day']}
                                                        disablePast={true}
                                                        InputProps={{
                                                            endAdornment: <DateRangeIcon sx={{color: 'v2.light.action.active'}} />,
                                                        }}
                                                        renderInput={params => (
                                                            <TextField
                                                                {...params}
                                                                fullWidth
                                                                label={t('transfer.create.endDate')}
                                                                variant="outlined"
                                                                error={!!errors?.[formElements.lastExecutionDate]}
                                                                helperText={
                                                                    errors?.[formElements.lastExecutionDate]?.message
                                                                }
                                                            />
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                            )}
                                        />
                                    </Box>
                                </Box>
                            </PaperWrapper>
                            )}

                            <PaperWrapper sx={{mb: 3}} header={t('transfer.create.categoryHeader')}>
                                {!mccKey
                                    && (
                                        <TransactionDetailsNonSelectedCategory
                                            onOpenCategory={onOpenCategory}
                                            suggestedCategory={undefined}
                                            onSelectCategory={onSelectCategory}
                                            isEditable={true}
                                        />
                                    )}

                                {mccKey
                                    && (
                                        <TransactionDetailsSelectedCategory
                                            onOpenCategory={onOpenCategory}
                                            selectedCategory={mccKey}
                                            isEditable={true}
                                        />
                                    )
                                }
                            </PaperWrapper>

                            <PaperWrapper sx={{mb: 2}} header={t('transfer.create.documentHeader')}>
                                <TransferFileSection
                                    fileList={fileList}
                                    setFileList={setFileList}
                                    details={{}}
                                    isEditable={true}
                                    setIsQROpen={setIsQROpen}
                                />
                            </PaperWrapper>
                        </Box>
                        {!isMobileSize && (
                            <ExpandableSidebar
                                isOpen={!!isSidebarOpen}
                                onClose={() => setIsSidebarOpen(false)}
                                isMobileSize={isMobileSize}
                                mt={-20}
                            >
                                <TransactionCategoryList
                                    isMobileSize={isMobileSize}
                                    onClose={() => setIsSidebarOpen(false)}
                                    selectedCategory={mccKey}
                                    setSelectedCategory={onSelectCategory}
                                />
                            </ExpandableSidebar>
                        )}

                        {!isMobileSize && isQROpen && (
                            <ExpandableSidebar
                                isOpen={true}
                                onClose={() => setIsQROpen(false)}
                                isMobileSize={isMobileSize}
                                mt={-20}
                            >
                                <BankQr
                                    id={undefined}
                                    bankAccountId={hiwayBankAccount?.id}
                                    onClose={() => setIsQROpen(false)}
                                    type="transfer"
                                />
                            </ExpandableSidebar>
                        )}
                    </Box>

                    <Box>
                        <Box sx={{
                            mt: 3,
                            display: 'flex',
                            justifyContent: isMobileSize ? 'normal' : 'space-around',
                        }}
                        >
                            <Box sx={{
                                display: 'flex',
                                flexDirection: isMobileSize ? 'column-reverse' : 'row',
                                width: isMobileSize ? '100%' : 'inherit',
                                gap: 2,
                            }}
                            >
                                <ReactRouterButtonLink
                                    to={RoutePaths.BANK_TRANSFER_LIST}
                                    fullWidth={isMobileSize}
                                    variant="outlined"
                                    size="large"
                                    startIcon={<ArrowBackIcon />}
                                    disabled={isLoading}
                                >
                                    {t('transfer.create.back')}
                                </ReactRouterButtonLink>
                                <LoadingButton
                                    variant="contained"
                                    fullWidth={isMobileSize}
                                    type="submit"
                                    color="secondary"
                                    size="large"
                                    endIcon={<ArrowForwardIcon />}
                                    disabled={isLoading || !isValid}
                                    loading={isLoading}
                                    loadingPosition="end"
                                >
                                    {t('transfer.create.submit')}
                                </LoadingButton>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </form>
        </>
    );
};

export default CreateRecurringTransfer;
