import React from 'react';
import {useDispatch} from 'react-redux';
import {FinalScreen} from '../../../../freelancer/modules/finalization/components/FinalScreen';
import {MainOnboardingActions} from '../../../store/main-onboarding.actions';

const Launch = () => {
    const dispatch = useDispatch();

    const onConfirmClick = () => {
        dispatch(MainOnboardingActions.postLaunchOnboarding());
    };

    return (
        <FinalScreen
            onConfirmClick={onConfirmClick}
            isUmbrella={true}
        />
    );
};

export default Launch;
