import {Skeleton, Stepper} from '@mui/material';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';

const UmbrellaLoadingSteps = ({
    onboardingStepsArray,
    isSidebarExpanded,
}) => {
    return (
        <Box sx={{
            px: isSidebarExpanded ? 3 : 3.45,
            pt: 3,
            mt: isSidebarExpanded ? 0 : -1,
        }}
        >
            <Stepper orientation="vertical">
                {onboardingStepsArray.map((step, index) => {
                    return (
                        <Box
                            key={index}
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                flexDirection: 'column',
                            }}
                        >
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                py: 1,
                            }}
                            >
                                <Skeleton variant="circular" width={24} height={24} />
                            </Box>
                            {onboardingStepsArray.length !== index + 1 && (
                                <Skeleton
                                    sx={{
                                        ml: !isSidebarExpanded ? '12px' : '11px',
                                    }}
                                    variant="rectangular"
                                    width={2}
                                    height={24}
                                />
                            )}
                        </Box>
                    );
                })}
            </Stepper>
        </Box>
    );
};

UmbrellaLoadingSteps.propTypes = {
    onboardingStepsArray: PropTypes.array.isRequired,
    isSidebarExpanded: PropTypes.bool.isRequired,
};

export default UmbrellaLoadingSteps;
