import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import LegalPortageADP from '../../../../../v1/components/legal-pages/LegalPageADP/LegalPortageADP';
import {AnimationActions} from '../../../../animations/store/animations.action';
import {MainOnboardingActions} from '../../../store/main-onboarding.actions';
import {UmbrellaStepperOnboardingActions} from '../../../store/umbrella/umbrella-onboarding.actions';
import LegalDocumentsWrapper from '../../general/LegalDocumentsWrapper';

// TODO Add correct legal screen for umbrella after it is merged
// TODO Add translation
const AcceptDP = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation('freelancerOnboarding');

    const onConfirm = () => {
        dispatch(MainOnboardingActions.putSignADP());
    };

    const onCancel = () => {
        dispatch(AnimationActions.setIsAnimationActive(false));
        dispatch(AnimationActions.storeNextDispatch(
            UmbrellaStepperOnboardingActions.refuseSigning(),
        ));
    };

    return (
        <LegalDocumentsWrapper
            title={t('umbrella.adpTitle')}
            onCancel={onCancel}
            onConfirm={onConfirm}
        >
            <LegalPortageADP isHeaderShown={false} />
        </LegalDocumentsWrapper>
    );
};

export default AcceptDP;
