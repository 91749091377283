import {all, call, fork, put, select, takeLatest} from 'redux-saga/effects';

import {getAssignedFreelancers} from './assignedFreelancers/assignedFreelancers.actions';
import {watchAssignedFreelancersSagas} from './assignedFreelancers/assignedFreelancers.sagas';
import {watchAssignFreelancersFormSagas} from './assignFreelancersForm/assignFreelancersForm.sagas';
import {getAvailableFreelancers} from './availableFreelancers/availableFreelancers.actions';
import {watchAvailableFreelancersSaga} from './availableFreelancers/availableFreelancers.sagas';
import {getCoachingInformationForm} from './coachingInformation/coachingInformation.actions';
import {
    watchCoachingInformationSagas,
} from './coachingInformation/coachingInformation.sagas';
import * as actions from './user.actions';
import {setIsLoadingUser} from './user.actions';
import {USER_TABS} from './user.constants';
import {selectCoachAccounts, selectLoggedInUser, selectUser} from './user.selectors';
import {getConfig} from '../../../config/get-config';
import {COMPANY_TYPES} from '../../../consts/company-constants';
import {selectCurrentCognitoUser} from '../../../features/auth/store/auth.selectors';
import {updateCompaniesFlow} from '../../../features/backoffice/store/backoffice.saga';
import {loadFreelancerCompanies} from '../../../features/freelancer/modules/companies/store/companies.saga';
import {SidebarSelectors} from '../../../features/sidebar/store/sidebar.selector';
import {
    activateUserRequest,
    deactivateUserRequest,
    getLoggedInUserRequest,
    getUserRequest, reinviteUserRequest,
    updateCanSetupCompanyRequest, updateCanSetupHoldingCompanyRequest,
    updateLoggedInUserRequest,
    updateUserRequest,
} from '../../../features/user-list/api/user.api';
import * as userListActions from '../../../features/user-list/store/userList.actions';
import {watchUserListSagas} from '../../../features/user-list/store/userList.sagas';
import {LoggedInUserActions} from '../../../features/user/modules/logged-in-user';
import {push} from '../../../lib/router/connected-router-saga';
import {resolveRoute} from '../../../lib/router/resolveRoute';
import {ROUTE_PATHS} from '../../../lib/router/route-paths';
import {Toast} from '../../../lib/toast';
import {USER_ROLES, isUserClientFn} from '../../../utils/user-roles';
import {getCoaches} from '../company/companyList/companyList.actions';
import {getContractData} from '../freelancer/contract/contract.actions';
import {getFreelancerAccount} from '../freelancer/freelancer.actions';

export const getUserSaga = function* ({payload: userId}) {
    try {
        yield put(actions.setIsLoadingUser(true));

        const user = yield call(getUserRequest, userId);

        yield put(actions.storeUser(user));
    } catch (error) {
        // TODO:LOW Better error handling.
        // eslint-disable-next-line no-console
        console.error(error);

        if (error?.response?.status === 403) {
            // TODO:HIGH Redirect to 403, not to dashboard...
            yield put(push(getConfig().ROUTE_PATHS.DASHBOARD));

            return;
        }

        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsLoadingUser(false));
    }
};

const activateUserSaga = function* ({payload: userId}) {
    try {
        yield put(actions.setIsUpdatingUserActiveStatus(true));

        yield call(activateUserRequest, userId);

        // TODO:LOW Remove this once API has the functionality to return the updated user.
        const user = yield select(selectUser);

        yield put(actions.storeUser({
            ...user,
            active: true,
        }));
    } catch (error) {
        // TODO:LOW Better error handling.
        // eslint-disable-next-line no-console
        console.error(error);

        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsUpdatingUserActiveStatus(false));
    }
};

const deactivateUserSaga = function* ({payload: userId}) {
    try {
        yield put(actions.setIsUpdatingUserActiveStatus(true));

        yield call(deactivateUserRequest, userId);

        // TODO:LOW Remove this once API has the functionality to return the updated user.
        const user = yield select(selectUser);

        yield put(actions.storeUser({
            ...user,
            active: false,
        }));
    } catch (error) {
        // TODO:LOW Better error handling.
        // eslint-disable-next-line no-console
        console.error(error);

        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsUpdatingUserActiveStatus(false));
    }
};

const reinviteUserSaga = function* ({payload: userId}) {
    try {
        yield put(actions.setIsUpdatingUser(true));

        yield call(reinviteUserRequest, userId);

        Toast.success('userReinvited');
    } catch (error) {
        if (error?.response?.data?.error_type === 'HTTP_CONFLICT_ERROR') {
            Toast.error('lessThanTwoMinutesError');

            return;
        }

        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsUpdatingUser(false));
    }
};

const updateUserSaga = function* ({payload}) {
    try {
        yield put(actions.setIsUpdatingUser(true));

        const {userId, user, propagateAddressChange} = payload;

        yield call(updateUserRequest, userId, user, propagateAddressChange);

        // TODO:LOW Remove this once API has the functionality to return the updated user.
        const oldUser = yield select(selectUser);

        yield put(actions.storeUser({
            ...oldUser,
            ...user,
        }));

        yield put(push(resolveRoute(ROUTE_PATHS.USER, {
            userId: userId,
            tab: USER_TABS.ACCOUNT.value,
        })));
    } catch (error) {
        // TODO:LOW Better error handling.
        // eslint-disable-next-line no-console
        console.error(error);

        if (error?.response?.data?.error_type === 'HTTP_CONFLICT_ERROR') {
            Toast.error('userExists');

            return;
        }

        if (error?.response?.data?.error) {
            Toast.error(error?.response?.data?.error);

            return;
        }

        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsUpdatingUser(false));
    }
};

const enableCanSetupCompanySaga = function* ({payload}) {
    try {
        yield put(actions.setAdminMustEnterDataBeforeSetupCompany(false));
        yield put(actions.setIsUpdatingCanSetupCompany(true));

        const {data, companyType} = payload;
        const {
            activityStartDate,
            userId,
            legalStatus,
            taxSystem,
            useCustomCompanyAddress,
            registrationType,
        } = data;

        const requestFunc = companyType === COMPANY_TYPES.HOLDING
            ? updateCanSetupHoldingCompanyRequest
            : updateCanSetupCompanyRequest;

        yield call(requestFunc,
            userId,
            activityStartDate,
            legalStatus,
            taxSystem,
            useCustomCompanyAddress,
            registrationType);

        Toast.success('userInvitationSent');

        yield call(updateCompaniesFlow);
    } catch (error) {
        // TODO:LOW Better error handling.
        // eslint-disable-next-line no-console
        console.error(error);
        if (error?.response?.data?.error_type === 'HTTP_CONFLICT_ERROR') {
            yield put(actions.setAdminMustEnterDataBeforeSetupCompany(true));
            return;
        }
        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsUpdatingCanSetupCompany(false));
    }
};

export const getLoggedInUserSaga = function* () {
    try {
        yield put(actions.setIsLoadingLoggedInUser(true));

        const data = yield call(getLoggedInUserRequest);

        yield put(actions.storeLoggedInUser(data?.users));

        yield put(LoggedInUserActions.storeLoggedInUserAccountForDashboard(data?.loggedInUsers));
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsLoadingLoggedInUser(false));
    }
};

const updateLoggedInUserSaga = function* ({payload: user}) {
    try {
        yield put(actions.setIsUpdatingLoggedInUser(true));

        yield call(updateLoggedInUserRequest, user);

        const oldUser = yield select(selectLoggedInUser);

        yield put(actions.storeLoggedInUser({
            ...oldUser,
            ...user,
        }));

        yield put(push(getConfig().ROUTE_PATHS.MY_PROFILE));

        Toast.success('profileUpdated');
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsUpdatingLoggedInUser(false));
    }
};

export const usersLoaderSagas = function* usersLoaderSagas() {
    const coaches = yield select(selectCoachAccounts);
    if (coaches?.length === 0) {
        yield put(getCoaches());
    }

    const sidebarText = yield select(SidebarSelectors.selectSearchText);

    // If it is from global search we don't to run this
    if (!sidebarText) {
        yield put(userListActions.getUsers());
    }
};

export const userLoaderSaga = function* (action) {
    const {userId, tab} = action.payload.params;

    if (!userId) {
        return;
    }

    const currentCognitoUser = yield select(selectCurrentCognitoUser);

    let user = yield select(selectUser);

    if (!user || user.id !== userId) {
        yield call(getUserSaga, {
            payload: userId,
        });

        user = yield select(selectUser);
    }

    if (userId && isUserClientFn(user)) {
        yield put(getFreelancerAccount(userId));

        // yield put(getFreelancerCompanies(userId));
        yield call(loadFreelancerCompanies, {freelancerId: userId});
    }

    if (!tab || tab === 'account') {
        if (isUserClientFn(user)) {
            yield put(getContractData(userId));
        }

        return;
    }

    if (tab === 'managed-freelancers') {
        if (currentCognitoUser.role !== USER_ROLES.ADMINISTRATOR) {
            yield put(push(resolveRoute(ROUTE_PATHS.USER, {
                userId: userId,
            })));

            return;
        }

        yield put(getAssignedFreelancers(userId));
        yield put(getAvailableFreelancers(userId));
    }

    if (tab === 'coaching') {
        yield put(getCoachingInformationForm(userId));
    }

    yield put(setIsLoadingUser(false));
};

export const myProfileLoaderSaga = function* () {
    const userAccount = yield select(selectLoggedInUser);

    if (userAccount) {
        return;
    }

    yield put(actions.getLoggedInUser());
};

export const watchUserSagas = function* watchUserSagas() {
    yield all([
        fork(watchUserListSagas),
        fork(watchAssignedFreelancersSagas),
        fork(watchAvailableFreelancersSaga),
        fork(watchAssignFreelancersFormSagas),
        // fork(watchChangePasswordFormSagas),
        fork(watchCoachingInformationSagas),
        takeLatest(actions.GET_USER, getUserSaga),
        takeLatest(actions.ACTIVATE_USER, activateUserSaga),
        takeLatest(actions.DEACTIVATE_USER, deactivateUserSaga),
        takeLatest(actions.REINVITE_USER, reinviteUserSaga),
        takeLatest(actions.UPDATE_USER, updateUserSaga),
        takeLatest(actions.ENABLE_CAN_SETUP_COMPANY, enableCanSetupCompanySaga),
        takeLatest(actions.GET_LOGGED_IN_USER, getLoggedInUserSaga),
        takeLatest(actions.UPDATE_LOGGED_IN_USER, updateLoggedInUserSaga),
    ]);
};
