/* eslint-disable react/prop-types */
import composeClasses from '@mui/base/composeClasses';
import {Box, Typography} from '@mui/joy';
import {styled, useTheme} from '@mui/styles';
import {useThemeProps} from '@mui/system';
import clsx from 'clsx';
import * as React from 'react';
import {getDayPickerUtilityClass} from './dayPickerClasses';
import {PickersSlideTransition} from './PickersSlideTransition';
import {DAY_MARGIN, DAY_SIZE} from '../internals/constants/dimensions';
import {useNow, useUtils} from '../internals/hooks/useUtils';
import {useIsDayDisabled} from '../internals/hooks/validation/useDateValidation';
import {findClosestEnabledDate} from '../internals/utils/date-utils';
import {PickersDay} from '../PickersDay/PickersDay';

const useUtilityClasses = ownerState => {
    const {classes} = ownerState;
    const slots = {
        slideTransition: ['slideTransition'],
    };

    return composeClasses(slots, getDayPickerUtilityClass, classes);
};

const defaultDayOfWeekFormatter = day => day.charAt(0).toUpperCase();

const weeksContainerHeight = (DAY_SIZE + DAY_MARGIN * 2) * 6;

const PickersCalendarSlideTransition = styled(PickersSlideTransition, {
    name: 'MuiDayPicker',
    slot: 'SlideTransition',
    overridesResolver: (_, styles) => styles.slideTransition,
})({
    minHeight: weeksContainerHeight,
});

export const DayPicker = inProps => {
    const now = useNow();
    const utils = useUtils();
    const props = useThemeProps({props: inProps, name: 'MuiDayPicker'});
    const classes = useUtilityClasses(props);
    const {
        onFocusedDayChange,
        className,
        currentMonth,
        selectedDays,
        disabled,
        disableHighlightToday,
        focusedDay,
        isMonthSwitchingAnimating,
        loading,
        onSelectedDaysChange,
        onMonthSwitchingAnimationEnd,
        readOnly,
        reduceAnimations,
        renderDay,
        renderLoading = () => <span data-mui-test="loading-progress">...</span>,
        showDaysOutsideCurrentMonth,
        slideDirection,
        TransitionProps,
        disablePast,
        disableFuture,
        minDate,
        maxDate,
        shouldDisableDate,
        dayOfWeekFormatter = defaultDayOfWeekFormatter,
        hasFocus,
        onFocusedViewChange,
        gridLabelId,
    } = props;

    const isDateDisabled = useIsDayDisabled({
        shouldDisableDate,
        minDate,
        maxDate,
        disablePast,
        disableFuture,
    });

    const [internalFocusedDay, setInternalFocusedDay] = React.useState(
        () => focusedDay || now,
    );

    const changeHasFocus = React.useCallback(
        newHasFocus => {
            if (onFocusedViewChange) {
                onFocusedViewChange(newHasFocus);
            }
        },
        [onFocusedViewChange],
    );

    const handleDaySelect = React.useCallback(
        (day, isFinish = 'finish') => {
            if (readOnly) {
                return;
            }

            onSelectedDaysChange(day, isFinish);
        },
        [onSelectedDaysChange, readOnly],
    );

    const focusDay = React.useCallback(
        day => {
            if (!isDateDisabled(day)) {
                onFocusedDayChange(day);
                setInternalFocusedDay(day);
                changeHasFocus(true);
            }
        },
        [isDateDisabled, onFocusedDayChange, changeHasFocus],
    );

    const theme = useTheme();

    const handleKeyDown = (event, day) => {
        switch (event.key) {
            case 'ArrowUp':
                focusDay(utils.addDays(day, -7));
                event.preventDefault();
                break;
            case 'ArrowDown':
                focusDay(utils.addDays(day, 7));
                event.preventDefault();
                break;
            case 'ArrowLeft': {
                const newFocusedDayDefault = utils.addDays(
                    day,
                    theme.direction === 'ltr' ? -1 : 1,
                );
                const nextAvailableMonth = theme.direction === 'ltr'
                    ? utils.getPreviousMonth(day)
                    : utils.getNextMonth(day);

                const closestDayToFocus = findClosestEnabledDate({
                    utils,
                    date: newFocusedDayDefault,
                    minDate: theme.direction === 'ltr'
                        ? utils.startOfMonth(nextAvailableMonth)
                        : newFocusedDayDefault,
                    maxDate: theme.direction === 'ltr'
                        ? newFocusedDayDefault
                        : utils.endOfMonth(nextAvailableMonth),
                    isDateDisabled,
                });
                focusDay(closestDayToFocus || newFocusedDayDefault);
                event.preventDefault();
                break;
            }
            case 'ArrowRight': {
                const newFocusedDayDefault = utils.addDays(
                    day,
                    theme.direction === 'ltr' ? 1 : -1,
                );
                const nextAvailableMonth = theme.direction === 'ltr'
                    ? utils.getNextMonth(day)
                    : utils.getPreviousMonth(day);

                const closestDayToFocus = findClosestEnabledDate({
                    utils,
                    date: newFocusedDayDefault,
                    minDate: theme.direction === 'ltr'
                        ? newFocusedDayDefault
                        : utils.startOfMonth(nextAvailableMonth),
                    maxDate: theme.direction === 'ltr'
                        ? utils.endOfMonth(nextAvailableMonth)
                        : newFocusedDayDefault,
                    isDateDisabled,
                });
                focusDay(closestDayToFocus || newFocusedDayDefault);
                event.preventDefault();
                break;
            }
            case 'Home':
                focusDay(utils.startOfWeek(day));
                event.preventDefault();
                break;
            case 'End':
                focusDay(utils.endOfWeek(day));
                event.preventDefault();
                break;
            case 'PageUp':
                focusDay(utils.getNextMonth(day));
                event.preventDefault();
                break;
            case 'PageDown':
                focusDay(utils.getPreviousMonth(day));
                event.preventDefault();
                break;
            default:
                break;
        }
    };

    const handleFocus = (_, day) => {
        focusDay(day);
    };

    const handleBlur = (_, day) => {
        if (hasFocus && utils.isSameDay(internalFocusedDay, day)) {
            changeHasFocus(false);
        }
    };

    const currentMonthNumber = utils.getMonth(currentMonth);
    const validSelectedDays = selectedDays
        .filter(day => !!day)
        .map(day => utils.startOfDay(day));

    // need a new ref whenever the `key` of the transition changes: http://reactcommunity.org/react-transition-group/transition/#Transition-prop-nodeRef.
    const transitionKey = currentMonthNumber;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const slideNodeRef = React.useMemo(() => React.createRef(), [transitionKey]);
    const startOfCurrentWeek = utils.startOfWeek(now);

    const focusableDay = React.useMemo(() => {
        const startOfMonth = utils.startOfMonth(currentMonth);
        const endOfMonth = utils.endOfMonth(currentMonth);
        if (
            isDateDisabled(internalFocusedDay)
                || utils.isAfterDay(internalFocusedDay, endOfMonth)
                || utils.isBeforeDay(internalFocusedDay, startOfMonth)
        ) {
            return findClosestEnabledDate({
                utils,
                date: internalFocusedDay,
                minDate: startOfMonth,
                maxDate: endOfMonth,
                disablePast,
                disableFuture,
                isDateDisabled,
            });
        }
        return internalFocusedDay;
    }, [
        currentMonth,
        disableFuture,
        disablePast,
        internalFocusedDay,
        isDateDisabled,
        utils,
    ]);

    return (
        <div role="grid" aria-labelledby={gridLabelId}>
            <Box
                role="row"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                className="JoyDatePickerWeekDays"
            >
                {utils.getWeekdays().map((day, i) => (
                    <Typography
                        key={day + i.toString()}
                        level="body-sm"
                        textColor="text.tertiary"
                        textAlign="center"
                        role="columnheader"
                        aria-label={utils.format(
                            utils.addDays(startOfCurrentWeek, i),
                            'weekday',
                        )}
                        sx={{
                            width: 36,
                            height: 40,
                            margin: '0 2px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        className="JoyDatePickerWeekDay"
                    >
                        {dayOfWeekFormatter?.(day) ?? day}
                    </Typography>
                ))}
            </Box>

            {loading ? (
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: weeksContainerHeight,
                }}
                >
                    {renderLoading()}
                </Box>
            ) : (
                <PickersCalendarSlideTransition
                    transKey={transitionKey}
                    onExited={onMonthSwitchingAnimationEnd}
                    reduceAnimations={reduceAnimations}
                    slideDirection={slideDirection}
                    className={clsx(className, classes.slideTransition)}
                    {...TransitionProps}
                    nodeRef={slideNodeRef}
                >
                    <Box
                        data-mui-test="pickers-calendar"
                        ref={slideNodeRef}
                        role="rowgroup"
                        sx={{
                            overflow: 'hidden',
                        }}
                    >
                        {utils.getWeekArray(currentMonth).map(week => (
                            <Box
                                className="JoyDatePickerWeek"
                                role="row"
                                key={`week-${week[0]}`}
                                sx={{
                                    margin: `${DAY_MARGIN}px 0`,
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                {week.map(day => {
                                    const isFocusableDay = focusableDay !== null && utils.isSameDay(day, focusableDay);
                                    const isSelected = validSelectedDays
                                        .some(selectedDay => utils.isSameDay(selectedDay, day));
                                    const isToday = utils.isSameDay(day, now);
                                    const pickersDayProps = {
                                        'key': day?.toString(),
                                        day,
                                        'isAnimating': isMonthSwitchingAnimating,
                                        'disabled': disabled || isDateDisabled(day),
                                        'autoFocus': hasFocus && isFocusableDay,
                                        'today': isToday,
                                        'outsideCurrentMonth': utils.getMonth(day) !== currentMonthNumber,
                                        'selected': isSelected,
                                        disableHighlightToday,
                                        showDaysOutsideCurrentMonth,
                                        'onKeyDown': handleKeyDown,
                                        'onFocus': handleFocus,
                                        'onBlur': handleBlur,
                                        'onDaySelect': handleDaySelect,
                                        'tabIndex': isFocusableDay ? 0 : -1,
                                        'role': 'gridcell',
                                        'aria-selected': isSelected,
                                    };
                                    if (isToday) {
                                        pickersDayProps['aria-current'] = 'date';
                                    }

                                    return renderDay ? (
                                        renderDay(day, validSelectedDays, pickersDayProps)
                                    ) : (
                                        <PickersDay
                                            {...pickersDayProps}
                                            key={pickersDayProps.key}
                                        />
                                    );
                                })}
                            </Box>
                        ))}
                    </Box>
                </PickersCalendarSlideTransition>
            )}
        </div>
    );
};
