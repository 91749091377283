import {FormLabel, Grid, Grow, TextField} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {Controller} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {
    usePersonalInformationFormStyles,
} from '../../../../../../v1/components/setup-company/PersonalInformationForm/styles';
import {AnimationsSelectors} from '../../../../../animations/store/animations.selector';
import {FORM_ANIMATION_DELAY} from '../../../../../animations/utils/constants';

const PersonalInformationSsn = ({t, control, errors}) => {
    const styles = usePersonalInformationFormStyles();
    const isSubAnimationActive = useSelector(AnimationsSelectors.selectIsSubAnimationActive);

    return (
        <>
            <FormLabel component="legend" className={styles.formLabel}>
                {t('socialSecurityNumber')}
            </FormLabel>

            <Grow
                in={isSubAnimationActive}
                timeout={FORM_ANIMATION_DELAY * 6}
            >
                <Grid container spacing={4}>
                    <Grid item xs={12} md={7}>
                        <Controller
                            name="socialSecurityNumber"
                            control={control}
                            rules={{required: true}}
                            render={({field}) => {
                                return (
                                    <TextField
                                        {...field}
                                        label={t('socialSecurityNumber')}
                                        variant="filled"
                                        error={!!errors.socialSecurityNumber}
                                        helperText={errors.socialSecurityNumber?.message}
                                        required
                                        fullWidth
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>
            </Grow>
        </>
    );
};

PersonalInformationSsn.propTypes = {
    t: PropTypes.func.isRequired,
    control: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

export default PersonalInformationSsn;
