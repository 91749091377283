import {put, select} from 'redux-saga/effects';
import {VATDeclarationActions} from './vat-declaration.action';
import {VatDeclarationSelectors as VATDeclarationSelectors, VatDeclarationSelectors} from './vat-declaration.selector';
import hiwayLocalStorage from '../../../config/storage';
import {selectRouterLocation} from '../../../lib/router/connected-router-saga';
import {RoutePaths} from '../../../lib/router/route-paths';
import {Toast} from '../../../lib/toast';
import {Debug} from '../../../utils/debug';
import {VAT_LOCAL_STORAGE_KEY} from '../../formalities/modules/vat-declaration/utils/consts';
import {LoadingActions, LoadingTypes} from '../../loading';
import {UiActions} from '../../ui/store/ui.action';
import {ModalsKeys} from '../../ui/utils/constants';


const handleSSEBatchSubmissionUpdate = function* ({data: sseData}) {
    try {
        const {data} = JSON.parse(sseData);
        const {pendingEventId} = data;
        yield put(VATDeclarationActions.storeBatchProgress(data));

        // Write new batchId
        const {initialisedBatchId} = hiwayLocalStorage.get(VAT_LOCAL_STORAGE_KEY) ?? {};
        hiwayLocalStorage.set(VAT_LOCAL_STORAGE_KEY, {
            batchId: pendingEventId,
            initialisedBatchId,
        });

        // For multiple declarations we're doing async processing on the UI
        // so we can terminate the progress flag right away.
        const isBatchSentByUser = initialisedBatchId === pendingEventId;
        const totalProcessed = yield select(VatDeclarationSelectors.selectProcessedDeclarationsCount);

        if (totalProcessed > 1 && isBatchSentByUser) {
            yield put(LoadingActions.setLoading(LoadingTypes.VAT_DECLARATIONS_SEND_IN_PROGRESS, false));
            yield put(UiActions.setActiveModal(ModalsKeys.SEND_VAT_DECLARATIONS, false));
        }
    } catch (e) {
        Debug.error('VAT handleSSEBatchSubmissionUpdate', 'Error: ', {e});
        Toast.error('genericError');
        yield put(LoadingActions.setLoading(LoadingTypes.VAT_DECLARATIONS_SEND_IN_PROGRESS, false));
    }
};

const handleSSEBatchSubmissionCompleted = function* ({data: sseData}) {
    try {
        const location = yield select(selectRouterLocation);
        const {data} = JSON.parse(sseData);
        const {pendingEventId} = data;
        yield put(VATDeclarationActions.storeBatchProgress(data));

        // If the response is for a declaration that was re-sent:
        const currentDeclaration = yield select(VatDeclarationSelectors.selectVATDeclaration);
        const declarationId = currentDeclaration?.id;

        if (data.batchSize === 1 && data.declarationIds[0] === declarationId) {
            yield put(VATDeclarationActions.declarationResentSSE({declarationId}));
        }

        // Processing done for single declaration
        const {initialisedBatchId} = hiwayLocalStorage.get(VAT_LOCAL_STORAGE_KEY) ?? {};
        const isBatchSentByUser = initialisedBatchId === pendingEventId;

        if (isBatchSentByUser && location.pathname === RoutePaths.VAT_DECLARATION) {
            yield put(VATDeclarationActions.getDeclarations());
            if (data.batchSize === 1) {
                yield put(UiActions.setActiveModal(ModalsKeys.PROCESS_SINGLE_VAT_DECLARATION, true));
                yield put(UiActions.setActiveModal(ModalsKeys.SEND_VAT_DECLARATIONS, false));
            }
        }
    } catch (e) {
        Debug.error('VAT handleSSEBatchSubmissionCompleted ', 'Error: ', {e});
        Toast.error('genericError');
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.VAT_DECLARATIONS_SEND_IN_PROGRESS, false));
    }
};

const handleSSEDocumentUpload = function* ({data}) {
    try {
        const declarationId = JSON.parse(data).data.declaration_id;
        const currentDeclaration = yield select(VATDeclarationSelectors.selectVATDeclaration);
        if (declarationId === currentDeclaration?.id) {
            yield put(VATDeclarationActions.documentsUploadedSSE({declarationId}));
        }
    } catch (e) {
        Debug.error('VAT handleSSEDocumentUpload', 'Error: ', {e});
        Toast.error('genericError');
    }
};

const handleSSEDocumentDelete = function* ({data}) {
    try {
        const parsedMessage = JSON.parse(data);
        const declarationId = parsedMessage.data.declaration_id;
        const documentId = parsedMessage.data.document_id;
        const currentDeclaration = yield select(VATDeclarationSelectors.selectVATDeclaration);
        if (declarationId === currentDeclaration?.id) {
            yield put(VATDeclarationActions.documentDeletedSSE({declarationId, documentId}));
        }
    } catch (e) {
        Debug.error('VAT handleSSEDocumentDelete', 'Error: ', {e});
        Toast.error('genericError');
    }
};

export const VAT_DECLARATION_SSE_EVENTS_ENUM = {
    VAT_BATCH_INFO_UPDATE: 'VAT_BATCH_INFO_UPDATE',
    VAT_BATCH_INFO_COMPLETE: 'VAT_BATCH_INFO_COMPLETE',
    DECLARATION_DOCUMENTS_UPLOAD_SUCCESS: 'DECLARATION_DOCUMENTS_UPLOAD_SUCCESS',
    DECLARATION_DOCUMENTS_DELETE_SUCCESS: 'DECLARATION_DOCUMENTS_DELETE_SUCCESS',
};

export const VAT_DECLARATION_SSE_EVENTS = [
    {
        key: VAT_DECLARATION_SSE_EVENTS_ENUM.VAT_BATCH_INFO_UPDATE,
        handler: handleSSEBatchSubmissionUpdate,
    },
    {
        key: VAT_DECLARATION_SSE_EVENTS_ENUM.VAT_BATCH_INFO_COMPLETE,
        handler: handleSSEBatchSubmissionCompleted,
    },
    {
        key: VAT_DECLARATION_SSE_EVENTS_ENUM.DECLARATION_DOCUMENTS_UPLOAD_SUCCESS,
        handler: handleSSEDocumentUpload,
    },
    {
        key: VAT_DECLARATION_SSE_EVENTS_ENUM.DECLARATION_DOCUMENTS_DELETE_SUCCESS,
        handler: handleSSEDocumentDelete,
    },
];
