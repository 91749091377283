import {
    faPlus,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Chip} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import useMediaQuery from '../../../../../../components/joy-ui/mui-legacy/useMediaQuery';
import {useIsMobileSize} from '../../../../../../hooks/use-is-mobile-size';

export const AddButtons = ({
    isHiwayBankAddAllowed,
    onClickHiwayAdd,
    isBankAddAllowed,
    onClickAdd,
    handleClose,
}) => {
    const {t} = useTranslation('bank');
    const isMobileSize = useIsMobileSize();
    const isDesktopSmall = useMediaQuery('(max-width: 1250px)');
    const isMobileSmall = useMediaQuery('(max-width: 500px)');
    const isHiddenRecommended = (isDesktopSmall && !isMobileSize)
        || (isMobileSmall && isMobileSize);

    return (
        <>
            {isHiwayBankAddAllowed && (
                <Button
                    fullWidth
                    startDecorator={(
                        <FontAwesomeIcon icon={faPlus} />
                    )}
                    endDecorator={isHiddenRecommended ? null : (
                        <Chip
                            color="danger"
                            size="sm"
                            variant="solid"
                        >
                            {t('connectProPage.recommended')}
                        </Chip>
                    )}
                    onClick={() => {
                        onClickHiwayAdd();
                        if (handleClose) {
                            handleClose();
                        }
                    }}
                    variant="soft"
                    size={isMobileSmall ? 'sm' : 'lg'}
                    color="primary"
                >
                    {t('creationProPage.addHiwayPro')}
                </Button>
            )}

            {isBankAddAllowed && (
                <Button
                    fullWidth
                    startDecorator={(
                        <FontAwesomeIcon icon={faPlus} />
                    )}
                    onClick={() => {
                        onClickAdd();
                        if (handleClose) {
                            handleClose();
                        }
                    }}
                    color="primary"
                    size={isMobileSmall ? 'sm' : 'lg'}
                    variant="outlined"
                >
                    {t('creationProPage.addButtonTitle')}
                </Button>
            )}
        </>
    );
};

AddButtons.propTypes = {
    isHiwayBankAddAllowed: PropTypes.bool.isRequired,
    onClickHiwayAdd: PropTypes.func.isRequired,
    isBankAddAllowed: PropTypes.bool.isRequired,
    onClickAdd: PropTypes.func.isRequired,
    handleClose: PropTypes.func,
};

AddButtons.defaultProps = {
    handleClose: null,
};
