export const MainOnboardingActionTypes = {
    GET_ONBOARDING_DATA: 'mainOnboarding/GET_ONBOARDING_DATA',
    STORE_ONBOARDING_DATA: 'mainOnboarding/STORE_ONBOARDING_DATA',

    PUT_SIGN_CGVS: 'mainOnboarding/PUT_SIGN_CGVS',
    PUT_SIGN_AD: 'mainOnboarding/PUT_SIGN_AD',

    POST_LAUNCH_UMBRELLA_ONBOARDING: 'mainOnboarding/POST_LAUNCH_UMBRELLA_ONBOARDING',
    POST_PERSONAL_INFORMATION: 'mainOnboarding/POST_PERSONAL_INFORMATION',
    POST_PERSONAL_DOCUMENTS: 'mainOnboarding/POST_PERSONAL_DOCUMENTS',

    SET_STEP: 'mainOnboarding/SET_STEP',
    SET_SUBSTEP: 'mainOnboarding/SET_SUBSTEP',
    DETERMINE_STEP_SUBSTEP: 'mainOnboarding/DETERMINE_STEP_SUBSTEP',
};
