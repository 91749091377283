import {Box, Typography, useTheme} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';

/*
 * TODO: We have two other PageHeader components with the same purpose.
 * When migrating to joy-ui we should investigate which of the components
 * should be removed.
 */
const PageHeader = ({title, subTitle, titleVariant, hasMargin, buttonComponent}) => {
    const theme = useTheme();
    const {t} = useTranslation();

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                ...(hasMargin && {
                    marginBottom: theme.spacing(2),
                    [theme.breakpoints.down('lg')]: {
                        marginBottom: theme.spacing(2),
                    },
                }),
            }}
        >
            <Box sx={{flex: 1}}>
                {title ? (
                    <Typography
                        variant={titleVariant}
                        sx={{
                            [theme.breakpoints.down('lg')]: {
                                fontSize: theme.typography.pxToRem(34),
                            },
                            [theme.breakpoints.down('md')]: {
                                fontSize: theme.typography.pxToRem(28),
                            },

                        }}
                    >
                        {title}
                    </Typography>
                ) : (
                    <Typography
                        variant={titleVariant}
                        sx={{
                            fontStyle: 'italic',
                            color: 'rgba(0, 0, 0, 0.3)',
                        }}
                    >
                        {t('noInput')}
                    </Typography>
                )}

                {!!subTitle && (
                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: theme.typography.pxToRem(16),
                            fontWeight: theme.typography.fontWeightMedium,
                            letterSpacing: '0.1px',
                            color: 'rgba(0, 0, 0, 0.54)',
                            marginTop: theme.spacing(2),
                            [theme.breakpoints.down('lg')]: {
                                fontSize: theme.typography.pxToRem(14),
                            },
                        }}
                    >
                        {subTitle}
                    </Typography>
                )}
            </Box>

            {!!buttonComponent && buttonComponent}
        </Box>
    );
};

PageHeader.propTypes = {
    title: PropTypes.string.isRequired,
    buttonComponent: PropTypes.node,
    subTitle: PropTypes.string,
    hasMargin: PropTypes.bool,
    titleVariant: PropTypes.oneOf([
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'subtitle1',
        'subtitle2',
        'body1',
        'body2',
        'caption',
        'button',
        'overline',
        'srOnly',
    ]),
};

PageHeader.defaultProps = {
    buttonComponent: null,
    subTitle: null,
    titleVariant: 'h4',
    hasMargin: true,
};

export default PageHeader;
