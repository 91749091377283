import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import RoleBasedVisibility from '../../../components/app/RoleBasedVisibility';
import ReactRouterButtonLink from '../../../components/app/router/ReactRouterButtonLink';
import PageHeader from '../../../components/headers/PageHeader/PageHeader';
import {push, selectRouterLocation} from '../../../lib/router/connected-router-saga';
import {resolveRoute} from '../../../lib/router/resolveRoute';
import {ROUTE_PATHS, RoutePaths} from '../../../lib/router/route-paths';
import {USER_ROLES, isUserClientFn} from '../../../utils/user-roles';
import {selectCoachAccounts} from '../../../v1/app/user/user.selectors';
import DataTable from '../../../v1/components/ui-kit/DataTable/DataTable';
import {
    getAdminUsersTableConfig,
    getAllUsersTableConfig,
    getUsersTableConfig,
} from '../../../v1/components/user/users-table-config';
import {SidebarActions} from '../../sidebar/store/sidebar.action';
import {createUsersSelector, selectIsLoadingUsers} from '../store/userList.selectors';

const UsersView = ({isSidebarSearch}) => {
    const {t} = useTranslation('users');
    const dispatch = useDispatch();

    const location = useSelector(selectRouterLocation);

    const search = location?.query;

    const coachAccounts = useSelector(selectCoachAccounts);

    const columnConfigAdmin = useMemo(() => getAdminUsersTableConfig(t, coachAccounts), [coachAccounts, t]);
    const columnConfigAll = useMemo(() => getAllUsersTableConfig(t, coachAccounts), [coachAccounts, t]);
    const columnConfigUser = useMemo(() => getUsersTableConfig(t, coachAccounts), [coachAccounts, t]);

    const columnConfig = useMemo(() => {
        if (search?.['users-tabFilter'] === USER_ROLES.ADMINISTRATOR) {
            return columnConfigAdmin;
        } else if (search?.['users-tabFilter'] === USER_ROLES.CARE || search?.['users-tabFilter'] === USER_ROLES.FREELANCER) {
            return columnConfigUser;
        }

        return columnConfigAll;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search?.['users-tabFilter']]);

    const handleRowClick = (e, rowData) => {
        if (isSidebarSearch) {
            if (rowData.role === USER_ROLES.CARE) {
                dispatch(SidebarActions.setRoleSelected(USER_ROLES.CARE));
            }

            if (isUserClientFn(rowData)) {
                dispatch(SidebarActions.setRoleSelected(USER_ROLES.FREELANCER));
            }

            dispatch(SidebarActions.setSearchText(''));
        }

        if (e?.target?.classList.contains('company')) {
            dispatch(push(
                resolveRoute(ROUTE_PATHS.COMPANY, {
                    freelancerId: rowData.userId,
                    companyId: rowData.companyId ?? rowData.id,
                }),
            ));
        } else {
            dispatch(push(resolveRoute(ROUTE_PATHS.USER, {
                userId: rowData.userId,
            })));
        }
    };

    const isLoading = useSelector(selectIsLoadingUsers);

    return (
        <>
            <PageHeader
                title={isSidebarSearch ? t('usersTitleSearch') : t('usersTitle')}
                buttonComponent={!isSidebarSearch ? (
                    <RoleBasedVisibility requiredRoles={USER_ROLES.ADMINISTRATOR}>
                        <ReactRouterButtonLink
                            variant="contained"
                            size="medium"
                            color="secondary"
                            startIcon={<PersonAddIcon />}
                            to={RoutePaths.CREATE_USER}
                        >
                            {t('usersTable.createUser')}
                        </ReactRouterButtonLink>
                    </RoleBasedVisibility>
                ) : undefined}
            />

            <DataTable
                tableId="users"
                defaultSortByKey="createdAt"
                columnConfig={columnConfig}
                missingDataMessage={t('usersDataTable.missingDataMessage')}
                dataSelector={createUsersSelector}
                searchPlaceholder={t('usersDataTable.searchPlaceholder')}
                tabFilterConfig={Object.values(USER_ROLES).map(role => ({
                    id: role,
                    label: t(`userRoles.${role}`),
                }))}
                searchBy={['email', 'firstName', 'lastName']}
                tabFilterKey="role"
                isLoading={isLoading}
                hasSearch={!isSidebarSearch}
                hasTabFilters={!isSidebarSearch}
                hasColumnFilters={false}
                onRowClick={handleRowClick}
            />
        </>
    );
};

UsersView.propTypes = {
    isSidebarSearch: PropTypes.bool,
};

UsersView.defaultProps = {
    isSidebarSearch: false,
};

export default UsersView;
