import AdapterMoment from '@date-io/moment';
import {Box, styled} from '@mui/joy';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {LocalizationProvider, PickersDay, StaticDatePicker} from '../../../../components/joy-ui/x-date-pickers';

const styles = {
    borderRadiusFirst: {
        'borderTopLeftRadius': '50%',
        'borderBottomLeftRadius': '50%',
    },
    borderRadiusLast: {
        'borderTopRightRadius': '50%',
        'borderBottomRightRadius': '50%',
    },
    boxWrapperFirstDay: {
        'backgroundColor': 'background.level1',
        'borderTopLeftRadius': '50%',
        'borderBottomLeftRadius': '50%',
    },
    boxWrapperLastDay: {
        'backgroundColor': 'background.level1',
        'borderTopRightRadius': '50%',
        'borderBottomRightRadius': '50%',
    },
};

const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: prop => prop !== 'dayIsBetween'
        && prop !== 'isFirstDay'
        && prop !== 'isLastDay'
        && prop !== 'startOfWeek'
        && prop !== 'endOfWeek',
})(({theme, dayIsBetween, isFirstDay, startOfWeek, endOfWeek, isLastDay}) => ({
    ...(startOfWeek && {
        'borderRadius': 0,
        ...styles.borderRadiusFirst,
        'backgroundColor': theme.palette.background.level1,
        'color': theme.palette.text.secondary,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.background.level3,
        },
    }),
    ...(endOfWeek && {
        'borderRadius': 0,
        ...styles.borderRadiusLast,
        'backgroundColor': theme.palette.background.level1,
        'color': theme.palette.text.secondary,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.background.level3,
        },
    }),
    ...(dayIsBetween && {
        'borderRadius': 0,
        'backgroundColor': theme.palette.background.level1,
        'color': theme.palette.text.secondary,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.background.level3,
        },
    }),
    ...(isFirstDay && {
        ...styles.borderRadiusFirst,
        ...styles.borderRadiusLast,
        'backgroundColor': theme.palette.neutral.solidActiveBg,
        'color': theme.palette.primary.solidColor,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.neutral.solidHoverBg,
        },
    }),
    ...(isLastDay && {
        ...styles.borderRadiusFirst,
        ...styles.borderRadiusLast,
        'backgroundColor': theme.palette.neutral.solidActiveBg,
        'color': theme.palette.primary.solidColor,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.neutral.solidHoverBg,
        },
    }),
    'width': '40px',
    'height': '36px',
}));

// TODO Do this some other way
const EmptyBoxComponent = () => {
    return <Box />;
};

const now = moment();
const startMonth = moment(now).subtract(1, 'month');

export const DateRangePicker = ({
    startDate,
    endDate,
    onStartDateChange,
    onEndDateChange,
    isSingleDate,
}) => {
    const [chosenMonthLeft, setChosenMonthLeft] = useState(startDate ?? startMonth);
    const [monthRight] = useState(startDate ? moment(startDate).add(1, 'month') : now);

    const renderDay = (date, selectedDates, pickersDayProps) => {
        const startOfWeek = date.weekday() === 0 || date.date() === 1;
        const endOfWeek = date.weekday() === 6 || date.isSame(moment(date).endOf('month'), 'day');
        const dayIsBetween = date.isBetween(startDate, endDate);
        const isFirstDay = date.isSame(startDate, 'day');
        const isLastDay = date.isSame(endDate, 'day');

        // TODO remove selected in some better way
        pickersDayProps.selected = false;

        let boxStyle = {};
        if (startDate
            && endDate && !startDate.isSame(endDate, 'day')
            && !pickersDayProps.outsideCurrentMonth
        ) {
            if (isFirstDay) {
                boxStyle = styles.boxWrapperFirstDay;
            } else if (isLastDay) {
                boxStyle = styles.boxWrapperLastDay;
            }
        }

        return (
            <Box sx={boxStyle} key={date.format('YYYY-MM-DD')}>
                <CustomPickersDay
                    {...pickersDayProps}
                    disableMargin
                    dayIsBetween={dayIsBetween}
                    isFirstDay={isFirstDay}
                    isLastDay={isLastDay}
                    startOfWeek={dayIsBetween && startOfWeek && (!isFirstDay || !isLastDay)}
                    endOfWeek={dayIsBetween && endOfWeek && (!isFirstDay || !isLastDay)}
                />
            </Box>
        );
    };

    const onChangeValue = newValue => {
        // If there are no dates set start date
        if (!startDate && !endDate) {
            onStartDateChange(newValue);
            onEndDateChange(newValue);
            return;
        }

        // If there is start, but no end date, set end date
        if (startDate && !endDate) {
            if (newValue.isBefore(startDate, 'day')) {
                onEndDateChange(startDate);
                onStartDateChange(newValue);
                return;
            }
            onEndDateChange(newValue);
            return;
        }

        // If new value is before start date set new start date
        if (newValue.isBefore(startDate)) {
            onStartDateChange(newValue);
            return;
        }

        // If new value is after end date set new end date
        if (newValue.isAfter(endDate)) {
            onEndDateChange(newValue);
            return;
        }

        // If user clicked already selected date put both days on that day
        if (newValue.isSame(startDate, 'day')) {
            onEndDateChange(newValue);
            return;
        }

        if (newValue.isSame(endDate, 'day')) {
            onStartDateChange(newValue);
            return;
        }

        // If new value is in between get the closest date
        const startDiff = newValue.diff(startDate, 'day');
        const endDiff = newValue.diff(endDate, 'day');

        if (startDiff > Math.abs(endDiff)) {
            onEndDateChange(newValue);
        } else {
            onStartDateChange(newValue);
        }
    };

    return (
        <>
            <LocalizationProvider dateLibInstance={moment} dateAdapter={AdapterMoment}>
                {!isSingleDate && (
                    <StaticDatePicker
                        displayStaticWrapperAs="desktop"
                        views={['day']}
                        openTo="day"
                        value={chosenMonthLeft}
                        renderDay={renderDay}
                        onChange={newValue => {
                            onChangeValue(newValue);
                            return newValue;
                        }}
                        disableOpenPicker
                        components={{
                            RightArrowIcon: EmptyBoxComponent,
                            RightArrowButton: EmptyBoxComponent,
                            LeftArrowIcon: EmptyBoxComponent,
                            LeftArrowButton: EmptyBoxComponent,
                        }}
                        openPicker={() => {}}
                        rawValue={startDate}
                        renderInput={EmptyBoxComponent}
                        disableHighlightToday
                        isInRange
                    />
                )}
                <StaticDatePicker
                    displayStaticWrapperAs="desktop"
                    views={['day']}
                    openTo="day"
                    value={isSingleDate ? chosenMonthLeft : monthRight}
                    renderDay={renderDay}
                    onChange={newValue => {
                        onChangeValue(newValue);
                        return newValue;
                    }}
                    disableOpenPicker
                    onMonthChange={date => {
                        if (!isSingleDate) {
                            if (!moment(chosenMonthLeft).add(1, 'month').isSame(date, 'month')) {
                                setChosenMonthLeft(moment(date).subtract(1, 'month'));
                            }
                        }
                    }}
                    openPicker={() => {}}
                    rawValue={endDate}
                    renderInput={EmptyBoxComponent}
                    disableHighlightToday
                    isInRange
                />

            </LocalizationProvider>
        </>
    );
};

DateRangePicker.propTypes = {
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    onStartDateChange: PropTypes.func.isRequired,
    onEndDateChange: PropTypes.func.isRequired,
    isSingleDate: PropTypes.bool,
};

DateRangePicker.defaultProps = {
    startDate: null,
    endDate: null,
    isSingleDate: false,
};
