import {all, call, delay, fork, put, takeEvery} from 'redux-saga/effects';
import {QuickAccessAction} from './quick-access.action';
import {QuickAccessActionType} from './quick-access.action-type';
import {DOCUMENT_STATUSES} from '../../../../../consts/document-constants';
import {LoadingActions, LoadingTypes} from '../../../../../features/loading';
import {Toast} from '../../../../../lib/toast';
import {Debug} from '../../../../../utils/debug';
import {QuickAccessApi} from '../api/quick-access.api';

const getQuickAccessDocsFlow = function* (freelancerId, companyId) {
    yield put(LoadingActions.setLoading(LoadingTypes.QUICK_ACCESS_DOCS, true));

    yield all([
        fork(getVigilanceCertSaga, freelancerId, companyId),
        fork(getLatestBalanceSheetSaga, freelancerId, companyId),
        fork(getKbisSaga, freelancerId, companyId),
    ]);

    yield put(LoadingActions.setLoading(LoadingTypes.QUICK_ACCESS_DOCS, false));
};

const getVigilanceCertSaga = function* (freelancerId, companyId) {
    try {
        const vigilanceCert = yield call(QuickAccessApi.getVigilanceCert, freelancerId, companyId);
        yield put(QuickAccessAction.storeVigilanceCert(vigilanceCert));

        if (vigilanceCert?.doc_status === DOCUMENT_STATUSES.GENERATING) {
            yield call(pollVigilanceCert, freelancerId, companyId);
        }
    } catch (error) {
        Debug.error('documents', 'Error: ', {error});
    }
};

const getLatestBalanceSheetSaga = function* (freelancerId, companyId) {
    try {
        const balanceSheet = yield call(QuickAccessApi.getLatestBalanceSheet, freelancerId, companyId);
        yield put(QuickAccessAction.storeBalanceSheet(balanceSheet));
    } catch (error) {
        Debug.error('documents', 'Error: ', {error});
    }
};

const getKbisSaga = function* (freelancerId, companyId) {
    try {
        const kbis = yield call(QuickAccessApi.getKbis, freelancerId, companyId);
        yield put(QuickAccessAction.storeKbis(kbis));
    } catch (error) {
        Debug.error('documents', 'Error: ', {error});
    }
};

export const getQuickAccessDocsWorker = function* ({payload}) {
    const {freelancerId, companyId} = payload;

    if (!freelancerId || !companyId) {
        return;
    }

    yield call(getQuickAccessDocsFlow, freelancerId, companyId);
};

const POLL_INTERVAL = 5000; // milliseconds
const POLL_MAX_DURATION = 180000; // milliseconds
export const pollVigilanceCert = function* (freelancerId, companyId) {
    let pollDuration = 0;

    while (true) {
        const vigilanceCert = yield call(QuickAccessApi.getVigilanceCert, freelancerId, companyId);
        yield put(QuickAccessAction.storeVigilanceCert(vigilanceCert));

        const isDocumentGenerationInProgress = vigilanceCert?.doc_status === DOCUMENT_STATUSES.GENERATING;
        const hasError = vigilanceCert?.doc_status === DOCUMENT_STATUSES.ERROR;
        const isDone = vigilanceCert?.doc_status === DOCUMENT_STATUSES.DONE;

        if (isDone) {
            Toast.success('newVigilanceCertAvailable');
            break;
        }

        if (hasError) {
            Toast.error('vigilanceCertificateRenewalError');
            break;
        }

        if (pollDuration >= POLL_MAX_DURATION) {
            Toast.error('vigilanceCertificateRenewalError');
            break;
        }

        if (!isDocumentGenerationInProgress) {
            break;
        }

        pollDuration += POLL_INTERVAL;
        yield delay(POLL_INTERVAL);
    }
};

const reissueVigilanceCertFlow = function* (freelancerId, companyId) {
    yield put(LoadingActions.setLoading(LoadingTypes.REISSUE_VIGILANCE_CERT, true));

    try {
        yield call(QuickAccessApi.reissueVigilanceCert, freelancerId, companyId);

        Toast.success('requestForNewCertificate');

        yield delay(POLL_INTERVAL);

        yield call(pollVigilanceCert, freelancerId, companyId);

        yield put(LoadingActions.setLoading(LoadingTypes.REISSUE_VIGILANCE_CERT, false));
    } catch (error) {
        Debug.error('documents', 'Error: ', {error});
        Toast.error('vigilanceCertificateRenewalError');

        yield put(LoadingActions.setLoading(LoadingTypes.REISSUE_VIGILANCE_CERT, false));
    }
};

export const reissueVigilanceCertWorker = function* ({payload}) {
    const {freelancerId, companyId} = payload;

    if (!freelancerId || !companyId) {
        return;
    }

    yield call(reissueVigilanceCertFlow, freelancerId, companyId);
};

export const watchQuickAccess = function* () {
    yield all([
        takeEvery(QuickAccessActionType.GET_QUICK_ACCESS_DOCS, getQuickAccessDocsWorker),
        takeEvery(QuickAccessActionType.REISSUE_VIGILANCE_CERT, reissueVigilanceCertWorker),
    ]);
};
