import {Auth} from 'aws-amplify';
import {all, call, delay, fork, put, select, takeLatest} from 'redux-saga/effects';

import * as actions from './emailVerificationForm.actions';
import {selectIsVerificationCodeResent} from './emailVerificationForm.selectors';
import {getConfig} from '../../../../config/get-config';
import {push} from '../../../../lib/router/connected-router-saga';
import {Toast} from '../../../../lib/toast';

const submitEmailVerificationFormSaga = function* ({payload}) {
    yield put(actions.setIsLoadingEmailVerificationForm(true));

    try {
        yield call([Auth, Auth.verifyCurrentUserAttributeSubmit], 'email', payload);

        Toast.success('emailVerified');

        yield put(actions.setIsVerificationCodeResent(false));

        const config = yield call(getConfig);

        yield put(push(config.ROUTE_PATHS.DASHBOARD));
    } catch (error) {
        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsLoadingEmailVerificationForm(false));
    }
};

const resendEmailVerificationCodeSaga = function* () {
    yield put(actions.setIsLoadingEmailVerificationForm(true));
    yield put(actions.setIsVerificationCodeResent(true));

    try {
        yield fork(resendEmailVerificationCodeTimer);

        yield call([Auth, Auth.verifyCurrentUserAttribute], 'email');

        yield put(actions.setIsVerificationCodeResent(true));
    } catch (error) {
        if (error.code === 'LimitExceededException') {
            Toast.error('attemptLimitExceeded');

            return;
        }

        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsLoadingEmailVerificationForm(false));
    }
};

const INTERVAL = 1000; // milliseconds
const MINIMAL_WAITING_TIME = 60000; // milliseconds
const resendEmailVerificationCodeTimer = function* () {
    let timeLeft = MINIMAL_WAITING_TIME;

    while (true) {
        yield put(actions.setResendVerificationCodeTimeLeft(timeLeft));

        yield delay(INTERVAL);

        timeLeft -= INTERVAL;

        const isVerificationCodeResent = yield select(selectIsVerificationCodeResent);

        if (timeLeft === 0 || !isVerificationCodeResent) {
            yield put(actions.setIsVerificationCodeResent(false));

            yield put(actions.setResendVerificationCodeTimeLeft(null));

            break;
        }
    }
};

export const watchEmailVerificationFormSagas = function* () {
    yield all([
        takeLatest(actions.SUBMIT_EMAIL_VERIFICATION_FORM, submitEmailVerificationFormSaga),
        takeLatest(actions.RESEND_EMAIL_VERIFICATION_CODE, resendEmailVerificationCodeSaga),
    ]);
};
