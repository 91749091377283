/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
// TERM ID : 2a9c114c-6fba-4025-bc84-5bc96fc499ee, VERSION CODE: 1
import {Card, CardContent, Container, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import {useLegalPageADPStyles} from './styles';
import {ReactComponent as HiwayText} from '../../../../assets/svg/logo/hiway.svg';
import {UserRoleColors} from '../../../../utils/user-roles';

const LegalPortageADP = ({isHeaderShown}) => {
    const styles = useLegalPageADPStyles();

    return (
        <div className={styles.wrapper}>
            <Container className={styles.container}>
                {isHeaderShown && (
                <header className={styles.header}>
                    <div className={styles.logoContainer}>
                        <HiwayText fill={UserRoleColors.PORTAGE} />
                    </div>

                    <h1 className={styles.title}>Annexe 1:</h1>
                    <h2 className={styles.subTitle}>
                        Accord de donnees personnelles regissant la conformité des traitements de données à caractère
                        personnel réalisés via la plateforme
                    </h2>
                </header>
                )}


                <Card>
                    <CardContent className={styles.paper}>
                        <Typography className={styles.heading}>preambule: </Typography>
                        <Typography className={styles.paragraph}>
                            Le présent Accord sur les Données Personnelles (ci-après désigné <strong>« ADP »</strong>)
                            régit
                            la conformité
                            des traitements de Données à caractère personnel réalisés via la Plateforme.
                        </Typography>
                        <Typography className={styles.paragraph}>
                            Le présent ADP est conclu conformément au RGPD et à la loi n°78-17 du 6 janvier 1978
                            modifiée
                            dite loi « Informatique et Libertés ».
                        </Typography>
                        <Typography className={styles.paragraph}>
                            Pour l’exécution des Services telles que définis à l’article 2 des CGVS et en particulier de
                            l’Abonnement souscrit dans les conditions de l’article 5 des CGVS, et pour toute la durée de
                            l’Abonnement :
                        </Typography>

                        <div className={styles.listItem}>
                            <Typography className={styles.listItemBullet}>
                                (i)
                            </Typography>
                            <Typography>
                                HIWAY sera amenée à collecter depuis la Plateforme puis à accéder, stocker, et/ou
                                transférer
                                des Données Personnelles intégrées dans la Plateforme, de sorte que HIWAY interviendra
                                en
                                qualité de Responsable de traitement sur ces traitements des Données Personnelles et ce,
                                pendant toute la durée de l’Abonnement ;
                            </Typography>
                        </div>

                        <div className={styles.listItem}>
                            <Typography className={styles.listItemBullet}>
                                (ii)
                            </Typography>
                            <Typography>
                                les sociétés AWS, TDF et GOOGLE telles que visées à l’article 0 des CGVS agissent en
                                qualité
                                de Sous-Traitants au sens du RGPD dans le cadre de missions de support
                                technique/hébergement/stockage lors de l’exécution des Services / de l’Abonnement ;
                                elles
                                seront amenées en cette qualité à traiter des Données Personnelles pour le compte
                                d’HIWAY.
                                HIWAY pourra être amenée à communiquer aux Sous-traitants des Données Personnelles pour
                                l’exécution desdits Services/de l’Abonnement ;
                            </Typography>
                        </div>

                        <div className={styles.listItem}>
                            <Typography className={styles.listItemBullet}>
                                (iii)
                            </Typography>
                            <Typography>
                                le cabinet d’expertise-comptable partenaire de HIWAY, HIWAY COMPTA, agit en qualité de
                                Sous-Traitant au sens du RGPD dans le cadre de missions de suivi de l’activité comptable
                                annuelle du Libéral (déclarative, bilans etc.). HIWAY pourra être amenée à communiquer à
                                ce Sous-traitant des Données Personnelles pour l’exécution desdits Services/de
                                l’Abonnement ;
                            </Typography>
                        </div>

                        <div className={styles.listItem}>
                            <Typography className={styles.listItemBullet}>
                                (iv)
                            </Typography>
                            <Typography>
                                les Parties reconnaissent et acceptent que dans le cadre de l’exécution des Services/de
                                l’Abonnement, HIWAY est susceptible de mettre en relation le Libéral avec des tiers
                                partenaires, et notamment, sans que cette liste ne soit exhaustive: des assureurs,
                                des banques, des gestionnaire de patrimoine, des organismes de formations ;
                            </Typography>
                        </div>

                        <div className={styles.listItem}>
                            <Typography className={styles.listItemBullet}>
                                (v)
                            </Typography>
                            <Typography>
                                dans le cadre de l’exécution des Services/de l’Abonnement, HIWAY sera amenée à traiter
                                des Données Personnelles susceptibles de figurer sur les contrats commerciaux passés
                                avec des clients du Libéral ainsi que sur les factures correspondantes, pour le compte
                                et sur instruction du Libéral. Dans cette hypothèse spécifique, le Libéral agira comme
                                Responsable de Traitement et HIWAY comme Sous-Traitant.
                            </Typography>
                        </div>


                        <Typography className={styles.paragraph}>
                            Le présent ADP a donc pour objet de définir les conditions dans lesquelles les traitements
                            de Données Personnelles seront réalisés par HIWAY et/ou par le Libéral dans le cadre de
                            l’exécution des Services/de l’Abonnement ainsi que par leurs Sous-traitants pour leur
                            compte, conformément à la réglementation en vigueur précitée sur la protection des Données
                            à caractère personnel.
                        </Typography>

                        <Typography className={styles.paragraph}>
                            Il convient de rappeler que l’ensemble des stipulations du présent ADP et des CGVS forment
                            un ensemble contractuel qui régit les relations entre les Parties. Il est précisé que le
                            présent ADP prime sur toutes autres stipulations, seules les situations non couvertes par
                            le présent ADP seront régies par l’article 7 des CGVS.
                        </Typography>

                        <Typography className={styles.heading}>CECI ETANT EXPOSE, IL EST CONVENU CE QUI SUIT
                            :
                        </Typography>

                        <Typography className={styles.subHeader}>
                            <span className={styles.redText}>1.</span> Traitements de Données Personnelles par HIWAY :
                            cf.
                            (i) du Préambule
                        </Typography>
                        <Typography className={styles.subItemHeader}>
                            <span className={styles.redText}>1.1</span> Nature et finalités des Traitements
                        </Typography>
                        <div className={styles.paddedText}>
                            <Typography className={styles.paragraph}>
                                La Plateforme a pour objet la gestion et le suivi de la situation comptable,
                                administrative,
                                sociale, fiscale, commerciale et financière du Libéral, la première étape de ce suivi
                                consistant à accompagner le Libéral dans la constitution d’une entreprise.
                            </Typography>
                            <Typography className={styles.paragraph}>
                                Tous les autres Services sont visés à l’article 2 des CGVS.
                            </Typography>
                            <Typography className={styles.paragraph}>
                                Aux fins d’exécution pleine et entière de ces Services, le Libéral devra
                                impérativement
                                fournir
                                sur la Plateforme accessible dès la souscription d’un Abonnement par ses soins, les
                                Données
                                Personnelles visées à l’article 1.1.2 ci-après.
                            </Typography>
                            <Typography className={styles.paragraph}>
                                Il est précisé que ces Données Personnelles sont essentielles à l’accomplissement des
                                formalités auprès des différents organismes (URSSAF, Sécurité sociale, Greffe du
                                Tribunal de Commerce compétent, services fiscaux et tout autre partenaire de HIWAY)
                                auprès desquels HIWAY assurera une gestion déléguée pour le compte du Libéral,
                                directement ou via le cabinet d’expertise-comptable, en sa qualité de Sous-Traitant.
                            </Typography>
                        </div>

                        <Typography className={styles.subItemHeader}>
                            <span className={styles.redText}>1.1.1</span> Personnes concernées
                        </Typography>
                        <div className={styles.paddedText}>
                            <ul className={styles.bulletList}>
                                <li className={styles.customBullet}>Libéral, personne physique</li>
                                <li className={styles.customBullet}>Tiers hébergeur du Libéral personne physique</li>
                            </ul>
                        </div>


                        <Typography className={styles.subItemHeader}>
                            <span className={styles.redText}>1.1.2</span> Catégories de Données Personnelles traitées
                        </Typography>
                        <div className={styles.paddedText}>
                            <ul className={styles.bulletList}>
                                <li className={styles.customBullet}>État civil, identité, données d’identification du
                                    Libéral personne physique (genre –
                                    féminin/masculin – nom de famille, prénom, nom de jeune fille, date et lieu de
                                    naissance, nationalité, numéro de sécurité sociale, coordonnées postales, adresse de
                                    messagerie électronique, photographie)
                                </li>
                                <li className={styles.customBullet}>État civil (nom/nom de jeune fille/prénom) des
                                    parents
                                    du Libéral personne physique
                                </li>
                                <li className={styles.customBullet}>Etat civil (nom/prénom/coordonnées postales) de tout
                                    tiers susceptible d’héberger le
                                    Libéral personne physique
                                </li>
                                <li className={styles.customBullet}>Données financières apparaissant sur les factures
                                    internet, mobile, électricité, gaz
                                    etc.
                                </li>
                                <li className={styles.customBullet}>
                                    Données relatives à la situation familiale, économique, patrimoniale et financière ;
                                </li>
                            </ul>
                        </div>

                        <Typography className={styles.subItemHeader}>
                            <span className={styles.redText}>1.1.3</span> Nature des Traitements
                        </Typography>
                        <div className={styles.paddedText}>
                            <ul className={styles.bulletList}>
                                <li className={styles.customBullet}>Gestion de la création de la structure du Libéral
                                    personne physique
                                </li>
                                <li className={styles.customBullet}>
                                    Gestion du suivi comptable, administratif, social, fiscal, financier et commercial
                                    de la structure du Libéral, directement, auprès des organismes de constitution
                                    (greffe de Tribunal de Commerce) sociaux et fiscaux et/ou par l’intermédiaire du
                                    cabinet d’expertise-comptable partenaire de HIWAY, agissant en qualité de
                                    Sous-Traitant pour son compte
                                </li>
                            </ul>
                        </div>

                        <Typography className={styles.subItemHeader}>
                            <span className={styles.redText}>1.1.4</span> Finalités des Traitements
                        </Typography>
                        <div className={styles.paddedText}>
                            <ul className={styles.bulletList}>
                                <li className={styles.customBullet}>Exécution des Services / de l’Abonnement, objet
                                    des CGVS, en vue d’un accompagnement optimal et continu de l’activité du Libéral,
                                    dont la création d’entreprise
                                </li>
                                <li className={styles.customBullet}>
                                    Gestion de la relation directe entre le Libéral et les organismes de constitution
                                    (greffe de Tribunal de Commerce) sociaux et fiscaux et/ou par l’intermédiaire
                                    du cabinet d’expertise-comptable partenaire de HIWAY et Sous-Traitant pour son
                                    compte, visé au (ii) du Préambule
                                </li>
                                <li className={styles.customBullet}>Gestion technique de la Plateforme auprès des
                                    supports techniques Sous-Traitants visés au (iii) du Préambule notamment dans le
                                    cadre d’opérations internes de maintenance des Comptes personnels des Libéraux et
                                    des systèmes de sécurité de la Plateforme
                                </li>
                                <li className={styles.customBullet}>
                                    Assistance à la gestion de tout litige avec les organismes organismes sociaux et
                                    fiscaux et/ou par l’intermédiaire du cabinet d’expertise-comptable partenaire de
                                    HIWAY
                                </li>
                                <li className={styles.customBullet}>
                                    Assistance en vue de respecter une obligation légale à laquelle serait soumise
                                    HIWAY.
                                </li>
                            </ul>
                        </div>

                        <Typography className={styles.subItemHeader}>
                            <span className={styles.redText}>1.2</span> Description des Traitements
                        </Typography>
                        <div className={styles.paddedText}>
                            <Typography className={styles.paragraph}>
                                Dans le cadre de l’exécution des Services, HIWAY sera amenée à effectuer les traitements
                                de
                                Données Personnelles tels que visés au point (i) du préambule du présent ADP.
                            </Typography>

                            <Typography className={styles.paragraph}>
                                Plus spécifiquement, dans le cadre des traitements visés au point (i) ci-avant, HIWAY
                                effectuera limitativement les traitements suivants:
                            </Typography>

                            <ul className={styles.bulletList}>
                                <li className={styles.customBullet}>
                                    Accès aux Données Personnelles intégrées dans la Plateforme ; et/ou
                                </li>
                                <li className={styles.customBullet}>
                                    Stockage des Données Personnelles intégrées dans la Plateforme, notamment sur le
                                    CRM Hubspot ou le Drive Google de HIWAY ; et/ou
                                </li>
                                <li className={styles.customBullet}>
                                    Communication par transmission et/ou transfert des Données Personnelles intégrées
                                    dans la Plateforme, aux organismes de constitution (greffe de Tribunal de Commerce)
                                    sociaux et fiscaux nécessaires à l’exécution des Services / de l’Abonnement ;
                                </li>
                                <li className={styles.customBullet}>
                                    Communication par transmission et/ou transfert des Données Personnelles intégrées
                                    dans la Plateforme, à l’expert-comptable partenaire de HIWAY
                                </li>
                                <li className={styles.customBullet}>
                                    Communication par transmission et/ou transfert des Données Personnelles intégrées
                                    dans la Plateforme aux Sous-Traitants techniques visées au (ii) du Préambule
                                </li>
                                <li className={styles.customBullet}>Communication par transmission et/ou transfert des
                                    Données Personnelles intégrées dans la Plateforme auprès de tiers partenaires, et
                                    notamment, sans que cette liste ne soit exhaustive : des assureurs, des banques, des
                                    gestionnaires de patrimoine, des organismes de formations.
                                </li>
                            </ul>
                        </div>

                        <Typography className={styles.subItemHeader}>
                            <span className={styles.redText}>1.3</span> Partage et transfert de Données Personnelles
                        </Typography>
                        <div className={styles.paddedText}>
                            <Typography className={styles.paragraph}>
                                HIWAY peut divulguer à des tiers les Données Personnelles objet des traitements visés à
                                l’article 1.2 ci-dessus, dans la mesure où cela est strictement nécessaire à
                                l’administration de la Plateforme, et plus généralement, à la fourniture des Services
                                décrits à l’article 2 des CGVS.
                            </Typography>

                            <Typography className={styles.paragraph}>
                                Lesdites Données Personnelles seront stockées sur les serveurs du fournisseur
                                d'hébergement de la Plateforme, à savoir AWS, située au Luxembourg, ainsi que sur le
                                CRM Hubspot et sur le Drive de GOOGLE utilisé par HIWAY.
                            </Typography>

                            <Typography className={styles.paragraph}>
                                Tout partage de ces Données Personnelles effectuées auprès de partenaires commerciaux de
                                HIWAY (assureurs, des banques, des gestionnaires de patrimoine, des organismes de
                                formations) ou de tous autres tiers est soumis au consentement spécifique du Libéral
                                lors
                                de la signature des CGVS.
                            </Typography>

                            <Typography className={styles.paragraph}>
                                Les Sous-Traitants de HIWAY sont impliqués dans les domaines suivants :
                            </Typography>

                            <ul className={styles.bulletList}>
                                <li className={styles.customBullet}>
                                    l’hébergement, l’entretien de la Plateforme et toutes autres opérations techniques y
                                    afférentes, notamment de stockage, cf. (ii) du Préambule ;
                                </li>
                                <li className={styles.customBullet}>
                                    la livraison et l’exécution des Services fournis via la Plateforme, et toutes les
                                    opérations de nature administrative et comptable qui y sont liées, cf. (iii) du
                                    Préambule.
                                </li>
                            </ul>

                            <Typography className={styles.paragraph}>
                                Lesdites Données Personnelles seront stockées sur les serveurs du fournisseur
                                d&apos;hébergement
                                de la Plateforme, à savoir AWS, située au Luxembourg, ainsi que sur le Drive de GOOGLE
                                utilisé par HIWAY.
                            </Typography>

                            <Typography className={styles.paragraph}>
                                Dans leurs relations avec ces Sous-Traitants, ces derniers se sont engagés
                                respectivement à
                                répondre aux exigences concernant la sécurité et la conformité contenues dans le RGPD,
                                en
                                mettant en œuvre des mesures techniques et organisationnelles rigoureuses pour protéger
                                la
                                confidentialité, l’intégrité et la disponibilité des Données Personnelles. En cas de
                                destinataire des Données Personnelles établi en dehors de l&apos;Espace économique
                                européen
                                (EEE)
                                et ne présentant pas un niveau de protection suffisant au sens du RGPD, les
                                Sous-Traitants
                                garantissent HIWAY sur la base d’un contrat encadrant ce transfert de Données, reprenant
                                les
                                clauses contractuelles types adoptées par la Commission européenne dans sa décision
                                n°2010/87/UE du 5 février 2010.
                            </Typography>

                            <Typography className={styles.paragraph}>
                                Enfin, outre les divulgations spécifiques de Données Personnelles listées dans cet
                                article, HIWAY pourra divulguer les Données Personnelles :
                            </Typography>

                            <ul className={styles.bulletList}>
                                <li className={styles.customBullet}>
                                    afin de se conformer à une obligation légale ;
                                </li>
                                <li className={styles.customBullet}>
                                    lorsque cette divulgation est nécessaire pour l’établissement, l’exercice ou la
                                    défense
                                    de HIWAY en cas de réclamations, que ce soit dans le cadre d’une procédure
                                    judiciaire ou
                                    dans le cadre d’une procédure administrative ou non judiciaire.
                                </li>
                            </ul>
                        </div>

                        <Typography className={styles.subItemHeader}>
                            <span className={styles.redText}>1.4</span> Durée de conservation des Données Personnelles
                        </Typography>
                        <div className={styles.paddedText}>
                            <Typography className={styles.paragraph}>
                                HIWAY conservera les Données Personnelles aussi longtemps que cela est nécessaire à
                                réalisation des finalités décrites au 1.1.4 et notamment : à la fourniture des Services/
                                de l’Abonnement par l’utilisation de la Plateforme et dans le cadre de l’exécution de la
                                relation commerciale (le cas échéant) avec le Libéral, ou à d’autres fins essentielles
                                telles que : le respect d’obligations légales, le règlement de différends et
                                l’application de tout accord liant les Parties.

                            </Typography>

                            <Typography className={styles.paragraph}>
                                Il est précisé en tant que de besoin qu’HIWAY conserve les Données Personnelles du
                                Libéral aussi longtemps que le Libéral maintient son Compte personnel (lui
                                permettant d’accéder à la Plateforme) actif, et utilise de manière générale ses
                                Services, dans le cadre de l’Abonnement.

                            </Typography>

                            <Typography className={styles.paragraph}>
                                En cas de résiliation de l’Abonnement et donc de clôture du Compte personnel du
                                Libéral, HIWAY s’engage à supprimer toutes les Données Personnelles qu’il n’a plus de
                                raison commerciale de conserver. HIWAY conservera cependant toutes les Données
                                Personnelles liées à sa relation commerciale (le cas échéant) avec le Libéral afin de
                                respecter ses obligations légales en ce sens.
                            </Typography>

                            <Typography className={styles.paragraph}>
                                En effet, en cas de demande de suppression de ces Données Personnelles et/ou si HIWAY
                                exerce son droit de demander la suppression desdites Données Personnelles, HIWAY pourra
                                les conserver sous forme d’archivage intermédiaire, pour la durée nécessaire au respect
                                de ses obligations légales, comptables et fiscales.
                            </Typography>

                            <Typography className={styles.paragraph}>
                                Les périodes de conservation effective des Données Personnelles peuvent alors varier,
                                compte tenu des obligations légales ou contractuelles de HIWAY, et du type de Données
                                Personnelles concernées, ce que le Libéral reconnaît et accepte.
                            </Typography>
                        </div>

                        <Typography className={styles.subItemHeader}>
                            <span className={styles.redText}>1.5</span> Sécurité
                        </Typography>
                        <div className={styles.paddedText}>
                            <Typography className={styles.paragraph}>
                                HIWAY prend toutes les mesures appropriées et raisonnables de nature à protéger les
                                Données
                                Personnelles contre l’accès non autorisé, l’utilisation, la divulgation, la modification
                                et
                                la destruction.
                            </Typography>

                            <Typography className={styles.paragraph}>
                                HIWAY assure ainsi la sécurité des Données Personnelles et met en œuvre les mesures
                                techniques et organisationnelles nécessaires, en ce qui concerne la nature, la portée et
                                le
                                contexte de transmission des Données Personnelles, ainsi que s’agissant des risques
                                présentés par leur traitement, afin de préserver leur sécurité et, en particulier, de
                                prévenir tout accident ou destruction illégale, perte, modification, divulgation,
                                intrusion
                                ou accès non autorisé à ces Données.
                            </Typography>

                            <Typography className={styles.paragraph}>
                                Si un mot de passe est utilisé pour protéger votre Compte personnel et qu’il contient
                                des
                                Données Personnelles, il est de votre seule responsabilité de conserver votre mot de
                                passe
                                confidentiel.
                            </Typography>

                        </div>

                        <Typography className={styles.subItemHeader}>
                            <span className={styles.redText}>1.6</span> Les droits des Libéraux, personnes physiques
                        </Typography>
                        <div className={styles.paddedText}>
                            <Typography className={styles.paragraph}>
                                Les principaux droits dont disposent les Libéraux personnes physiques en vertu de la
                                législation en vigueur sur la protection des Données Personnelles sont les suivants :
                            </Typography>

                            <ul className={styles.bulletList}>
                                <li className={styles.customBullet}>
                                    le droit d’accès – le Libéral peut demander des copies de ses Données Personnelles
                                    dans un format structuré, communément utilisé et lisible;
                                </li>
                                <li className={styles.customBullet}>
                                    le droit de rectification - le Libéral peut demander de corriger ses Données
                                    Personnelles inexactes et/ou de les compléter ;
                                </li>
                                <li className={styles.customBullet}>
                                    le droit à l&apos;effacement - le Libéral peut demander d’effacer ses Données
                                    Personnelles
                                    ;

                                </li>
                                <li className={styles.customBullet}>
                                    le droit de restreindre le traitement - le Libéral peut demander de restreindre le
                                    traitement de ses Données Personnelles ;

                                </li>
                                <li className={styles.customBullet}>
                                    le droit de s’opposer au traitement - le Libéral peut s’opposer au traitement de
                                    ses
                                    Données Personnelles ;

                                </li>
                                <li className={styles.customBullet}>
                                    le droit à la portabilité des Données - le Libéral peut demander de transférer ses
                                    Données Personnelles à une autre organisation ou de les lui restituer ;

                                </li>
                                <li className={styles.customBullet}>
                                    le droit de porter plainte auprès d’une autorité de surveillance - en France, la
                                    CNIL: <a href="https://www.cnil.fr/" target="_blank" rel="noreferrer">
                                    www.cnil.fr
                                </a> ;
                                </li>
                                <li className={styles.customBullet}>
                                    le droit de retirer son consentement - dans la mesure où la base juridique du
                                    traitement
                                    de ses Données Personnelles est le consentement s’agissant uniquement de leur
                                    communication et transfert à des partenaires commerciaux (cf. supra), le Libéral
                                    peut
                                    solliciter le retrait de ce consentement.

                                </li>
                            </ul>
                            <Typography className={styles.paragraph}>
                                Le Libéral peut exercer l’un de ces droits en rapport avec ses Données Personnelles,
                                en
                                formulant une demande écrite à l’adresse digital@hiway.fr, ou par courrier en recommandé
                                avec accusé de réception à HIWAY, à l’adresse mentionnée en tête des présentes.
                            </Typography>
                        </div>

                        <Typography className={styles.subHeader}>
                            <span className={styles.redText}>2.</span> Sous-Traitance de Données Personnelles par HIWAY
                            :
                            cf. (v) du Préambule
                        </Typography>
                        <div className={styles.paddedText}>
                            <Typography className={styles.paragraph}>
                                Dans le cadre du point (v) du préambule du présent ADP, HIWAY agira en qualité de
                                Sous-traitant
                                et traitera des Données Personnelles susceptibles de figurer sur les contrats
                                commerciaux
                                des
                                clients du Libéral et sur ses factures, pour le compte et sur instruction du
                                Libéral, à
                                des
                                fins de suivi de la relation client établie avec le Libéral, ces traitements étant
                                plus
                                spécifiquement :
                            </Typography>
                            <ul className={styles.bulletList}>
                                <li className={styles.customBullet}>
                                    L’accès et l’enregistrement par téléchargement / la consultation via la Plateforme ;
                                </li>
                                <li className={styles.customBullet}>
                                    La communication par transmission et/ou transfert aux clients du Libéral (via un
                                    mécanisme
                                    de courriers, emails de toutes natures afin de suivre la relation client du
                                    Libéral).
                                </li>
                            </ul>
                            <Typography className={styles.paragraph}>
                                Le Libéral garantit se conformer à la règlementation application en matière de Données
                                Personnelles, en qualité de Responsable de Traitement des Données collectées auprès de
                                ses
                                clients.
                            </Typography>
                        </div>
                        <Typography className={styles.subHeader}>
                            <span className={styles.redText}>3.</span> Obligations générales
                        </Typography>

                        <div className={styles.paddedText}>
                            <Typography className={styles.paragraph}>
                                Chaque Partie s’engage à ne procéder à aucun traitement de Données Personnelles en
                                dehors
                                des
                                traitements mentionnés aux articles 1 et 2 du présent ADP et des traitements et des
                                finalités
                                définis dans leur Registre de Traitement respectif.
                            </Typography>

                            <Typography className={styles.paragraph}>
                                En particulier, les Parties s’interdisent tout accès, visualisation, consultation,
                                extraction
                                pour diffusion et plus généralement toute utilisation des Données Personnelles qui ne
                                serait
                                pas
                                strictement nécessaire à l’exécution des opérations de traitement prévues au présent
                                ADP, et
                                ce,
                                pour quelque raison que ce soit.
                            </Typography>

                            <Typography className={styles.paragraph}>
                                Afin de garantir le respect de cette obligation, chacune des Parties devra fournir, dans
                                les
                                huit (8) jours suivant une demande écrite de l’autre Partie, les preuves de sa
                                conformité, y
                                compris les moyens techniques permettant à l’autre Partie de contrôler de façon autonome
                                le
                                respect de cette obligation.
                            </Typography>

                            <Typography className={styles.paragraph}>
                                Toutefois, chacune des Parties est autorisée à accéder aux Données Personnelles de
                                l’autre
                                Partie :
                            </Typography>

                            <ul className={styles.bulletList}>
                                <li className={styles.customBullet}>
                                    Sur demande écrite et préalable de l’une des Parties, pour les seuls besoins
                                    d’exécution
                                    de
                                    la demande soumise et sous contrôle de celle-ci ;
                                </li>
                                <li className={styles.customBullet}>
                                    Pour les besoins de la réalisation d’opérations purement techniques qui
                                    nécessiteraient
                                    un
                                    accès incident aux Données Personnelles.
                                </li>
                            </ul>
                        </div>
                        <Typography className={styles.subHeader}>
                            <span className={styles.redText}>4.</span> Obligations des Responsables de Traitement
                            autonomes
                            (articles 1 et 2 de l’ADP)
                        </Typography>

                        <Typography
                            className={styles.paragraph}
                            style={{
                                marginLeft: '33px',
                                textIndent: '-30px',
                            }}
                        >
                            {/* eslint-disable-next-line react/jsx-child-element-spacing */}
                            <span className={styles.redText}>4.1</span>
                            Les Parties sont responsables des procédures et des
                            outils de sécurité de leurs propres systèmes. En particulier, elles doivent mettre en place
                            toute solution permettant de tracer l’accès à ces systèmes et aux Données Personnelles qui
                            s’y
                            trouvent.
                        </Typography>
                        <div className={styles.paddedText}>
                            <Typography className={styles.paragraph}>
                                Lorsque l’une des Parties doit accéder à des Données Personnelles, l’autre Partie fera
                                en
                                sorte que cet accès et les manipulations nécessaires se fassent à travers un accès
                                sécurisé.
                            </Typography>
                            <Typography className={styles.paragraph}>
                                En conséquence, les Parties se tiennent réciproquement indemnes contre toute poursuite,
                                réclamation, condamnation ou amende administrative qui serait prononcée contre elles en
                                raison de manquements de chacune à leurs obligations relatives à la Réglementation en
                                vigueur sur la protection des données à caractère personnel.
                            </Typography>
                        </div>
                        <Typography
                            className={styles.paragraph}
                            style={{
                                marginLeft: '33px',
                                textIndent: '-30px',
                            }}
                        >
                            {/* eslint-disable-next-line react/jsx-child-element-spacing */}
                            <span className={styles.redText}>4.2</span>
                            Chaque Partie se conforme aux obligations qui lui incombent en qualité de Responsable de
                            traitement autonome, et s’assure notamment, chacune pour leurs propres traitements :
                        </Typography>
                        <div className={styles.paddedText}>
                            <ul className={styles.bulletList}>
                                <li className={styles.customBullet}>
                                    De délivrer l&apos;information due aux personnes physiques ;
                                </li>
                                <li className={styles.customBullet}>
                                    De gérer les demandes d&apos;exercice de droits ou réclamations émanant des
                                    personnes
                                    physiques concernées ;
                                </li>
                                <li className={styles.customBullet}>
                                    De notifier à l&apos;autorité de contrôle compétente et/ou aux personnes physiques,
                                    une
                                    violation de Données à caractère personnel.
                                </li>
                            </ul>
                        </div>

                        <Typography className={styles.subHeader}>
                            <span className={styles.redText}>5.</span> Obligations de HIWAY en qualité de Sous-traitant
                            et
                            des Libéraux en qualité de Responsable de Traitement dans le cadre du point (v) du
                            Préambule
                        </Typography>

                        <Typography
                            className={styles.paragraph}
                            style={{
                                marginLeft: '33px',
                                textIndent: '-30px',
                            }}
                        >
                            {/* eslint-disable-next-line react/jsx-child-element-spacing */}
                            <span className={styles.redText}>5.1</span>
                            HIWAY s’engage à utiliser les Données Personnelles communiquées par les Libéraux dans le
                            cadre
                            de la mise à disposition des contrats commerciaux et factures clients, exclusivement pour
                            accomplir les Services visés à l’article 2 des CGVS selon les termes du présent ADP et selon
                            les
                            seules instructions des Libéraux, notamment aux fins de suivi de la relation client et de
                            gestion des éventuels impayés (relances, courriers).
                        </Typography>
                        <div className={styles.paddedText}>
                            <Typography className={styles.paragraph}>
                                HIWAY répondra aux instructions documentées des Libéraux, notamment sur la
                                confidentialité
                                des Données Personnelles, la durée de conservation des Données Personnelles,
                                l’effacement ou
                                les modifications de Données Personnelles, y compris en cas de demandes émises par les
                                personnes dont les Données ont été collectées et transférées par les Libéraux.
                            </Typography>
                        </div>

                        <div className={styles.paddedText}>
                            <Typography className={styles.paragraph}>
                                HIWAY s’interdit de transférer ces Données Personnelles en dehors de l’Union
                                Européenne sans l’accord préalable et écrit des libéraux.
                            </Typography>
                        </div>

                        <Typography
                            className={styles.paragraph}
                            style={{
                                marginLeft: '33px',
                                textIndent: '-30px',
                            }}
                        >
                            {/* eslint-disable-next-line react/jsx-child-element-spacing */}
                            <span className={styles.redText}>5.2</span>
                            Les Libéraux sont responsables des procédures et des outils de sécurité de leurs propres
                            systèmes. En particulier, ils doivent mettre en place toute solution permettant de tracer
                            l’accès à ces systèmes et aux Données Personnelles qui s’y trouvent.
                        </Typography>

                        <div className={styles.paddedText}>
                            <Typography className={styles.paragraph}>
                                En conséquence, les Libéraux tiennent indemne HIWAY contre toute poursuite,
                                réclamation,
                                condamnation ou amende administrative qui serait prononcée contre eux en raison de
                                manquements des Libéraux à leurs obligations relatives à la Réglementation en vigueur
                                sur
                                la protection des Données à caractère personnel.
                            </Typography>
                            <Typography className={styles.paragraph}>
                                Les Libéraux, en leur qualité de Responsables de Traitement, sont tenus de fournir
                                l’information requise par la Réglementation en vigueur sur la protection des Données à
                                caractère, aux personnes concernées par les opérations de traitement, lors de la
                                collecte des Données.

                            </Typography>
                        </div>

                        <Typography
                            className={styles.paragraph}
                            style={{
                                marginLeft: '33px',
                                textIndent: '-30px',
                            }}
                        >
                            {/* eslint-disable-next-line react/jsx-child-element-spacing */}
                            <span className={styles.redText}>5.3</span>
                            De son côté, HIWAY s’engage à assister les Libéraux dans la mise en œuvre des obligations
                            suivantes mais également à respecter elle-même lesdites obligations et à les faire respecter
                            par
                            son personnel et/ou, le cas échéant, par ses propres partenaires/prestataires :
                        </Typography>

                        <div className={styles.paddedText}>
                            <ul className={styles.bulletList}>
                                <li className={styles.customBullet}>
                                    N’utiliser les Données Personnelles qu’afin d’exécuter les Services prévus aux CGVS
                                    ;
                                </li>
                                <li className={styles.customBullet}>
                                    Ne pas divulguer les Données Personnelles à d’autres personnes sans l’accord
                                    préalable
                                    des Libéraux, qu’il s’agisse de personnes privées ou publiques, physiques ou
                                    morales ;
                                </li>
                                <li className={styles.customBullet}>
                                    Mettre en place les mesures nécessaires en matière de sécurité et de confidentialité
                                    de
                                    nature à éviter toute utilisation à des fins détournées ou frauduleuses des Données
                                    Personnelles ;
                                </li>
                                <li className={styles.customBullet}>
                                    Ne pas vendre, céder, louer et plus généralement ne pas transférer les Données
                                    Personnelles pour quelque raison que ce soit sans obtenir l’accord explicite
                                    préalable
                                    des Libéraux ;
                                </li>
                                <li className={styles.customBullet}>
                                    Ne pas réaliser de copies ou duplications des Données Personnelles sans
                                    l’autorisation
                                    écrite préalable des Libéraux concernés, à moins que ces copies ou duplications
                                    soient
                                    nécessaires à l’exécution de ses Services ;

                                </li>
                                <li className={styles.customBullet}>
                                    Ne pas conserver les Données Personnelles au-delà de la durée de conservation
                                    imposée
                                    par la réglementation applicable, et à tout le moins, aussi longtemps que durera la
                                    fourniture auprès des Libéraux des Services/ de l’Abonnement par l’utilisation
                                    notamment de la Plateforme ;
                                </li>
                                <li className={styles.customBullet}>
                                    Informer immédiatement les Libéraux de tout accès accidentel ou non autorisé aux
                                    Données Personnelles, de tout manquement à la réglementation applicable en matière
                                    de
                                    protection des Données personnelles ou toute suspicion d’un tel manquement ;
                                </li>
                                <li className={styles.customBullet}>
                                    De manière générale, prendre et réviser régulièrement les mesures de sécurité et de
                                    confidentialité au regard de l’état de l’art et des réglementations applicables
                                    nécessaires pour assurer la conservation et l’intégrité des informations traitées ;
                                </li>
                                <li className={styles.customBullet}>
                                    HIWAY ne fera appel qu’aux employés et/ou aux personnes autorisées qui (i) ont
                                    besoin
                                    d’avoir accès aux informations dans le cadre de l’exécution du présent ADP, et (ii)
                                    ont
                                    signé un accord de confidentialité le cas échéant, et (iii) ont été informés de
                                    toutes
                                    exigences particulières relatives à la protection des Données Personnelles de
                                    l’utilisation limitée aux finalités telles que définies par les Libéraux dans le
                                    présent ADP ;
                                </li>
                                <li className={styles.customBullet}>
                                    Se conformer aux instructions des Libéraux en matière de sécurité et de
                                    confidentialité tant qu’elles sont proportionnées, raisonnables et compatibles avec
                                    le
                                    présent ADP.
                                </li>
                            </ul>
                        </div>

                        <Typography className={styles.subHeader}>
                            <span className={styles.redText}>6.</span> Sous-traitance
                        </Typography>
                        <div className={styles.paddedText}>
                            Quels que soient les traitements de Données Personnelles auxquels il est fait référence en
                            Préambule du présent ADP, les Parties s’assurent que les obligations auxquelles elles
                            s’obligent
                            dans le présent ADP sont également respectées par leurs Sous-Traitants.
                        </div>

                        <Typography className={styles.subHeader}>
                            <span className={styles.redText}>7.</span> Retour ou suppression des Données Personnelles
                        </Typography>

                        <div className={styles.paddedText}>
                            <Typography className={styles.paragraph}>
                                Quels que soient les Traitements de Données Personnelles auxquels il est fait référence
                                en
                                Préambule du présent ADP, à la fin de l’exécution des CGVS, HIWAY :
                            </Typography>

                            <ul className={styles.bulletList}>
                                <li className={styles.customBullet}>
                                    adressera aux Libéraux l’ensemble des Données Personnelles qui auront été générées
                                    du
                                    fait de l’exécution des Services, dans un format structuré, couramment utilisé et
                                    lisible par machine ; et
                                </li>
                                <li className={styles.customBullet}>
                                    procédera ensuite à leur suppression dans un délai maximum d’un (1) mois, à
                                    l’exception
                                    des Données Personnelles intégrées dans la Plateforme qui sont strictement
                                    nécessaires à
                                    la justification par HIWAY de l’accomplissement de ses obligations légales et
                                    règlementaires, conformément à l’article 1.4 du présent ADP.
                                </li>
                            </ul>
                        </div>

                        <Typography className={styles.subHeader}>
                            <span className={styles.redText}>8.</span> Points de contact des Parties
                        </Typography>
                        <div className={styles.paddedText}>
                            <Typography className={styles.paragraph}>
                                HIWAY informe les Libéraux avoir nommé un interlocuteur dédié à la protection des
                                données,
                                qui supervisera la mise en œuvre du présent ADP et sera l’interlocuteur privilégié des
                                bénéficiaires pour les sujets relatifs à son exécution.
                            </Typography>
                            <Typography className={styles.paragraph}>
                                Le délégué à la protection des données de HIWAY désigné au jour du présent ADP est :
                                Alexandre FRANCHI.
                            </Typography>
                            <Typography className={styles.paragraph}>
                                Le Libéral informe HIWAY avoir désigné son représentant légal comme interlocuteur
                                privilégié sur les sujets relatifs à l’exécution du présent ADP.
                            </Typography>
                        </div>

                        <Typography className={styles.subHeader}>
                            <span className={styles.redText}>9.</span> Dispositions finales
                        </Typography>
                        <div className={styles.paddedText}>
                            <Typography className={styles.paragraph}>
                                Toute notification écrite dans le cadre des présentes sera faite à l’adresse mentionnée
                                en
                                tête des présentes (chaque Partie informera l’autre Partie de tout changement éventuel
                                d’adresse de notification).
                            </Typography>
                            <Typography className={styles.paragraph}>
                                L’ADP exprime l’intégralité des accords entre les Parties et portant sur le même objet.
                                Il
                                annule et remplace tous autres accords verbaux ou écrits, de quelque nature que ce soit,
                                qui
                                pourraient être intervenus préalablement entre elles et relatifs au même objet.
                            </Typography>
                            <Typography className={styles.paragraph}>
                                La renonciation d’une des Parties à se prévaloir de ses droits à l’occasion d’une
                                violation
                                quelconque des dispositions du présent ADP par l’autre Partie ne saurait être
                                interprétée
                                comme une renonciation définitive de se prévaloir de ses droits ultérieurement.
                            </Typography>
                            <Typography className={styles.paragraph}>
                                Toute modification des termes du présent ADP devra être établie par un avenant écrit
                                signé
                                des Parties.
                            </Typography>
                            <Typography className={styles.paragraph}>
                                Dans l’hypothèse où une ou plusieurs stipulations du présent ADP seraient considérées
                                comme
                                nulles ou non opposables par une juridiction compétente, cette disposition sera
                                supprimée du
                                présent ADP sans que la validité ainsi que l’opposabilité des autres dispositions n’en
                                soient affectées.
                            </Typography>
                            <Typography className={styles.paragraph}>
                                Le présent ADP est soumis au droit français.
                            </Typography>
                            <Typography className={styles.paragraph}>
                                Les Parties s’efforceront de résoudre à l’amiable tout différend susceptible de naître
                                de l’interprétation et/ou l’exécution des présentes. Toutefois, et à défaut d’accord,
                                toute contestation portant sur l’application ou l’interprétation des présentes relèvera
                                de la compétence exclusive des tribunaux de Paris.
                            </Typography>

                        </div>
                    </CardContent>
                </Card>
                <Typography className={styles.info}>
                    Hiway SAS - Capital social : 9 446,85€ - Siège social : 14 rue Bausset 75015 Paris - SIRET 80106660074
                    000035 RCS Paris - TVA FR22080106660074
                </Typography>
            </Container>
        </div>
    );
};

LegalPortageADP.propTypes = {
    isHeaderShown: PropTypes.bool,
};

LegalPortageADP.defaultProps = {
    isHeaderShown: true,
};

export default LegalPortageADP;
