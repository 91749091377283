import {createSelector} from 'reselect';
import {createDataTableSortCallback, getPaginatedResult} from '../../../../utils/data-table-utils';
import {getTwoDecimals} from '../../../../utils/holidays';
import {TRAVEL_TYPES} from '../expenses.consts';

export const selectIsLoadingBusinessAllowanceTravels = state => state.expenses?.businessAllowance?.isLoadingTravels;

export const selectIsLoadingBusinessAllowanceRecaps = state => state.expenses?.businessAllowance?.isLoadingRecaps;

export const selectBusinessAllowanceTravels = state => state.expenses?.businessAllowance?.travels ?? {};

export const selectBusinessAllowanceRecaps = state => state.expenses?.businessAllowance?.recaps ?? {};

export const selectIsGeneratingRecap = state => state.expenses?.businessAllowance?.isGeneratingRecap;

export const selectCurrentlyEditingTravel = state => state.expenses?.businessAllowance?.currentlyEditingTravel;

export const createHomeOfficeTravelsSelector = () => createSelector(
    [
        selectBusinessAllowanceTravels,
    ],
    businessAllowanceTravels => {
        if (!businessAllowanceTravels) {
            return [];
        }
        return Object.values(businessAllowanceTravels).filter(travel => travel.type === TRAVEL_TYPES.HOME_OFFICE);
    },
);

export const createProfessionalTravelsSelector = () => createSelector(
    [
        selectBusinessAllowanceTravels,
    ],
    businessAllowanceTravels => {
        if (!businessAllowanceTravels) {
            return [];
        }
        return Object.values(businessAllowanceTravels).filter(travel => travel.type === TRAVEL_TYPES.PROFESSIONAL);
    },
);

export const createTravelsTotalAmountSelector = () => createSelector(
    [
        selectBusinessAllowanceTravels,
    ],
    businessAllowanceTravels => {
        let totalAmount = 0;

        Object.values(businessAllowanceTravels).forEach(travel => {
            totalAmount += travel.amount;
        });

        return getTwoDecimals(totalAmount);
    },
);

export const createBusinessAllowanceRecapsSelector = () => createSelector(
    [
        selectBusinessAllowanceRecaps,
    ],
    businessAllowanceRecaps => {
        return Object.values(businessAllowanceRecaps);
    },
);

export const createBusinessAllowanceRecapsTableSelector = ({
    sortBy,
    sortDirection,
    pagination,
}) => createSelector(
    [selectBusinessAllowanceRecaps],
    businessAllowanceRecaps => {
        if (!Object.keys(businessAllowanceRecaps).length) {
            return {data: [], count: 0};
        }

        const data = Object.values(businessAllowanceRecaps);

        if (sortBy) {
            data.sort(createDataTableSortCallback(sortBy, sortDirection));
        }

        return getPaginatedResult(data, pagination);
    },
);

export const createHomeOfficeTravelsTableSelector = ({
    sortBy,
    sortDirection,
    pagination,
}) => createSelector(
    [createHomeOfficeTravelsSelector()],
    travels => {
        if (!travels.length) {
            return {data: [], count: 0};
        }

        if (sortBy) {
            travels.sort(createDataTableSortCallback(sortBy, sortDirection));
        }

        return getPaginatedResult(travels, pagination);
    },
);

export const createProfessionalTravelsTableSelector = ({
    sortBy,
    sortDirection,
    pagination,
}) => createSelector(
    [createProfessionalTravelsSelector()],
    travels => {
        if (!travels.length) {
            return {data: [], count: 0};
        }

        if (sortBy) {
            travels.sort(createDataTableSortCallback(sortBy, sortDirection));
        }

        return getPaginatedResult(travels, pagination);
    },
);

