import {FormControlLabel, FormLabel, Grid, TextField} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import React from 'react';
import {Controller} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {Checkbox} from '../../../../../../components/checkbox/Checkbox';
import Grow from '../../../../../../components/joy-ui/mui-legacy/Grow';
import {BE_DATE_FORMAT} from '../../../../../../utils/constants';
import {
    usePersonalInformationFormStyles,
} from '../../../../../../v1/components/setup-company/PersonalInformationForm/styles';
import {AnimationsSelectors} from '../../../../../animations/store/animations.selector';
import {FORM_ANIMATION_DELAY} from '../../../../../animations/utils/constants';

const PersonalInformationMedical = ({t, control, errors, hasNoMedicalCheckup}) => {
    const styles = usePersonalInformationFormStyles();
    const isSubAnimationActive = useSelector(AnimationsSelectors.selectIsSubAnimationActive);

    const maxDate = moment();
    const minDate = moment('01/01/1900', BE_DATE_FORMAT);

    return (
        <>
            <FormLabel component="legend" className={styles.formLabel}>
                {t('lastMedicalCheckupDateLabel')}
            </FormLabel>

            <Grow
                in={isSubAnimationActive}
                timeout={FORM_ANIMATION_DELAY * 2}
            >
                <Grid container spacing={4}>
                    <Grid
                        item
                        xs={12}
                        md={7}
                        sx={{
                            display: 'flex',
                            gap: 3,
                            alignItems: 'flex-start',
                        }}
                    >
                        <Controller
                            name="lastMedicalCheckupDate"
                            control={control}
                            render={({field}) => {
                                return (
                                    <DatePicker
                                        {...field}
                                        label={t('lastMedicalCheckupDate')}
                                        invalidDateMessage={t('form:validation.notDate')}
                                        error={!!errors.lastMedicalCheckupDate}
                                        helperText={errors.lastMedicalCheckupDate?.message}
                                        format={moment.localeData().longDateFormat('L')}
                                        views={['year', 'month', 'day']}
                                        inputVariant="filled"
                                        minDate={minDate}
                                        maxDate={maxDate}
                                        minDateMessage={t('form:validation.minDate', {date: minDate.format('L')})}
                                        maxDateMessage={t('form:validation.maxDate', {date: maxDate.format('L')})}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                variant="filled"
                                                required={true}
                                                disabled={hasNoMedicalCheckup}
                                            />
                                        )}
                                    />
                                );
                            }}
                            required
                            fullWidth
                            disableFuture
                        />

                        <Controller
                            control={control}
                            name="doNotHaveMedical"
                            render={({field}) => (
                                <FormControlLabel
                                    {...field}
                                    sx={{
                                        mt: 0.8,
                                    }}
                                    control={(
                                        <Checkbox />
                                    )}
                                    label={t('doNotHaveMedical')}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </Grow>
        </>
    );
};

PersonalInformationMedical.propTypes = {
    t: PropTypes.func.isRequired,
    control: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    hasNoMedicalCheckup: PropTypes.bool.isRequired,
};

export default PersonalInformationMedical;
