import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DOCUMENT_STATUSES} from '../../../../../../consts/document-constants';
import {useMemoizedCreateSelector} from '../../../../../../hooks/use-memoized-create-selector';
import {FreelancerSelectors} from '../../../../../freelancer';
import {
    CompanyRegistrationFileList,
} from '../../../../../freelancer/modules/company-registration/components/CompanyRegistrationFileList';
import {InsuranceActions} from '../../../../../insurance/store/insurance.action';
import {InsuranceStatus} from '../../../../../insurance/utils/constants';
import {DatabaseSelectors} from '../../../database/store/database.selector';
import {SigningActions} from '../../store/signing.action';
import {SigningSelectors} from '../../store/signing.selector';
import {DocumentSigningWrapper} from '../DocumentSigningWrapper';

const DocumentsSigningInsuranceWrapper = ({
    type,
}) => {
    const dispatch = useDispatch();

    const generatedDocuments = useSelector(DatabaseSelectors.selectDocuments);

    const documentId = useSelector(SigningSelectors.selectCurrentDocumentId);
    const document = useMemoizedCreateSelector(DatabaseSelectors.createDocumentByIdSelector, documentId);
    const freelancer = useSelector(FreelancerSelectors.selectAccount);

    const onNextFn = () => {
        dispatch(InsuranceActions.setInsurance({
            freelancerId: freelancer?.id,
            companyId: freelancer?.defaultCompanyId,
            data: {status: InsuranceStatus.SIGNED},
            loadInsurance: false,
        }));
    };

    useEffect(() => {
        const generatedDocumentsArray = Object.values(generatedDocuments);
        if (!document && generatedDocumentsArray?.length === 1) {
            dispatch(SigningActions.setCurrentCompanyDocument(generatedDocumentsArray[0]?.id));
        }
    }, [generatedDocuments, document, dispatch]);

    // If document is still generating or there is some error
    // show document list screen since it has handling of mentioned situations already implemented
    if (!document
        || (document?.status !== DOCUMENT_STATUSES.PENDING_SIGNING
            && document?.status !== DOCUMENT_STATUSES.SIGNED)
    ) {
        return (
            <CompanyRegistrationFileList hasIntegration={true} />
        );
    }

    return (
        <DocumentSigningWrapper
            type={type}
            onNextFn={onNextFn}
        />
    );
};

DocumentsSigningInsuranceWrapper.propTypes = {
    type: PropTypes.string,
};

DocumentsSigningInsuranceWrapper.defaultProps = {
    type: undefined,
};


export default DocumentsSigningInsuranceWrapper;
