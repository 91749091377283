import {OnboardingDto} from './onboarding.dto';
import {coreApiInstance} from '../../../lib/api-providers/core-api-instance';

// TODO For now only umbrella, later it will be for all users
const postUmbrellaUser = data => {
    return coreApiInstance
        .post(`/api/v2/onboardings`, OnboardingDto.toPostCreateUserDto(data))
        .then(result => result.data);
};

const getOnboarding = ({userId}) => {
    return coreApiInstance
        .get(`/api/v2/users/${userId}/onboarding`)
        .then(result => OnboardingDto.fromOnboardingDto(result.data));
};

const postPersonalInformation = ({userId, information}) => {
    return coreApiInstance
        .post(`/api/v2/users/${userId}/onboarding/personal-info`, OnboardingDto.toPostInformationDto(information))
        .then(result => OnboardingDto.fromOnboardingDto(result.data));
};

const postDocumentsOnboarding = ({userId, documents}) => {
    return coreApiInstance
        .post(`/api/v2/users/${userId}/onboarding/personal-documents`, OnboardingDto.toPostDocumentsDto(documents))
        .then(result => OnboardingDto.fromOnboardingDto(result.data));
};

const postLunchOnboarding = ({userId, onboardingId}) => {
    return coreApiInstance
        .post(`/api/v2/users/${userId}/onboarding/${onboardingId}/launch`)
        .then(result => OnboardingDto.fromOnboardingDto(result.data));
};

const getTerms = () => {
    return coreApiInstance
        .get(`/api/v2/terms`)
        .then(result => OnboardingDto.fromTermsDto(result.data));
};

const postSignTerm = ({userId, termId}) => {
    return coreApiInstance
        .post(`/api/v2/users/${userId}/onboarding/consent`, {termIds: [termId]})
        .then(result => OnboardingDto.fromOnboardingDto(result.data));
};

export const OnboardingApi = {
    postUmbrellaUser,
    getOnboarding,
    postLunchOnboarding,
    postPersonalInformation,
    postDocumentsOnboarding,
    getTerms,
    postSignTerm,
};
