import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PersonIcon from '@mui/icons-material/Person';
import {Box, Button, Grid, Grow, Paper, Slide, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import fingerPointEmoji from '../../../../../assets/png/finger-point-emoji.png';
import AndroidSvg from '../../../../../assets/svg/android.svg';
import FbSvg from '../../../../../assets/svg/facebook.svg';
import FigmaSvg from '../../../../../assets/svg/figma.svg';
import FlutterSvg from '../../../../../assets/svg/flutter.svg';
import GitLabSvg from '../../../../../assets/svg/gitlab.svg';
import HtmlSvg from '../../../../../assets/svg/html.svg';
import KotlinSvg from '../../../../../assets/svg/kotlin.svg';
import PhpSvg from '../../../../../assets/svg/php.svg';
import PosgreSvg from '../../../../../assets/svg/posgre.svg';
import PythonSvg from '../../../../../assets/svg/python.svg';
import ReactSvg from '../../../../../assets/svg/react.svg';
import VueSvg from '../../../../../assets/svg/vue.svg';
import {ButtonAction} from '../../../../../components/buttons/ButtonAction';
import TextEmoji from '../../../../../components/text-emoji/TextEmoji';
import {MainHeadlineLayout} from '../../../../../layout/MainHeadlineLayout/MainHeadlineLayout';
import {RoutePaths} from '../../../../../lib/router/route-paths';
import {isUserCareFn} from '../../../../../utils/user-roles';
import {AnimationActions} from '../../../../animations/store/animations.action';
import {AnimationsSelectors} from '../../../../animations/store/animations.selector';
import {ANIMATION_TYPE, BANNER_ANIMATION_DELAY} from '../../../../animations/utils/constants';
import {LoggedInUserSelectors} from '../../../../user/modules/logged-in-user';

const styles = {
    card: {
        width: '100%',
        // minHeight: '300px',
        borderRadius: '16px',
        boxShadow: '0 1px 1px 0 rgb(11 35 51 / 5%), 0 1px 1px -1px rgb(11 35 51 / 5%), 0 1px 3px 0 rgb(11 35 51 / 17%)',
        background: '#fff',
        py: 5,
        px: 6,
    },
    cardBackground: {
        background: 'linear-gradient(0deg, #F1F8E9 0%, #FFFFFF 55.73%)',
    },
    leftContainer: {
        pl: 6,
        pt: 5,
    },
    header: {
        fontSize: '48px',
        lineHeight: '120%',
        letterSpacing: '-0.5px',
        fontFamily: 'Red Hat Text',
        fontWeight: 700,
        width: '90%',
        pb: 4,
    },
    subtext: {
        fontWeight: 500,
        fontSize: '15px',
        color: 'rgba(0, 0, 0, 0.38)',
        mt: 2,
    },
    estimate: {
        ml: 5,
        mb: 3,
    },
    iconConatiner: {
        marginTop: '-36px',
        mr: 6,
    },
    icon: {
        width: '171px',
        height: '217px',
    },
    button: {
        pl: 3,
        pr: 3,
    },
};

const mobileStyles = {
    mainContainer: {
        minHeight: '300px',
        display: 'flex',
    },
    card: {
        width: '100%',
        height: '100%',
        p: 3,
    },
    successBackground: {
        background: 'linear-gradient(0deg, #F1F8E9 0%, #FFFFFF 55.73%)',
    },
    header: {
        fontSize: '34px',
        lineHeight: '120%',
        letterSpacing: '-0.2px',
        fontFamily: 'Red Hat Text',
        fontWeight: 700,
        width: '90%',
    },
    subtext: {
        fontWeight: 500,
        fontSize: '15px',
        color: 'rgba(0, 0, 0, 0.38)',
        mt: 2,
    },
    estimate: {
        mt: 1,
        mb: 3,
    },
    buttonContainer: {
        position: 'fixed',
        bottom: 0,
        background: 'white',
        boxShadow: '0px 7px 8px -4px rgba(11, 35, 51, 0.08), 0px 12px 17px 2px rgba(11, 35, 51, 0.08), 0px 5px 22px 4px rgba(11, 35, 51, 0.1)',
        borderRadius: '16px 16px 0px 0px',
        height: '128px',
        pt: 2,
        pl: 2,
        pr: 2,
    },
    buttonText: {
        fontSize: '16px',
        fontWeight: 400,
        letterSpacing: '0.15px',
    },
    button: {
        width: '100%',
        pt: 1,
        pb: 1,
        mb: 2,
    },
};

export const WorkShopBanner = ({
    headline,
    header,
    headlineText,
    nextText,
    nextButtonText,
    onNextClick,
    content,
    isFinished,
    NextButtonProps,
}) => {
    const dispatch = useDispatch();

    const {t} = useTranslation('freelancerOnboarding');

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const loggedInUser = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const isUserCareRole = isUserCareFn(loggedInUser);

    const isAnimationActive = useSelector(AnimationsSelectors.selectIsAnimationActive);
    const isSubAnimationActive = useSelector(AnimationsSelectors.selectIsSubAnimationActive);

    const onSubEndedAnimation = () => {
        dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.SECONDARY));
    };

    if (!isMobileSize) {
        return (
            <MainHeadlineLayout headline={isFinished ? '' : headline} text={isFinished ? '' : headlineText}>
                <Slide
                    direction="left"
                    in={isAnimationActive}
                    onTransitionEnd={() => {
                        dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.MAIN));
                    }}
                >
                    <Grid
                        sx={isFinished ? {...styles.card, ...styles.cardBackground} : styles.card}
                        container
                        flexDirection="row"
                        justifyContent="space-between"
                        flexWrap="nowrap"
                    >
                        <Grid item xs={12}>
                            {isFinished && (
                                <Grid item sx={styles.header}>
                                    {header} <TextEmoji src={fingerPointEmoji} alt="finger point emoji" />
                                </Grid>
                            )}
                            <Grow
                                in={isSubAnimationActive}
                                timeout={BANNER_ANIMATION_DELAY}
                                onTransitionEnd={onSubEndedAnimation}
                            >
                                <Grid item container justifyContent="center">{content}</Grid>
                            </Grow>
                        </Grid>
                    </Grid>
                </Slide>

                {!isFinished && !isUserCareRole && (
                    <Paper
                        sx={{
                            mt: 4,
                            borderRadius: '16px',
                        }}
                        elevation={1}
                    >
                        <Box sx={{display: 'flex', justifyContent: 'space-between', overflow: 'hidden', gap: 1}}>
                            <Box
                                sx={{
                                    pt: 3,
                                    pb: 4,
                                    pl: 5,
                                    flexShrink: 0,
                                }}
                            >
                                <Typography variant="h5">
                                    {t('opportunity.header')}
                                </Typography>
                                <Typography sx={{maxWidth: '380px', mt: 1, mb: 2}}>
                                    {t('opportunity.text1')}
                                    {t('opportunity.text2')}
                                </Typography>
                                <Button
                                    component={Link}
                                    to={RoutePaths.MY_PROFILE}
                                    startIcon={<PersonIcon />}
                                    variant="contained"
                                    sx={{mr: 2}}

                                >
                                    {t('opportunity.button')}
                                </Button>
                                <Button
                                    component={Link}
                                    to={RoutePaths.CLUSTER}
                                    endIcon={<ArrowForwardIcon />}
                                    variant="outlined"
                                >
                                    {t('opportunity.button2')}
                                </Button>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-evenly',
                                flex: 1,
                                height: '200px',
                                alignItems: 'center',
                            }}
                            >
                                <Box sx={{position: 'relative', bottom: '8px'}}>
                                    <Box sx={{mb: 5}}>
                                        <img src={GitLabSvg} alt="git lab" />
                                    </Box>
                                    <Box>
                                        <img src={AndroidSvg} alt="android" />
                                    </Box>
                                </Box>

                                <Box sx={{position: 'relative', top: '37px'}}>
                                    <Box sx={{mb: 5}}>
                                        <img src={PosgreSvg} alt="posgre" />
                                    </Box>
                                    <Box>
                                        <img src={FlutterSvg} alt="flutter" />
                                    </Box>
                                </Box>

                                <Box sx={{position: 'relative', bottom: '8px'}}>
                                    <Box sx={{mb: 5}}>
                                        <img src={VueSvg} alt="vuejs" />
                                    </Box>
                                    <Box>
                                        <img src={FigmaSvg} alt="figma" />
                                    </Box>
                                </Box>

                                <Box sx={{position: 'relative', top: '37px'}}>
                                    <Box sx={{mb: 5}}>
                                        <img src={KotlinSvg} alt="kolin" />
                                    </Box>
                                    <Box>
                                        <img src={PhpSvg} alt="php" />
                                    </Box>
                                </Box>

                                <Box sx={{position: 'relative', bottom: '8px'}}>
                                    <Box sx={{mb: 5}}>
                                        <img src={PythonSvg} alt="python" />
                                    </Box>
                                    <Box>
                                        <img src={FbSvg} alt="facebook" />
                                    </Box>
                                </Box>

                                <Box sx={{position: 'relative', top: '37px'}}>
                                    <Box sx={{mb: 5}}>
                                        <img src={ReactSvg} alt="react" />
                                    </Box>
                                    <Box>
                                        <img src={HtmlSvg} alt="html" />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Paper>
                )}

                <Grid container item justifyContent="flex-end" sx={{mt: 3}} alignItems="center">
                    <Slide direction="left" in={isAnimationActive} timeout={BANNER_ANIMATION_DELAY * 3}>
                        <div>
                            <Box component="span" sx={{color: 'rgba(11, 35, 51, 1)', mr: 4}}>{nextText}</Box>
                            <ButtonAction
                                endIcon={<ArrowForwardIcon />}
                                sx={styles.button}
                                color="secondary"
                                onClick={onNextClick}
                                {...NextButtonProps}
                            >
                                {nextButtonText}
                            </ButtonAction>
                        </div>
                    </Slide>
                </Grid>
            </MainHeadlineLayout>
        );
    }

    return (
        <Grid container flexDirection="column">
            <Box sx={{p: 2}}>
                {!isFinished && (
                <Typography variant="h4">{isUserCareRole
                    ? t('workshop.completeCoachingEI')
                    : t('workshop.completeCoaching')}
                </Typography>
                )}
                {isFinished && (
                <Typography variant="h4">{t('workshop.finalHeader')}
                    <TextEmoji src={fingerPointEmoji} alt="finger point emoji" />
                </Typography>
                )}
                {!isFinished && <Typography sx={{mt: 1}}>{t('workshop.coachingHeadlineText')} </Typography>}
            </Box>
            <Paper
                sx={{
                    borderRadius: '16px',
                    mb: isFinished ? 12 : 2,
                }}
                elevation={1}
            >
                <Grid item sx={mobileStyles.mainContainer}>
                    <Grid sx={isFinished ? {...mobileStyles.card, ...mobileStyles.successBackground} : mobileStyles.card} container flexDirection="column" flexWrap="nowrap" justifyContent="center">
                        <Grid item container sx={{pb: 10}}>
                            {content}
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>

            {!isFinished && !isUserCareRole && (
                <Paper
                    sx={{
                        mb: 14,
                        borderRadius: '16px',
                    }}
                    elevation={1}
                >
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                    }}
                    >
                        <Box
                            sx={{
                                pt: 3,
                                pb: 4,
                                pl: 5,
                            }}
                        >
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-evenly',
                                width: '100%',
                                height: '134px',
                                alignItems: 'center',
                            }}
                            >
                                <Box sx={{position: 'relative', bottom: '8px'}}>
                                    <Box sx={{mb: 5}}>
                                        <img width="60%" height="60%" src={GitLabSvg} alt="git lab" />
                                    </Box>
                                    <Box>
                                        <img width="60%" height="60%" src={AndroidSvg} alt="android" />
                                    </Box>
                                </Box>

                                <Box sx={{position: 'relative', top: '12px'}}>
                                    <Box sx={{mb: 5}}>
                                        <img width="60%" height="60%" src={PosgreSvg} alt="posgre" />
                                    </Box>
                                    <Box>
                                        <img width="60%" height="60%" src={FlutterSvg} alt="flutter" />
                                    </Box>
                                </Box>

                                <Box sx={{position: 'relative', bottom: '8px'}}>
                                    <Box sx={{mb: 5}}>
                                        <img width="60%" height="60%" src={VueSvg} alt="vuejs" />
                                    </Box>
                                    <Box>
                                        <img width="60%" height="60%" src={FigmaSvg} alt="figma" />
                                    </Box>
                                </Box>

                                <Box sx={{position: 'relative', top: '12px'}}>
                                    <Box sx={{mb: 5}}>
                                        <img width="60%" height="60%" src={KotlinSvg} alt="kolin" />
                                    </Box>
                                    <Box>
                                        <img width="60%" height="60%" src={PhpSvg} alt="php" />
                                    </Box>
                                </Box>

                                <Box sx={{position: 'relative', bottom: '8px'}}>
                                    <Box sx={{mb: 5}}>
                                        <img width="60%" height="60%" src={PythonSvg} alt="python" />
                                    </Box>
                                    <Box>
                                        <img width="60%" height="60%" src={FbSvg} alt="facebook" />
                                    </Box>
                                </Box>

                                <Box sx={{position: 'relative', top: '12px'}}>
                                    <Box sx={{mb: 5}}>
                                        <img width="60%" height="60%" src={ReactSvg} alt="react" />
                                    </Box>
                                    <Box>
                                        <img width="60%" height="60%" src={HtmlSvg} alt="html" />
                                    </Box>
                                </Box>
                            </Box>

                            <Typography variant="h5">
                                {t('opportunity.header')}
                            </Typography>
                            <Typography sx={{maxWidth: '380px', mt: 1, mb: 2}}>
                                {t('opportunity.text1')}
                                {t('opportunity.text2')}
                            </Typography>
                            <Button
                                component={Link}
                                to={RoutePaths.MY_PROFILE}
                                startIcon={<PersonIcon />}
                                variant="contained"
                                sx={{mr: 2, mb: 2}}
                            >
                                {t('opportunity.button')}
                            </Button>
                            <Button
                                component={Link}
                                to={RoutePaths.CLUSTER}
                                endIcon={<ArrowForwardIcon />}
                                variant="outlined"
                                sx={{verticalAlign: 'top'}}
                            >
                                {t('opportunity.button2')}
                            </Button>
                        </Box>
                    </Box>
                </Paper>
            )}

            <Grid container sx={mobileStyles.buttonContainer} justifyContent="center">
                <Box sx={mobileStyles.buttonText}>{nextText}</Box>
                <ButtonAction
                    endIcon={<ArrowForwardIcon />}
                    sx={mobileStyles.button}
                    color="secondary"
                    onClick={onNextClick}
                    {...NextButtonProps}
                >
                    {nextButtonText}
                </ButtonAction>
            </Grid>
        </Grid>
    );
};

WorkShopBanner.propTypes = {
    headline: PropTypes.string,
    header: PropTypes.string.isRequired,
    headlineText: PropTypes.string,
    nextText: PropTypes.string,
    nextButtonText: PropTypes.string.isRequired,
    onNextClick: PropTypes.func.isRequired,
    content: PropTypes.object,
    isFinished: PropTypes.bool.isRequired,
    NextButtonProps: PropTypes.object,
};

WorkShopBanner.defaultProps = {
    nextText: '',
    content: null,
    headline: '',
    headlineText: '',
    NextButtonProps: {},
};
