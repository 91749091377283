export const getTag = value => {
    if (value == null) {
        return value === undefined ? '[object Undefined]' : '[object Null]';
    }

    return Object.prototype.toString.call(value);
};

export const isObjectLike = value => typeof value === 'object' && value !== null;

export const isNumber = value => typeof value === 'number'
    || (isObjectLike(value) && getTag(value) === '[object Number]');

export const isString = value => {
    const type = typeof value;

    return type === 'string'
        || (type === 'object' && value != null && !Array.isArray(value) && getTag(value) === '[object String]');
};
