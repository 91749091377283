import {all, call, fork, put} from 'redux-saga/effects';
import {FreelancerActions} from './freelancer.action';
import {Debug} from '../../../utils/debug';
import * as actions from '../../../v1/app/freelancer/freelancer.actions';
import {contractSaga} from '../../contract/store/contract.saga';
import {LoadingActions, LoadingTypes} from '../../loading';
import {FreelancerApi} from '../api/freelancer.api';
import {companiesSaga} from '../modules/companies';
import {contractSigningSaga} from '../modules/contract-signing/store/contract-signing.saga';
// eslint-disable-next-line import/no-cycle
import {onboardingSaga} from '../modules/onboarding/store/onboarding.saga';
// eslint-disable-next-line import/no-cycle
import {workshopsSaga} from '../modules/workshops/store/workshops.saga';

const getAccountFlow = function* ({freelancerId}) {
    try {
        const data = yield call(FreelancerApi.getAccount, {freelancerId});

        yield put(FreelancerActions.storeAccount(data));
        // TODO Join this data as one and use single selector
        yield put(actions.storeFreelancerAccount(data));
    } catch (error) {
        Debug.error('freelancer', error.message, {error});
    }
};

export const loadFreelancerAccount = function* ({freelancerId}) {
    yield put(LoadingActions.setLoading(LoadingTypes.FREELANCER_ACCOUNT, true));

    yield call(getAccountFlow, {freelancerId});

    yield put(LoadingActions.setLoading(LoadingTypes.FREELANCER_ACCOUNT, false));
};

export const freelancerSaga = function* () {
    yield all([
        fork(companiesSaga),
        fork(onboardingSaga),
        fork(contractSigningSaga),
        fork(workshopsSaga),
        fork(contractSaga),
    ]);
};
