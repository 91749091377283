import DocumentScannerTwoToneIcon from '@mui/icons-material/DocumentScannerTwoTone';
import PlayLessonIcon from '@mui/icons-material/PlayLesson';
import {Box, Divider, List, ListItemButton, ListItemIcon, ListItemText} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import UmbrellaStepper from './UmbrellaStepper';
import JoyUIThemeProvider from '../../../../components/joy-ui/joyui-theme-provider/JoyUiThemeProvider';
import {importLocaleBundle} from '../../../../lib/i18next';
import {resolveRoute} from '../../../../lib/router/resolveRoute';
import {ROUTE_PATHS, RoutePaths} from '../../../../lib/router/route-paths';
import {UserStatuses} from '../../../../utils/user-statuses';
import {ProfileItemSection} from '../../../sidebar/components/Common/ProfileItemSection';
import {SidebarSelectors} from '../../../sidebar/store/sidebar.selector';
import {LoggedInUserSelectors} from '../../../user/modules/logged-in-user';

importLocaleBundle('freelancerOnboarding');
// ONBOARDING SIDEBAR LOCATION CHANGE - Shorthand to find all places we need to change when adding new item
export const UmbrellaOnboardingSidebar = () => {
    const isSidebarExpanded = useSelector(SidebarSelectors.selectIsSidebarExpanded);
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const loggedInUser = useSelector(LoggedInUserSelectors.selectLoggedInUser);

    const isExpandedOrMobile = isSidebarExpanded || isMobileSize;

    return (
        <Box sx={{pb: 11}}>
            {!isMobileSize && (
                <JoyUIThemeProvider>
                    <ProfileItemSection
                        user={loggedInUser}
                        isSidebarExpanded={isSidebarExpanded}
                    />
                </JoyUIThemeProvider>
            )}

            {!isExpandedOrMobile && <Divider />}
            {isMobileSize && <Box sx={{mt: 2}} />}

            <UmbrellaStepper
                loggedInUser={loggedInUser}
            />

            <AdditionalSidebarOptions
                isExpandedOrMobile={isExpandedOrMobile}
            />
        </Box>
    );
};


const AdditionalSidebarOptions = ({isExpandedOrMobile}) => {
    const {t} = useTranslation(['common']);

    const loggedInUser = useSelector(LoggedInUserSelectors.selectLoggedInUser);

    return (
        <List sx={{mt: 1, pb: 0}}>
            <Divider />
            <ListItemButton
                sx={{
                    minHeight: 48,
                    justifyContent: isExpandedOrMobile ? 'initial' : 'center',
                    px: !isExpandedOrMobile ? 4 : 2.5,
                    mt: 1,
                }}
                key="DOCUMENTATION"
                to={resolveRoute(RoutePaths.DOCUMENTATION)}
                style={{textDecoration: 'none', color: '#0B2333'}}
                component={Link || undefined}
            >
                <ListItemIcon
                    sx={{
                        minWidth: 0,
                        mr: isExpandedOrMobile ? 2 : 'auto',
                        justifyContent: 'center',
                        color: 'v2.blueGray.300',
                    }}
                >
                    <PlayLessonIcon />
                </ListItemIcon>

                <ListItemText
                    primary={t('common:sideMenu.documentation')}
                    primaryTypographyProps={{fontSize: theme => theme.typography.pxToRem(14)}}
                    sx={{
                        display: isExpandedOrMobile ? 'inline' : 'none',
                    }}
                />

            </ListItemButton>

            <ListItemButton
                sx={{
                    minHeight: 48,
                    justifyContent: isExpandedOrMobile ? 'initial' : 'center',
                    px: !isExpandedOrMobile ? 4 : 2.5,
                }}
                key="Documents"
                to={resolveRoute(ROUTE_PATHS.DOCUMENTS, {companyId: 'TODO'})}
                style={{textDecoration: 'none', color: '#0B2333'}}
                disabled={loggedInUser.status === UserStatuses.CONTRACT_PENDING}
                component={Link || undefined}
            >

                <ListItemIcon
                    sx={{
                        minWidth: 0,
                        mr: isExpandedOrMobile ? 2 : 'auto',
                        justifyContent: 'center',
                        color: 'v2.blueGray.300',
                    }}
                >
                    <DocumentScannerTwoToneIcon />
                </ListItemIcon>

                <ListItemText
                    primary={t('common:sideMenu.documents')}
                    primaryTypographyProps={{fontSize: theme => theme.typography.pxToRem(14)}}
                    sx={{display: isExpandedOrMobile ? 'inline' : 'none'}}
                />

            </ListItemButton>
        </List>
    );
};

AdditionalSidebarOptions.propTypes = {
    isExpandedOrMobile: PropTypes.bool.isRequired,
};
