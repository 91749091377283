import {
    faArrowRightArrowLeft,
    faArrowRotateLeft,
    faBell,
    faBolt,
    faBoxesPacking,
    faBoxesStacked,
    faBriefcase,
    faBriefcaseMedical,
    faBuilding,
    faBuildingColumns,
    faBus,
    faCar,
    faCarSide,
    faCircleDollarToSlot,
    faComputer,
    faCouch,
    faCreditCard,
    faDoorOpen,
    faEllipsis,
    faEuroSign,
    faFileInvoiceDollar,
    faFileLines,
    faGasPump,
    faGavel,
    faGift,
    faGraduationCap,
    faKey,
    faListCheck,
    faMobileScreen,
    faMoneyBillTransfer,
    faMoneyBills,
    faMoneyCheckDollar,
    faMugSaucer,
    faNoteSticky,
    faPiggyBank,
    faPlug,
    faQuestion,
    faReceipt,
    faScrewdriverWrench,
    faShieldHalved,
    faTicket,
    faTrain,
    faUserGroup,
    faUtensils,
    faWallet,
} from '@fortawesome/free-solid-svg-icons';
import {theme} from '../../../../../lib/theme';

export const PAYMENT_TYPES = {
    TRANSFER_IN: 'TRANSFER_IN',
    TRANSFER_OUT: 'TRANSFER_OUT',
    DIRECT_DEBIT: 'DIRECT_DEBIT',
    CREDIT_CARD_PAYMENT: 'CREDIT_CARD_PAYMENT',
};

export const PAYMENT_OUT_TYPES = {
    SINGLE: 'SINGLE',
    RECURRING: 'RECURRING',
};

export const PAYMENT_TYPES_FOR_FILTER = {
    'TRANSFER': {
        type: 'TRANSFER',
        contaningTypesList: [
            PAYMENT_TYPES.TRANSFER_IN,
            PAYMENT_TYPES.TRANSFER_OUT,
        ],
    },
};

export const FILTER_LIST_MAP = [
    PAYMENT_TYPES.CREDIT_CARD_PAYMENT,
    PAYMENT_TYPES_FOR_FILTER['TRANSFER'].type,
    PAYMENT_TYPES.DIRECT_DEBIT,
];

export const LIMIT_COUNT = 20;

export const SOURCE_OF_REVENUE_OPTIONS = {
    INVOICE: 'INVOICE',
    REIMBURSEMENT_TRANSACTIONS: 'REIMBURSEMENT_TRANSACTIONS',
    SUBSIDIES: 'SUBSIDIES',
    CAPITAL_DEPOSIT: 'CAPITAL_DEPOSIT',
    DONT_KNOW: 'DONT_KNOW',
    TRANSFER_BETWEEN_ACCOUNTS: 'TRANSFER_BETWEEN_ACCOUNTS',
    CONTRIBUTION_TO_THE_SHAREHOLDER_ACCOUNT: 'CONTRIBUTION_TO_THE_SHAREHOLDER_ACCOUNT',
    OTHER_EXPENSES: 'OTHER_EXPENSES',
    // CARE
    CARE_FEE: 'CARE_FEE',
    RETROCESSION_FEE: 'RETROCESSION_FEE',
    SOCIAL_SECURITY_PAYMENT_FEE: 'SOCIAL_SECURITY_PAYMENT_FEE',
};

const MCC_GROUP_CONSTANTS = {
    MCC_GROUP_EXPENSES: 'MCC_GROUP_EXPENSES',
    MCC_GROUP_REVENUE: 'MCC_GROUP_REVENUE',
    MCC_GROUP_TAXES: 'MCC_GROUP_TAXES',
    MCC_GROUP_OTHER: 'MCC_GROUP_OTHER',
};

const SOR_GROUP_CONSTANTS = {
    SOURCE_OF_REVENUE_GROUP_INVOICES: 'SOURCE_OF_REVENUE_GROUP_INVOICES',
    SOURCE_OF_REVENUE_GROUP_REIMBURSEMENT: 'SOURCE_OF_REVENUE_GROUP_REIMBURSEMENT',
    SOURCE_OF_REVENUE_GROUP_OTHER_REVENUES: 'SOURCE_OF_REVENUE_GROUP_OTHER_REVENUES',
    SOURCE_OF_REVENUE_GROUP_OTHER: 'SOURCE_OF_REVENUE_GROUP_OTHER',
    SOURCE_OF_REVENUE_GROUP_REVENUES: 'SOURCE_OF_REVENUE_GROUP_REVENUES',
};

export const COLORS_PALETTE = {
    // Categories
    [MCC_GROUP_CONSTANTS.MCC_GROUP_EXPENSES]: theme.palette.v2.light.warning.shades12,
    [MCC_GROUP_CONSTANTS.MCC_GROUP_REVENUE]: theme.palette.v2.dark.info.shades12,
    [MCC_GROUP_CONSTANTS.MCC_GROUP_TAXES]: theme.palette.v2.light.error.shades12,
    [MCC_GROUP_CONSTANTS.MCC_GROUP_OTHER]: theme.palette.gray_v2[100],
    // Sources
    [SOR_GROUP_CONSTANTS.SOURCE_OF_REVENUE_GROUP_INVOICES]: theme.palette.v2.dark.info.shades30,
    [SOR_GROUP_CONSTANTS.SOURCE_OF_REVENUE_GROUP_REIMBURSEMENT]: theme.palette.v2.light.success.shades30,
    [SOR_GROUP_CONSTANTS.SOURCE_OF_REVENUE_GROUP_OTHER_REVENUES]: theme.palette.v2.light.warning.shades30,
    [SOR_GROUP_CONSTANTS.SOURCE_OF_REVENUE_GROUP_OTHER]: theme.palette.v2.blueGray[100],
    [SOR_GROUP_CONSTANTS.SOURCE_OF_REVENUE_GROUP_REVENUES]: theme.palette.v2.dark.info.shades30,
};

/*
TODO: Remove this after BE returns FA icons for categories
We don't use it, but for now don't remove for comparison just in case

export const CATEGORY_ICONS = {
    // MCC_GROUP_EXPENSES - 53 items
    TRANSFERS_BETWEEN_ACCOUNTS: 'SyncAltTwoToneIcon',
    TRANSPORT_VEHICLE_CREDIT: 'DirectionsCarTwoToneIcon',
    TRANSPORT_VEHICLE_LOAN_INTEREST: 'DirectionsCarTwoToneIcon',
    COLLECTIVE_PENSION_CONTRACT: 'HealthAndSafetyTwoToneIcon',
    LEADERS_MEAL: 'RestaurantTwoToneIcon',
    BUSINESS_MEAL: 'CoffeeTwoToneIcon',
    TRAVEL: 'TrainTwoToneIcon',
    HARDWARE: 'DevicesTwoToneIcon',
    INVESTMENTS_SCPI: 'BusinessCenterTwoToneIcon',
    CESU: 'CreditCardTwoToneIcon',
    OTHER_EMPLOYEES_FEES: 'PaymentsTwoToneIcon',
    HOLIDAYS_CHECK_ANCV: 'AirplaneTicketTwoToneIcon',
    STATIONERY: 'PrintTwoToneIcon',
    FURNITURE: 'TableRestaurantTwoToneIcon',
    PURCHASE_OF_SOFTWARE: 'WysiwygTwoToneIcon',
    SOFTWARE_SUBSCRIPTION: 'SubscriptionsTwoToneIcon',
    SUBCONTRACTING_OTHERS: 'ElectricalServicesTwoToneIcon',
    EXHIBITION_OR_CONFERENCE: 'MeetingRoomTwoToneIcon',
    INSURANCE: 'HealthAndSafetyTwoToneIcon',
    RETIREMENT: 'WidgetsTwoToneIcon',
    SOCIAL_PROTECTION: 'MedicalServicesTwoToneIcon',
    INTERNET_AND_MOBILE_SUBSCRIPTION: 'CellWifiTwoToneIcon',
    BANK_CHARGES: 'AccountBalanceTwoToneIcon',
    VEHICLE_RENT_MORE_THAN_SIX_MONTHS: 'CarRentalTwoToneIcon',
    VEHICLE_RENT_UP_TO_SIX_MONTHS: 'CarRentalTwoToneIcon',
    FINES_AND_PENALTIES: 'PaymentsTwoToneIcon',
    DONATIONS: 'RedeemTwoToneIcon',
    EMPLOYEE_COMPENSATION: 'AccountBalanceWalletTwoToneIcon',
    TECHNICAL_DOCUMENTATION: 'DescriptionTwoToneIcon',
    MAINTENANCE_AND_REPAIR: 'HandymanTwoToneIcon',
    HOUSE_RENTAL: 'ApartmentTwoToneIcon',
    SHORT_TERM_RENT: 'KeyTwoToneIcon',
    RENTAL_CHARGES: 'VpnKeyTwoToneIcon',
    VEHICLE_PURCHASE: 'DirectionsCarTwoToneIcon',
    ADMINISTRATIVE_SUPPLIES: 'NoteTwoToneIcon',
    OTHER: 'SmsTwoToneIcon',
    COURT_FEES: 'GavelTwoToneIcon',
    FUEL_PETROL: 'LocalGasStationTwoToneIcon',
    REPAYMENT_OF_CREDITS: 'AccountBalanceTwoToneIcon',
    REPAYMENT_OF_INTEREST_CREDITS: 'PaidTwoToneIcon',
    HIWAY_SUBCONTRACTING: 'WidgetsTwoToneIcon',
    FEES: 'WidgetsTwoToneIcon',
    HIWAY_ACCOUNTING: 'WidgetsTwoToneIcon',
    OGI: 'WidgetsTwoToneIcon',
    COMMISSION_ON_SALES: 'WidgetsTwoToneIcon',
    MILEAGE_ALLOWANCES: 'WidgetsTwoToneIcon',
    CAPITAL_DEPOSIT: 'WidgetsTwoToneIcon',
    GIFTS_TO_CLIENT: 'CardGiftcardIcon',
    VOCATIONAL_TRAINING: 'SchoolTwoToneIcon',
    ADESSAT: 'GroupIcon',
    ELECTRICITY_AND_GAS: 'BoltTwoToneIcon',
    PER: 'SavingsTwoToneIcon',
    HEALTH_INSURANCE: 'MedicalServicesTwoToneIcon',
    // MCC_GROUP_REVENUE - 6 items
    BNC_N: 'EuroSymbolTwoToneIcon',
    WITHDRAWAL: 'AtmTwoToneIcon',
    MANAGERS_INCOME_N: 'EuroSymbolTwoToneIcon',
    MANAGERS_INCOME_N-1: 'EuroSymbolTwoToneIcon',
    DIVIDENDS: 'MonetizationOnIcon',
    INVESTMENT_REVENUE: 'ApartmentTwoToneIcon',
    // MCC_GROUP_TAXES - 13 items
    URSSAF: 'AssuredWorkloadTwoToneIcon',
    URSSAF_TNS: 'AssuredWorkloadTwoToneIcon',
    CIPAV: 'AssuredWorkloadTwoToneIcon',
    PENSION: 'HealthAndSafetyTwoToneIcon',
    CFE: 'AssuredWorkloadTwoToneIcon',
    VAT_PAYMENT: 'AssuredWorkloadTwoToneIcon',
    INCOME_TAX_DIRECT_DEBIT: 'AssuredWorkloadTwoToneIcon',
    DEDUCTIBLE_CSG_PAYMENT: 'AssuredWorkloadTwoToneIcon',
    CORPORATE_TAX: 'AssuredWorkloadTwoToneIcon',
    FLAT_TAX: 'MonetizationOnIcon',
    TVS: 'CommuteTwoToneIcon',
    TRAINING_TAX: 'SchoolTwoToneIcon',
    OTHER_TAXES: 'RequestQuoteTwoToneIcon',
    // MCC_GROUP_OTHER - 3 items
    DEBIT_OTHER_EXPENSES: 'AccountBalanceIcon',
    AMR_RECLASSIFIED: 'PlaylistAddCheckIcon',
    I_DONT_KNOW: 'QuestionMarkIcon',
};
*/

export const SOURCE_ICONS = {
    [SOURCE_OF_REVENUE_OPTIONS.INVOICE]: 'ReceiptTwoTone',
    [SOURCE_OF_REVENUE_OPTIONS.REIMBURSEMENT_TRANSACTIONS]: 'RotateLeftTwoTone',
    [SOURCE_OF_REVENUE_OPTIONS.SUBSIDIES]: 'WidgetsTwoTone',
    [SOURCE_OF_REVENUE_OPTIONS.CAPITAL_DEPOSIT]: 'WidgetsTwoTone',
    [SOURCE_OF_REVENUE_OPTIONS.TRANSFER_BETWEEN_ACCOUNTS]: 'WidgetsTwoTone',
    [SOURCE_OF_REVENUE_OPTIONS.DONT_KNOW]: 'QuestionMark',
    [SOURCE_OF_REVENUE_OPTIONS.CONTRIBUTION_TO_THE_SHAREHOLDER_ACCOUNT]: 'WidgetsTwoTone',
    [SOURCE_OF_REVENUE_OPTIONS.OTHER_EXPENSES]: 'WidgetsTwoTone',
    [SOURCE_OF_REVENUE_OPTIONS.CARE_FEE]: 'PaymentsTwoTone',
    [SOURCE_OF_REVENUE_OPTIONS.RETROCESSION_FEE]: 'SwapHorizontalCircleTwoTone',
    [SOURCE_OF_REVENUE_OPTIONS.SOCIAL_SECURITY_PAYMENT_FEE]: 'PaymentsTwoTone',
};

export const convertIconsFromMUIToFA = {
    'AccountBalance': faBuildingColumns,
    'AccountBalanceTwoTone': faBuildingColumns,
    'AccountBalanceWalletTwoTone': faWallet,
    'AirplaneTicketTwoTone': faTicket, // faTicketsAirline - pro or faPlaneDeparture - free
    'ApartmentTwoTone': faBuilding,
    'AssuredWorkloadTwoTone': faBuildingColumns,
    'AtmTwoTone': faCreditCard,
    'BoltTwoTone': faBolt,
    'BusinessCenterTwoTone': faBriefcase,
    'CardGiftcard': faGift,
    'CarRentalTwoTone': faCarSide,
    'CellWifiTwoTone': faMobileScreen,
    'CoffeeTwoTone': faMugSaucer,
    'CommuteTwoTone': faBus,
    'CreditCardTwoTone': faCreditCard,
    'DescriptionTwoTone': faFileLines,
    'DevicesTwoTone': faComputer,
    'DirectionsCarTwoTone': faCar,
    'ElectricalServicesTwoTone': faPlug,
    'EuroSymbolTwoTone': faEuroSign,
    'GavelTwoTone': faGavel,
    'Group': faUserGroup,
    'HandymanTwoTone': faScrewdriverWrench,
    'HealthAndSafetyTwoTone': faShieldHalved,
    'KeyTwoTone': faKey,
    'LocalGasStationTwoTone': faGasPump,
    'MedicalServicesTwoTone': faBriefcaseMedical,
    'MeetingRoomTwoTone': faDoorOpen,
    'MonetizationOn': faCircleDollarToSlot,
    'NoteTwoTone': faNoteSticky,
    'PaidTwoTone': faMoneyCheckDollar,
    'PaymentsTwoTone': faMoneyBills,
    'PlaylistAddCheck': faListCheck,
    'ReceiptTwo': faReceipt,
    'RedeemTwoTone': faGift,
    'RestaurantTwoTone': faUtensils,
    'RequestQuoteTwoTone': faFileInvoiceDollar,
    'RotateLeftTwo': faArrowRotateLeft,
    'PrintTwoTone': faBoxesPacking,
    'QuestionMark': faQuestion,
    'SavingsTwoTone': faPiggyBank,
    'SchoolTwoTone': faGraduationCap,
    'SmsTwoTone': faEllipsis,
    'SubscriptionsTwoTone': faBell,
    'SwapHorizontalCircleTwoTone': faArrowRightArrowLeft,
    'SyncAltTwoTone': faMoneyBillTransfer,
    'TableRestaurantTwoTone': faCouch, // faBoxesPacking,
    'TrainTwoTone': faTrain,
    'VpnKeyTwoTone': faKey,
    'WidgetsTwoTone': faBoxesStacked,
    'WysiwygTwoTone': faReceipt,
    'default': faReceipt,
};

export const TVA_ENUM = {
    TVA_0: 'TVA_0',
    TVA_5: 'TVA_5',
    TVA_10: 'TVA_10',
    TVA_16: 'TVA_16',
    TVA_20: 'TVA_20',
    NO_VAT: 'NO_TVA',
    TVA_CUSTOM: 'TVA_CUSTOM',
};

export const TVA_FACTORS_ENUM = {
    TVA_0: 0,
    TVA_5: 0.05,
    TVA_10: 0.1,
    TVA_16: 0.16,
    TVA_20: 0.2,
};

export const TVA_OPTIONS = [{
    value: TVA_ENUM.TVA_0,
}, {
    value: TVA_ENUM.TVA_5,
}, {
    value: TVA_ENUM.TVA_10,
}, {
    value: TVA_ENUM.TVA_20,
}, {
    value: TVA_ENUM.TVA_CUSTOM,
}];

export const VAT_VALIDATION_STATUS = {
    validated: 'VALIDATED',
    corrected: 'CORRECTED',
    pending: 'PENDING',
};

export const VAT_MATCH_STATUS = {
    match: 'MATCH',
    mismatch: 'MISMATCH',
};

export const emptyFunction = () => {};

export const TransactionStatus = {
    'BOOKED': 'BOOKED',
    'CANCELED': 'CANCELED',
    'PENDING': 'PENDING',
    'REJECTED': 'REJECTED',
    'RELEASED': 'RELEASED',
    'UPCOMING': 'UPCOMING',
};

export const TransactionSide = {
    CREDIT: 'CREDIT',
    DEBIT: 'DEBIT',
};

export const TransactionCategorizationWarningType = {
    'MissingArticles': 'MISSING_ARTICLES',
    'IncompleteArticleSetup': 'ARTICLE_SETUP_INCOMPLETE',
    'ArticleAmountMismatch': 'ARTICLE_AMOUNT_MISMATCH',
    'MissingDocuments': 'MISSING_DOCUMENTS',
    'MissingArticleDescription': 'MISSING_ARTICLE_DESCRIPTION',
    'MissingArticleCategory': 'MISSING_ARTICLE_CATEGORY',
    'MissingArticleTVA': 'MISSING_ARTICLE_TVA',
    'IncorrectArticleAmount': 'INCORRECT_ARTICLE_AMOUNT',
};

export const TransactionMatchType = {
    BEST_MATCH: 'BEST_MATCH',
    OTHER_MATCH: 'OTHER_MATCH',
};

export const categorizationState = {
    NOT_CATEGORIZED: 'NOT_CATEGORIZED',
    CATEGORIZED: 'CATEGORIZED',
};

export const CREDIT_CATEGORIZATION_TYPE = {
    REIMBURSEMENT_MATCHING: 'REIMBURSEMENT_MATCHING',
    EXTERNAL_INVOICES: 'EXTERNAL_INVOICES',
    OTHER: 'OTHER',
};

export const MAX_TRANSACTIONS_ANIMATION = 250;

export const TOP_OFFSET = 54;
export const ADMIN_TOP_OFFSET = 210;

export const TransactionDetailsSupportedPaymentTypes = [
    PAYMENT_TYPES.TRANSFER_IN,
    PAYMENT_TYPES.TRANSFER_OUT,
    PAYMENT_TYPES.DIRECT_DEBIT,
];

export const BANK_ACCOUNT_MISSING = 'missing-bank-account';
export const HIWAY_PRO_NAME = 'HIWAY PRO';

export const TRANSACTION_CATEGORIZATION_SCREENS = {
    CATEGORY: 'CATEGORY',
    DOCUMENT: 'DOCUMENT',
    TVA: 'TVA',
};

export const CATEGORY_MAGIC_SUBSCRIPTION_TYPE = {
    ONLY_THIS: 'ONLY_THIS',
    THIS_AND_FUTURE: 'THIS_AND_FUTURE',
    ALL: 'ALL',
};

export const CATEGORIZATION_SIDEBAR_TYPE = {
    INFO: 'INFO',
    SUBSCRIPTION: 'SUBSCRIPTION',
    SUBSCRIPTION_TRANSACTIONS: 'SUBSCRIPTION_TRANSACTIONS',
};

export const CATEGORIZATION_OVERLAY_TYPE = {
    ALL_CATEGORIES: 'ALL_CATEGORIES',
    ARTICLE_VIEW: 'ARTICLE_VIEW',
    INVOICE_MATCHING: 'INVOICE_MATCHING',
    REIMBURSEMENT_MATCHING: 'REIMBURSEMENT_MATCHING',
    EXTERNAL_INVOICES: 'EXTERNAL_INVOICES',
};

export const UNCATEGORIZED_FILTER_PARAM_NAME = 'uncategorized';

export const FILTER_ANCHORS = {
    DATES: 'dr',
    NON_CATEGIROZED: 'nonCategorized',
    TYPE: 'type',
};

export const IN_HEADER = 'inHeader';
