import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import PageHeader from '../../components/headers/PageHeader/PageHeader';
import ProfileChip from '../../components/profile-chip/ProfileChip';
import {LoggedInUserSelectors} from '../../features/user/modules/logged-in-user';
import {UpdateProfileForm} from '../../features/user/modules/logged-in-user/components/UpdateProfileForm';

export const MyProfileEditScreen = () => {
    const {t} = useTranslation('users');
    const user = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const content = useMemo(() => {
        if (!user) {
            return null;
        }

        return (
            <>
                <Box component="div" sx={{mt: {lg: 0, xs: 3}}}>
                    <PageHeader title={t('editProfile')} hasMargin={false} />
                </Box>
                {!!user && (
                    <>
                        <ProfileChip userId={user.id} />

                        <Paper elevation={0} sx={{borderRadius: 4}}>
                            <UpdateProfileForm user={user} />
                        </Paper>
                    </>
                )}
            </>
        );
    }, [t, user]);

    return (
        <Box sx={{px: {xs: 2, md: 1}}}>
            {content}
        </Box>
    );
};
