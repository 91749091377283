import {createSelector} from 'reselect';
import {createDataTableSortCallback, getPaginatedResult} from '../../../../utils/data-table-utils';

export const selectIsLoadingPersonalExpenses = state => state.expenses?.personalExpenses?.isLoading;

export const selectPersonalExpenses = state => state.expenses?.personalExpenses?.expenses ?? {};

export const createPersonalExpensesSelector = ({
    sortBy,
    sortDirection,
    pagination,
}) => createSelector(
    [selectPersonalExpenses],
    personalExpenses => {
        if (!Object.keys(personalExpenses).length) {
            return {data: [], count: 0};
        }

        const data = Object.values(personalExpenses);

        if (sortBy) {
            data.sort(createDataTableSortCallback(sortBy, sortDirection));
        }

        return getPaginatedResult(data, pagination);
    },
);
